<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.project", 2) }} {{ loading }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Projetos
                </h3>
            </template>
            <template v-slot:content>
                <div class="row mb-3" id="cliente">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Cliente:
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <Select2 v-model="filterRule.client" :options="clients" :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" @change="onClientChange" @select="onClientChange" />
                    </div>
                </div>
                <div class="row mb-3" id="client-team-leader">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Contato direto:
                        </label>
                    </div>
                    <div class="col-sm-4">
                        <Select2 v-model="filterRule.clientLeader" :options="clientTeamLeader" :settings="{ 
                                dropdownParent: '#client-team-leader', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <div class="row mb-3" id="company-team-leader">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Lider Técnico:
                        </label>
                    </div>
                    <div class="col-sm-4">
                        <Select2 v-model="filterRule.companyLeader" :options="companyTeamLeader" :settings="{ 
                                dropdownParent: '#company-team-leader', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <div class="row mb-3" id="state">
                    <div class="col-sm-2 text-right mt-2">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Estado:
                        </label>
                    </div>
                    <div class="col-sm-4 mt-2">

                        <app-dropdown v-model="filterRule.state" :items="states">
                        </app-dropdown>
                    </div>
                </div>
                <div class="row mb-3" id="contract">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Contrato:
                        </label>
                    </div>
                    <div class="col-sm-4">
                        <Select2 v-model="filterRule.contract" :options="contracts" :settings="{ 
                                dropdownParent: '#contract', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onFilterProject">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <a href="project/create" type="button" class="btn btn-success rounded-0 shadow" id="open"
                    v-if="visibility('services.project.project.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </a>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de contratos
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: project }">
                        <a href="javascript:;" @click="onDeleteProject(project.id)"
                            v-if="visibility('services.project.project.delete')"><i
                                class="fas fa-trash-alt red"></i></a>
                        &nbsp;
                        <a href="javascript:;" @click="onProjectDetail(project.id)"
                            v-if="visibility('services.project.detail')">
                            <i class="fas fa-eye orange"></i>
                        </a>
                        &nbsp;
                        <a :href="`project/${project.id}`" v-if="visibility('services.project.project.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                    <template v-slot:cell-active="{ row: project }">
                        <h5 v-if="project.state === 'Em andamento'"><span class="badge bg-success rounded-0">{{
                                project.state }}</span></h5>
                        <h5 v-else="!project.state === 'Finalizado'"><span class="badge bg-danger rounded-0">{{
                                project.state }}</span></h5>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->

<app-curtain right-curtain-name="project-detail" ref="projectDetail" card-class="card-default" card-title-class="mt-2"
    style-width-opened="87%">
    <template v-slot:title>
        Detalhe do projeto
    </template>
    <template v-slot:content>
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                <div class="row">
                    <div class="col-12 col-sm-3">
                        <div class="info-box bg-light">
                            <div class="info-box-content">
                                <span class="info-box-text text-center text-muted">Orçamento estimado</span>
                                <span class="info-box-number text-center text-muted mb-0">{{ detailInfo.estimatedBudget
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="info-box bg-light">
                            <div class="info-box-content">
                                <span class="info-box-text text-center text-muted">Valor total gasto</span>
                                <span class="info-box-number text-center text-muted mb-0">{{ detailInfo.totalAmountSpent
                                    }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="info-box bg-light">
                            <div class="info-box-content">
                                <span class="info-box-text text-center text-muted">Duração estimada do projeto</span>
                                <span class="info-box-number text-center text-muted mb-0">{{
                                    detailInfo.estimatedDuration }} Horas</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-3">
                        <div class="info-box bg-light">
                            <div class="info-box-content">
                                <span class="info-box-text text-center text-muted">Tempo utilizado</span>
                                <span class="info-box-number text-center text-muted mb-0">20 Horas</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h4>Indicadores</h4>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                <h3 class="text-primary"><i class="fas fa-paint-brush"></i> {{ detailInfo.projectName }}</h3>
                <p class="text-muted">
                    {{ detailInfo.projectDescription }}
                </p>
                <br>
                <div class="text-muted">
                    <p class="text-sm">Cliente
                        <b class="d-block">{{ detailInfo.projectClient }}</b>
                    </p>
                    <p class="text-sm">
                        Representate
                        <b class="d-block">{{ detailInfo.projectClientLeader }}</b>
                    </p>
                    <p class="text-sm">
                        Lider Técnico
                        <b class="d-block">{{ detailInfo.projectTeamLeader }}</b>
                    </p>
                </div>

                <h5 class="mt-5 text-muted">Arquivos do projeto</h5>
                <ul class="list-unstyled">
                    <li v-for="file in detailInfo.files" :key="file">
                        <a href="javascript:;" class="btn-link text-secondary"
                            @click="onFileDownload(file.id, fileName(file.file))"
                            v-if="visibility('project.file.download')">
                            <i class="fas fa-file"></i>
                            {{ fileName(file.file) }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </template>
</app-curtain>