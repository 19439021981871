import { CostCenterData, CostCenterObject } from "@/@types";

export type State = {
  costCenter: CostCenterObject | null;
  costCenterData: CostCenterData;
};

export const state: State = {
  costCenter: null,
  costCenterData: [],
};
