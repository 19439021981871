<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.bank", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar instituição bancária
                </h3>
            </template>
            <template v-slot:content>
                <app-input-default 
                    label-for="Nome" 
                    label="Banco" 
                    input-name="longname" 
                    input-type="text"
                    place-holder="Nome do Banco" 
                    v-model="formSearch.longName" 
                    input-id="text"
                >
                </app-input-default>
                <form action="javascript:;" @submit.prevent="onStore">
                    <app-right-curtain right-curtain-name="right" ref="form" card-class="card-default">
                        <template v-slot:title>
                            Formulário
                        </template>
                        <template v-slot:content>
                            <input type="hidden" name="id" v-model="formCreate.form.id" />
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="Compe" 
                                            label="Compe"
                                            input-name="compe" 
                                            input-type="text"
                                            place-holder="Compe" 
                                            v-model="v$.form.compe.$model"
                                            input-id="compe" 
                                            :has-error="v$.form.compe.$error"
                                            :is-active="v$.form.compe.$error" 
                                            :error="v$.form.compe.$errors"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                            label-for="ispb" 
                                            label="ISPB"
                                            input-name="ispb" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="v$.form.ispb.$model"
                                            input-id="text" 
                                            :has-error="v$.form.ispb.$error"
                                            :is-active="v$.form.ispb.$error" 
                                            :error="v$.form.ispb.$errors"
                                        >
                                        </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="CNPJ" 
                                            label="CNPJ"
                                            input-name="cnpj" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="v$.form.document.$model"
                                            input-id="cnpj" 
                                            :has-error="v$.form.document.$error"
                                            :is-active="v$.form.document.$error" 
                                            :error="v$.form.document.$errors"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                            label-for="longName" 
                                            label="Razão Social"
                                            input-name="longName" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="v$.form.longName.$model"
                                            input-id="longname" 
                                            :has-error="v$.form.longName.$error"
                                            :is-active="v$.form.longName.$error" 
                                            :error="v$.form.longName.$errors"
                                        >
                                        </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="shortName" 
                                            label="Nome Fantasia"
                                            input-name="shortName" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="v$.form.shortName.$model"
                                            input-id="text" 
                                            :has-error="v$.form.shortName.$error"
                                            :is-active="v$.form.shortName.$error" 
                                            :error="v$.form.shortName.$errors"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                            label-for="network" 
                                            label="Rede"
                                            input-name="network" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="formCreate.form.network"
                                            input-id="text" 
                                        >
                                        </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="datePixStarted" 
                                            label="Inicio das atividades do PIX"
                                            input-name="datePixStarted" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="formCreate.form.datePixStarted"
                                            input-id="text">
                                        </app-input-default>

                                       
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                            label-for="pixType" 
                                            label="Tipo de PIX"
                                            input-name="pixType" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="formCreate.form.pixType"
                                            input-id="text" 
                                        >
                                        </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1">Cobrança</label>
                                        <app-input-dropdown
                                            :items="inputDropDownChargePayload"
                                            v-model="formCreate.form.charge"
                                        >
                                        </app-input-dropdown>
                                    </div>
                                    <div class="col">
                                        <label for="exampleInputEmail1">Documento de Credito</label>
                                        <app-input-dropdown
                                            :items="inputDropDownCreditDocumentPayload"
                                            v-model="formCreate.form.creditDocument"
                                        >
                                        </app-input-dropdown>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="salaryPortability" 
                                            label="Portabilidade de salário"
                                            input-name="salaryPortability" 
                                            input-type="text"
                                            place-holder="Portabilidade de Salário" 
                                            v-model="formCreate.form.salaryPortability"
                                            input-id="text" >
                                        </app-input-default>
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                        label-for="type" 
                                        label="Tipo"
                                        input-name="type" 
                                        input-type="text"
                                        place-holder="" 
                                        v-model="formCreate.form.type"
                                        input-id="text" 
                                    >
                                    </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-input-default 
                                            label-for="url" 
                                            label="URL"
                                            input-name="url" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="formCreate.form.url"
                                            input-id="text">
                                        </app-input-default>
                                    </div>
                                    <div class="col">
                                        <app-input-default 
                                            label-for="dateOperationStarted" 
                                            label="Inicio das operações"
                                            input-name="dateOperationStarted" 
                                            input-type="text"
                                            place-holder="" 
                                            v-model="formCreate.form.dateOperationStarted"
                                            input-id="text">
                                        </app-input-default>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label for="exampleInputEmail1">Observação</label>
                                        <textarea 
                                            name="products" 
                                            class="form-control" 
                                            v-model="formCreate.form.products" 
                                            id="products" 
                                            cols="30" 
                                            rows="10"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <app-button 
                                type="submit" 
                                class="btn btn-default rounded-0"
                                :disabled="v$.form.$invalid"
                            >
                                <i class="fa fa-save"></i>
                                Gravar
                            </app-button>
                        </template>
                    </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button 
                    type="button" 
                    class="btn btn-default rounded-0" 
                    @click="onSearch"
                >
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0" id="open" 
                    @click="openForm()"
                    v-if="visibility('person.type.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de instituição bancária
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: type }">
                        <a href="javascript:;" @click="onDelete(type.id)" v-if="visibility('person.type.delete')">
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(type.id)" v-if="visibility('person.type.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->