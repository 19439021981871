import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Program",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
  },

  setup() {
    const gridColumns = {
      id: "",
      description: "Description",
    };
    const data: never[] = [];
    return { gridColumns, data };
  },
});
