<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.people") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <!-- ******************* START SEARCH ************************* -->
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar tipo de pessoa
                </h3>
            </template>
            <template v-slot:content>
                <app-input-default 
                    label-for="DescricaoLabel" 
                    label="Descrição" 
                    input-name="descriptionSearch"
                    input-type="text" 
                    place-holder="Nome do Tipo de pessoa (ex: Física ou Jurídica)"
                    v-model="peopleFormSearch.document" 
                    input-id="text"
                ></app-input-default>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" 
                            class="btn btn-default rounded-0" 
                            id="open" 
                            @click="onOpenForm()"
                            v-if="visibility('person.insert')"
                >
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>
        <!-- ******************* END SEARCH ************************* -->

        <!-- ******************* LIST RECORDS ************************* -->
        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de pessoas
                </h3>
            </template>
            <template v-slot:content>
                <app-table 
                    :columns="gridColumns" 
                    :rows="rows" 
                    table-class="table table-striped"
                >
                    <template v-slot:cell-id="{ row: person }">
                        <a href="javascript:;" @click="onEdit(person.id)" 
                            v-if="visibility('person.edit')"
                        >
                            <i class="far fa-edit blue"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onPersonStatus(person.id)"
                        v-if="visibility('person.change.status')"
                        >
                            <i class="fa fa-power-off orange"></i>
                        </a>  
                        &nbsp;
                        <i class="far fa-check-circle" style="color: #57c193;" v-if="person.asaas"></i>     
                        <i class="far fa-check-circle" style="color: #aba6a6;" v-if="!person.asaas"></i>              
                    </template>
                    <template v-slot:cell-active="{ row: active }">
                        <h5 v-if="active.active"><span class="badge bg-success rounded-0">Sim</span></h5>
                        <h5 v-else="!active.active"><span class="badge bg-danger rounded-0">Não</span></h5>
                    </template>
                    <template v-slot:cell-roles="{ row: roles }">
                        {{ concatArray(roles.roles) }}
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination 
                    :total-pages="totalPages" 
                    :total="total" 
                    :per-page="perPage" 
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
        <!-- ******************* END RECORDS ************************* -->

        <!-- ******************* START FORM ************************** -->
        <form action="javascript:;" @submit.prevent="onStore">
            <app-right-curtain 
                right-curtain-name="right" 
                ref="peopleCurtain" 
                card-class="card-default"
                style-width-opened="87%"
            >
                <template v-slot:title>
                    Formulário
                </template>
                <template v-slot:content>
                    <div class="row">
                        <div class="col-sm-12">
                            
                            <app-image-input 
                                @removepicture="onRemovePicture"
                                :url="imageUrl"
                                @input="updateFormPicture"
                            ></app-image-input>
                            <input type="hidden" name="id" v-model="peopleFormRegistration.id" />
                            <input type="hidden" name="asaas" v-model="peopleFormRegistration.asaas" />
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-sm text-center">
                            <div class="btn-group btn-group-toggle rounded-0" data-toggle="buttons">
                                <label 
                                    class="btn rounded-0 btn-secondary" 
                                    v-for="person in people" :key="person.acronym" 
                                    :class="{ active: pickedPerson == person.acronym}"
                                >
                                    <input 
                                        type="radio" 
                                        name="pessoa" 
                                        id="option1" 
                                        :value="person.acronym" 
                                        v-model="pickedPerson" 
                                        autocomplete="off"
                                        @click="onChoosedPerson(person.acronym)"
                                    /> 
                                    {{ person.name }}
                                </label>
                              </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-group" id="niches">
                                <label class="col-form-label" for="person" id="country">País</label>
                                <Select2 
                                    v-model="pv$.form.country.$model" 
                                    :options="countries"
                                    :settings="{ 
                                        dropdownParent: '#country', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }"
                                />
                            </div>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="CpfLabel" 
                                label="CPF" 
                                input-name="document"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.document.$model" 
                                input-id="document"
                                :has-error="pv$.form.document.$error" 
                                :is-active="pv$.form.document.$error"
                                :error="pv$.form.document.$errors" 
                                :maskara="mascara"
                                @keyup="getPeopleInfo()"
                                :readonly="international"
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="IdentityLabel" 
                                label="RG" 
                                input-name="identity" 
                                input-type="text"
                                place-holder="" 
                                v-model="peopleFormRegistration.form.identity"
                                input-id="text"
                                :readonly="international"
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Firstname" 
                                label="Nome" 
                                input-name="firstname"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.firstname.$model" 
                                input-id="firstname"
                                :has-error="pv$.form.firstname.$error" 
                                :is-active="pv$.form.firstname.$error"
                                :error="pv$.form.firstname.$errors" 
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Lastname" 
                                label="Sobrenome" 
                                input-name="lastname" 
                                input-type="text"
                                place-holder="" 
                                v-model="pv$.form.lastname.$model"
                                input-id="lastname"
                                :has-error="pv$.form.lastname.$error" 
                                :is-active="pv$.form.lastname.$error"
                                :error="pv$.form.lastname.$errors" 
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-group" id="niches">
                                <label for="person">Nicho</label>
                                <Select2 
                                    v-model="peopleFormRegistration.form.niche" 
                                    :options="niches"
                                    :settings="{ 
                                        dropdownParent: '#niches', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }"
                                />
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="form-group" id="role">
                                <label for="person">Papel</label>
                                <Select2 
                                    v-model="peopleFormRegistration.form.role" 
                                    :options="roles"
                                    :settings="{ 
                                        dropdownParent: '#role', 
                                        width: '100%',
                                        multiple: true,
                                        allowClear: true
                                    }"
                                />
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="col-sm">
                                <label for="Datebirth" id="Datebirth">Data de Nascimento</label>
                                <app-datepicker 
                                    v-model="peopleFormRegistration.form.dtOfBirth" 
                                    class="form-control rounded-0" 
                                    :locale="ptBR"
                                    inputFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Email" 
                                label="E-mail" 
                                input-name="email"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.email.$model" 
                                input-id="email"
                                :has-error="pv$.form.email.$error" 
                                :is-active="pv$.form.email.$error"
                                :error="pv$.form.email.$errors" 
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Phone" 
                                label="Phone" 
                                input-name="phone" 
                                input-type="text"
                                place-holder="" 
                                v-model="pv$.form.phone.$model"
                                input-id="phone"
                                :has-error="pv$.form.phone.$error" 
                                :is-active="pv$.form.phone.$error"
                                :error="pv$.form.phone.$errors" 
                                maskara="['(##) ####-####', '(##) #####-####']"
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <label for="infoObservation">Observação</label>
                            <textarea 
                                name="observarion" 
                                class="form-control rounded-0" 
                                placeholder="Informações que queira adicionar e que seja de relevância" 
                                id="" 
                                cols="30" 
                                rows="10"
                                v-model="peopleFormRegistration.form.observation"
                            ></textarea>
                        </div>
                    </div>
                    
                    <div class="bg-gray mt-3">
                        <h3>Localização</h3>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <app-input-default 
                                label-for="Zipcode" 
                                label="Zipcode" 
                                input-name="zipcode"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.zipcode.$model" 
                                input-id="zipcode"
                                :has-error="pv$.form.zipcode.$error" 
                                :is-active="pv$.form.zipcode.$error"
                                :error="pv$.form.zipcode.$errors" 
                                maskara="#####-###"
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Address" 
                                label="Address" 
                                input-name="address"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.address.$model" 
                                input-id="address"
                                :has-error="pv$.form.address.$error" 
                                :is-active="pv$.form.address.$error"
                                :error="pv$.form.address.$errors" 
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Number" 
                                label="Number" 
                                input-name="number"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.number.$model" 
                                input-id="number"
                                :has-error="pv$.form.number.$error" 
                                :is-active="pv$.form.number.$error"
                                :error="pv$.form.number.$errors" 
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Complement" 
                                label="Complement" 
                                input-name="complement"
                                input-type="text" 
                                place-holder=""
                                v-model="peopleFormRegistration.form.complement" 
                                input-id="text"
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="District" 
                                label="Bairro" 
                                input-name="district"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.district.$model" 
                                input-id="text"
                                :has-error="pv$.form.district.$error" 
                                :is-active="pv$.form.district.$error"
                                :error="pv$.form.district.$errors" 
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="State" 
                                label="UF" 
                                input-name="state"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.state.$model" 
                                input-id="state"
                                :has-error="pv$.form.state.$error" 
                                :is-active="pv$.form.state.$error"
                                :error="pv$.form.state.$errors" 
                                maxlength="2"
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="City" 
                                label="Cidade" 
                                input-name="city"
                                input-type="text" 
                                place-holder=""
                                v-model="pv$.form.city.$model" 
                                input-id="text"
                                :has-error="pv$.form.city.$error" 
                                :is-active="pv$.form.city.$error"
                                :error="pv$.form.city.$errors" 
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="bg-gray mt-1">
                        <h3>CONTATOS</h3>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <input type="hidden" v-model="contactId" />
                            <app-input-default 
                                label-for="Nome" 
                                label="Nome" 
                                input-name="contact_firstname"
                                input-type="text" 
                                place-holder=""
                                v-model="cv$.form.firstname.$model" 
                                input-id="contact_firstname"
                                :has-error="cv$.form.firstname.$error" 
                                :is-active="cv$.form.firstname.$error"
                                :error="cv$.form.firstname.$errors" 
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Lastname" 
                                label="Sobrenome" 
                                input-name="contact_lastnme"
                                input-type="text" 
                                place-holder=""
                                v-model="cv$.form.lastname.$model" 
                                input-id="contact_lastname"
                                :has-error="cv$.form.lastname.$error" 
                                :is-active="cv$.form.lastname.$error"
                                :error="cv$.form.lastname.$errors" 
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-input-default 
                                label-for="EMail" 
                                label="E-mail" 
                                input-name="contact_email"
                                input-type="email" 
                                place-holder=""
                                v-model="cv$.form.email.$model" 
                                input-id="contact_email"
                                :has-error="cv$.form.email.$error" 
                                :is-active="cv$.form.email.$error"
                                :error="cv$.form.email.$errors" 
                            ></app-input-default>
                        </div>
                        <div class="col-sm">
                            <app-input-default 
                                label-for="Phone" 
                                label="Phone" 
                                input-name="contact_phone"
                                input-type="text" 
                                place-holder=""
                                v-model="cv$.form.phone.$model" 
                                input-id="contact_phone"
                                :has-error="cv$.form.phone.$error" 
                                :is-active="cv$.form.phone.$error"
                                :error="cv$.form.phone.$errors" 
                                maskara="['(##) ####-####', '(##) #####-####']"
                            ></app-input-default>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <label for="exampleInputEmail1">Observação</label>
                            <textarea 
                                name="contact_observation" 
                                class="form-control rounded-0" 
                                id="" 
                                cols="30" 
                                rows="10"
                                v-model="peopleFormContact.form.observation"
                                placeholder="Informações de relevancia, como por ex: cargo, departamento e poder de decisão, etc..."
                            ></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <app-button 
                                type="button" 
                                class="btn btn-secondary rounded-0 mt-3 float-right"
                                @click="onAddContact"
                            >
                                <i class="fas fa-arrow-down"></i>
                                Adicionar
                            </app-button>
                        </div>
                    </div>
                    <hr>
                    
                    <table class="table table-striped table-responsive-sm">
                        <thead>
                          <tr>
                            <th scope="col" width="10%">#</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Observation</th>
                            <th scope="col">Active</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row, index) in contactArray" :key="index">
                            <td scope="row" v-if="row.email !== null">
                                
                                <i class="far fa-edit blue pointer" @click="onContactEdit(index)">&nbsp;</i>
                                <i class="fas fa-trash-alt red pointer" @click="onContactDelete(index)">&nbsp;</i>
                                <i class="fa fa-power-off orange pointer" @click="onContactStatus(index)">&nbsp;</i>
                            </td>
                            <td v-if="row.email !== null">{{ row.firstname }}</td>
                            <td v-if="row.email !== null">{{ row.lastname }}</td>
                            <td v-if="row.email !== null">{{ row.email }}</td>
                            <td v-if="row.email !== null">{{ row.phone }}</td>
                            <td v-if="row.email !== null">{{ row.observation }}</td>
                            <td v-if="row.email !== null">
                                <h5 v-if="row.active"><span class="badge bg-success rounded-0">Sim</span></h5>
                                <h5 v-else="!row.active"><span class="badge bg-danger rounded-0">Não</span></h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </template>
                <template v-slot:footer>
                    <app-button type="submit" class="btn btn-success rounded-0">
                        <i class="fa fa-save"></i>
                        Gravar
                    </app-button>
                </template>
            </app-right-curtain>
        </form>
        <!-- ******************* END FORM ************************** -->
    </div>
</section>