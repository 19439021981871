<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.user") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar usuário
                </h3>
            </template>
            <template v-slot:content>
                <Select2 
                    v-model="formSearch.company" 
                    :options="companies"
                    :settings="{ allowClear: true, settingOption: value }" 
                    @change="myChangeEvent($event)"
                    @select="selected($event)" />
            </template>
            <template v-slot:footer>
                <app-button 
                    type="button" 
                    class="btn btn-default rounded-0" 
                    @click="onSearch"
                >
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0" @click="openForm" v-if="visibility('user.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de usuário
                </h3>
            </template>
            <template v-slot:content>
                <app-table 
                    :columns="gridColumns" 
                    :rows="rows" 
                    table-class="table table-striped"
                >
                <template v-slot:cell-id="{ row: type }">
                    <a 
                        href="javascript:;" @click="onDelete(type.id)"
                        v-if="visibility('user.delete')"
                    >
                        <i class="fas fa-trash-alt red"></i>
                    </a>
                    &nbsp;
                    <a href="javascript:;" @click="onEdit(type.id)" v-if="visibility('user.edit')">
                        <i class="far fa-edit blue"></i>
                    </a>
                    &nbsp;
                    <a href="javascript:;" @click="onStatus(type.contact)" v-if="visibility('user.status')">
                        <i class="fa fa-power-off orange"></i>
                    </a>                        
                </template>
                <template v-slot:cell-active="{ row: active }">
                    <h5 v-if="active.active === 'Y'"><span class="badge bg-success rounded-0">Sim</span></h5>
                    <h5 v-else="active.active"><span class="badge bg-danger rounded-0">Não</span></h5>
                </template>
            </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination 
                    :total-pages="totalPages" 
                    :total="total" 
                    :per-page="perPage" 
                    :current-page="currentPage"
                    @pagechanged="onPageChange">
                </app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->

    <form action="javascript:;" @submit.prevent="onStore">
        <app-right-curtain right-curtain-name="right" ref="usercurtain" card-class="card-default">
            <template v-slot:title>
                Formulário
            </template>
            <template v-slot:content>
                <input type="hidden" name="id" v-model="formCreate.form.id" />
                <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <div class="form-group" id="empresa">
                            <label for="person" class="col-form-label">Empresa</label>
                            <Select2 
                                v-model="v$.form.company.$model" 
                                :options="companies"
                                :settings="{ 
                                    dropdownParent: '#empresa', 
                                    width: '100%', 
                                    allowClear: true,
                                    placeholder:'Selecione uma empresa',
                                    language: 'ptBR'
                                }"
                                @select="getContacts()"
                            />
                            <span v-if="checkArray(v$.form.company.$errors)">
                                <small 
                                    id="emailHelp" 
                                    class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.company.$errors" 
                                    :key="index"
                                >
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-sm">
                            <div class="form-group" id="usuario">
                                <label for="homepage" class="col-form-label">Usuário</label>
                                <Select2 
                                    v-model="v$.form.contact.$model" 
                                    :options="contacts"
                                    :settings="{ 
                                        dropdownParent: '#usuario', 
                                        width: '100%',
                                        allowClear: true,
                                        placeholder:'Selecione um usuário',
                                        language: 'ptBR'
                                    }"
                                    @select="validateForm('contact')"
                                />
                                <span v-if="checkArray(v$.form.contact.$errors)">
                                    <small 
                                        id="emailHelp" 
                                        class="form-text text-danger"
                                        v-for="(errorMessage, index) of v$.form.contact.$errors" 
                                        :key="index"
                                    >
                                        {{ errorMessage.$message }}
                                    </small>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm">
                          <div class="form-group" id="grupo">
                              <label for="person" class="col-form-label">Grupo</label>
                              <Select2 
                                v-model="v$.form.roles.$model" 
                                :options="roles"
                                :settings="{ 
                                    dropdownParent: '#grupo', 
                                    width: '100%',
                                    allowClear: true,
                                    multiple: true
                                }"
                            />
                            <span v-if="checkArray(v$.form.roles.$errors)">
                                <small 
                                    id="emailHelp" 
                                    class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.roles.$errors" 
                                    :key="index"
                                >
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                          </div>
                        </div>
                    </div>
                    <hr>
                    <h6>Pagina de acesso inicial</h6>
                    <small class="text-danger">Quando o usuário fazer login, é para essa tela que ele será direcionado!</small>
                    <hr>
                    <div id="page-permission-container" style="font-size: 1rem!important;"></div>
                    <span v-if="checkArray(v$.form.homepage.$errors)">
                        <small 
                            id="emailHelp" 
                            class="form-text text-danger mt-3"
                            v-for="(errorMessage, index) of v$.form.homepage.$errors" 
                            :key="index"
                        >
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                  </div>
            </template>
            <template v-slot:footer>
                <app-button 
                    type="submit" 
                    class="btn btn-default rounded-0"
                >
                    <i class="fa fa-save"></i>
                    Gravar
                </app-button>
            </template>
        </app-right-curtain>
    </form>
</section>
<!-- /.content -->