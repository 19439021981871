/* eslint-disable */
import Dropdown from "@/components/form/dropdown/dropdown.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { PeopleActionTypes } from "@/store/admin/person/people/action-types";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "PersonContact",
  components: {
    "app-panel": Panel,
    "input-dropdown": Dropdown
  },
  setup(props, ctx) {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const contacts = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const companies = ref([]);

    const exampleItems = [...Array(150).keys()].map((i) => ({
      id: i + 1,
      name: "Item " + (i + 1),
    }));

    const company: any = ref("");
    const apiUrl = ref(process.env.VUE_APP_API_URL);

    onMounted(() => {
      getContacts();
      getCompany();
    });

    const concatArray = (value: any) => {
      if (value !== undefined) {
        const roles = JSON.parse(value);
        return roles.join(",");
      }
    };

    const onChangePage = () => {
      return;
    };

    const getContacts = () => {
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        company: company.value,
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(PeopleActionTypes.GET_CONTACT_PAGING, params).then((data) => {
        contacts.value = data.data;
        loader.hide();
      })
      .catch(({ error }) => {
        loader.hide();
      });
    };

    const getImage = (id: any, path: any) => {
      let image: any = "";
      const url: any = process.env.VUE_APP_API_DOMAIN + '/assets/img/person/' + id + '/';
      if(path !== undefined && path !== '' && path !== 'null' && path !== null){
        image = url + path;
      } else {
        image = require('@/assets/img/default-profile.png');
      }
      return image;
    };

    const getCompany = async () => {
      try {
        await store.dispatch(PeopleActionTypes.GET_ALL_COMPANIES).then((data) => {
          companies.value = data;
        });
      } catch (err) {
        return;
      }
    };

    const selected = () => {
      getContacts();
    }

    const changed = () => {
      getContacts();
    }
    
    return {
      getContacts,
      contacts,
      exampleItems,
      onChangePage,
      concatArray,
      apiUrl,
      getImage,
      companies,
      company,
      selected,
      changed,
    };
  },
});
