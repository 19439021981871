<!-- Navbar -->
<nav id="main-header" class="main-header navbar navbar-expand" :class="navbarVariant">
    <ul class="navbar-nav">
        <li class="nav-item">
            <button class="nav-link" role="button" @click="onToggleMenuSidebar">
                <i class="fas fa-bars"></i>
            </button>
        </li>
        <li class="nav-item d-none d-sm-inline-block" v-if="visibility('general.dashboard')">
            <router-link to="/" class="nav-link">
                {{ $t("labels.home") }}
            </router-link>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <router-link to="/registration/contact" class="nav-link" v-if="visibility('person.list.paginated.contact')">
                {{ $t("labels.contact") }}
            </router-link>
        </li>
    </ul>

    <ul class="navbar-nav ml-auto">
        <support-helpdesk></support-helpdesk>
        <messages-dropdown></messages-dropdown>
        <notifications-dropdown></notifications-dropdown>
        <languages-dropdown></languages-dropdown>
        <user-dropdown></user-dropdown>
        <li class="nav-item">
            <button class="nav-link" @click="onToggleControlSidebar">
                <i class="fas fa-th-large"></i>
            </button>
        </li>
    </ul>
</nav>