/* eslint-disable */
import { State as UserSignInWithCredentialsState, store as userSignInCredentials,  UserSignInWithCredentialsStore, } from "@/store/auth";
import { PersonTypeStore, State as PersonTypeState, store as personType, } from "@/store/admin/person/type";
import { RoleStore, State as RoleState, store as role,} from "@/store/admin/person/roles";
import { UserStore, State as UserState, store as user,} from "@/store/admin/person/user";
import { MenuStore, State as MenuState, store as menu,} from "@/store/admin/menu";
import { PeopleStore, State as PeopleState, store as people, } from "@/store/admin/person/people";
import { IntegrationStore, State as IntegrationState, store as integration, } from "@/store/admin/integration";
import { SystemInformationStore, State as SystemInformationState, store as systemInformation, } from "@/store/admin/system-info";
import { AccessLogStore, State as AccessLogState, store as accessLog, } from "@/store/admin/log/access";
import { NicheStore, State as NicheState, store as niche, } from "@/store/admin/person/niche";
import { CountryStore, State as CountryState, store as country, } from "@/store/admin/person/country";
/* FINANCE */
import { AccountPlanStore, State as AccountPlanState, store as accountPlan, } from "@/store/finance/accountplan";
import { CostCenterStore, State as CostCenterState, store as costCenter, } from "@/store/finance/costcenter";
import { FinanceAccountStatusStore, State as FinanceAccountStatusState, store as financeAccountStatus, } from "@/store/finance/status";
import { FinanceBankAccountTypeStore, State as FinanceBankAccountTypeState, store as financeBankAccountType, } from "@/store/finance/bankaccounttype";
import { FinanceBanksStore, State as FinanceBankState, store as financeBank } from "@/store/finance/bank";
import { FinanceMovementStore, State as FinanceMovementState, store as financeMovement } from "@/store/finance/movement";
import { FinanceBankAccountStore, State as FinanceBankAccountState, store as financeBankAccount, } from "@/store/finance/bankaccount";
import { FinanceAccountStore, State as FinanceAccountState, store as financeAccount, } from "@/store/finance/account";
import { FinanceDashboardStore, State as FinanceDashboardState, store as financeDashboard, } from "@/store/finance/dashboard";
/* SERVICE */
import { ServiceTypeStore, State as ServiceTypeState, store as serviceType, } from "@/store/services/type";
import { ServiceStore, State as ServiceState, store as service, } from "@/store/services/service";
import { ContractTypeStore, State as ContractTypeState, store as contractType, } from "@/store/services/contract-type";
import { ContractStore, State as ContractState, store as contract, } from "@/store/services/contract";
import { InvoiceStore, State as InvoiceState, store as invoice, } from "@/store/services/invoice";
/* ISSUE */
import { IssueCategoryStore, State as IssueCategoryState, store as issueCategory, } from "@/store/issue/category";
import { IssueStateStore, State as IssueStateState, store as issueState, } from "@/store/issue/state";
import { IssuePriorityStore, State as IssuePriorityState, store as issuePriority, } from "@/store/issue/priority";
import { IssueProblemTypeStore, State as IssueProblemTypeState, store as issueProblemType, } from "@/store/issue/problem-type";
import { IssueSolutionTypeStore, State as IssueSolutionTypeState, store as issueSolutionType, } from "@/store/issue/solution-type";
import { IssueStore, State as IssueState, store as issue, } from "@/store/issue/issue";
import { IssueTaskStore, State as IssueTaskState, store as issueTask, } from "@/store/issue/task";
/* PROJECT */
import { ProjectStateStore, State as ProjectStateState, store as projectState, } from "@/store/project/state";
import { ProjectStore, State as ProjectState, store as project, } from "@/store/project/project";

import { createLogger, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import uiModule from "./ui";

export type RootState = {
  userSignInWithCredentials: UserSignInWithCredentialsState;
  integration: IntegrationState;
  personType: PersonTypeState;
  role: RoleState;
  user: UserState;
  menu: MenuState;
  people: PeopleState;
  systemInfo: SystemInformationState;
  accessLog: AccessLogState;
  accountPlan: AccountPlanState;
  costCenter: CostCenterState;
  financeAccountStatus: FinanceAccountStatusState;
  financeBankAccountType: FinanceBankAccountTypeState;
  financeBank: FinanceBankState;
  financeMovement: FinanceMovementState;
  financeBankAccount: FinanceBankAccountState;
  financeAccount: FinanceAccountState;
  financeDashboard: FinanceDashboardState;
  serviceType: ServiceTypeState;
  service: ServiceState;
  contractType: ContractTypeState;
  contract: ContractState;
  niche: NicheState;
  country: CountryState;
  invoice: InvoiceState;
  issueCategory: IssueCategoryState;
  issueState: IssueStateState;
  issuePriority: IssuePriorityState;
  projectState: ProjectStateState;
  project: ProjectState;
  issue: IssueState;
  issueProblemType: IssueProblemTypeState;
  issueSolutionType: IssueSolutionTypeState;
  issueTask: IssueTaskState;

  /*
  section: SectionState;
  group: GroupState;
  user: UserState;
  log: LogState */
};

export type Store = UserSignInWithCredentialsStore<
  Pick<RootState, "userSignInWithCredentials">
> &
  IntegrationStore<Pick<RootState, "integration">> &
  PersonTypeStore<Pick<RootState, "personType">> &
  RoleStore<Pick<RootState, "role">> &
  UserStore<Pick<RootState, "user">> &
  MenuStore<Pick<RootState, "menu">> &
  PeopleStore<Pick<RootState, "people">> &
  SystemInformationStore<Pick<RootState, "systemInfo">> &
  AccessLogStore<Pick<RootState, "accessLog">> &
  AccountPlanStore<Pick<RootState, "accountPlan">> &
  CostCenterStore<Pick<RootState, "costCenter">> &
  FinanceAccountStatusStore<Pick<RootState, "financeAccountStatus">> &
  FinanceBankAccountTypeStore<Pick<RootState, "financeBankAccountType">> &
  FinanceBanksStore<Pick<RootState, "financeBank">> &
  FinanceMovementStore<Pick<RootState, "financeMovement">> &
  FinanceBankAccountStore<Pick<RootState, "financeBankAccount">> &
  FinanceAccountStore<Pick<RootState, "financeAccount">> & 
  FinanceDashboardStore<Pick<RootState, "financeDashboard">> & 
  ServiceTypeStore<Pick<RootState, "serviceType">> &
  ServiceStore<Pick<RootState, "service">> &
  ContractTypeStore<Pick<RootState, "contractType">> &
  ContractStore<Pick<RootState, "contract">> &
  NicheStore<Pick<RootState, "niche">> &
  CountryStore<Pick<RootState, "country">> &
  InvoiceStore<Pick<RootState, "invoice">> &
  IssueCategoryStore<Pick<RootState, "issueCategory">> &
  IssueStateStore<Pick<RootState, "issueState">> &
  IssuePriorityStore<Pick<RootState, "issuePriority">> &
  ProjectStateStore<Pick<RootState, "projectState">> &
  ProjectStore<Pick<RootState, "project">> &
  IssueProblemTypeStore<Pick<RootState, "issueProblemType">> &
  IssueSolutionTypeStore<Pick<RootState, "issueSolutionType">> &
  IssueStore<Pick<RootState, "issue">> & 
  IssueTaskStore<Pick<RootState, "issueTask">>;
/*&
  ProgramStore<Pick<RootState, 'program'>> &
  SectionStore<Pick<RootState, 'section'>> &
  GroupStore<Pick<RootState, 'group'>> &
  UserStore<Pick<RootState, 'user'>> & 
  AccessLogStore<Pick<RootState, 'log'>> */

const debug = process.env.NODE_ENV != "production";
const plugins = debug ? [createLogger({})] : [];

plugins.push((store) => {
  // Save the state to sessionStorage
  window.addEventListener('beforeunload', () => {
    sessionStorage.setItem('vuex', JSON.stringify(store.state));
  });

  // Optionally, you can also load the state from sessionStorage on page load
  const storedState = sessionStorage.getItem('vuex');
  if (storedState) {
    store.replaceState(JSON.parse(storedState));
  }
});

export const store = createStore({
  plugins,
  modules: {
    userSignInCredentials,
    integration,
    personType,
    role,
    user,
    menu,
    people,
    systemInformation,
    accessLog,
    accountPlan,
    costCenter,
    financeAccountStatus,
    financeBankAccountType,
    financeBank,
    financeMovement,
    financeBankAccount,
    financeAccount,
    financeDashboard,
    serviceType,
    service,
    contractType,
    contract,
    niche,
    country,
    invoice,
    issueCategory,
    issueState,
    issuePriority,
    projectState,
    project,
    issue,
    issueProblemType,
    issueSolutionType,
    issueTask,
    /*  program,
      section,
      group,
      user,
      log, */
    ui: uiModule,
  },
});

export function useStore(): Store {
  return store as Store;
}
