/* eslint-disable */
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { SystemInformationActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [SystemInformationActionTypes.GET_SYSTEM_INFORMATION](): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [SystemInformationActionTypes.GET_SYSTEM_INFORMATION]() {
    return new Promise<boolean>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/info").then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
