import { ContractTypeData, ContractTypeObject } from "@/@types";

export type State = {
  data: ContractTypeObject | null;
  contractTypeData: ContractTypeData;
};

export const state: State = {
  data: null,
  contractTypeData: [],
};
