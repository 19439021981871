/* eslint-disable */
import { FinanceBanksObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceBankActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceBankActionTypes.FINANCE_BANK_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [FinanceBankActionTypes.FINANCE_BANK_CREATE]( { commit }: AugmentedActionContext, params: FinanceBanksObject): Promise<boolean>;
  [FinanceBankActionTypes.FINANCE_BANK_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [FinanceBankActionTypes.FINANCE_BANK_UPDATE]( { commit }: AugmentedActionContext, params: FinanceBanksObject ): Promise<any>;
  [FinanceBankActionTypes.FINANCE_BANK_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceBankActionTypes.FINANCE_BANK_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        longName: params.longName
      }
      ApiService.post(`/finance/banks/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankActionTypes.FINANCE_BANK_CREATE]({ commit }, params: FinanceBanksObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        compe               : params.compe,
        ispb                : params.ispb,
        document            : params.document,
        longName            : params.longName,
        shortName           : params.shortName,
        network             : params.network,
        type                : params.type,
        pixType             : params.pixType,
        charge              : params.charge,
        creditDocument      : params.creditDocument,
        salaryPortability   : params.salaryPortability,
        products            : params.products,
        url                 : params.url,
        dateOperationStarted: params.dateOperationStarted,
        datePixStarted      : params.datePixStarted,
        dateRegistered      : params.dateRegistered,
        dateUpdated         : params.dateUpdated,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/finance/banks", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankActionTypes.FINANCE_BANK_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/banks/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankActionTypes.FINANCE_BANK_UPDATE]({ commit }, params: FinanceBanksObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        compe               : params.compe,
        ispb                : params.ispb,
        document            : params.document,
        longName            : params.longName,
        shortName           : params.shortName,
        network             : params.network,
        type                : params.type,
        pixType             : params.pixType,
        charge              : params.charge,
        creditDocument      : params.creditDocument,
        salaryPortability   : params.salaryPortability,
        products            : params.products,
        url                 : params.url,
        dateOperationStarted: params.dateOperationStarted,
        datePixStarted      : params.datePixStarted,
        dateRegistered      : params.dateRegistered,
        dateUpdated         : params.dateUpdated,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/finance/banks/${params.id}`, payload).then(({ data }) => {
          const result = data.data;
          resolve(result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [FinanceBankActionTypes.FINANCE_BANK_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/banks/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
