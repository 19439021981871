<curtain 
    right-curtain-name="issue-viwer" 
    ref="issueViewerCurtain" 
    card-class="card-secondary"
    style-width-opened="87%"
>
    <template v-slot:title>
        Detalhe do chamado <b>#{{ datas.id }}</b>
    </template>
    <template v-slot:content>

        <div class="form-group row">
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Número:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        # {{ datas.id }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4"></div>
            <div class="col-sm-4"></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Data de abertura:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.openingDate }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Início de atendimento:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.start_date }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Data de Encerramento:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.closingDate }}
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Solicitante:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.requestedBy }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Horas Usadas:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.hours }} H
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Situação:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        <div class="label" :style="`background-color: ${datas.situationColor};`">
                            {{ datas.situation }}
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Projeto:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.project }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Categoria:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.category }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Prioridade:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.priorityText }}
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Atendente:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.attendant }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Tipo de problema:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.problemType }}
                    </span>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="issue-div-view">
                    <label class="issue-label-view">
                        Tipo da solução:
                    </label>
                </div>
                <div class="issue-div-content">
                    <span class="issue-span-content">
                        {{ datas.solution }}
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <div style="display: inherit;vertical-align:top;;width: 100%">
                    <label style="color: #607D8B;font-size: 16px;font-weight: bold;width: 100%;">
                        <i class="far fa-comment-dots" style="color: #607D8B;"></i>
                        Observações:
                    </label>
                </div>
            </div>
            <div class="col-sm-6" style="min-height: 26px;">
                <div style="vertical-align:top;float:left;width: 100%;padding-right: 10px;">
                    <label style="font-size: 14px;font-weight: bold;width: 100%;color: blue;">
                        Abertura
                    </label>
                </div>
                <div style="vertical-align:top;float:left">
                    <span style="font-size:14px">
                        {{ datas.observation }}
                    </span>
                </div>
            </div>
            <div class="col-sm-6" style="min-height: 26px;">
                <div style="vertical-align:top;float:left;width: 100%;padding-right: 10px;">
                    <label style="font-size: 14px;font-weight: bold;width: 100%;color: blue;">
                        Encerramento
                    </label>
                </div>
                <div style="vertical-align:top;float:left">
                    <span style="font-size:14px">
                        {{ datas.finalObservation }}
                    </span>
                </div>
            </div>
            <div class="col-sm-12" style="min-height:26px">
                <div style="display: inherit;vertical-align:top;;width: 100%">
                    <label style="font-size: 12px;width: 100%;">&nbsp;</label>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12" style="min-height:26px">
                <div style="display: inherit;vertical-align:top;width:100%">
                    <app-tab :tabs="tabs">
                        <template #tab1>
                            <app-button 
                                type="button" 
                                class="btn btn-default rounded-0 shadow"
                                @click="openNoteForm(datas.id)" 
                                v-if="(datas.closingDate === null)"
                            >
                                <i class="far fa-comment-dots orange"></i>
                                Adicionar nota
                            </app-button>

                            <!-- Timelime  -->
                            <div class="container-full">
                                <div class="container" v-for="(item, index) in notes" :key="index">
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <h5 style="margin-left: 50px;">
                                            {{ index }}
                                        </h5>
                                    </div>
                                </div>
                                <span v-for="values in item" :key="values.name">
                                    
                                <div class="row" v-if="values.role==='Webmaster'">
                                    <div class="col-sm-7">
                                      <panel-secondary class="card-success">
                                        <template #header>
                                            <i class="fas fa-headset"></i>
                                            {{ values.nome }}
                                            <div class="float-right">
                                                <i class="fas fa-clock"></i>
                                                {{ values.time }}
                                            </div>
                                        </template>
                                        <template #content>
                                            {{ values.note }}
                                            <hr v-if="(values.files.length > 0)">
                                            <p v-for="files in values.files" :key="files.file">
                                                <a href="javascript:;" @click="openNoteFile(files.id)"> 
                                                    {{ fileName(files.file) }} 
                                                </a>
                                            </p>
                                        </template>
                                      </panel-secondary>
                                    </div>
                                    <div class="col-sm"></div>
                                </div>
                                
                                <div class="row" v-if="values.role==='Cliente'">
                                    <div class="col-sm"></div>                                      
                                    <div class="col-sm-7">
                                        <panel-secondary class="card-danger">
                                            <template #header>
                                                <i class="fas fa-user"></i>
                                                {{ values.nome }}
                                                <div class="float-right">
                                                    <i class="fas fa-clock"></i>
                                                    {{ values.time }}
                                                </div>
                                            </template>
                                            <template #content>
                                                {{ values.note }}
                                                <hr v-if="(values.files.length > 0)">
                                                <p v-for="files in values.files" :key="files.file">
                                                    <a href="javascript:;" @click="openNoteFile(files.id)"> 
                                                        {{ fileName(files.file) }}
                                                    </a>
                                                </p>
                                            </template>
                                        </panel-secondary>
                                    </div>
                                </div>
                            </span>
                                </div>
                            </div>  
                            <!-- /Timeline -->
                        </template>
                        <template #tab2>
                            <p v-for="item in files" :key="item.id">
                                <a href="javascript:;" @click="openIssueFile(item.id)"> 
                                    {{ fileName(item.file) }}
                                </a>
                            </p>
                        </template>
                        <template #tab3 v-if="visibility('services.issue.task.list')">
                            <todo-list 
                                :data="todoListData" 
                                :initialData="taskForm"
                                :visible="todoListSituation"
                                @onTaskSave="onTaskStore"
                                @onTaskEdit="onTaskEdit"
                                @onTaskRemove="onTaskRemove"
                                @onHandleCheckboxChange="onHandleCheckboxChange"
                                @onReOrder="tastOrder"
                            ></todo-list>
                        </template>
                    </app-tab>
                </div>
            </div>
        </div>
    </template>
    <template v-slot:footer>
        
    </template>
</curtain>

<note-form ref="issueNoteForm" @reload="getNotes" />