<form action="javascript:;" @submit.prevent="onStore">
    <issue-form-edit 
        right-curtain-name="right" 
        ref="editFormCurtain" 
        card-class="card-secondary"
        style-width-opened="87%"
    >
        <template v-slot:title>
            {{ formTitle }}
        </template>
        <template v-slot:content>
            <div class="row">
                
                <div class="col-sm">
                    
                    <s-input 
                        label-for="Número" 
                        label="Número" 
                        input-name="numero"
                        input-type="text" 
                        place-holder="" 
                        readonly="true"
                        v-model="form.form.id"
                        input-id="text" 
                    ></s-input>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Data de abertura:</label>
                        <VueDatePicker 
                            v-model="form.form.openingDate"
                            :format="format" 
                            locale="pt-BR" 
                            auto-apply 
                            :enable-time-picker="true"
                            readonly
                            class="issue-readonly"
                        >
                        </VueDatePicker>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Data de início:</label>
                        <VueDatePicker 
                            v-model="form.form.startDate"
                            :format="format" 
                            locale="pt-BR" 
                            auto-apply
                            readonly
                            :enable-time-picker="true"
                        >
                        </VueDatePicker>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Data de conclusão:</label>
                        <VueDatePicker 
                            v-model="form.form.closingDate"
                            :format="format" 
                            locale="pt-BR" 
                            auto-apply 
                            :enable-time-picker="true"
                            readonly
                            class="issue-readonly"
                        >
                        </VueDatePicker>
                    </div>
                </div>
                <div class="col-sm">
                    
                    <s-input 
                        label-for="TempoDespendido" 
                        label="Horas Usadas" 
                        input-name="horas"
                        input-type="number" 
                        place-holder="" 
                        :readonly="false"
                        v-model="v$.form.usedTime.$model"
                        input-id="usedtext" 
                    ></s-input>
                    <span v-if="checkArray(v$.form.usedTime.$errors)">
                        <small 
                            id="emailHelp" 
                            class="form-text text-danger"
                            v-for="(errorMessage, index) of v$.form.usedTime.$errors" 
                            :key="index"
                        >
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group" id="cliente">
                        <label for="exampleInputEmail1" class="col-form-label">Cliente:</label>
                        <Select2 
                            v-model="v$.form.client.$model" 
                            :options="clients"
                            :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true,
                                disabled: 'readonly'
                            }" 
                            @change="onClienteSelected"
                            @select="onClienteSelected"
                        />
                        <span v-if="checkArray(v$.form.client.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.client.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="contract-product">
                        <label for="exampleInputEmail1" class="col-form-label">Contratos - Projeto:</label>
                        <Select2 
                            v-model="v$.form.project.$model" 
                            :options="projects"
                            :settings="{ 
                                dropdownParent: '#contract-product', 
                                width: '100%',
                                multiple: false,
                                allowClear: true,
                                disabled: 'readonly'
                            }"
                           
                        />
                        <span v-if="checkArray(v$.form.project.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.project.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="category">
                        <label for="exampleInputEmail1" class="col-form-label">Categoria:</label>
                        <Select2 
                            v-model="v$.form.category.$model" 
                            :options="categories"
                            :settings="{ 
                                dropdownParent: '#category', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                        />
                        <span v-if="checkArray(v$.form.category.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.category.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group" id="priority">
                        <label for="exampleInputEmail1" class="col-form-label">Prioridade:</label>
                        <Select2 
                            v-model="v$.form.priority.$model" 
                            :options="priorities"
                            :settings="{ 
                                dropdownParent: '#priority', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                        />
                        <span v-if="checkArray(v$.form.priority.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.priority.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="situation">
                        <label for="exampleInputEmail1" class="col-form-label">Situação:</label>
                        <Select2 
                            v-model="v$.form.situation.$model" 
                            :options="states"
                            :settings="{ 
                                dropdownParent: '#situation', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                            @select="situationSelectEvent($event)"
                        />
                        <span v-if="checkArray(v$.form.situation.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.situation.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Observação:</label>
                        <textarea 
                            name="" 
                            class="form-control rounded-0   " 
                            id="" 
                            cols="30" 
                            rows="10"
                            v-model="v$.form.observation.$model" 
                            readonly
                        ></textarea>
                        <span v-if="checkArray(v$.form.observation.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.observation.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group" id="problem-types">
                        <label for="exampleInputEmail1" class="col-form-label">Tipo de problema:</label>
                        <Select2 
                            v-model="v$.form.problemType.$model" 
                            :options="problemTypes"
                            :settings="{ 
                                dropdownParent: '#problem-types', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" 
                        />
                        <span v-if="checkArray(v$.form.problemType.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.problemType.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="solution-type">
                        <label for="exampleInputEmail1" class="col-form-label">Tipo da solução:</label>
                        <Select2 
                            v-model="v$.form.solutionType.$model" 
                            :options="solutionTypes"
                            :settings="{ 
                                dropdownParent: '#solution-type', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                        />
                        <span v-if="checkArray(v$.form.solutionType.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.solutionType.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="recurring">
                        <label for="exampleInputEmail1" class="col-form-label">Recorrente:</label>
                        <div class="col">
                            <s-radio-button 
                                :options="radioOptions" 
                                v-model="form.form.recurring"
                                div-class=""
                            ></s-radio-button>
                        </div>
                        
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="user">
                        <label for="exampleInputEmail1" class="col-form-label">Atendentes:</label>
                        <Select2 
                            v-model="v$.form.user.$model" 
                            :options="users"
                            :settings="{ 
                                dropdownParent: '#user', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" 
                        />
                        <span v-if="checkArray(v$.form.user.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.user.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Observação de finalização:</label>
                        <textarea 
                            name="" 
                            class="form-control rounded-0" 
                            id="" 
                            v-model="v$.form.finalObservation.$model"
                            cols="30" 
                            rows="10"
                        ></textarea>
                        <span v-if="checkArray(v$.form.finalObservation.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.finalObservation.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="arquivos" class="col-form-label">Arquivo:</label>
                        <div class="input_container">
                            <input type="file" id="fileUpload" multiple ref="fileInput"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <app-button 
                type="submit" 
                class="btn btn-default rounded-0 shadow" 
            >
                <i class="fa fa-save"></i>
                Gravar
            </app-button>
        </template>
    </issue-form-edit >
</form>
