import { defineComponent } from "vue";

export default defineComponent({
  name: "Grid",
  props: {
    columns: {
      type: Object,
      required: true,
    },
    rows: {
      type: Object,
    },
    tableClass: {
      type: String,
    },
    theadClass: {
      type: String,
    },
    thrClass: {
      type: String,
    },
    thClass: {
      type: String,
    },
    tbodyClass: {
      type: String,
    },
    tbrClass: {
      type: String,
    },
    tdClass: {
      type: String,
    },
  },
  setup() {
    const sortColumn = "";
    return { sortColumn };
  },
});
