import {
  UserCredentials,
  UserSignInWithCredentials,
} from "@/@types/app/SignInWithCredentials";
import { MutationTree } from "vuex";
import { SignInMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [SignInMutationTypes.SetSignInData](
    state: S,
    payload: UserSignInWithCredentials
  ): void;
  [SignInMutationTypes.SetUserData](state: S, payload: UserCredentials): void;
  [SignInMutationTypes.SetToken](state: S, payload: any): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [SignInMutationTypes.SetSignInData](
    state: State,
    signin: UserSignInWithCredentials
  ) {
    state.data = signin;
  },
  [SignInMutationTypes.SetUserData](state: State, profile: UserCredentials) {
    state.userCredentials = profile;
  },
  [SignInMutationTypes.SetToken](state: State, data: any) {
    state.token = data;
  },
};

/* import { IAuthState } from "@/interfaces/state";
import { IUser } from "@/interfaces/user";

export default {
  setToken: (state: IAuthState, payload: string): void => {
    state.token = payload;
  },
  setUser: (state: IAuthState, payload: IUser): void => {
    state.user = payload;
  },
};
 */
