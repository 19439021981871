import { AccountPlanData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getAccountPlan(state: State): AccountPlanData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getAccountPlan: (state) => state.accountPlanData,
};
