<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.niche") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Nicho
                </h3>
            </template>
            <template v-slot:content>
                <input type="text" class="form-control rounded-0" name="description" v-model="formFilter.description"/>
                {{ description }}
                <form action="javascript:;" @submit.prevent="onStore">
                <app-right-curtain right-curtain-name="right" ref="formcurtain" card-class="card-default">
                    <template v-slot:title>
                        Formulário
                    </template>
                    <template v-slot:content>
                        <input type="hidden" name="id" v-model="form.id" />
                        <app-input-default 
                            label-for="Descrição" 
                            label="Descrição" 
                            input-name="descriptionCreate"
                            input-type="text" 
                            place-holder="Tipo de pessoa" 
                            v-model="v$.form.description.$model"
                            input-id="text" 
                            :has-error="v$.form.description.$error" 
                            :is-active="v$.form.description.$error"
                            :error="v$.form.description.$errors" 
                        ></app-input-default>
                    </template>
                    <template v-slot:footer>
                        <app-button 
                            type="submit" 
                            class="btn btn-default rounded-0"
                            :disabled="v$.form.$invalid"
                        >
                            <i class="fa fa-save"></i>
                            Gravar
                        </app-button>
                    </template>
                </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button 
                    type="button" 
                    class="btn btn-default" 
                    @click="openForm"
                    v-if="visibility('niche.insert')"
                >
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de Nicho
                </h3>
            </template>
            <template v-slot:content>
                <app-table 
                    :columns="gridColumns" 
                    :rows="rows" 
                    table-class="table table-striped"
                >
                    <template v-slot:cell-id="{ row: type }">
                        <a 
                            href="javascript:;" 
                            @click="onDelete(type.id)" 
                            v-if="visibility('niche.delete')"
                        >
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(type.id)" v-if="visibility('niche.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination
                    :total-pages="totalPages"
                    :total="total"
                    :per-page="perPage"
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->