/* eslint-disable */
import inputDefault from "@/components/form/input-default/input-default.vue";
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { FinanceMovementActionTypes } from "@/store/finance/movement/action-types";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";


export default defineComponent({
  name: "FinanceMovement",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const formSearch = ref({
      description: "",
    });
    const formCreate = ref({
      form: {
        id: "",
        description: "",
      }
    });

    const rules = computed(() => {
      return {
        form:{
          description: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(88)
            )
          },
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const financeMovementForm = ref();
    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "description",
        text: "Description",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      }
    ];

    const onSearch = () => {
      onList();
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const openForm = () => {
      formCreate.value.form.description = "";
      formCreate.value.form.id = "";
      financeMovementForm.value.openNav();
    };

    onMounted(() => {
      onList();
    });

    const onList = async () => {
      
        const params = {
          limit: perPage.value,
          offset: (currentPage.value - 1) * perPage.value,
          description: formSearch.value.description,
        };

        const loader = $loading.show({
          loader: "dots",
        });

        await store.dispatch(FinanceMovementActionTypes.FINANCE_MOVEMENT_LIST, params).then((data) => {
          rows.value = data.data;
          total.value = data.totalRecords;
          totalPages.value = data.totalPages;
          loader.hide();
        }).catch(() => {
          loader.hide();
        });
      
    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceMovementActionTypes.FINANCE_MOVEMENT_UPDATE, formCreate.value.form).then(() => {
        onList();
        financeMovementForm.value.closeNav();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch((err) => {
        onList();
        financeMovementForm.value.closeNav();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onCreate = async () => {
      
        const result = await v$.value.$validate();
        if(!result){
          return;
        };

        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch(FinanceMovementActionTypes.FINANCE_MOVEMENT_CREATE, formCreate.value.form ).then(() => {
            onList();
            financeMovementForm.value.closeNav();
            loader.hide();
            Swal.fire({
              title: "Good job!",
              text: "Record stored!",
              icon: "success",
              allowOutsideClick: false,
            });
        }).catch(() => {
          loader.hide();
        });          

    };

    const onDelete = (id: number) => {
      
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(FinanceMovementActionTypes.FINANCE_MOVEMENT_DELETE, { id: id }).then(() => {
              onList();
              loader.hide();
              Swal.fire({
                title: "Deleted!",
                text: "Your record has been deleted.",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
            });
          }
        });
      
    };

    const onEdit = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceMovementActionTypes.FINANCE_MOVEMENT_EDIT, { id: id }).then((data) => {
        openForm();
        formCreate.value.form.description = data.description;
        formCreate.value.form.id = data.id;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    return {
      formSearch,
      formCreate,
      gridColumns,
      rows,
      onSearch,
      onCreate,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      financeMovementForm,
      openForm,
      v$,
      onList,
      onDelete,
      onEdit,
      onStore,
      visibility,
    };
  },
});
