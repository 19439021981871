<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.billsToReceive", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">

        <app-panel class="card-default rounded-0 shadow">
            <template v-slot:header>
                <div class="container-full">
                    <div class="row">
                        <div class="col-sm">
                            <h3 class="card-title mt-2">
                                GERAR CONTAS A RECEBER
                            </h3>
                        </div>
                        <div class="col-sm">
                            <app-button type="button" class="btn btn-success rounded-0 shadow float-right"
                                @click="submitForm">
                                <i class="fa fa-clipboard-check"></i>
                                Gerar
                            </app-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="container-full">
                    <div class="row">
                        <div class="col-sm text-left">
                            Faturas
                        </div>
                        <div class="col-sm text-right" id="cliente-form-3">
                            <Select2 
                            v-model="filtering" 
                            :options="clients" 
                            :settings="{ 
                                dropdownParent: '#cliente-form-3', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                            @select="filterInvoice($event)"
                            @change="filterInvoice($event)" 
                        />
                            
                        </div>
                    </div>
                </div>
                <table class="table table-striped invoice">
                    <thead>
                        <tr>
                            <th width="1%">
                                <input 
                                    type="checkbox" 
                                    v-model="checkAll"
                                    class="form-check mb-2 check-invoice" 
                                    name="" 
                                    id=""
                                />
                            </th>
                            <th>Cliente</th>
                            <th class="text-right">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(contract, index) in contracts" :key="index">
                            <td>
                                <input type="checkbox" v-model="checked" :value="contract.id" />
                            </td>
                            <td>{{ contract.client }}</td>
                            <td class="text-right">{{ contract.total }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <app-pagination 
                    :total-pages="totalPages" 
                    :total="total" 
                    :per-page="perPage" 
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->