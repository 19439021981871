/* eslint-disable */
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Breadcrumb",
  props: {
    class: {
      type: String,
      default: "",
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadcrumb = reactive({
      route: [] as any,
    });
    watch(route, () => {
      updateList();
    });
    onMounted(() => {
      updateList();
    });
    const routeTo = (pRouteTo: any) => {
      if (breadcrumb.route[pRouteTo].link) {
        router.push(breadcrumb.route[pRouteTo].link);
      }
    };
    const updateList = () => {
      breadcrumb.route = router.currentRoute.value.meta.breadcrumb;
    };
    return { breadcrumb };
  },
});
