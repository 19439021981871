/* eslint-disable */
import Input from "@/components/form/input/input.vue";
import { useToast } from "vue-toastification";
import { PfButton } from "@profabric/vue-components";
import { useRoute } from "vue-router";
import { SignInActionsTypes } from "@/store/auth/action-types";
import router from "@/router";
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  components: {
    "app-input": Input,
    "pf-button": PfButton,
  },
  setup(props) {
    let appElement: HTMLElement | null = null;
    const password: any = ref("");
    const confirmPassword = ref("");
    const toast = useToast();
    const route = useRoute();
    const store = useStore();
    const passwordStrength = ref("");
    const passwordsMatch = ref(false);
    const isAuthLoading = false;
    const icon = 'lock';
    const bg = ref("bg-danger");
    const width = ref("width: 0%");
    const isDisabled = true;
    const show = ref(false);
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    
    onMounted(() => {
      appElement = document.getElementById("app") as HTMLElement;
      appElement.classList.add("login-page");
      validateToken();
    });

    onUnmounted(() => {
      appElement?.classList.remove("login-page");
    });

    const validateToken = async () => {
      try {
        const token = route.params.token;
        await store.dispatch(SignInActionsTypes.tokenValidation, { token });
      } catch (error: any) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          router.replace({name: 'ForgotPassword'});  
        }, 5000);    
      }
    };

    const newPassword = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      try {
        const API_KEY = process.env.VUE_APP_GEOCODE_KEY;
        const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + API_KEY;
        const apiResponse = await fetch(API_URL);
        const data = await apiResponse.json();
        const {city, country, region} = data;

        const params = {
          password: password.value,
          password_confirmation: confirmPassword.value,
          token: route.params.token,
          operating_system: getOs(),
          browser_name: getBrowser(),
          country: country,
          state: region,
          city: city
        }
        await store.dispatch(SignInActionsTypes.newPassword, params);
        toast.success('Senha redefinida com sucesso! Vocé sera redirecionando para o Login!');
        loader.hide();
        setTimeout(() => {
          router.replace({name: 'Login'});  
        }, 5000); 
      } catch (error: any) {
        toast.error(error.response.data.message);
        loader.hide();
        setTimeout(() => {
          router.replace({name: 'ForgotPassword'});  
        }, 5000);   
      }
    };

    const getPasswordStrength = () => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if(passwordRegex.test(password.value)) {
        width.value = 'width: 100%;';
        bg.value = 'bg-success';
      } else if(password.value.length >= 8) {
        width.value = 'width: 50%;';
        bg.value = 'bg-warning';
      } else {
        width.value = 'width: 25%;';
        bg.value = 'bg-danger';
      } 
    };

    const checkPasswordsMatch = () => {
      passwordsMatch.value = password.value === confirmPassword.value;
      show.value = true;
    };

    const getOs = () => {
      const userAgent = window.navigator.userAgent;
      let os = '';
  
      if (userAgent.indexOf('Win') !== -1) {
        os = 'Windows';
      } else if (userAgent.indexOf('Mac') !== -1) {
        os = 'MacOS';
      } else if (userAgent.indexOf('Linux') !== -1) {
        os = 'Linux';
      } else if (userAgent.indexOf('Android') !== -1) {
        os = 'Android';
      } else if (userAgent.indexOf('iOS') !== -1) {
        os = 'iOS';
      } else {
        os = 'Unknown';
      }
  
      // Update data
      os = os;
      return os;
    }
  
    const getBrowser = () => {
      const userAgent = navigator.userAgent;
      let browser = '';
  
      if (userAgent.indexOf('Firefox') > -1) {
        browser = 'Mozilla Firefox';
      } else if (userAgent.indexOf('Chrome') > -1) {
        browser = 'Google Chrome';
      } else if (userAgent.indexOf('Safari') > -1) {
        browser = 'Apple Safari';
      } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browser = 'Opera';
      } else if (userAgent.indexOf('Trident') > -1) {
        browser = 'Microsoft Internet Explorer';
      } else if (userAgent.indexOf('Edge') > -1) {
        browser = 'Microsoft Edge';
      } else if (userAgent.indexOf('SamsungBrowser') > -1) {
        browser = 'Samsung Internet';
      } else {
        browser = 'Unknown';
      }
  
      browser = browser;
      return browser;
    }

    return { 
      validateToken, 
      newPassword, 
      getPasswordStrength, 
      checkPasswordsMatch, 
      passwordsMatch, 
      password, 
      confirmPassword, 
      passwordStrength,
      icon,
      bg,
      width,
      isDisabled,
      show,
    }
  }
});

/* @Options({
  components: {
    "app-input": Input,
    "pf-button": PfButton,
  },
})
export default class RecoverPassword extends Vue {
  private appElement: HTMLElement | null = null;
  public password = "";
  public confirmPassword = "";
  private toast = useToast();
  private route = useRoute();
  private isAuthLoading = false;

  public mounted(): void {
    this.appElement = document.getElementById("app") as HTMLElement;
    this.appElement.classList.add("login-page");
    this.validateToken();
  }

  public unmounted(): void {
    (this.appElement as HTMLElement).classList.remove("login-page");
  }

  public async validateToken() {
    try {
      const token = this.route.params.token;
      await this.$store.dispatch(SignInActionsTypes.tokenValidation, {token})
    } catch (error: any) {
      this.toast.error(error.response.data.message);
      setTimeout(() => {
        router.replace({name: 'ForgotPassword'});  
      }, 5000);      
    }
  }

  public async newPassword() {
    try {
      const params = {
        password: this.password,
        password_confirmation: this.confirmPassword,
        token: this.route.params.token
      }
      await this.$store.dispatch(SignInActionsTypes.newPassword, params);
    } catch (error: any) {
      this.toast.error(error.response.data.message);
    }
  }
} */
