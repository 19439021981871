/* eslint-disable */
export enum IssueTaskActionTypes {
  ISSUE_TASK_LIST   = "IssueTaskList",
  ISSUE_TASK_CREATE = "IssueTaskCreate",
  ISSUE_TASK_EDIT   = "IssueTaskEdit",
  ISSUE_TASK_UPDATE = "IssueTaskUpdate",
  ISSUE_TASK_DELETE = "IssueTaskDelete",
  ISSUE_TASK_ORDER  = "IssueTaskOrder",
  ISSUE_TASK_STATUS = "IssueTaskStatus",
}
