/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "Database",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-table": grid,
    "app-pagination": Paginate,
    "app-button": Button,
  },
  setup() {
    const rows = reactive([]);
    const description = ref("");
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref();
    const perPage = ref(10);

    const gridColumns = [
      {
        key: "user",
        text: "User",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "sql",
        text: "SQL Query",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "data",
        text: "Data",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "created_at",
        text: "At",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const onSearch = () => {
      return;
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
    };

    const onList = () => {
      return;
    };

    return {
      gridColumns,
      rows,
      description,
      onSearch,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
    };
  },
});
