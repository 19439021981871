/* eslint-disable */
import { FinanceAccountStatusData, FinanceAccountStatusObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceAccountStatusMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceAccountStatusMutationTypes.SET_INSERT_FINANCE_ACCOUNT_STATUS_DATA](state: S, payload: FinanceAccountStatusObject): void;
  [FinanceAccountStatusMutationTypes.SET_FINANCE_ACCOUNT_STATUS_DATA](state: S, payload: FinanceAccountStatusData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceAccountStatusMutationTypes.SET_INSERT_FINANCE_ACCOUNT_STATUS_DATA](state: State, financeAccountStatus: FinanceAccountStatusObject) {
    state.financeAccountStatus = financeAccountStatus;
  },
  [FinanceAccountStatusMutationTypes.SET_FINANCE_ACCOUNT_STATUS_DATA](state: State, financeAccountStatusData: FinanceAccountStatusData) {
    state.financeAccountStatusData = financeAccountStatusData;
  },
};
