import { IssueData, IssueObject } from "@/@types";

export type State = {
  data: IssueObject | null;
  issueData: IssueData;
};

export const state: State = {
  data: null,
  issueData: [],
};
