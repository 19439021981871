/* eslint-disable */
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import rightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import BootstrapTabComponent from "@/components/ui/tabs/tab/tab.vue";
import Button from "@/components/ui/button/button.vue";
import Note from "../note/note.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import TodoList from "@/components/todolist/TodoList.vue";
import { useLoading } from "vue-loading-overlay";
import Swal from "sweetalert2";
import { IssueTaskActionTypes } from "@/store/issue/task/action-types";
import { isVisible } from "@/classes/is_visible";

export default defineComponent({
    name: "IssueView",
    components: {
        "curtain": rightCurtain,
        "app-tab": BootstrapTabComponent,
        "app-button": Button,
        "note-form": Note,
        "panel-secondary": PanelSecondary,
        "todo-list": TodoList,
    },
    setup() {
        const store = useStore();
        const $loading = useLoading({
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999999,
        });
        const issueId = ref<any>();
        const datas = ref({});
        const files = ref({});
        const notes = ref({});
        const issueNoteForm = ref();
        const issueViewerCurtain = ref();
        const curtainTitle = "Visualizar chamado";
        const todoListData = ref([]);
        const todoListSituation = ref(false);

        const taskForm = ref({
            id: "",
            issue: "",
            task: "",
        });
        const formTaskClear = ref(false);

        const getInformation = (issue: any) => {
            const loader = $loading.show({
                loader: "dots",
            });

            issueId.value = issue;

            store.dispatch(IssueActionTypes.ISSUE_VIEW, { issue: issue }).then((data) => {
                datas.value = data.data;
                files.value = data.files;
                todoListSituation.value = (data.data.situation !== 'Encerrado' && data.data.situation !== 'Rejeitado' && data.data.situation !== 'Aberto') ?? false;
                issueViewerCurtain.value.openNav();
                getNotes(data.data.id);
                onTaskList();
                loader.hide();
            }).catch((error) => {
                loader.hide();
            });
        };

        const getNotes = (issue: number) => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueActionTypes.ISSUE_GET_NOTE, { issue: issue }).then((data) => {
                notes.value = data;
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        };

        const openNoteForm = (issue: number) => {
            issueNoteForm.value.openIssueNoteForm(issue);
        };

        const tabs = ref([
            {
                name: "tab1",
                label: "Notas",
                id: "custom-tabs-four-one-tab",
                ariaControls: "custom-tabs-four-one"
            },
            {
                name: "tab2",
                label: "Anexos",
                id: "custom-tabs-four-two-tab",
                ariaControls: "custom-tabs-four-two"
            },
            {
                name: "tab3",
                label: "Tarefas",
                id: "custom-tabs-four-three-tab",
                ariaControls: "custom-tabs-four-three"
            }
        ]);

        const fileName = (filename: string) => {
            return filename.split('/').pop();
        };

        const openNoteFile = (file: number) => {
            const loader = $loading.show({
                loader: "dots",
            });
            store.dispatch(IssueActionTypes.ISSUE_GET_NOTE_FILE, { file: file }).then((response) => {
                const file = new Blob([response.data], { type: response.headers['content-type'] });
                const fileURL = window.URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        };

        const openIssueFile = (file: number) => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueActionTypes.ISSUE_FILE_GET, { file: file }).then((response) => {
                const file = new Blob([response.data], { type: response.headers['content-type'] });
                const fileURL = window.URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        };

        /* TO DO LIST */
        const onTaskStore = (item: any) => {
            taskForm.value = { ...item };
            taskForm.value.issue = issueId.value;
            if (taskForm.value.id) {
                onTaskUpdate();
            } else {
                onTaskSave();
            }
        };

        const onTaskList = async () => {

            const loader = $loading.show({
                loader: "dots",
            });

            await store.dispatch(IssueTaskActionTypes.ISSUE_TASK_LIST, { issue: issueId.value }).then((data) => {
                todoListData.value = data.data;
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        };

        const onTaskSave = () => {
            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_CREATE, taskForm.value).then(() => {
                taskForm.value = { id: '', task: '', issue: ''};
                onTaskList();
            }).catch(() => {
                return;
            });

        }

        const onTaskEdit = (item: any) => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_EDIT, { id: item }).then((data) => {
                taskForm.value.id = data.id;
                taskForm.value.task = data.task;
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        }

        const onTaskUpdate = () => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_UPDATE, taskForm.value).then(() => {
                taskForm.value = { id: '', task: '', issue: ''};
                onTaskList();
                loader.hide();
            }).catch((err) => {
                onTaskList();
                loader.hide();
                Swal.fire({
                    title: "Ooops!",
                    text: "Registro não atualizado, fale com o seu administrador!",
                    icon: "error",
                    allowOutsideClick: false,
                });
            });
        }

        const onTaskRemove = (item: any) => {

            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_DELETE, { id: item }).then(() => {
                onTaskList();
                loader.hide();
            });
        }

        const onHandleCheckboxChange = (item: any) => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_STATUS, { id: item }).then(() => {
                onTaskList();
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        }

        const tastOrder = (item: any) => {
            const loader = $loading.show({
                loader: "dots",
            });

            store.dispatch(IssueTaskActionTypes.ISSUE_TASK_ORDER, item).then(() => {
                onTaskList();
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        }

        const visibility = (name: any) => {
            return isVisible(name);
        };
        /* END TO DO LIST */

        return {
            tabs,
            datas,
            files,
            getInformation,
            curtainTitle,
            issueViewerCurtain,
            openNoteForm,
            issueNoteForm,
            getNotes,
            notes,
            fileName,
            openNoteFile,
            openIssueFile,
            todoListData,
            onTaskStore,
            onTaskSave,
            onTaskEdit,
            onTaskRemove,
            onHandleCheckboxChange,
            tastOrder,
            formTaskClear,
            todoListSituation,
            visibility,
            taskForm,
        };
    },
});
