/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { defineComponent, onMounted, ref } from "vue";
import inputInline from "@/components/form/input-inline/input-inline.vue";
import inputDropdown from "@/components/form/dropdown/dropdown.vue";
import { useStore } from "vuex";
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import { isVisible } from "@/classes/is_visible";
import TopTooltip from "@/components/ui/tooltip/top/tooltip.vue";
import IssueForm from "@/pages/services/issue/issue/form/form.vue";
import IssueViewer from "@/pages/services/issue/issue/view/issue-view.vue";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "Issue",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-table": grid,
    "app-pagination": Paginate,
    "app-button": Button,
    "input-inline": inputInline,
    "input-dropdown": inputDropdown,
    "tooltip": TopTooltip,
    "issue-form": IssueForm,
    "issue-viewer": IssueViewer,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const issueForm = ref();
    const rows = ref({});
    const description = ref("");
    const currentPage = ref(1);
    const totalPages = ref(1);
    const issueViewer = ref();
    const issueNoteFormData = ref();
    const issueId: any = ref("");
    const total = ref();
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "7%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "code",
        text: "Código",
        thClass: "text-center",
        thWidth: "5%",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "openingDate",
        text: "Abertura",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "problemType",
        text: "Tipo do problema",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "requestedBy",
        text: "Solicitante",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "project",
        text: "Projeto",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "category",
        text: "Categoria",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "situation",
        text: "Situação",
        thClass: "text-center",
        thWidth: "15%",
        tdClass: "",
        tdWidth: "",
      },
    ];
    const clients = ref({});
    const states = ref({});
    const projects = ref({});
    const priorities = ref({});
    const categories = ref({});
    const problemTypes = ref({});
    const users = ref({});

    const formFilter = ref({
      code: "",
      client: "",
      state: "",
      project: "",
      priority: "",
      category: "",
      problemType: "",
      user: "",
    });

    onMounted(() => {
      getClients();
      getState();
      getPriorities();
      getCategories();
      getUsers();
      getProblemType();
      onList();
    });

    const onSearch = () => {
      onList();
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onList = async () => {

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        number: formFilter.value.code,
        client: formFilter.value.client,
        state: formFilter.value.state,
        project: formFilter.value.project,
        priority: formFilter.value.priority,
        category: formFilter.value.category,
        problemType: formFilter.value.problemType,
        user: formFilter.value.user,
      };

      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(IssueActionTypes.ISSUE_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });

    };

    const onEdit = (issue: any) => {
      issueForm.value.onEdit(issue);
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onNew = () => {
      issueForm.value.onNew();
    }

    const onView = (issue: any) => {
      issueViewer.value.getInformation(issue);
    }

    /* GETs */
    const getClients = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_CLIENT).then((data) => {
        clients.value = data;
      }).catch((error) => {
        return;
      });
    };

    const getState = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_STATE).then((data) => {
        states.value = data;
      }).catch((error) => {
        return;
      });
    };

    const getProjects = () => {
      projects.value = [];
      store.dispatch(IssueActionTypes.ISSUE_GET_PROJECT, { client: formFilter.value.client }).then((data) => {
        projects.value = data.projects;
      }).catch((error) => {
        return;
      });
    };

    const getPriorities = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_PRIORITY).then((data) => {
        priorities.value = data;
      }).catch((error) => {
        return;
      });
    };

    const getCategories = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_CATEGORY).then((data) => {
        categories.value = data;
      }).catch((error) => {
        return;
      });
    };

    const getUsers = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_USERS).then((data) => {
        users.value = data;
      }).catch((error) => {
        return;
      });
    };

    const getProblemType = () => {
      store.dispatch(IssueActionTypes.ISSUE_GET_PROBLEM_TYPE).then((data) => {
        problemTypes.value = data;
      }).catch((error) => {
        return;
      });
    }

    const onClientChange = () => {
      getProjects();
    };

    return {
      onNew,
      gridColumns,
      rows,
      description,
      onSearch,
      onEdit,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      visibility,
      onList,
      issueForm,
      formFilter,
      clients,
      states,
      projects,
      priorities,
      categories,
      problemTypes,
      users,
      onClientChange,
      issueViewer,
      onView,
      issueNoteFormData,
      issueId,
    };
  },
});
