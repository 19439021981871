import { IssueProblemTypeData, IssueProblemTypeObject } from "@/@types";

export type State = {
  data: IssueProblemTypeObject | null;
  issueProblemTypeData: IssueProblemTypeData;
};

export const state: State = {
  data: null,
  issueProblemTypeData: [],
};
