export enum PeopleActionTypes {
  ALL_PEOPLE = "AllPeople",
  LIST_PEOPLE = "ListPeople",
  CREATE_PEOPLE = "CreatePeople",
  EDIT_PEOPLE = "EditPeople",
  UPDATE_PEOPLE = "UpdatePeople",
  DELETE_PEOPLE = "DeletePeople",
  DELETE_PEOPLE_CONTACT = "DeletePeopleContact",
  GET_PEOPLE_INFO = "PeopleInfo",
  GET_PEOPLE_ADDRESS = "GetPeopleAddress",
  GET_ALL_COMPANIES = "GetAllCompanies",
  GET_ALL_CONTACTS = "GetAllContacts",
  GET_CONTACT_PAGING = "GetContact",
  GET_ALL_NICHE = "GetAllNiche",
  GET_ALL_COUNTRIES = "GetAllCountries",
}
