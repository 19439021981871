import { NicheData, NicheObject } from "@/@types";

export type State = {
  data: NicheObject | null;
  nicheData: NicheData;
};

export const state: State = {
  data: null,
  nicheData: [],
};
