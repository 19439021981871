<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.issueNotification") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Informação da Nota
                </h3>
            </template>
            <template v-slot:content>
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <strong>Origem:</strong>
                        </div>
                        <div class="col-sm-6">
                            {{ info.name }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <strong>Assunto:</strong>
                        </div>
                        <div class="col-sm-6">
                            Nova Nota
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <strong>Mensagem:</strong>
                        </div>
                        <div class="col-sm-6">
                            {{ info.note }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <strong>Data:</strong>
                        </div>
                        <div class="col-sm-6">
                            {{ info.createdAt }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <strong>Verificado:</strong>
                        </div>
                        <div class="col-sm-6">
                            <span v-if="info.viewed === 'N'">Não</span>
                            <span v-if="info.viewed === 'Y'">Sim</span>
                        </div>
                    </div>
                </div>
                <s-button 
                    type="button" 
                    class="btn btn-primary rounded-0 shadow" 
                    @click="setIssue(info.issue)"
                >
                    <i class="far fa-bell"></i>
                    Abrir chamado #{{ issue }}
                </s-button>
                &nbsp;
                <a
                    href="/service/issue/mine"
                    type="button" 
                    class="btn btn-default rounded-0 shadow" 
                >
                    <i class="fas fa-table"></i>
                    Ir para a listagem de chamado
            </a>
            </template>
        </panel>
    </div>
</section>