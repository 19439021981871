
<!-- TO DO List -->
<div class="container-full" :class="{hidden: !visible}">  
    <div class="row">
        <div class="col-sm-11"> 
            <input type="hidden" v-model="formInputs.id">
            <input 
                type="text" 
                class="form-control rounded-0" 
                v-model="formInputs.task" 
                @keyup.enter="onSave"
            >
        </div>
        <div class="col-sm">
            <button 
                type="button" 
                class="btn btn-md btn-primary float-left mb-2"
                @click="onSave()"
            >
                <i class="fas fa-save"></i> 
                Save
            </button>
        </div>
    </div>
    <hr>
</div> 

<ul class="todo-list" data-widget="todo-list">
    <draggable class="todo-list" v-model="localData" item-key="id" @end="reOrder" :disabled="!visible">
        <li v-for="item in localData" :key="item.id" :class="{ done: item.done }">
            <!-- drag handle -->
            <span class="handle" v-if="visible">
                <i class="fas fa-ellipsis-v "></i>
                <i class="fas fa-ellipsis-v"></i>
            </span>
            <!-- checkbox -->
            <div class="icheck-primary d-inline ml-2" v-if="visible">
                <input 
                    type="checkbox" 
                    :value="item.id" 
                    :name="'todo' + item.id" 
                    style="z-index: 1;" 
                    @change="handleCheckboxChange($event, item)" 
                    :id="'todoCheck' + item.id"
                    :checked="item.done"
                >
                <label for="todoCheck1"></label>
            </div>
            <!-- todo text -->
            <span class="text">{{ item.text }}</span>
            <!-- General tools such as edit or delete-->
            <div class="tools" v-if="visible">
                <i class="fas fa-edit" @click="onEdit(item.id)"></i>
                <i class="fas fa-trash" @click="onRemove(item.id)"></i>
            </div>
        </li>
    </draggable>
</ul>
<!-- /.card -->
