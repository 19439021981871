/* eslint-disable */
import { IssuePriorityData, IssuePriorityObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssuePriorityMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssuePriorityMutationTypes.SET_INSERT_ISSUE_PRIORITY_DATA](
    state: S,
    payload: IssuePriorityObject
  ): void;
  [IssuePriorityMutationTypes.SET_ISSUE_PRIORITY_DATA](
    state: S,
    payload: IssuePriorityData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssuePriorityMutationTypes.SET_INSERT_ISSUE_PRIORITY_DATA](
    state: State,
    issuePriority: IssuePriorityObject
  ) {
    state.data = issuePriority;
  },
  [IssuePriorityMutationTypes.SET_ISSUE_PRIORITY_DATA](
    state: State,
    issuePriorityData: IssuePriorityData
  ) {
    state.issuePriorityData = issuePriorityData;
  },
};
