/* eslint-disable */
import { computed, defineComponent, onMounted, ref } from "vue";
import Panel from "@/components/ui/panel/panel.vue";
import { useStore } from "vuex";
import { ContractActionTypes } from "@/store/services/contract/action-types";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
    name: "ContractDashboard",
    components: {
        "app-panel": Panel,
        "app-breadcrumb": Breadcrumb,
    },
    setup() {
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const activeContracts = ref(0);
        const pendingRenewalContracts = ref(0);
        const contractsByGroup = ref([]);
        const contractsByTypes = ref({
            "label": "",
            "serie": ""
        });
        const topFiveClients = ref({});
        const topFiveOldClients = ref({});

        onMounted(() => {
            getDatas();
        });

        const chartOptions:any = ref({
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        });

        const series:any = ref([]);

        const nicheChartOptions:any = ref({
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        });
        const nicheSeries:any = ref([]);

        const getDatas = () => {
            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(ContractActionTypes.CONTRACT_DASHBOARD).then((data) => {
                activeContracts.value = data.actives;
                pendingRenewalContracts.value = data.pendingRenewal;
                topFiveOldClients.value = data.byTopFiveOldClients;
                topFiveClients.value = data.byTopFiveClients;
                //contractsByTypes.value.label = data.byType.label;

                data.byType.labels.forEach((item:any, index: any) => {
                    chartOptions.value.labels.push(item.label);
                });                
                data.byType.series.forEach((item: any, index: any) => {
                    series.value.push(item.serie)
                });

                data.byNiche.labels.forEach((item:any, index: any) => {
                    nicheChartOptions.value.labels.push(item.label);
                });                
                data.byNiche.series.forEach((item: any, index: any) => {
                    nicheSeries.value.push(item.serie)
                });
                loader.hide();
            }).catch((error) => {
                loader.hide();
                // Handle the error as needed
            });
        };

        return {
            activeContracts,
            pendingRenewalContracts,
            topFiveOldClients,
            topFiveClients,
            chartOptions,
            series,
            nicheChartOptions,
            nicheSeries,
            contractsByTypes,
        };
    }
});