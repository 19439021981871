import { IssueData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getIssue(state: State): IssueData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIssue: (state) => state.issueData,
};
