/* eslint-disable */


import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Iradiobox from "@/components/form/iradio/iradio.vue";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { ProjectActionTypes } from "@/store/project/project/action-types";
import inputDefault from "@/components/form/input-default/input-default.vue";
import { useRoute } from "vue-router";
import BootstrapTabComponent from "@/components/ui/tabs/tab/tab.vue";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Dropdown from "@/components/form/dropdown/dropdown.vue";
import Upload from "@/components/form/upload-files/upload.vue";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "ProjectInsert",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-button": Button,
    "app-iradiobox": Iradiobox,
    "app-input-default": inputDefault,
    "app-tab": BootstrapTabComponent,
    "app-dropdown": Dropdown,
    "input-file": Upload,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const contractTypes = ref({});
    const clients = ref({});
    const services = ref({});
    const route = useRoute();
    const contracts = ref({});
    const clientTeamLeaders = ref({});
    const companyTeamLeaders = ref({});
    const states = ref({});
    const file = ref();
    const serverFiles = ref({});
    
    const form: any = ref({
      form: {
        id: "",
        state: "",
        client: "",
        contract: '',
        clientTeamLeader: "",
        companyTeamLeader: "",
        name: "",
        description: "",
        estimatedBudget: "",
        totalAmountSpent: "",
        estimatedDuration: "",
        attachment: [] 
      }
    });

    const rules = computed(() => {
      return {
        form: {
          state: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          client: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          contract: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          name: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          description: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          estimatedBudget: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          totalAmountSpent: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
        }
      }
    });

    const v$ = useVuelidate(rules, form);

    const tabs = ref([
      { 
        name: "tab1", 
        label: "Arquivos",
        id: "custom-tabs-four-one-tab",
        ariaControls: "custom-tabs-four-one"
      },
      { 
        name: "tab2", 
        label: "Enviar",
        id: "custom-tabs-four-two-tab",
        ariaControls: "custom-tabs-four-two"
      }
    ]);

    const moneyConfig = computed(() => ({
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false /* doesn't work with directive */,
      focusOnRight: true,
      minimumNumberOfCharacters: 0,
    }));

    const activeItems = ref([
      { text: "Sim", value: 'Y' },
      { text: "Não", value: 'N' },
    ]);

    const items: any = ref([{
      service: "",
      value: "",
      quantity: ""
    }]);

    const configValor = computed(() => ({
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
      shouldRound: true,
      focusOnRight: true,
    }));

    onMounted(() => {
      onGetClents();
      onEdit();
      onGetCompanyLeader();
      onGetState();
    });

    const dueDateInputOptions = (date: any) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = new Date(year, month - 1, day);
      const formattedString = formattedDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

      return formattedString;
    };

    const onCreate = async () => {
      form.value.form.attachment = file.value.files;
      
      if (route.params.id !== undefined && route.params.id !== "") {
        onUpdate();
      } else {
        const result = await v$.value.$validate();
        if(!result){
          return;
        }
        const loader = $loading.show({
          loader: "dots",
        });

        form.value.services = items.value;
        store.dispatch(ProjectActionTypes.PROJECT_CREATE, form.value.form).then(data => {
          form.value.form.id = "";
          form.value.form.state = "";
          form.value.form.client = "";
          form.value.form.contract = "",
          form.value.form.clientTeamLeader = "";
          form.value.form.companyTeamLeader = "";
          form.value.form.name = "";
          form.value.form.description = "";
          form.value.form.estimatedBudget = "";
          form.value.form.totalAmountSpent = "";
          form.value.form.estimatedDuration = "";
          form.value.form.attachment = []; 
          loader.hide();
          Swal.fire({
            title: "Good job!",
            text: "Record stored!",
            icon: "success",
            allowOutsideClick: false,
          });
        }).catch(() => {
          loader.hide();
        });
      } 
    };

    const onEdit = () => {

      if (route.params.id !== undefined && route.params.id !== "") {
        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch(ProjectActionTypes.PROJECT_EDIT, { id: route.params.id }).then((data) => {
          form.value.form.id = data.project.id;
          form.value.form.client = data.project.client;
          onGetClientLeader();
          onGetContract();
          form.value.form.contract = data.project.contract;
          form.value.form.name = data.project.name;
          form.value.form.description = data.project.description;
          form.value.form.state = data.project.state;
          form.value.form.clientTeamLeader = data.project.clientTeamLeader;
          form.value.form.companyTeamLeader = data.project.companyTeamLeader;
          form.value.form.estimatedBudget = data.project.estimatedBudget;
          form.value.form.totalAmountSpent = data.project.totalAmountSpent;
          form.value.form.estimatedDuration = data.project.estimatedDuration;
          serverFiles.value = data.files;
          loader.hide();
          
          //items.value = JSON.parse(data.services);
        }).catch(() => {
          loader.hide();
        });
        
      }
    };

    const onUpdate = () => {
      const loader = $loading.show({
        loader: "dots",
      });
      
      form.value.form.id = route.params.id;
      form.value.form.attachment = file.value.files;
      store.dispatch(ProjectActionTypes.PROJECT_UPDATE, form.value.form).then(data => {
        onEdit();
        loader.hide();
        Swal.fire({
          title: "Bom Trabalho!",
          text: "Dados atualizados com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        onEdit();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Registro não foi atualizado, Verifique com o administrador!",
          icon: "error",
          allowOutsideClick: false,
        });
      })
    };

    const onGetClents = async () => {
      try {
        await store.dispatch(ProjectActionTypes.PROJECT_CLIENT).then((data) => {
          clients.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onGetContract = async () => {
     
      const loader = $loading.show({
        loader: "dots",
      });

      const client = form.value.form.client;
      await store.dispatch(ProjectActionTypes.PROJECT_CONTRACT, client).then((data) => {
        contracts.value = data;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const onGetClientLeader = () => {
      try {
        const client = form.value.form.client;        
        store.dispatch(ProjectActionTypes.PROJECT_CLIENT_LEADER, client).then((data) => {
          clientTeamLeaders.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onClientChange = (evt: any) => {
      onGetClientLeader();
      onGetContract();
    };

    const onGetCompanyLeader = async () => {
      try {
        await store.dispatch(ProjectActionTypes.PROJECT_COMPANY_LEADER).then((data) => {
          companyTeamLeaders.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetState = () => {
      try {
        store.dispatch(ProjectActionTypes.PROJECT_STATE).then((data) => {
          states.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const fileName = (filename: string) => {
      return filename.split('/').pop();
    }

    const onFileDownload = async (id: number, filename: any) => {
      const loader = $loading.show({
        loader: "dots",
      });
      
      store.dispatch(ProjectActionTypes.PROJECT_FILE_DOWNLOAD, id).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        loader.hide();
      }).catch(() => {
        loader.hide();
      }); 
      
    };

    const onFileDelete = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      Swal.fire({
        title: "Tem certeza?",
        text: "Não poderá reverter depois!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, exclua!",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(ProjectActionTypes.PROJECT_FILE_DELETE, id).then((data) => {
            onEdit();
            loader.hide();
            Swal.fire({
              title: "Excluido!",
              text: "Arquivo foi excluido com sucesso.",
              icon: "success",
              allowOutsideClick: false,
            });
          }).catch(() => {
            loader.hide();
          })
        }
      });
    };

    return {
      form,
      dueDateInputOptions,
      configValor,
      items,
      visibility,
      contractTypes,
      activeItems,
      onGetClents,
      services,
      clients,
      onCreate,
      moneyConfig,
      tabs,
      v$,
      onClientChange,
      contracts,
      clientTeamLeaders,
      companyTeamLeaders,
      states,
      file,
      serverFiles,
      fileName,
      onFileDownload,
      onFileDelete,
    };
  },
});
