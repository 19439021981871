<div :class="class">
    <div class="inner">
        <h3>{{ content }}</h3>

        <p>{{ text }}</p>
    </div>
    <div class="icon">
        <i :class="fa"></i>
    </div>
    <slot name="footer"></slot>
</div>