import { defineComponent } from "vue";

export default defineComponent({
  name: "RightCurtain",
  props: {
    rightCurtainName: {
      type: String,
      required: true,
    },
    cardClass: {
      type: String,
      required: true,
    },
    cardTitleClass: {
      type: String,
      required: false,
    },
    styleWidthOpened: {
      type: String,
      required: false,
      default: "50%",
    },
    styleWidthClosed: {
      type: String,
      required: false,
      default: "0%",
    },
  },
  setup(props) {
    const openNav = () => {
      const app = document.getElementById(props.rightCurtainName);
      if (app) {
        app.style.width = props.styleWidthOpened;
      }
    };

    const closeNav = () => {
      const app = document.getElementById(props.rightCurtainName);
      if (app) {
        app.style.width = props.styleWidthClosed;
      }
    };

    return {
      openNav,
      closeNav,
    };
  },
});
