/* eslint-disable */
import { IntegrationObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { IntegrationActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [IntegrationActionTypes.CREATE_UPDATE_INTEGRATION]( { commit }: AugmentedActionContext, params: IntegrationObject ): Promise<boolean>;
  [IntegrationActionTypes.EDIT_INTEGRATION]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [IntegrationActionTypes.CREATE_UPDATE_INTEGRATION]( { commit }, params: IntegrationObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        key   : params.app,
        data  : params.data,
        type  : params.type,
        active: params.active,
      };
      //const dataForm = payload;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/integration", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IntegrationActionTypes.EDIT_INTEGRATION]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/integration/${params.key}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
