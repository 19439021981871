/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import { useStore } from "vuex";
import { IssueCategoryActionTypes } from "@/store/issue/category/action-types";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from '@vuelidate/validators';
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "IssueCategory",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-table": grid,
    "app-pagination": Paginate,
    "app-button": Button,
    "app-right-curtain": RightCurtain,
    "app-input-default": inputDefault,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const formcurtain = ref();
    const rows = ref({});
    const description = ref("");
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref();
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "description",
        text: "Categoria",
        thClass: "",
        thWidth: "50%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "email",
        text: "E-mail para abertura de chamado",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const form = reactive({
      id: "",
      description: "",
      email: "",
      observation: "",
    });

    const formFilter = ref({
      description: "",
    });

    const rules = computed(() => {
      return {
        description: { 
          required: helpers.withMessage("Este campo é obrigatório!", required) 
        },
        email: { 
          required: helpers.withMessage("Este campo é obrigatório!", required) , 
          email: helpers.withMessage("E-mail incorreto!", email)  
        },
      }
    });

    const v$ = useVuelidate(rules, form);

    onMounted(() => {
      onList();
    });

    const onSearch = () => {
      form.description = formFilter.value.description;
      onList();
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onList = async() => {
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        description: formFilter.value.description,
      };

      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(IssueCategoryActionTypes.ISSUE_CATEGORY_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    const onStore = () => {
      if (form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onCreate = async () => {

      const result = await v$.value.$validate();
      if(!result){
        return;
      }

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(IssueCategoryActionTypes.ISSUE_CATEGORY_CREATE, form).then(() => {
        form.description = "";
        form.email = "";
        form.observation = "";
        
        formcurtain.value.closeNav();
        onList();
        loader.hide();
        Swal.fire({
          title: "Bom Trabalho!",
          text: "Categoria cadastrada com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
      });
    };

    const onUpdate = () => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(IssueCategoryActionTypes.ISSUE_CATEGORY_UPDATE, form).then(() => {
        form.description = "";
        form.email = "";
        form.observation = "";
        form.id = "";
        formcurtain.value.closeNav();
        onList();
        loader.hide();
        Swal.fire({
          title: "Bom Trabalho!",
          text: "Categoria atualizada com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch((err) => {
        onList();
        loader.hide();
        Swal.fire({
          title: "Ooops!",
          text: "Registro não atualizado, fale com o seu administrador!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onDelete = (id: any) => {
      Swal.fire({
        title: "Tem certeza?",
        text: "Não será possível recuperar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Exclua!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(IssueCategoryActionTypes.ISSUE_CATEGORY_DELETE, { id: id }).then(() => {
            onList();
            loader.hide();
            Swal.fire({
              title: "Excluido!",
              text: "O registro foi excluido com sucesso.",
              icon: "success",
              allowOutsideClick: false,
            });
          }).catch(() => {
            loader.hide();
          });
        }
      });
    };

    const onEdit = (id: any) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(IssueCategoryActionTypes.ISSUE_CATEGORY_EDIT, { id: id }).then((data) => {
        formcurtain.value.openNav();
        form.description = data.description;
        form.email = data.email;
        form.observation = data.observation;
        form.id = data.id;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onNew = () => {
      form.description = "";
      form.email = "";
      form.observation = "";
      form.id = "";
      formcurtain.value.openNav();
    }

    return {
      onNew,
      gridColumns,
      rows,
      description,
      onSearch,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      form,
      visibility,
      onStore,
      onList,
      onEdit,
      onDelete,
      formcurtain,
      formFilter,
      v$
    };
  },
});
