/* eslint-disable */
import { ContractObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { ContractActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ContractActionTypes.CONTRACT_LIST]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_CREATE]({ commit }: AugmentedActionContext, params: ContractObject): Promise<boolean>;
  [ContractActionTypes.CONTRACT_EDIT]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_UPDATE]({ commit }: AugmentedActionContext, params: ContractObject): Promise<any>;
  [ContractActionTypes.CONTRACT_DELETE]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_TYPE_GET]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_CLIENT]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_STATE]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_FOR_INVOICE]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_TO_INVOICE]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [ContractActionTypes.CONTRACT_DASHBOARD]({ commit }: AugmentedActionContext, params: any): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [ContractActionTypes.CONTRACT_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`contracts/contract/${params.limit}/${params.offset}`, params.formData).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_CREATE]({ commit }, params: ContractObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        client: params.client,
        type: params.type,
        active: params.active,
        contractNumber: params.contractNumber,
        startDate: params.startDate,
        endDate: params.endDate,
        observation: params.obs,
        services: params.services
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("contracts/contract", payload).then(({ data }) => {
        resolve(true);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_UPDATE]({ commit }, params: ContractObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        client: params.client,
        type: params.type,
        active: params.active,
        contractNumber: params.contractNumber,
        startDate: params.startDate,
        endDate: params.endDate,
        observation: params.obs,
        services: params.services
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`contracts/contract/${params.id}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`contracts/contract/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractActionTypes.CONTRACT_TYPE_GET]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/type`).then(({ data }) => {
        const result = data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractActionTypes.CONTRACT_CLIENT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/client`).then(({ data }) => {
        const result = data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractActionTypes.CONTRACT_STATE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/state/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_SERVICE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/service`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_SERVICE_VALUE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/service/${params.id}/value`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_FOR_INVOICE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`contracts/contract/for/invoice/${params.limit}/${params.offset}`, params.formData).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_TO_INVOICE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      const data: any = {
        contracts: params
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`contracts/contract/to/invoice`, data).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [ContractActionTypes.CONTRACT_DASHBOARD]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract/dashboard`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
