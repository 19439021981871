/* eslint-disable */
import { UserObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../..";
import { UserActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import { UserMutationTypes } from "./mutation-types";
import axios from "axios";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [UserActionTypes.ALL_USER]({ commit }: AugmentedActionContext): Promise<any>;
  [UserActionTypes.LIST_USER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.CREATE_USER]( { commit }: AugmentedActionContext, params: UserObject ): Promise<boolean>;
  [UserActionTypes.EDIT_USER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.UPDATE_USER]( { commit }: AugmentedActionContext, params: UserObject ): Promise<any>;
  [UserActionTypes.DELETE_USER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.getPersonalData]({ commit, }: AugmentedActionContext): Promise<any>;
  [UserActionTypes.getDocumentData]({ commit }: AugmentedActionContext): Promise<any>;
  [UserActionTypes.getAddressData]({ commit }: AugmentedActionContext): Promise<any>;
  [UserActionTypes.getAddressByZipData]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [UserActionTypes.updatePersonalData]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.updateDocumentData]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.updateAddressData]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.updatePasswordData]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [UserActionTypes.updatePicture]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UserActionTypes.ALL_USER]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/system/user/all").then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.LIST_USER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload:any = {
        company: params.company
      }
      ApiService.post(`/system/user/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.CREATE_USER]({ commit }, params: UserObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload:any = {
        company: params.company,
        contact: params.contact,
        roles: params.roles,
        homepage: params.homepage,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/user", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.EDIT_USER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/user/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.UPDATE_USER]({ commit }, params: UserObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        company: params.company,
        contact: params.contact,
        roles: params.roles,
        homepage: params.homepage,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/system/user/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.DELETE_USER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/user/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [UserActionTypes.getPersonalData]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/profile/personal").then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.getDocumentData]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/profile/document").then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.getAddressData]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/profile/address").then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.updatePersonalData]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.put("/system/profile/personal", params).then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.updateDocumentData]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.put("/system/profile/document", params).then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.updateAddressData]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.put("/system/profile/address", params).then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.updatePasswordData]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.put("/system/profile/password", params).then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.getAddressByZipData]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.get(`/address/${params.zipcode}`).then(({ data }) => {
          const result = data;
          resolve(result)
      })
      .catch(({response}) => {
          reject(response);
      }) 
    });
  },

  async [UserActionTypes.updatePicture]( { commit }, params: any ) {
    return new Promise<boolean>((resolve, reject) => {
      const formdata = new FormData();
      ApiService.setHeader();
      formdata.append('image', params);
      axios.post("/system/profile/picture", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject(error);
      });
    });
  },
};
