<div class="table-responsive text-nowrap">
    <table class="table" :class="tableClass">
        <thead :class="theadClass">
            <tr>
                <th v-for="( column, prop ) in columns" 
                    :key="prop" 
                    :class="column.thClass" 
                    :width="column.thWidth"
                >
                    {{ column.text }}
                    <!-- <div 
                        class="arrow" 
                        v-if="column == sortColumn" 
                        v-bind:class="[ascending ? 'arrow_up' : 'arrow_down']"
                    >
                    </div> -->
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="( row, index ) in rows" :key="index" :class="row.class">
                <td v-for="( _, prop ) in columns" 
                    :key="prop" 
                    :class="_.tdClass"
                    :width="_.thWidth"
                    >
                    <slot :name="'cell-' + _.key" :row="row">
                        {{ row[ _.key ] }}
                    </slot>
                </td>
            </tr>
        </tbody>
    </table>
</div>
