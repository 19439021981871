import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "container-fluid" }

export function render(_ctx, _cache) {
  const _component_app_breadcrumb = _resolveComponent("app-breadcrumb")
  const _component_grid = _resolveComponent("grid")
  const _component_account_form = _resolveComponent("account-form")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("labels.billsToPay", 2)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_breadcrumb, { class: "float-sm-right" })
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_grid, {
          "account-type": "P",
          onEdit: _ctx.edit,
          onNew: _ctx.onNew,
          "new-record": true,
          ref: "accountGrid"
        }, null, 8, ["onEdit", "onNew"]),
        _createVNode(_component_account_form, {
          ref: "accountForm",
          onAftersave: _ctx.onGrid,
          "account-type": "P"
        }, null, 8, ["onAftersave"])
      ])
    ])
  ], 64))
}