<div class="custom-file">
    <input 
        type="file" 
        ref="fileInput"
        @change="handleFileChange"
        :multiple="multiple"
        class="custom-file-input" 
        id="custom-file"
    >
    <label for="custom-file" class="custom-file-label">{{ text }}</label>
</div>