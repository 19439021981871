<!-- The overlay -->
<div :id="rightCurtainName" class="right-overlay">
    <div class="card rounded-0 shadow" :class="cardClass">
        <div class="card-header rounded-0 shadow-sm">
            <h2 class="card-title mt-2" :class="cardTitleClass">
                <slot name="title"></slot>
            </h2>
            <div class="card-tools">
                <button 
                    type="button" 
                    @click="closeNav()" 
                    class="btn btn-default rounded-0 shadow-sm" 
                    data-card-widget="remove"
                >
                    <i class="fas fa-times"></i>
                    FECHAR
                </button>
            </div>
        </div>
        <div class="card-body">
            <slot name="content"></slot>
        </div>
        <!-- /.card-body -->
        <div class="card-footer rounded-0">
            <slot name="footer"></slot>
        </div>
        <!-- /.card-footer-->
    </div>
</div>