/* eslint-disable */
import inputDefault from "@/components/form/input-default/input-default.vue";
import inputDropDown from "@/components/form/dropdown/dropdown.vue";
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { FinanceBankActionTypes } from "@/store/finance/bank/action-types";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "FinanceBank",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-input-dropdown": inputDropDown
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const formSearch = ref({
      longName: "",
    });
    const formCreate = ref({
      form: {
        id: "",
        compe: "",
        ispb: "",
        document: "",
        longName: "",
        shortName: "",
        network: "",
        type: "",
        pixType: "",
        charge: "",
        creditDocument: "",
        salaryPortability: "",
        products: "",
        url: "",
        dateOperationStarted: "",
        datePixStarted: "",
        dateRegistered: "",
        dateUpdated: ""
      }
    });

    const rules = computed(() => {
      return {
        form:{
          compe: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(10)
            )
          },
          ispb: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(255)
            )
          },
          document: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(14)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(18)
            )
          },
          longName: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(4)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(255)
            )
          },
          shortName: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(255)
            )
          },
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const inputDropDownChargePayload: any = ref([
      { key: true, text: 'SIM'},
      { key: false, text: 'NÂO'},
    ]);

    const inputDropDownCreditDocumentPayload: any = ref([
      { key: true, text: 'SIM'},
      { key: false, text: 'NÂO'},
    ]);

    const form = ref();
    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "ID",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "compe",
        text: "Compe",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "longName",
        text: "Razão Social",
        thClass: "",
        thWidth: "45%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "shortName",
        text: "Nome Fantasia",
        thClass: "",
        thWidth: "45%",
        tdClass: "",
        tdWidth: "",
      }
    ];

    const onSearch = () => {
      onList();
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const openForm = () => {
      formCreate.value.form.compe = "";
      formCreate.value.form.ispb = "";
      formCreate.value.form.document = "";
      formCreate.value.form.longName = "";
      formCreate.value.form.shortName = "";
      formCreate.value.form.network = "";
      formCreate.value.form.type = "";
      formCreate.value.form.pixType = "";
      formCreate.value.form.charge = "";
      formCreate.value.form.creditDocument = "";
      formCreate.value.form.salaryPortability = "";
      formCreate.value.form.products = "";
      formCreate.value.form.url = "";
      formCreate.value.form.dateOperationStarted = "";
      formCreate.value.form.datePixStarted = "";
      formCreate.value.form.id = "";
      form.value.openNav();
    };

    onMounted(() => {
      onList();
    });

    const onList = async () => {
      
      const loader = $loading.show({
        loader: "dots",
      });

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        longName: formSearch.value.longName,
      };

      await store.dispatch(FinanceBankActionTypes.FINANCE_BANK_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceBankActionTypes.FINANCE_BANK_UPDATE, formCreate.value.form).then(() => {
        form.value.closeNav();
        onList();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
      })
      .catch((err) => {
        onList();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onCreate = async () => {
     
        const result = await v$.value.$validate();
        if(!result){
          return;
        };

        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch( FinanceBankActionTypes.FINANCE_BANK_CREATE, formCreate.value.form ).then(() => {
            form.value.closeNav();
            onList();
            loader.hide();
            Swal.fire({
              title: "Good job!",
              text: "Record stored!",
              icon: "success",
              allowOutsideClick: false,
            });
        }).catch(() => {
          loader.hide();
        });
        
    };

    const onDelete = (id: number) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            const loader = $loading.show({
              loader: "dots",
            });
      
            store.dispatch(FinanceBankActionTypes.FINANCE_BANK_DELETE, { id: id }).then(() => {
              Swal.fire({
                title: "Deleted!",
                text: "Your record has been deleted.",
                icon: "success",
                allowOutsideClick: false,
              });
              onList();
              loader.hide();
            }).catch(() => {
              loader.hide();
            });
          }
        });

    };

    const onEdit = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceBankActionTypes.FINANCE_BANK_EDIT, { id: id }).then((data) => {
        openForm();
        formCreate.value.form.compe = data.compe;
        formCreate.value.form.ispb = data.ispb;
        formCreate.value.form.document = data.document;
        formCreate.value.form.longName = data.longName;
        formCreate.value.form.shortName = data.shortName;
        formCreate.value.form.network = data.network;
        formCreate.value.form.type = data.type;
        formCreate.value.form.pixType = data.pixType;
        formCreate.value.form.charge = data.charge;
        formCreate.value.form.creditDocument = data.creditDocument;
        formCreate.value.form.salaryPortability = data.salaryPortability;
        formCreate.value.form.products = data.products;
        formCreate.value.form.url = data.url;
        formCreate.value.form.dateOperationStarted = data.dateOperationStarted;
        formCreate.value.form.datePixStarted = data.datePixStarted;
        formCreate.value.form.id = data.id;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    return {
      formSearch,
      formCreate,
      gridColumns,
      rows,
      onSearch,
      onCreate,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      openForm,
      form,
      v$,
      onList,
      onDelete,
      onEdit,
      onStore,
      visibility,
      inputDropDownChargePayload,
      inputDropDownCreditDocumentPayload,
    };
  },
});
