import { AccessLogData, AccessLogObject } from "@/@types/app/accesslog";

export type State = {
  data: AccessLogObject | null;
  accessLogData: AccessLogData;
};

export const state: State = {
  data: null,
  accessLogData: [],
};
