import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-box" }
const _hoisted_2 = { class: "card card-outline rounded-0 card-primary" }
const _hoisted_3 = { class: "card-header text-center" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "login-box-msg" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-7" }
const _hoisted_8 = { class: "col-5" }
const _hoisted_9 = { class: "mb-1" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_input = _resolveComponent("app-input")
  const _component_pf_checkbox = _resolveComponent("pf-checkbox")
  const _component_pf_button = _resolveComponent("pf-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "h1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t("labels.solluzi")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("labels.signInToStart")), 1),
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers((...args) => (_ctx.loginByAuth && _ctx.loginByAuth(...args)), ["prevent"]))
        }, [
          _createVNode(_component_app_input, {
            icon: "envelope",
            class: "mb-3",
            type: "email",
            placeholder: _ctx.usernamePlaceholder,
            autocomplete: "off",
            modelValue: _ctx.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.email) = $event))
          }, null, 8, ["placeholder", "modelValue"]),
          _createVNode(_component_app_input, {
            icon: "lock",
            class: "mb-3",
            type: "password",
            placeholder: _ctx.passwordPlaceholder,
            autocomplete: "off",
            modelValue: _ctx.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.password) = $event))
          }, null, 8, ["placeholder", "modelValue"]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_pf_checkbox, {
                id: "icheck",
                modelValue: _ctx.rememberMe,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.rememberMe) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("labels.rememberMe")), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_pf_button, {
                type: "submit",
                class: "rounded-0",
                block: "true",
                loading: _ctx.isAuthLoading,
                disabled: _ctx.isFacebookLoading || _ctx.isGoogleLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("labels.signIn")), 1)
                ]),
                _: 1
              }, 8, ["loading", "disabled"])
            ])
          ])
        ], 32),
        _createElementVNode("p", _hoisted_9, [
          _createVNode(_component_router_link, { to: "/forgot-password" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("labels.forgotPassword")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}