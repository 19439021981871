import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx((slotProps) => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(slotProps.Component)))
    ]),
    _: 1
  }))
}