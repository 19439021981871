import { InvoiceData, InvoiceObject } from "@/@types";

export type State = {
  data: InvoiceObject | null;
  invoiceData: InvoiceData;
};

export const state: State = {
  data: null,
  invoiceData: [],
};
