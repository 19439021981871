/* eslint-disable */
import { CountryObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../..";
import { CountryActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [CountryActionTypes.LIST_COUNTRY]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [CountryActionTypes.CREATE_COUNTRY]( { commit }: AugmentedActionContext, params: CountryObject ): Promise<boolean>;
  [CountryActionTypes.EDIT_COUNTRY]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [CountryActionTypes.UPDATE_COUNTRY]( { commit }: AugmentedActionContext, params: CountryObject ): Promise<any>;
  [CountryActionTypes.DELETE_COUNTRY]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CountryActionTypes.LIST_COUNTRY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        nicename: params.nicename,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/system/country/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CountryActionTypes.CREATE_COUNTRY]( { commit }, params: CountryObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload:any = {
        iso: params.iso,
        name: params.name,
        nicename: params.nicename,
        iso3: params.iso3,
        numcode: params.numcode,
        phonecode: params.phonecode,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/country", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CountryActionTypes.EDIT_COUNTRY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/country/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CountryActionTypes.UPDATE_COUNTRY]( { commit }, params: CountryObject ) {
    return new Promise<void>((resolve, reject) => {
      const payload:any = {
        iso: params.iso,
        name: params.name,
        nicename: params.nicename,
        iso3: params.iso3,
        numcode: params.numcode,
        phonecode: params.phonecode,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/system/country/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CountryActionTypes.DELETE_COUNTRY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/country/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
