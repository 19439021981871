import { ServiceTypeData, ServiceTypeObject } from "@/@types";

export type State = {
  data: ServiceTypeObject | null;
  serviceTypeData: ServiceTypeData;
};

export const state: State = {
  data: null,
  serviceTypeData: [],
};
