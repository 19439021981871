/* eslint-disable */ 
import { Ref, defineComponent, ref, defineEmits } from "vue";

export default defineComponent({
    name: "UploadMultiple",
    props: {
        multiple: Boolean,
        text: String
    },
    setup(props: { multiple: boolean }, { emit }){
        const fileInputRef: Ref<HTMLInputElement | null> = ref(null);

        //const emit = defineEmits(['files-selected']);

        const handleFileChange = (event: Event) => {
            const input = event.target as HTMLInputElement;
            if(!input.files) return;

            const selectedFiles = Array.from(input.files);
            emit('files-selected', selectedFiles);

            // Reset the input field to allow selecting the same files again
            input.value = "";
        }

        return {
            fileInputRef,
            handleFileChange
        }
    }
});