/* eslint-disable */
import Cookies from "js-cookie";

export const setCookie = (name: string, value: string, expiresDays: number) => {
    Cookies.set(name, value, { expires: expiresDays });
};

export const getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
};

export const deleteCookie = (name: string) => {
    Cookies.remove(name);
};
