import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "table-responsive text-nowrap" }
const _hoisted_2 = ["width"]
const _hoisted_3 = ["width"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass(["table", _ctx.tableClass])
    }, [
      _createElementVNode("thead", {
        class: _normalizeClass(_ctx.theadClass)
      }, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, prop) => {
            return (_openBlock(), _createElementBlock("th", {
              key: prop,
              class: _normalizeClass(column.thClass),
              width: column.thWidth
            }, _toDisplayString(column.text) + " ", 11, _hoisted_2))
          }), 128))
        ])
      ], 2),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: index,
            class: _normalizeClass(row.class)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (_, prop) => {
              return (_openBlock(), _createElementBlock("td", {
                key: prop,
                class: _normalizeClass(_.tdClass),
                width: _.thWidth
              }, [
                _renderSlot(_ctx.$slots, 'cell-' + _.key, { row: row }, () => [
                  _createTextVNode(_toDisplayString(row[ _.key ]), 1)
                ])
              ], 10, _hoisted_3))
            }), 128))
          ], 2))
        }), 128))
      ])
    ], 2)
  ]))
}