import { CountryData, CountryObject } from "@/@types";

export type State = {
  data: CountryObject | null;
  countryData: CountryData;
};

export const state: State = {
  data: null,
  countryData: [],
};
