/* eslint-disable */
import { IUser } from "@/interfaces/user";
import { Options, Vue } from "vue-class-component";
import { PfDropdown, PfImage } from "@profabric/vue-components";
import { SignInActionsTypes } from "@/store/auth/action-types";

@Options({
  name: "user-dropdown",
  components: {
    "pf-dropdown": PfDropdown,
    "pf-image": PfImage,
  },
})
export default class User extends Vue {
  get user(): any {
    //return this.$store.getters["auth/user"];
    return localStorage.getItem("name");
  }

  private logout() {
    this.$store.dispatch(SignInActionsTypes.signOut);
  }

  get readableCreatedAtDate() {
    if (this.user) {
      /* return DateTime.fromISO(this.user.createdAt).toFormat(
                'dd LLLL yyyy'
            ); */
            return localStorage.getItem("created_at");
      //return null;
    }
    return "";
  }
  
  get userPicture() {
    let image: any = "";
      if(localStorage.getItem('picture') !== undefined && localStorage.getItem('picture') !== '' && localStorage.getItem('picture') !== 'null'){
        image = localStorage.getItem('picture');
      } else {
        image = require('@/assets/img/default-profile.png');
      }

      return image;
  }
}
