/* eslint-disable */
import { FinanceBankAccountData, FinanceBankAccountObject } from "@/@types";

export type State = {
  financeBankAccount: FinanceBankAccountObject | null;
  financeBankAccountData: FinanceBankAccountData;
};

export const state: State = {
  financeBankAccount: null,
  financeBankAccountData: [],
};
