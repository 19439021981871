/* eslint-disable */
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import tableList from "../List/table.vue";
import Form from "../Form/form.vue";


export default defineComponent({
  name: "FinanceBillsToReceive",
  components: {
    "app-breadcrumb": Breadcrumb,
    "grid": tableList,
    'account-form': Form
  },
  setup() {
    const accountForm = ref();
    const accountGrid = ref();

    function onGrid(){
      accountGrid.value.onList();
    };

    function edit(id: any) {
      accountForm.value.onEdit(id);    
    };

    function onNew(){
      accountForm.value.onFormOpen();
    }

    return {
      edit,
      onNew,
      accountForm,
      onGrid,
      accountGrid,
    }
  },
});
