import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "disabled", "indeterminate", "true-value", "false-value", "value", "checked"]
const _hoisted_2 = ["for"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-inline", _ctx.iradioClass])
  }, [
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.computedValue) = $event)),
      type: "radio",
      name: _ctx.name,
      disabled: _ctx.disabled,
      indeterminate: _ctx.indeterminate,
      "true-value": _ctx.trueValue,
      "false-value": _ctx.falseValue,
      value: _ctx.value,
      checked: _ctx.checked,
      class: "iradio-custom"
    }, null, 8, _hoisted_1), [
      [_vModelRadio, _ctx.computedValue]
    ]),
    _createElementVNode("label", { for: _ctx.id }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ], 2))
}