<pf-dropdown hide-arrow>
    <div slot="button">
        <i class="far fa-bell"></i>
        <span class="badge badge-warning navbar-badge" v-if="totalRecords">{{ totalRecords }}</span>
    </div>
    <div slot="menu">
        <span class="dropdown-item dropdown-header">
            {{ totalRecords }} {{ $t("labels.notifications") }}
        </span>
        <div class="dropdown-divider"></div>
        <span  v-for="notification in notifications" :key="notification.id">
            <a :href="`/service/issue/notification/${notification.id}`" class="dropdown-item">
                <i class="far fa-comment mr-2"></i> 
                {{ notification.message }}
            </a>
            <div class="dropdown-divider"></div>
        </span>
        <!-- <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 {{ $t("labels.friendRequests")
            }}
            <span class="float-right text-muted text-sm"
                >12 {{ $t("labels.hours") }}</span
            >
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 {{ $t("labels.newReports") }}
            <span class="float-right text-muted text-sm"
                >2 {{ $t("labels.days") }}</span
            >
        </a>
        <div class="dropdown-divider"></div> -->
        <!-- <a href="#" class="dropdown-item dropdown-footer"
            >{{ $t("labels.seeAllNotification") }}</a
        > -->
    </div>
</pf-dropdown>