/* eslint-disable */
export enum ContractActionTypes {
  CONTRACT_LIST          = "ContractList",
  CONTRACT_CREATE        = "ContractCreate",
  CONTRACT_EDIT          = "ContractEdit",
  CONTRACT_UPDATE        = "ContractUpdate",
  CONTRACT_DELETE        = "ContractDelete",
  CONTRACT_TYPE_GET      = "ContractTypeGet",
  CONTRACT_CLIENT        = "ContractClient",
  CONTRACT_STATE         = "ContractState",
  CONTRACT_SERVICE       = "ContractService",
  CONTRACT_SERVICE_VALUE = "ContractServiceValue",
  CONTRACT_FOR_INVOICE   = "ContractForInvoice",
  CONTRACT_TO_INVOICE    = "ContractToInvoice",
  CONTRACT_DASHBOARD     = "ContractDashboard",
}
