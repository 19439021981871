/* eslint-disable */
import { FinanceMovementData, FinanceMovementObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceMovementMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceMovementMutationTypes.SET_INSERT_FINANCE_MOVEMENT_DATA](state: S, payload: FinanceMovementObject): void;
  [FinanceMovementMutationTypes.SET_FINANCE_MOVEMENT_DATA](state: S, payload: FinanceMovementData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceMovementMutationTypes.SET_INSERT_FINANCE_MOVEMENT_DATA](state: State, financeMovement: FinanceMovementObject) {
    state.financeMovement = financeMovement;
  },
  [FinanceMovementMutationTypes.SET_FINANCE_MOVEMENT_DATA](state: State, financeMovementData: FinanceMovementData) {
    state.financeMovementData = financeMovementData;
  },
};
