/* eslint-disable */
import { AccountPlanData, AccountPlanObject } from "@/@types";
import { MutationTree } from "vuex";
import { AccountPlanMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [AccountPlanMutationTypes.SET_INSERT_ACCOUNT_PLAN_DATA](state: S, payload: AccountPlanObject): void;
  [AccountPlanMutationTypes.SET_ACCOUNT_PLAN_DATA](state: S, payload: AccountPlanData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AccountPlanMutationTypes.SET_INSERT_ACCOUNT_PLAN_DATA](state: State, accountPlan: AccountPlanObject) {
    state.data = accountPlan;
  },
  [AccountPlanMutationTypes.SET_ACCOUNT_PLAN_DATA](state: State, accountPlanData: AccountPlanData) {
    state.accountPlanData = accountPlanData;
  },
};
