import { ProjectData, ProjectObject } from "@/@types";

export type State = {
  data: ProjectObject | null;
  projectData: ProjectData;
};

export const state: State = {
  data: null,
  projectData: [],
};
