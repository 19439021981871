import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group row" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "name", "value", "id", "placeholder", "readonly", "maxlength", "size", "data-maska"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "text-error form-text"
}

export function render(_ctx, _cache) {
  const _directive_maska = _resolveDirective("maska")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.labelFor,
      class: _normalizeClass(["col-form-label", _ctx.labelClass])
    }, _toDisplayString(_ctx.label), 11, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.divClass)
    }, [
      _withDirectives(_createElementVNode("input", {
        type: _ctx.type,
        name: _ctx.name,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.updateInput && _ctx.updateInput(...args))),
        class: _normalizeClass(["form-control rounded-0", {'is-valid': _ctx.isActive, 'is-invalid': _ctx.hasError, [_ctx.inputClass]: true}]),
        id: _ctx.inputId,
        placeholder: _ctx.placeHolder,
        readonly: _ctx.readonly,
        onKeyup: _cache[1] || (_cache[1] = $event => (_ctx.onKeypress(_ctx.modelValue))),
        onBlur: _cache[2] || (_cache[2] = $event => (_ctx.onBlur(_ctx.modelValue))),
        maxlength: _ctx.maxlength,
        size: _ctx.size,
        "data-maska": _ctx.maskara
      }, null, 42, _hoisted_3), [
        [_directive_maska, void 0, "mask"]
      ]),
      (_ctx.checkArray(_ctx.error))
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.error, (errorMessage, index) => {
              return (_openBlock(), _createElementBlock("small", {
                class: "text-error form-text",
                key: index
              }, _toDisplayString(errorMessage.$message), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.checkArray(_ctx.error))
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            (!!_ctx.error)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.error), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}