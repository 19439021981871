/* eslint-disable */
import { ProjectObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { ProjectActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import axios from "axios";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ProjectActionTypes.PROJECT_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ProjectActionTypes.PROJECT_CREATE]( { commit }: AugmentedActionContext, params: ProjectObject ): Promise<boolean>;
  [ProjectActionTypes.PROJECT_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ProjectActionTypes.PROJECT_UPDATE]( { commit }: AugmentedActionContext, params: ProjectObject ): Promise<boolean>;
  [ProjectActionTypes.PROJECT_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ProjectActionTypes.PROJECT_STATE]( { commit }: AugmentedActionContext): Promise<any>;
  [ProjectActionTypes.PROJECT_CLIENT]( { commit }: AugmentedActionContext): Promise<any>;
  [ProjectActionTypes.PROJECT_CONTRACT]( { commit }: AugmentedActionContext, params: any): Promise<any>;
  [ProjectActionTypes.PROJECT_CLIENT_LEADER]( { commit }: AugmentedActionContext, params: any): Promise<any>;
  [ProjectActionTypes.PROJECT_COMPANY_LEADER]( { commit }: AugmentedActionContext): Promise<any>;
  [ProjectActionTypes.PROJECT_DETAIL]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ProjectActionTypes.PROJECT_FILE_DOWNLOAD]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ProjectActionTypes.PROJECT_FILE_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProjectActionTypes.PROJECT_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/project/${params.limit}/${params.offset}`, params.formData).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_CREATE]( { commit }, params: ProjectObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const formData = new FormData();
      formData.append("state", params.state);
      formData.append('client', params.client);
      formData.append('contract', params.contract);
      formData.append('clientTeamLeader', params.clientTeamLeader);
      formData.append('companyTeamLeader', params.companyTeamLeader);
      formData.append('name', params.name);
      formData.append('description', params.description);
      formData.append('estimatedBudget', params.estimatedBudget);
      formData.append('totalAmountSpent', params.totalAmountSpent);
      formData.append('estimatedDuration', params.estimatedDuration);
      for(let i = 0; i < params.attachment.length; i++){
        formData.append('attachment[]', params.attachment[i]);
      }
      
      //const dataForm = data;
      ApiService.setHeader();
      axios.post("/project", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_UPDATE]( { commit }, params: ProjectObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const formData = new FormData();
      formData.append("state", params.state);
      formData.append('client', params.client);
      formData.append('contract', params.contract);
      formData.append('clientTeamLeader', params.clientTeamLeader);
      formData.append('companyTeamLeader', params.companyTeamLeader);
      formData.append('name', params.name);
      formData.append('description', params.description);
      formData.append('estimatedBudget', params.estimatedBudget);
      formData.append('totalAmountSpent', params.totalAmountSpent);
      formData.append('estimatedDuration', params.estimatedDuration);
      for(let i = 0; i < params.attachment.length; i++){
        formData.append('attachment[]', params.attachment[i]);
      }
      ApiService.json();
      ApiService.setHeader();
      axios.post(`/project/${params.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });

      /* ApiService.put(`/project/state/${params.id}`, payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      }); */
    });
  },

  async [ProjectActionTypes.PROJECT_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/project/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_STATE]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/state`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_CLIENT]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get('/project/client').then(({ data }) => {
        const result = data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_CONTRACT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/contract/${params}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_CLIENT_LEADER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/client-team-leader/${params}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ProjectActionTypes.PROJECT_COMPANY_LEADER]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/company-team-leader`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  }, 

  async [ProjectActionTypes.PROJECT_DETAIL]({ commit }, params: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/project/detail/${params}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  }, 

  async [ProjectActionTypes.PROJECT_FILE_DOWNLOAD]({ commit }, params: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();

      axios.get(`/project/file-download/${params}`, { responseType: 'blob'}).then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }, 

  async [ProjectActionTypes.PROJECT_FILE_DELETE]({ commit }, params: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();

      ApiService.delete(`/project/file-delete/${params}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  }, 
};
