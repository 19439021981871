/* eslint-disable */
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Panel from "@/components/ui/panel/panel.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import { ContractActionTypes } from "@/store/services/contract/action-types";
import { useLoading } from "vue-loading-overlay";
import Swal from "sweetalert2";
import { InvoiceActionTypes } from "@/store/services/invoice/action-types";

export default defineComponent({
    name: "InvoiceBills",
    components: {
      "app-breadcrumb": Breadcrumb,
      "app-panel": Panel,
      "app-pagination": Paginate,
      "app-button": Button,
    },
    setup() {
      const store = useStore();
      const filter = ref("");
      const contracts = ref([]);
      const checked: any = ref([]);
      const filtering = ref("");
      const totalPages = ref(0);
      const perPage = ref(10);
      const currentPage = ref(1);
      const total = ref(2);
      const clients = ref({});

      onMounted(() => {
        getInvoices();
        onGetClents();
      });

      const $loading = useLoading({
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 9999,
      });
      
      const getInvoices = () => {
        const loader = $loading.show({
          loader: "dots",
        });

        const params = {
          limit: perPage.value,
          offset: (currentPage.value - 1) * perPage.value,
          formData: {
            client: filtering.value
          }
        };

        store.dispatch(InvoiceActionTypes.INVOICE_FOR_BILL, params).then((data) => {
          contracts.value = data.data;
          total.value = data.totalRecords;
          totalPages.value = data.totalPages;
          loader.hide();
        }).catch(() => {
          loader.hide();
        })
      };

      const onGetClents = async () => {
        try {
          await store.dispatch(InvoiceActionTypes.INVOICE_CLIENT).then((data) => {
            clients.value = data;
          });
        } catch (err: any) {
          return;
        }
      };

      const checkAll = computed({
        get: () => contracts.value ? checked.value.length === contracts.value.length : false,
        set: (value) => {
          if (value) {
            checked.value = contracts.value.map((contract: any) => contract.id);
          } else {
            checked.value = [];
          }
        }
      });

      const filterInvoice = ($event: any) => {
        getInvoices();
      };

      const onPageChange = (page: number) => {
        currentPage.value = page;
        getInvoices();
      };

      const submitForm = () => {
        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch(InvoiceActionTypes.INVOICE_TO_BILL, checked.value).then((data) => {
          getInvoices();
          loader.hide();
          Swal.fire({
            title: "Gerada(s)!",
            text: "Fatura(s) gerada(s) com sucesso!",
            icon: "success",
            allowOutsideClick: false,
          });
        }).catch(() => {
          getInvoices();
          loader.hide();
          Swal.fire({
            title: "Ooops!",
            text: "Algo errado acontceu, fale com seu administrador!",
            icon: "error",
            allowOutsideClick: false,
          });
        });
      }
      
      return { 
        filter, 
        contracts,
        checked,
        checkAll,
        submitForm,
        filterInvoice,
        filtering,
        onPageChange,
        totalPages,
        perPage,
        currentPage,
        total,
        clients,
      }
    }
});