import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-efff2524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card card-primary card-outline card-outline-tabs" }
const _hoisted_2 = { class: "card-header p-0 border-bottom-0" }
const _hoisted_3 = {
  class: "nav nav-tabs",
  id: "custom-tabs-four-tab",
  role: "tablist"
}
const _hoisted_4 = ["id", "aria-controls", "aria-selected"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = {
  class: "tab-content",
  id: "custom-tabs-four-tabContent"
}
const _hoisted_8 = ["id", "aria-labelledby"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "nav-item",
            id: tab.id,
            "data-toggle": "pill",
            role: "tab",
            "aria-controls": tab.ariaControls,
            "aria-selected": (_ctx.activeTab === index),
            key: index
          }, [
            _createElementVNode("a", {
              class: _normalizeClass(["nav-link", { active: _ctx.activeTab === index, 'disabled': tab.disabled }]),
              onClick: $event => (_ctx.changeTab(index)),
              href: "javascript:;"
            }, _toDisplayString(tab.label), 11, _hoisted_5)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["tab-pane fade", {
                    active: _ctx.activeTab === index,
                    show:  _ctx.activeTab === index
                }]),
            id: tab.ariaControls,
            role: "tabpanel",
            "aria-labelledby": tab.id,
            key: index
          }, [
            _renderSlot(_ctx.$slots, _ctx.tabs[_ctx.activeTab].name, {}, undefined, true)
          ], 10, _hoisted_8))
        }), 128))
      ])
    ])
  ]))
}