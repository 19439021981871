/* eslint-disable */
const getError = (error: any) => {
  const message = error.message || "Failed";
  return new Error(message);
};

export const loginByAuth = async (email: string, password: string) => {
  try {
    //const token = await Gatekeeper.loginByAuth(email, password);
    const token = email;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const registerByAuth = async (email: string, password: string) => {
  try {
    //const token = await Gatekeeper.registerByAuth(email, password);
    const token = await null;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const loginByGoogle = async () => {
  try {
    //const token = await Gatekeeper.loginByGoogle();
    const token = await null;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const registerByGoogle = async () => {
  try {
    //const token = await Gatekeeper.registerByGoogle();
    const token = await null;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const loginByFacebook = async () => {
  try {
    //const token = await Gatekeeper.loginByFacebook();
    const token = await null;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};

export const registerByFacebook = async () => {
  try {
    //const token = await Gatekeeper.registerByFacebook();
    const token = await null;
    return token;
  } catch (error: any) {
    throw getError(error);
  }
};
export const getProfile = async () => {
  try {
    //const user = await Gatekeeper.getProfile();
    const user = "Mauro Miranda";
    return user;
  } catch (error: any) {
    throw getError(error);
  }
};
