import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-xl" }
const _hoisted_3 = { class: "modal-content rounded-0" }
const _hoisted_4 = { class: "modal-header shadow-sm" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "modal-footer justify-content-between" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade rounded-0 extra",
    id: _ctx.identityName
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h4", _hoisted_5, [
            _renderSlot(_ctx.$slots, "title")
          ]),
          _createElementVNode("button", {
            type: "button",
            id: `${_ctx.identityName}-modal`,
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
            class: "btn btn-default rounded-0 shadow",
            "data-dismiss": "modal"
          }, "Fechar", 8, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "content")
        ]),
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "footer")
        ])
      ])
    ])
  ], 8, _hoisted_1))
}