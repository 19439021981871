<div class="card rounded-0 shadow" :class="class">
    <div class="card-header rounded-0 shadow-sm">
        <slot name="header"></slot>
        <!-- <h3 class="card-title">
            <slot name="title"></slot>
        </h3> -->
    </div>
    <div class="card-body">
        <slot name="content"></slot>
    </div>
    <!-- /.card-body -->
    <div class="card-footer rounded-0">
        <slot name="footer"></slot>
    </div>
    <!-- /.card-footer-->
</div>