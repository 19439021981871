/* eslint-disable */
import Dropdown from "@/components/form/dropdown/dropdown";
import inputDefault from "@/components/form/input-default/input-default.vue";
import Grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { PeopleActionTypes } from "@/store/admin/person/people/action-types";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import { vMaska } from "maska";
import { RoleActionTypes } from "@/store/admin/person/roles/action-types";
import Swal from "sweetalert2";
import { i18n } from "@/translation";
import Datepicker from "vue3-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import imageInput from "@/components/form/image-input/image-input.vue";
import { isVisible } from "@/classes/is_visible";
import { useToast } from "vue-toastification";
import { helpers, minLength, required, email, maxLength, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "People",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": Grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-select-input": Dropdown,
    "app-datepicker": Datepicker,
    "app-image-input": imageInput,
  },
  directives: { maska: vMaska },
  setup(props, ctx) {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const mascara = ref("###.###.###-##");
    const toast = useToast();
    /* ================= START LISTING ===================== */
    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "10%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "lastName",
        text: "Razão Social",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "niche",
        text: "Nicho",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "active",
        text: "Ativo",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "date",
        text: "Data de desativação",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const peopleFormSearch = ref({
      nome_fantasia: "",
      person_document: "",
      razao_social: "",
      email: "",
    });

    onMounted(() => {
      onList();
      getRoles();
      getNiches();
      getCountries();
    });

    const onSearch = () => {
      onList();
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onList = async () => {
      
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        document: peopleFormSearch.value.person_document,
        nome_fantasia: peopleFormSearch.value.nome_fantasia,
        razao_social: peopleFormSearch.value.razao_social,
        email: peopleFormSearch.value.email,
      };

      const loader = $loading.show({
        loader: "dots",
      });
      
      await store.dispatch(PeopleActionTypes.LIST_PEOPLE, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    /* ================== END LISTING ===================== */

    /* ================== START FORM ====================== */
    const people = ref([
      { name: "Pessoa Física", acronym: "PF" },
      { name: "Pessoa Júridica", acronym: "PJ" },
    ]);

    const peopleCurtain = ref();

    const imageUrl = ref();

    const roles = ref([]);

    const contactId: any = ref("");

    const contactArray: any = ref([]);

    const pickedPerson = ref("PF");

    const concatArray = (value: any) => {
      const roles = JSON.parse(value);
      return roles.join(",");
    };

    const peopleFormRegistration = ref({
      form: {
        id: "",
        country: "",
        picture: "",
        type: "",
        document: "",
        identity: "",
        dtOfBirth: new Date(),
        firstname: "",
        lastname: "",
        niche: "",
        role: [],
        phone: "",
        email: "",
        observation: "",
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        state: "",
        city: "",
        contacts: [],
        asaas: "",
      }
    });

    const prules: any = computed(() => {
      return {
        form:{
          country: { 
            required: helpers.withMessage("Escolha um país!", required),
            minLength: minLength(2) 
          },
          firstname: { 
            required: helpers.withMessage("Este campo é obrigatório!", required), 
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo de o tamanho de '${$model}', 
              porém tem que ter o minimo de '${$params.min}', sendo assim está ${$invalid} ? 'invalid' : 'valid'`, 
              minLength(5)
            )
          },
          lastname: { 
            required: helpers.withMessage("Este campo é obrigatório!", required),
            minLength: minLength(5) 
          },
          document: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(14) 
          },
          email: { 
            required: helpers.withMessage("Este campo é obrigatório!", required),
            email: helpers.withMessage("E-mail invalido!", email), 
          },
          phone: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(14),
            maxLength: maxLength(15) 
          },
          zipcode: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(9),
            maxLength: maxLength(9) 
          },
          address: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')), 
            minLength: minLength(5)
          },
          number: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(3)
          },
          district: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(5) 
          },
          state: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(2),
            maxLength: maxLength(2), 
          },
          city: { 
            required: helpers.withMessage("Este campo é obrigatório!", requiredIf(peopleFormRegistration.value.form.country === 'BR')),
            minLength: minLength(5)
          },
        }
      }
    });

    const pv$ = useVuelidate(prules, peopleFormRegistration);

    const peopleFormContact = ref({
      form: {
        id: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        observation: "",
      }
    });

    const crules = computed(() => {
      return {
        form:{
          firstname: { 
            required,
            minLength: minLength(5) 
          },
          lastname: { 
            required,
            minLength: minLength(5), 
          },
          email: { 
            required,
            email
          },
          phone: { 
            required,
            minLength: minLength(14),
            maxLength: maxLength(15)
          }
        }
      }
    });

    const cv$ = useVuelidate(crules, peopleFormContact);

    const contactGridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "firstname",
        text: "First Name",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "lastname",
        text: "Last Name",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "email",
        text: "E-mail",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "observation",
        text: "Observation",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const onChoosedPerson = (person: any) => {
      const label = document.getElementById("IdentityLabel") as HTMLElement;
      const cpfLabel = document.getElementById("CpfLabel") as HTMLElement;
      const RSLabel = document.getElementById("Lastname") as HTMLElement;
      const NFLabel = document.getElementById("Firstname") as HTMLElement;
      const dtLabel = document.getElementById("Datebirth") as HTMLElement;

      if (person === "PJ") {
        mascara.value = "##.###.###/####-##";
        label.innerHTML = "I.E.";
        cpfLabel.innerHTML = "CNPJ";
        RSLabel.innerHTML = "Razão Social";
        NFLabel.innerHTML = "Nome Fantasia";
        dtLabel.innerHTML = "Data de Criação";
      } else if (person === "PF") {
        mascara.value = "###.###.###-##";
        label.innerHTML = "RG";
        cpfLabel.innerHTML = "CPF";
        RSLabel.innerHTML = "Sobrenome";
        NFLabel.innerHTML = "Nome";
        dtLabel.innerHTML = "Data de Nascimento";
      }
    };

    const onOpenForm = () => {
      peopleFormRegistration.value.form.id = "";
      peopleFormRegistration.value.form.firstname = "";
      peopleFormRegistration.value.form.lastname = "";
      peopleFormRegistration.value.form.document = "";
      peopleFormRegistration.value.form.identity = "";
      peopleFormRegistration.value.form.niche = "";
      peopleFormRegistration.value.form.role = [];
      peopleFormRegistration.value.form.contacts = [];
      peopleFormRegistration.value.form.dtOfBirth = new Date();
      contactArray.value = [];
      peopleFormRegistration.value.form.email = "";
      peopleFormRegistration.value.form.phone = "";
      peopleFormRegistration.value.form.observation = "";
      peopleFormRegistration.value.form.zipcode = "";
      peopleFormRegistration.value.form.address = "";
      peopleFormRegistration.value.form.number = "";
      peopleFormRegistration.value.form.complement = "";
      peopleFormRegistration.value.form.district = "";
      peopleFormRegistration.value.form.state = "";
      peopleFormRegistration.value.form.city = "";
      peopleFormRegistration.value.form.picture = "";

      peopleFormContact.value.form.email = "";
      peopleFormContact.value.form.firstname = "";
      peopleFormContact.value.form.lastname = "";
      peopleFormContact.value.form.id = "";
      peopleFormContact.value.form.observation = "";
      peopleFormContact.value.form.phone = "";

      peopleCurtain.value.openNav();
    };

    const onAddContact = async () => {
      try {
        const result = await cv$.value.$validate();
        if(!result){
          return;
        }

        if ( peopleFormContact.value.form.firstname !== undefined || peopleFormContact.value.form.firstname !== null ) {
          if ( contactId.value !== undefined && contactId.value !== null && contactId.value !== "" ) {
            contactArray.value[contactId.value].id          = peopleFormContact.value.form.id;
            contactArray.value[contactId.value].firstname   = peopleFormContact.value.form.firstname;
            contactArray.value[contactId.value].firstname   = peopleFormContact.value.form.firstname;
            contactArray.value[contactId.value].lastname    = peopleFormContact.value.form.lastname;
            contactArray.value[contactId.value].email       = peopleFormContact.value.form.email;
            contactArray.value[contactId.value].phone       = peopleFormContact.value.form.phone;
            contactArray.value[contactId.value].observation = peopleFormContact.value.form.observation;
          } else {
            contactArray.value.push({
              id: 0,
              firstname: peopleFormContact.value.form.firstname,
              lastname: peopleFormContact.value.form.lastname,
              email: peopleFormContact.value.form.email,
              phone: peopleFormContact.value.form.phone,
              observation: peopleFormContact.value.form.observation,
            });
          }
          const unique = getUniqueListBy(contactArray.value, "email");
          contactArray.value = unique;
          contactId.value = "";
          peopleFormContact.value.form.firstname = "";
          peopleFormContact.value.form.lastname = "";
          peopleFormContact.value.form.email = "";
          peopleFormContact.value.form.phone = "";
          peopleFormContact.value.form.observation = "";
          /* contactArray.value = uniqueElementsBy(
            contactArray.value,
            (a: any, b: any) => a.id == b.id
          ); */
        }
          
      } catch (err: any) {
        return;
      }
    };

    const getUniqueListBy = (arr: [], key: any) => {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    const onContactDelete = (id: any) => {
      if(visibility('person.remove.contact')){
        if ( 
          peopleFormRegistration.value.form.id !== undefined && 
          peopleFormRegistration.value.form.id !== "" && 
          contactArray.value[id].id !== undefined && 
          contactArray.value[id].id !== "" 
        ) {
        
          Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              const loader = $loading.show({
                loader: "dots",
              });
              store.dispatch(PeopleActionTypes.DELETE_PEOPLE_CONTACT, { id: contactArray.value[id].id, }).then(() => {
                Swal.fire({
                  title: "Deleted!",
                  text: "Your record has been deleted.",
                  icon: "success",
                  allowOutsideClick: false,
                });
                onEdit(peopleFormRegistration.value.form.id);
                loader.hide();
              }).catch(() => {
                loader.hide();
              });
            }
          });
          
        } else {
          contactArray.value.splice(id, 1);
        }
      } else {
        toast.error('Você não tem permissão para excluir 1 contato. Por favor fale com o seu gestor!');
      }
    };

    const onContactEdit = (id: any) => {
      contactId.value                          = id;
      peopleFormContact.value.form.id          = contactArray.value[id].id
      peopleFormContact.value.form.firstname   = contactArray.value[id].firstname;
      peopleFormContact.value.form.firstname   = contactArray.value[id].firstname;
      peopleFormContact.value.form.lastname    = contactArray.value[id].lastname;
      peopleFormContact.value.form.email       = contactArray.value[id].email;
      peopleFormContact.value.form.phone       = contactArray.value[id].phone;
      peopleFormContact.value.form.observation = contactArray.value[id].observation;
    };

    const getPeopleInfo = async () => {
      const document: any = peopleFormRegistration.value.form.document.replace(/\D/g,"");
      if (
        (document.length == 11 || document.length === 14) && 
        (peopleFormRegistration.value.form.firstname !== undefined || peopleFormRegistration.value.form.firstname !== null)
      ) {
        const loader = $loading.show({
          loader: "dots",
        });
        await store.dispatch(PeopleActionTypes.GET_PEOPLE_INFO, { document: document }).then((data) => {
          peopleFormRegistration.value.form.lastname    = data.razao_social;
          peopleFormRegistration.value.form.firstname   = data.estabelecimento.nome_fantasia;
          peopleFormRegistration.value.form.email       = data.estabelecimento.email;
          peopleFormRegistration.value.form.dtOfBirth   = new Date(data.estabelecimento.data_inicio_atividade);
          peopleFormRegistration.value.form.phone       = data.estabelecimento.ddd1 + data.estabelecimento.telefone1;
          peopleFormRegistration.value.form.observation = data.natureza_juridica.descricao;
          /* Address */
          peopleFormRegistration.value.form.zipcode  = data.estabelecimento.cep;
          peopleFormRegistration.value.form.address  = data.estabelecimento.tipo_logradouro + " " + data.estabelecimento.logradouro;
          peopleFormRegistration.value.form.number   = data.estabelecimento.numero;
          peopleFormRegistration.value.form.district = data.estabelecimento.bairro;
          peopleFormRegistration.value.form.state    = data.estabelecimento.estado.sigla.toUpperCase();
          peopleFormRegistration.value.form.city     = data.estabelecimento.cidade.nome;
          loader.hide();
        }).catch(() => {
          loader.hide();
        });
      }
    };

    const getPeopleAddress = async () => {
      
      const address: any = peopleFormRegistration.value.form.zipcode.replace(/\D/g, "");
      if ( 
        address.length === 8 && 
        (peopleFormRegistration.value.form.address !== undefined || peopleFormRegistration.value.form.address !== null)
      ) {
        const loader = $loading.show({
          loader: "dots",
        });
        await store.dispatch(PeopleActionTypes.GET_PEOPLE_ADDRESS, { zipcode: peopleFormRegistration.value.form.zipcode, }).then((data) => {
          peopleFormRegistration.value.form.address = data.logradouro;
          peopleFormRegistration.value.form.district = data.bairro;
          peopleFormRegistration.value.form.state = data.uf;
          peopleFormRegistration.value.form.city = data.localidade;
          loader.hide();
        }).catch(() => {
          loader.hide();
        });
      }
      
    };

    const getRoles = async () => {
      try {
        await store.dispatch(RoleActionTypes.ALL_ROLE).then((data) => {
          roles.value = data;
        });
      } catch (error) {
        return;
      }
    };

    const onUpdate = () => {
      peopleFormRegistration.value.form.type = pickedPerson.value;
      peopleFormRegistration.value.form.contacts = contactArray.value;
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(PeopleActionTypes.UPDATE_PEOPLE, peopleFormRegistration.value.form).then(() => {
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
        peopleCurtain.value.closeNav();
      })
      .catch((err) => {
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
        peopleCurtain.value.closeNav();
      });
    };

    const onCreate = async () => {
      
      const result = await pv$.value.$validate();
      if(!result){
        return;
      }

      const loader = $loading.show({
        loader: "dots",
      });

      peopleFormRegistration.value.form.type = pickedPerson.value;
      peopleFormRegistration.value.form.contacts = contactArray.value;
      store.dispatch( PeopleActionTypes.CREATE_PEOPLE, peopleFormRegistration.value.form ).then(() => {
        peopleFormRegistration.value.form.id = "";
        peopleFormRegistration.value.form.country= "";
        peopleFormRegistration.value.form.firstname= "";
        peopleFormRegistration.value.form.lastname = "";
        peopleFormRegistration.value.form.document = "";
        peopleFormRegistration.value.form.identity = "";
        peopleFormRegistration.value.form.niche    = "";
        peopleFormRegistration.value.form.role     = [];
        peopleFormRegistration.value.form.contacts = [];
        contactArray.value = [];
        peopleFormRegistration.value.form.email      = "";
        peopleFormRegistration.value.form.phone      = "";
        peopleFormRegistration.value.form.observation= "";
        peopleFormRegistration.value.form.zipcode    = "";
        peopleFormRegistration.value.form.address    = "";
        peopleFormRegistration.value.form.number     = "";
        peopleFormRegistration.value.form.complement = "";
        peopleFormRegistration.value.form.district   = "";
        peopleFormRegistration.value.form.state      = "";
        peopleFormRegistration.value.form.city       = "";
        peopleFormRegistration.value.form.picture    = "";
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
        peopleCurtain.value.closeNav();
      }).catch(() => {
        loader.hide();
        peopleCurtain.value.closeNav();
      });
      
    };

    const onDelete = (item: any) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Deleted!",
            text: "Your record has been deleted.",
            icon: "success",
            allowOutsideClick: false,
          });
          store.dispatch(PeopleActionTypes.DELETE_PEOPLE, { id: item.id, }).then(() => {
            onList();
          });
        }
      });
    };

    const niches = ref({});

    const getNiches = async() => {
      try {
        await store.dispatch(PeopleActionTypes.GET_ALL_NICHE).then((data) => {
          niches.value = data;
        });
      } catch (error) {
        return;
      }
    };

    const countries = ref({});
    const international = ref(false);

    const getCountries = async() => {
      try {
        await store.dispatch(PeopleActionTypes.GET_ALL_COUNTRIES).then((data) => {
          countries.value = data;
        });
      } catch (error) {
        return;
      }
    };

    const onEdit = (item: any) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(PeopleActionTypes.EDIT_PEOPLE, { id: item }).then((data) => {
          onChoosedPerson(data.type);
          pickedPerson.value = data.type;
          contactArray.value = [];
          setTimeout(function () {
            peopleFormRegistration.value.form.id = data.id;
            peopleFormRegistration.value.form.firstname = data.firstName;
            const image = data.picture ? process.env.VUE_APP_API_DOMAIN + "/assets/img/person/" + data.id +'/'+ data.picture : require('@/assets/img/default-profile.png');
            imageUrl.value = image;
            peopleFormRegistration.value.form.country = data.country;
            peopleFormRegistration.value.form.lastname = data.lastName;
            peopleFormRegistration.value.form.document = data.document;
            peopleFormRegistration.value.form.identity = data.identity;
            peopleFormRegistration.value.form.niche    = data.niche;
            peopleFormRegistration.value.form.role = JSON.parse(data.roles);
            peopleFormRegistration.value.form.dtOfBirth = new Date(data.birthdate);
            peopleFormRegistration.value.form.email = data.email;
            peopleFormRegistration.value.form.phone = data.phone;
            peopleFormRegistration.value.form.observation = data.observation;
            peopleFormRegistration.value.form.zipcode = data.zipcode;
            peopleFormRegistration.value.form.address = data.address;
            peopleFormRegistration.value.form.number = data.number;
            peopleFormRegistration.value.form.complement = data.complement;
            peopleFormRegistration.value.form.district = data.district;
            peopleFormRegistration.value.form.state = data.state;
            peopleFormRegistration.value.form.city = data.city;
            peopleFormRegistration.value.form.asaas = data.asaas;
            contactArray.value = JSON.parse(data.contacts);
            /* Contact */
            peopleFormContact.value.form.email = "";
            peopleFormContact.value.form.firstname = "";
            peopleFormContact.value.form.lastname = "";
            peopleFormContact.value.form.id = "";
            peopleFormContact.value.form.observation = "";
            peopleFormContact.value.form.phone = "";

            loader.hide();
            peopleCurtain.value.openNav();
          }, 500);
        }).catch(() => {
          loader.hide();
        });
    };

    const onStore = () => {
      if (peopleFormRegistration.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onPersonStatus = (id: number) => {
      try {
        Swal.fire({
          title: "Are you sure?",
          text: "You can revert it after!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, change it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "Status!",
              text: "Your record status has been changed.",
              icon: "success",
              allowOutsideClick: false,
            });
            store
              .dispatch(PeopleActionTypes.DELETE_PEOPLE, {
                id: id,
              })
              .then(() => {
                onList();
              });
          }
        });
      } catch (err) {
        return;
      }
    };

    const onContactStatus = (id: number) => {
      if (
        peopleFormRegistration.value.form.id !== undefined &&
        peopleFormRegistration.value.form.id !== "" &&
        contactArray.value[id].id !== undefined &&
        contactArray.value[id].id !== ""
      ) {
        try {
          Swal.fire({
            title: "Are you sure?",
            text: "You can revert it after!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, change it!",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Status!",
                text: "Your record status has been changed.",
                icon: "success",
                allowOutsideClick: false,
              });
              store
                .dispatch(PeopleActionTypes.DELETE_PEOPLE, {
                  id: contactArray.value[id].id,
                })
                .then(() => {
                  onEdit(peopleFormRegistration.value.form.id);
                });
            }
          });
        } catch (err) {
          return;
        }
      }
    };

    const updateFormPicture = (event: any) => {
      peopleFormRegistration.value.form.picture = event;
    };

    const onRemovePicture = () => {
      if(visibility(''))
      if (peopleFormRegistration.value.form.id) {
        return;
      }
    };

    /* =================== END FORM ======================= */

    const visibility = (name: any) => {
      return isVisible(name);
    };

    return {
      /* LISTING */
      rows,
      gridColumns,
      totalPages,
      total,
      perPage,
      currentPage,
      onPageChange,
      /* SEARCH */
      peopleFormSearch,
      onSearch,
      /* REGISTRATION */
      peopleCurtain,
      peopleFormRegistration,
      peopleFormContact,
      pv$,
      cv$,
      concatArray,
      contactGridColumns,
      mascara,
      people,
      pickedPerson,
      contactArray,
      onAddContact,
      onContactDelete,
      onContactStatus,
      onChoosedPerson,
      roles,
      getPeopleInfo,
      getPeopleAddress,
      onOpenForm,
      onStore,
      onContactEdit,
      contactId,
      ptBR,
      onRemovePicture,
      imageUrl,
      updateFormPicture,
      onEdit,
      onPersonStatus,
      visibility,
      niches,
      countries,
      international,
    };
  },
});
