/* eslint-disable */
import { CostCenterData, CostCenterObject } from "@/@types";
import { MutationTree } from "vuex";
import { CostCenterMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [CostCenterMutationTypes.SET_INSERT_COST_CENTER_DATA](state: S, payload: CostCenterObject): void;
  [CostCenterMutationTypes.SET_COST_CENTER_DATA](state: S, payload: CostCenterData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CostCenterMutationTypes.SET_INSERT_COST_CENTER_DATA](state: State, costCenter: CostCenterObject) {
    state.costCenter = costCenter;
  },
  [CostCenterMutationTypes.SET_COST_CENTER_DATA](state: State, costCenterData: CostCenterData) {
    state.costCenterData = costCenterData;
  },
};
