import { IssueStateData, IssueStateObject } from "@/@types";

export type State = {
  data: IssueStateObject | null;
  issueStateData: IssueStateData;
};

export const state: State = {
  data: null,
  issueStateData: [],
};
