<form action="javascript:;" @submit.prevent="onStore">
    <app-right-curtain right-curtain-name="right" ref="bAccountCurtain" card-class="card-default rounded-0">
        <template v-slot:title>
            {{ formTitle }}
        </template>
        <template v-slot:content>
            <div class="card rounded-0 card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link rounded-0" 
                                :class="{ active: activeTab === tabbs.tabOne }"
                                id="custom-tabs-four-home-tab" 
                                data-toggle="pill" 
                                href="#" 
                                role="tab"
                                aria-controls="custom-tabs-four-home"
                                :aria-selected="(activeTab === tabbs.tabOne)"
                                @click="changeTab(tabbs.tabOne)"
                            >
                                INFORMAÇÕES GERAIS
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link rounded-0" 
                                :class="{ 
                                    active: activeTab === tabbs.tabTwo,
                                    'bg-danger': v$.form.accountPlan.$error
                                }"
                                id="custom-tabs-four-profile-tab" 
                                data-toggle="pill" 
                                href="#" 
                                role="tab"
                                aria-controls="custom-tabs-four-profile"
                                :aria-selected="(activeTab === tabbs.tabTwo)"
                                @click="changeTab(tabbs.tabTwo)"
                            >
                                PLANO DE CONTAS
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link rounded-0" 
                                :class="{ 
                                    active: activeTab === tabbs.tabTree,
                                    'bg-danger': v$.form.costCenter.$error
                                }"
                                id="custom-tabs-four-messages-tab" 
                                data-toggle="pill" 
                                href="#"
                                role="tab" 
                                aria-controls="custom-tabs-four-messages"
                                :aria-selected="(activeTab === tabbs.tabTree)"
                                @click="changeTab(tabbs.tabTree)"
                            >
                                CENTROS DE CUSTO
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link rounded-0" 
                                :class="{ active: activeTab === tabbs.tabFour }"
                                id="custom-tabs-four-settings-tab" 
                                data-toggle="pill" 
                                href="#"
                                role="tab" 
                                aria-controls="custom-tabs-four-settings"
                                :aria-selected="(activeTab === tabbs.tabFour)"
                                @click="changeTab(tabbs.tabFour)"
                            >
                                ARQUIVOS
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="custom-tabs-four-tabContent">
                        <div class="tab-pane fade" 
                            :class="{ 
                                active: activeTab === tabbs.tabOne, 
                                show: activeTab === tabbs.tabOne 
                            }" 
                            id="custom-tabs-four-home" 
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-home-tab"
                        >

                            <input type="hidden" name="id" v-model="formCreate.form.id" />
                            <h4>DADOS PESSOAIS</h4>
                            <hr>
                            <div class="row mb-3" id="company">
                                <div class="col-sm-4 text-right">
                                    <label for="Responsável" class="bills-to-pay-label-margin">
                                        {{ formLabel.company }}:
                                    </label>
                                </div>
                                <div class="col">
                                    <Select2 
                                        v-model="v$.form.client.$model"
                                        :options="companies"
                                        :settings="{ 
                                            dropdownParent: '#company', 
                                            width: '100%',
                                            multiple: false,
                                            allowClear: true
                                        }" 
                                    />
                                    <span v-if="checkArray(v$.form.client.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.client.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>

                            <h4>INFORMAÇÕES DE COBRANÇA</h4>
                            <hr>
                            <div class="row mb-3" id="form-payment">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.form }}:
                                    </label>
                                </div>
                                <div class="col-sm">
                                    <Select2 
                                        v-model="v$.form.receiptOrPaymentMethod.$model"
                                        :options="formOfPayments" 
                                        :settings="{ 
                                            dropdownParent: '#form-payment', 
                                            width: '100%',
                                            multiple: false,
                                            allowClear: true
                                        }" 
                                    />
                                    <span v-if="checkArray(v$.form.receiptOrPaymentMethod.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.receiptOrPaymentMethod.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <app-input-inline 
                                label-for="numberOfInstallments"
                                :label="labelFormInstallments" 
                                name="numberOfInstallments"
                                type="number"
                                place-holder=""
                                v-model="v$.form.numberOfInstallments.$model"
                                input-id="numberOfInstallments"
                                input-class="is-valid"
                                div-class="col-sm"
                                label-class="col-sm-4 text-right"
                                :has-error="v$.form.numberOfInstallments.$error" 
                                :is-active="!v$.form.numberOfInstallments.$error"
                                :error="v$.form.numberOfInstallments.$errors" 
                            ></app-input-inline>
                            <div class="row mb-3" id="destiny">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.bankAccount }}:
                                    </label>
                                </div>
                                <div class="col-sm">
                                    <Select2 
                                        v-model="v$.form.accountForReceiptOrPayment.$model" 
                                        :options="accountSource"
                                        :settings="{ 
                                            dropdownParent: '#destiny', 
                                            width: '100%',
                                            multiple: false,
                                            allowClear: true
                                        }" 
                                    />
                                    <span v-if="checkArray(v$.form.accountForReceiptOrPayment.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.accountForReceiptOrPayment.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.competition }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <VueDatePicker 
                                        v-model="v$.form.competition.$model"
                                        :format="dueDateInputOptions" 
                                        locale="pt-BR" 
                                        :clearable="true"
                                        auto-apply 
                                        :min-date="new Date()" 
                                        :enable-time-picker="false"
                                    >
                                    </VueDatePicker>
                                    <span v-if="checkArray(v$.form.competition.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.competition.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.due }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <VueDatePicker 
                                        v-model="v$.form.due.$model"
                                        :format="dueDateInputOptions" 
                                        locale="pt-BR" 
                                        :clearable="true"
                                        auto-apply 
                                        :min-date="new Date()" 
                                        :enable-time-picker="false"
                                    >
                                    </VueDatePicker>
                                    <span v-if="checkArray(v$.form.due.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.due.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.amount }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <money3 
                                        v-model="v$.form.amount.$model" 
                                        v-bind="config"
                                        class="form-control rounded-0 input-money" 
                                    />
                                    <span v-if="checkArray(v$.form.amount.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.amount.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>

                            <h4>INFORMAÇÕES DE PAGAMENTO</h4>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputAccountType" class="bills-to-pay-label-margin">
                                        {{ formLabel.state }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <app-input-dropdown 
                                        :items="accountStatuses"
                                        v-model="v$.form.accountStatus.$model"
                                    >
                                    </app-input-dropdown>
                                    <span v-if="checkArray(v$.form.accountStatus.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.accountStatus.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.paymentReceipt }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <VueDatePicker 
                                        v-model="formCreate.form.receivedPaydDate"
                                        :format="dueDateInputOptions" 
                                        locale="pt-BR" 
                                        :clearable="true"
                                        auto-apply 
                                        :enable-time-picker="false"
                                    >
                                    </VueDatePicker>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.fees }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <money3 
                                        v-model="formCreate.form.interestPercent" 
                                        v-bind="configInterest"
                                        class="form-control rounded-0 input-money" 
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.fine }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <money3 
                                        v-model="formCreate.form.fine" 
                                        v-bind="configInterest"
                                        class="form-control rounded-0 input-money" 
                                    />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-4 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        {{ formLabel.amountPaid }}:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <money3 
                                        v-model="v$.form.finalAmount.$model" 
                                        v-bind="config"
                                        class="form-control rounded-0 input-money" 
                                    />
                                    <span v-if="checkArray(v$.form.finalAmount.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.finalAmount.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <label for="infoObservation">{{ formLabel.observation }}</label>
                                    <textarea 
                                        name="observation" 
                                        class="form-control rounded-0"
                                        placeholder="Informações que queira adicionar e que seja de relevância"
                                        id="observation" 
                                        cols="30" 
                                        rows="10"
                                        v-model="formCreate.form.observation"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" 
                            :class="{ 
                                active: activeTab === tabbs.tabTwo, 
                                show: activeTab === tabbs.tabTwo 
                            }" 
                            id="custom-tabs-four-profile" 
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-profile-tab"
                        >
                            <div id="account-plan-container" style="font-size: 1rem!important;"></div>
                            <span v-if="checkArray(v$.form.accountPlan.$errors)">
                                <small 
                                    id="emailHelp" 
                                    class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.accountPlan.$errors" 
                                    :key="index"
                                >
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                        <div class="tab-pane fade" 
                            :class="{ 
                                active: activeTab === tabbs.tabTree, 
                                show: activeTab === tabbs.tabTree 
                            }" 
                            id="custom-tabs-four-messages" 
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-messages-tab"
                        >
                            <div id="account-cost-center-container" style="font-size: 1rem!important;"></div>
                            <span v-if="checkArray(v$.form.costCenter.$errors)">
                                <small 
                                    id="emailHelp" 
                                    class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.costCenter.$errors" 
                                    :key="index"
                                >
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                        <div class="tab-pane fade" 
                            :class="{ 
                                active: activeTab === tabbs.tabFour, 
                                show: activeTab === tabbs.tabFour 
                            }" 
                            id="custom-tabs-four-settings" 
                            role="tabpanel"
                            aria-labelledby="custom-tabs-four-settings-tab"
                        >
                            <file-input 
                                text="Boletos, Comprovantes, etc."
                                @files-selected="onFilesSelected"
                                :multiple="true"
                            ></file-input>
                            <hr>
                            <h5>Arquivos selecionados</h5>
                            <hr>
                            <ul>
                                <li v-for="(file, index) in selectedFiles" :key="index">
                                    {{ file.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /.card -->
            </div>
        </template>
        <template v-slot:footer>
            <app-button type="submit" class="btn btn-default rounded-0">
                <i class="fa fa-save"></i>
                Gravar
            </app-button>
        </template>
    </app-right-curtain>
</form>