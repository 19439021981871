import { AccessLogData } from "@/@types/app/accesslog";
import { GetterTree } from "vuex";
import { RootState } from "../../..";
import { State } from "./state";

export type Getters = {
  getAccessLog(state: State): AccessLogData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getAccessLog: (state) => state.accessLogData,
};
