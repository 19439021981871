<app-panel>
    <template #header>
        SALDO EM ABERTO POR FORNECEDOR
    </template>
    <template #content>
        <app-table 
            :columns="gridColumns" 
            :rows="allClients" 
            table-class="table table-striped"
        >
        </app-table>
    </template>
</app-panel>