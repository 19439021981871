/* eslint-disable */
import { IssueSolutionTypeData, IssueSolutionTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueSolutionTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueSolutionTypeMutationTypes.SET_INSERT_ISSUE_SOLUTION_TYPE_DATA](
    state: S,
    payload: IssueSolutionTypeObject
  ): void;
  [IssueSolutionTypeMutationTypes.SET_ISSUE_SOLUTION_TYPE_DATA](
    state: S,
    payload: IssueSolutionTypeData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueSolutionTypeMutationTypes.SET_INSERT_ISSUE_SOLUTION_TYPE_DATA](
    state: State,
    issueSolutionType: IssueSolutionTypeObject
  ) {
    state.data = issueSolutionType;
  },
  [IssueSolutionTypeMutationTypes.SET_ISSUE_SOLUTION_TYPE_DATA](
    state: State,
    issueSolutionTypeData: IssueSolutionTypeData
  ) {
    state.issueSolutionTypeData = issueSolutionTypeData;
  },
};
