/* eslint-disable */

export const PForm = {
    company:"Fornecedor",
    form: "Forma de pagamento",
    bankAccount: "Origem",
    competition: "Competência",
    due: "Vencimento",
    amount: "Valor a pagar",
    state: "Estado",
    paymentReceipt: "Data de pagamento",
    fees: "Juros",
    fine: "Multa",
    amountPaid: "Valor pago",
    observation: "Observação"
};

export const RForm = {
    company: "Cliente",
    form: "Forma de recebimento",
    bankAccount: "Destino",
    competition: "Competência",
    due: "Vencimento",
    amount: "Valor a Receber",
    state: "Estado",
    paymentReceipt: "Data de Recebimento",
    fees: "Juros",
    fine: "Multa",
    amountPaid: "Valor Recebido",
    observation: "Observação"
}