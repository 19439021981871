import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "container-fluid" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-sm" }

export function render(_ctx, _cache) {
  const _component_app_breadcrumb = _resolveComponent("app-breadcrumb")
  const _component_app_button = _resolveComponent("app-button")
  const _component_app_panel = _resolveComponent("app-panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("labels.button", 2)), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_breadcrumb, { class: "float-sm-right" })
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_app_panel, { class: "card-green" }, {
              title: _withCtx(() => [
                _createTextVNode(" Botões ")
              ]),
              content: _withCtx(() => [
                _createVNode(_component_app_button, {
                  type: "button",
                  class: "btn btn-success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Sucesso")
                  ]),
                  _: 1
                }),
                _createTextVNode("   "),
                _createVNode(_component_app_button, {
                  type: "button",
                  class: "btn btn-primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Primário")
                  ]),
                  _: 1
                }),
                _createTextVNode("   "),
                _createVNode(_component_app_button, {
                  type: "button",
                  class: "btn btn-danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Perigo")
                  ]),
                  _: 1
                }),
                _createTextVNode("   "),
                _createVNode(_component_app_button, {
                  type: "button",
                  class: "btn btn-warning"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Aviso")
                  ]),
                  _: 1
                }),
                _createTextVNode("   "),
                _createVNode(_component_app_button, {
                  type: "button",
                  class: "btn btn-default"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Padrão")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ], 64))
}