/* eslint-disable */
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Button from "@/components/ui/button/button.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import Swal from "sweetalert2";
import rightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import RadioGroupButton from "@/components/form/radio-group-button/radio-group-button.vue";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
    name: "CreateIssueForm",
    components: {
        "app-button": Button,
        "app-panel": Panel,
        "issue-create-form": rightCurtain,
        "s-input": inputDefault,
        "s-radio-button": RadioGroupButton,
    },
    setup(props, { emit }) {
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const createForm = ref();
        const formTitle = ref("Cadastrar Chamado");
        const problemTypes = ref({});
        const projects = ref<{id: number; key: number; text: string; contractNumber: string; active: string}[]>([]);
        const priorities = ref({});
        const categories = ref({});
        const users = ref({});
        const fileInput = ref();
        const form = reactive({
            form: {
                id: "",
                openingDate: new Date(),
                closingDate: "",
                client: "",
                project: "",
                category: "",
                priority: "",
                situation: "",
                observation: "",
                problemType: "",
                solutionType: "",
                recurring: "N",
                finalObservation: "",
                attachment: [],
                user: ""
            }
        });

        const radioOptions = ref([
            { label: "Sim", value: "Y" },
            { label: "Não", value: "N" }
        ]);

        const rules = computed(() => {
            return {
                form: {
                    project: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    category: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    priority: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    observation: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    problemType: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    }
                }
            }
        });

        const v$ = useVuelidate(rules, form);

        onMounted(() => {
            getProblemType();
            getPriorities();
            getCategories();
            getProjects();
        });

        /* GETs */

        const getProblemType = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PROBLEM_TYPE).then((data) => {
                problemTypes.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getProjects = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PROJECT, { client: "" }).then((data) => {
                projects.value = data.projects;
            }).catch((error) => {
                return;
            });
        };

        const getPriorities = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PRIORITY).then((data) => {
                priorities.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getCategories = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_CATEGORY).then((data) => {
                categories.value = data;
            }).catch((error) => {
                return;
            });
        };

        const onStore = () => {
            onCreate();
        };

        const onCreate = async () => {
            const loader = $loading.show({
              loader: "dots",
            });

            const result = await v$.value.$validate();
            if (!result) {
                return;
            }

            form.form.attachment = fileInput.value.files;

            store.dispatch(IssueActionTypes.ISSUE_CREATE, form.form).then(() => {
                form.form.project = "";
                form.form.category = "";
                form.form.priority = "";
                form.form.observation = "";
                form.form.problemType = "";
                form.form.recurring = "N";
                form.form.attachment = [];
                emit('reload');
                loader.hide();
                Swal.fire({
                    title: "Bom Trabalho!",
                    text: "Categoria cadastrada com sucesso!",
                    icon: "success",
                    allowOutsideClick: false,
                });
            }).catch(() => {
                loader.hide();
            });
        };

        const onNew = () => {
            form.form.id = "";
            form.form.openingDate = new Date();
            form.form.closingDate = "";
            form.form.client = "";
            getProjects();
            form.form.project = "";
            form.form.category = "";
            form.form.priority = "";
            form.form.situation = "";
            form.form.observation = "";
            form.form.problemType = "";
            form.form.solutionType = "";
            form.form.recurring = "N";
            form.form.finalObservation = "";
            form.form.user = "";
            openForm();
        };

        const openForm = () => {
            createForm.value.openNav();
        };

        function checkArray(variable: any) {
            return Array.isArray(variable);
        }

        const verifyContract = (contract: any) => {
            if(form.form.project !== null){
                if(contract.active === 'N') {
                    form.form.project = "";
                    Swal.fire({
                      title: "Proibido!",
                      text: "Este Contrato foi encerrado! Escolha um ativo",
                      icon: "info",
                      allowOutsideClick: false,
                    }); 
                } 
            }          
        }

        return {
            checkArray,
            projects,
            categories,
            priorities,
            users,
            radioOptions,
            formTitle,
            form,
            createForm,
            openForm,
            v$,
            onStore,
            problemTypes,
            fileInput,
            onNew,
            verifyContract,
        };
    },
});
