/* eslint-disable */
import { FinanceAccountData, FinanceAccountObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceAccountMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceAccountMutationTypes.SET_INSERT_FINANCE_ACCOUNT_DATA](state: S, payload: FinanceAccountObject): void;
  [FinanceAccountMutationTypes.SET_FINANCE_ACCOUNT_DATA](state: S, payload: FinanceAccountData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceAccountMutationTypes.SET_INSERT_FINANCE_ACCOUNT_DATA](state: State, financeAccount: FinanceAccountObject) {
    state.financeAccount = financeAccount;
  },
  [FinanceAccountMutationTypes.SET_FINANCE_ACCOUNT_DATA](state: State, financeAccountData: FinanceAccountData) {
    state.financeAccountData = financeAccountData;
  },
};
