import { UserData, UserObject } from "@/@types";

export type State = {
  data: UserObject | null;
  userData: UserData;
};

export const state: State = {
  data: null,
  userData: [],
};
