/* eslint-disable */
import { SignInWithCredentials } from "@/@types";
import router from "@/router";
import ApiService from "@/services/ApiService";
import jwtService from "@/services/JwtService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "..";
import { SignInActionsTypes } from "./action-types";
import { SignInMutationTypes } from "./mutation-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import { isTokenExpired } from "@/classes/jwtHelper";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [SignInActionsTypes.signInWithCredentials]( { commit }: AugmentedActionContext, params: SignInWithCredentials ): Promise<any>;
  [SignInActionsTypes.setToken]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [SignInActionsTypes.passwordRecovery]({ commit }: AugmentedActionContext, params: any): Promise<any>;
  [SignInActionsTypes.tokenValidation]({ commit }: AugmentedActionContext, params: any): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [SignInActionsTypes.signInWithCredentials]({ commit }, params: SignInWithCredentials) {
    return new Promise<void>((resolve, reject) => {
      const data: any = {
        username: params.username,
        password: params.password,
        remember: params.remember,
        ip: params.ip
      };

      ApiService.json();
      ApiService.post("/signin", data).then(({ data }) => {
        const result = data;
        setTimeout(() => {
          const baseUri = process.env.VUE_APP_API_DOMAIN;
          /* localStorage.setItem("name", result.name);
          localStorage.setItem("navigation", result.menu); */
          localStorage.setItem("name", result.name);
          localStorage.setItem("navigation", result.navigation);
          if(result.picture){
            localStorage.setItem("picture", baseUri + '/' + result.picture);
          }
          localStorage.setItem("role", result.roles.join('/'));
          localStorage.setItem("created_at", result.created_at);
          resolve(result);
        }, 2000)
      })
        .catch((response) => {
          reject(response);
        });
    });
  },

  async [SignInActionsTypes.passwordRecovery]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.post("/forgot-password", params).then(( { data }) => {
        resolve(data);
      }).catch((response) => {
        reject(response);
      })
    })
  },

  async [SignInActionsTypes.tokenValidation]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.get(`/check-token/${params.token}`).then(( { data }) => {
        resolve(data);
      }).catch((response) => {
        reject(response);
      })
    })
  },

  async [SignInActionsTypes.newPassword]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        password: params.password,
        confirm: params.password_confirmation,
        country: params.country,
        state: params.state,
        city: params.city,
        operating_system: params.operating_system,
        browser_name: params.browser_name
      };
      ApiService.json();
      ApiService.post(`/new-password/${params.token}`, payload).then(( { data }) => {
        resolve(data);
      }).catch((response) => {
        reject(response);
      })
    })
  },

  async [SignInActionsTypes.setToken]({ commit }, params: any) {
    if(params !== undefined && params !== null){
      commit(SignInMutationTypes.SetToken, params.hash);
      router.replace({ name: params.page })
    }
  },

  async [SignInActionsTypes.signOut]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.get("/signout").then((data) => {
        localStorage.clear();
        sessionStorage.clear();
        commit(SignInMutationTypes.SetToken, null);
        router.replace("/login");
      })
        .catch((err) => {
          return;
        });
    });
  },

  /* PROFILES DATA */
  
};
