/* eslint-disable */
import { defineComponent, onMounted, ref } from "vue";
import grid from "@/components/tables/grid/grid.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";
import { FinanceDashboardActionTypes } from "@/store/finance/dashboard/action-types";

export default defineComponent({
  name: "GetBillToPayByClient",
  components: {
    "app-table": grid,
    "app-panel": PanelSecondary,
  },
  setup() {
    const store = useStore();

    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });

    const gridColumns = [
      {
        key: "firstName",
        text: "Cliente",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "valor",
        text: "Saldo",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const allClients = ref({});

    onMounted(() => {
      onList();
    });

    const onList = async () => {
      const params = {
        type: 'P',
        key: '--pay-sum-by-supplier--',
        accountStatus: 'Pago'
      };

      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_BILL_BY_CLIENT, params).then((data) => {
        allClients.value = data;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
        
    };
    
    return { 
      gridColumns,
      allClients,
    };
  },
});
