import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icheck-primary" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.ID,
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", { for: _ctx.ID }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ]))
}