/* eslint-disable */ 
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Panel from "@/components/ui/panel-secondary/panel-secondary.vue";
import Button from "@/components/ui/button/button.vue";
import { useRoute, useRouter } from "vue-router";
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
    name: "NotificationView",
    components: {
        'panel': Panel,
        's-button': Button,
    },
    setup(){
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const info = ref({});
        const route = useRoute();
        const router = useRouter();
        const issue: any = ref("");

        onMounted(() => {
            issue.value = route.params.id;
            getIssueNote();
            setViewedNote()
        });

        function getIssueNote() {
            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(IssueActionTypes.ISSUE_GET_NOTIFICATION, { id: route.params.id }).then((data) => {
                info.value = data.data[0];
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        }

        function setIssue(issue: any) {
            localStorage.setItem("note_issue", issue);
            router.push({path: "/service/issue/mine"});
        }

        const setViewedNote = () => {
            try{
                store.dispatch(IssueActionTypes.ISSUE_NOTE_VIEWED, { id: route.params.id }).then();
            } catch (error) {
                return;
            }
        };

        return {
            info,
            issue,
            setIssue,
        };
    },
});
