/* eslint-disable */
import { defineComponent, onMounted, ref, watch } from "vue";
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
    name: "TodoListComponent",
    components: {
        draggable: VueDraggableNext,
    },
    emits: [
        "onTaskSave",
        "onTaskEdit",
        "onTaskRemove",
        "onHandleCheckboxChange",
        "update:modelValue",
        "onReOrder",
    ],
    props: {
        data: {
            type: Array,
            required: true,
        },
        initialData: {
            type: Object,
            default: () => ({
                id: '',
                task: ''
            }),
        },
        visible: {
            type: Boolean,
            default: true,
        }
    },
    setup(props, { emit }) {
        const formInputs = ref({ ...props.initialData });
        watch(() => props.initialData, (newData) => {
            formInputs.value = { ...newData }; // Reset form data when initialData changes
        },{ deep: true });

        const localData = ref([...props.data]);

        watch(() => props.data, (newData) => {
            localData.value = [...newData];
        }, { immediate: true });

        const onSave = () => {
            emit('onTaskSave', formInputs.value);
        }

        const onEdit = (item: number) => {
            emit('onTaskEdit', item);
        }

        const onRemove = (item: number) => {
            emit('onTaskRemove', item);
        }

        const handleCheckboxChange = (event: any, item: any) => {
            emit('onHandleCheckboxChange', item.id);
        }

        const reOrder = () => {
            emit('onReOrder', localData.value);
        }

        return {
            formInputs,
            handleCheckboxChange,
            onSave,
            onEdit,
            onRemove,
            reOrder,
            localData,
        };
    },
});
