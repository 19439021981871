<div class="register-box">
    <div class="card card-outline card-primary">
        <div class="card-header text-center">
            <router-link to="/" class="h1"><b>Admin</b>LTE</router-link>
        </div>
        <div class="card-body">
            <p class="login-box-msg">{{ $t("labels.registerMembership") }}</p>

            <form @submit.prevent="registerByAuth">
                <app-input
                    icon="envelope"
                    class="mb-3"
                    type="email"
                    placeholder="Email"
                    autocomplete="off"
                    v-model="email"
                />
                <app-input
                    icon="lock"
                    class="mb-3"
                    type="password"
                    placeholder="Password"
                    autocomplete="off"
                    v-model="password"
                />
                <app-input
                    icon="lock"
                    class="mb-3"
                    type="password"
                    placeholder="Retype password"
                    autocomplete="off"
                    v-model="rePassword"
                />
                <div class="row">
                    <div class="col-7">
                        <pf-checkbox type="icheck" v-model="agreeTerms">
                            {{ $t("labels.iAgree") }}
                            <a href="#">{{ $t("labels.term", 2) }}</a>
                        </pf-checkbox>
                    </div>
                    <!-- /.col -->
                    <div class="col-5">
                        <pf-button
                            type="submit"
                            block="true"
                            :loading="isAuthLoading"
                            :disabled="isFacebookLoading || isGoogleLoading"
                        >
                            {{ $t("labels.register") }}
                        </pf-button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>

            <div class="social-auth-links text-center">
                <pf-button
                    class="mb-2"
                    block="true"
                    @click="registerByFacebook"
                    :loading="isFacebookLoading"
                    :disabled="isAuthLoading || isGoogleLoading"
                >
                    <i class="fab fa-facebook mr-2"></i>
                    <span>{{ $t("labels.signUpUsingFB") }}</span>
                </pf-button>
                <!-- <pf-button
                    block="true"
                    theme="danger"
                    @click="registerByGoogle"
                    :loading="isGoogleLoading"
                    :disabled="isAuthLoading || isFacebookLoading"
                >
                    <i class="fab fa-google mr-2"></i>
                    <span> {{ $t("labels.signUpUsingGoogle") }} </span>
                </pf-button> -->
            </div>

            <router-link to="/login" class="text-center">
                {{ $t("labels.alreadyHaveMembership") }}
            </router-link>
        </div>
        <!-- /.form-box -->
    </div>
    <!-- /.card -->
</div>
<!-- /.register-box -->