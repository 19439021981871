/* eslint-disable */
import { ref } from "vue";
import { i18n } from "@/translation";
import { setCookie } from "./cookieUtils";

export const flagIcon = ref("flag-icon-us");
export const selectedLanguage = ref<string | null>(null);
export const languages: any = ref([
    {
        key: "en",
        flag: "flag-icon-us",
        label: "languages.english",
    },
    {
        key: "ptBR",
        flag: "flag-icon-br",
        label: "languages.brazil",
    },
]);

export const changeFlag = (locale: string) => {
    const language = languages.value.find((lang: any) => lang.key === locale);
    flagIcon.value = language.flag;
};

export const changeLanguage = (langCode: "en" | "ptBR" | "es" | "tr") => {
    if (i18n.global.locale.value !== langCode) {
        i18n.global.locale.value = langCode;
        selectedLanguage.value = langCode;
        setCookie("locale", langCode, 30);
    }
};
