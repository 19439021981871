/* eslint-disable */
export enum InvoiceActionTypes {
  INVOICE_LIST          = "InvoiceList",
  INVOICE_CREATE        = "InvoiceCreate",
  INVOICE_EDIT          = "InvoiceEdit",
  INVOICE_UPDATE        = "InvoiceUpdate",
  INVOICE_CLIENT        = "InvoiceClient",
  INVOICE_CONTRACT      = "InvoiceClientContract",
  INVOICE_STATE         = "InvoiceState",
  INVOICE_SERVICE       = "InvoiceService",
  INVOICE_SERVICE_VALUE = "InvoiceServiceValue",
  INVOICE_FOR_BILL      = "InvoiceForBill",
  INVOICE_TO_BILL       = "InvoiceToBill",
  INVOICE_DASHBOARD     = "InvoiceDashboard",
}
