import { IntegrationData, IntegrationObject } from "@/@types";
import { MutationTree } from "vuex";
import { IntegrationMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IntegrationMutationTypes.SET_INSERT_INTEGRATION_DATA](
    state: S,
    payload: IntegrationObject
  ): void;
  [IntegrationMutationTypes.SET_INTEGRATION_DATA](
    state: S,
    payload: IntegrationData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IntegrationMutationTypes.SET_INSERT_INTEGRATION_DATA](
    state: State,
    preference: IntegrationObject
  ) {
    state.data = preference;
  },
  [IntegrationMutationTypes.SET_INTEGRATION_DATA](
    state: State,
    preferenceData: IntegrationData
  ) {
    state.integrationData = preferenceData;
  },
};
