<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.general") }} {{ $t("labels.component", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Paleta de cores
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Alertas
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Callouts
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Tabs
                    </template>
                    <template v-slot:content>
                        <div class="container-full">
                            <div class="row">
                                <div class="col">
                                    <app-tab :tabs="tabs">
                                        <template #tab1>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada
                                            lacus ullamcorper dui molestie, sit amet congue quam finibus. Etiam
                                            ultricies nunc non magna feugiat commodo. Etiam odio magna, mollis
                                            auctor felis vitae, ullamcorper ornare ligula. Proin pellentesque
                                            tincidunt nisi, vitae ullamcorper felis aliquam id. Pellentesque
                                            habitant morbi tristique senectus et netus et malesuada fames ac turpis
                                            egestas. Proin id orci eu lectus blandit suscipit. Phasellus porta, ante
                                            et varius ornare, sem enim sollicitudin eros, at commodo leo est vitae
                                            lacus. Etiam ut porta sem. Proin porttitor porta nisl, id tempor risus
                                            rhoncus quis. In in quam a nibh cursus pulvinar non consequat neque.
                                            Mauris lacus elit, condimentum ac condimentum at, semper vitae lectus.
                                            Cras lacinia erat eget sapien porta consectetur.
                                        </template>
                                        <template #tab2>
                                            Mauris tincidunt mi at erat gravida, eget tristique urna bibendum.
                                            Mauris pharetra purus ut ligula tempor, et vulputate metus facilisis.
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante
                                            ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
                                            Maecenas sollicitudin, nisi a luctus interdum, nisl ligula placerat mi,
                                            quis posuere purus ligula eu lectus. Donec nunc tellus, elementum sit
                                            amet ultricies at, posuere nec nunc. Nunc euismod pellentesque diam.
                                        </template>
                                        <template #tab3>
                                            Morbi turpis dolor, vulputate vitae felis non, tincidunt congue mauris.
                                            Phasellus volutpat augue id mi placerat mollis. Vivamus faucibus eu
                                            massa eget condimentum. Fusce nec hendrerit sem, ac tristique nulla.
                                            Integer vestibulum orci odio. Cras nec augue ipsum. Suspendisse ut velit
                                            condimentum, mattis urna a, malesuada nunc. Curabitur eleifend facilisis
                                            velit finibus tristique. Nam vulputate, eros non luctus efficitur, ipsum
                                            odio volutpat massa, sit amet sollicitudin est libero sed ipsum. Nulla
                                            lacinia, ex vitae gravida fermentum, lectus ipsum gravida arcu, id
                                            fermentum metus arcu vel metus. Curabitur eget sem eu risus tincidunt
                                            eleifend ac ornare magna.
                                        </template>
                                    </app-tab>
                                </div>
                                <div class="col">
                                    Column
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Column
                                </div>
                                <div class="col">
                                    Column
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Column
                                </div>
                                <div class="col">
                                    Column
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Column
                                </div>
                                <div class="col">
                                    Column
                                </div>
                            </div>
                        </div>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Progress Bars different sizes
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Progress Bars
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Vertical Progress Bars different sizes
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Vertical Progress Bars
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Collapsible Accordion
                    </template>
                    <template v-slot:content>
                        <app-accordion id="accordionOne" card-class="card-primary">
                            <template v-slot:title>
                                Primary
                            </template>
                            <template v-slot:content>
                                conteudo 12
                            </template>
                        </app-accordion>
                        <app-accordion id="accordionTwo" card-class="card-green">
                            <template v-slot:title>
                                Success
                            </template>
                            <template v-slot:content>
                                conteudo 12
                            </template>
                        </app-accordion>
                        <app-accordion id="accordionTree" card-class="card-danger">
                            <template v-slot:title>
                                Danger
                            </template>
                            <template v-slot:content>
                                conteudo 12
                            </template>
                        </app-accordion>
                    </template>
                </app-panel>
            </div>
            <div class="col-sm-6">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Caroussel
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <app-panel class="card-default">
                    <template v-slot:title>
                        Pagination Month
                    </template>
                    <template v-slot:content>

                    </template>
                </app-panel>
            </div>
        </div>
    </div>
</section>