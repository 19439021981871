/* eslint-disable */
import { FinanceBankAccountTypeObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceBankAccountTypeActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_LIST]( 
    { commit }: 
    AugmentedActionContext, params: any 
  ): Promise<any>;
  [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_CREATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceBankAccountTypeObject
  ): Promise<boolean>;
  [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_EDIT]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_UPDATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceBankAccountTypeObject 
  ): Promise<any>;
  [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_DELETE]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        description: params.description
      }
      ApiService.post(`/finance/bank-account-type/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_CREATE]({ commit }, params: FinanceBankAccountTypeObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        description: params.description
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/finance/bank-account-type", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/bank-account-type/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_UPDATE]({ commit }, params: FinanceBankAccountTypeObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        description: params.description
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/finance/bank-account-type/${params.id}`, payload).then(({ data }) => {
          const result = data.data;
          resolve(result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [FinanceBankAccountTypeActionTypes.FINANCE_BANK_ACCOUNT_TYPE_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/bank-account-type/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
