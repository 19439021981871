<div class="card card-primary card-outline card-outline-tabs">
    <div class="card-header p-0 border-bottom-0">
        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
            <li v-for="(tab, index) in tabs" 
                class="nav-item" :id="tab.id" 
                data-toggle="pill"
                role="tab" 
                :aria-controls="tab.ariaControls" 
                :aria-selected="(activeTab === index)" 
                :key="index"
            >
                <a 
                    class="nav-link" 
                    :class="{ active: activeTab === index, 'disabled': tab.disabled }" 
                    @click="changeTab(index)" 
                    href="javascript:;"
                >
                    {{ tab.label }}
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body">
        <div class="tab-content" id="custom-tabs-four-tabContent">
            <div 
                v-for="(tab, index) in tabs" 
                class="tab-pane fade" 
                :class="{
                    active: activeTab === index,
                    show:  activeTab === index
                }"
                :id="tab.ariaControls" 
                role="tabpanel"
                :aria-labelledby="tab.id"
                :key="index"
            >
                <slot :name="tabs[activeTab].name"></slot>
            </div>
        </div>
    </div>
</div>