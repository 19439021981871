/* eslint-disable */
import Dropdown from "@/components/form/dropdown/dropdown.vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import { IntegrationActionTypes } from "@/store/admin/integration/action-types";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { useLoading } from "vue-loading-overlay";
// CKEditor
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { 
  Bold, 
  Code, 
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline 
} from '@ckeditor/ckeditor5-basic-styles';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { List } from '@ckeditor/ckeditor5-list';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import FullScreen from '@pikulinpw/ckeditor5-fullscreen';
import { Font } from '@ckeditor/ckeditor5-font';
import { Link } from '@ckeditor/ckeditor5-link';


export default defineComponent({
  name: "Integration",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel-secondary": PanelSecondary,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-selectbox": Dropdown,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    /* ============= START: SMTP ================= */
    const smtpcurtain: any = ref(null);
    const formSmtp = ref({
      from: "",
      auth: "",
      host: "",
      port: "",
      user: "",
      pass: "",
      supp: "",
      terms: "",
      app: "smtp",
    });
    const authItems = [
      { key: "Y", text: "Yes" },
      { key: "N", text: "No" },
    ];

    onMounted(() => {});

    const onOpenSmtpForm = () => {
      getSmtpData();
      smtpcurtain.value.openNav();
    };

    const onSubmitSmtp = () => {
      const params = {
        app: formSmtp.value.app,
        active: "I",
        type: "e-mail",
        data: {
          auth: formSmtp.value.auth,
          from: formSmtp.value.from,
          host: formSmtp.value.host,
          pass: formSmtp.value.pass,
          port: formSmtp.value.port,
          supp: formSmtp.value.supp,
          terms: formSmtp.value.terms,
          user: formSmtp.value.user,
        },
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(IntegrationActionTypes.CREATE_UPDATE_INTEGRATION, params).then(() => {
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
        loader.hide();
      })
      .catch(() => {
        Swal.fire({
          title: "Error Data could not bem cleared!",
          text: "Record not stored!",
          icon: "error",
          allowOutsideClick: false,
        });
        loader.hide();
      });
    };

    const getSmtpData = () => {

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(IntegrationActionTypes.EDIT_INTEGRATION, { key: "smtp" }).then((data) => {
        
          const info = JSON.parse(data.value);
          formSmtp.value.auth = info.auth;
          formSmtp.value.from = info.from;
          formSmtp.value.host = info.host;
          formSmtp.value.pass = info.pass;
          formSmtp.value.port = info.port;
          formSmtp.value.supp = info.supp;
          formSmtp.value.terms = info.terms;
          formSmtp.value.user = info.user;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    };
    /* ============= END: SMTP ================= */
    const visibility = (name: any) => {
      return isVisible(name);
    };

    /* ============= START: CKEDITOR ================= */

    const editorIntegration = ClassicEditor;
    const editorIntegrationConfig = {
      plugins: [
        Essentials,
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Code,
        Subscript,
        Superscript,
        Paragraph,
        Indent,
        List,
        Alignment,
        Image,
        BlockQuote,
        Heading,
        FullScreen,
        Font,
        Link
      ],
      toolbar: {
        items: [
          'undo', 'redo',
        '|', 'heading','fullscreen',
        '|', 'fontfamily', 'fontsize',
        '|', 'bold', 'italic', 'underline', 'strikethrough',
        '|', 'alignment','blockQuote','link',
        '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
        ],
        shouldNotGroupWhenFull: true
      },
    }

    return {
      formSmtp,
      authItems,
      onSubmitSmtp,
      onOpenSmtpForm,
      smtpcurtain,
      getSmtpData,
      visibility,
      editorIntegration,
      editorIntegrationConfig,
    };
  },
});
