import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["type", "placeholder", "value", "autocomplete"]
const _hoisted_3 = { class: "input-group-text" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: _ctx.type,
      class: "form-control rounded-0",
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      autocomplete: _ctx.autocomplete,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event)))
    }, null, 40, _hoisted_2),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "input-group-append",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.onEvent()))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.icon)
            }, null, 2),
            _createVNode(_component_font_awesome_icon, { icon: _ctx.icon }, null, 8, ["icon"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}