<!-- Content Header (Page header) -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">{{ $t("labels.financeDashboard") }}</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t("labels.home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">{{ $t("labels.finance") }}</li>
                    <li class="breadcrumb-item active">{{ $t("labels.dashboard") }}</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- /.row -->
        <div class="row">
            <div class="col">
                <app-tab 
                    :tabs="tabs" 
                    :active="active"
                >
                    <template #tab1>
                        <div class="container-fluid">
                            <!-- Small boxes (Stat box) -->
                            <div class="row">
                                <div class="col">
                                    <app-bill-to-receive-year-preview></app-bill-to-receive-year-preview>
                                </div>
                                <div class="col">
                                    <app-bill-to-receive-month-prview></app-bill-to-receive-month-prview>
                                </div>
                                <div class="col">
                                    <app-bill-to-receive-year-pending></app-bill-to-receive-year-pending>
                                </div>
                                <div class="col">
                                    <app-bill-to-receive-month-pending></app-bill-to-receive-month-pending>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <app-bill-to-receive-by-month></app-bill-to-receive-by-month>
                                </div>
                                <div class="col">
                                    <app-bill-to-receive-by-client></app-bill-to-receive-by-client>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #tab2>
                        <div class="container-fluid">
                            <!-- Small boxes (Stat box) -->
                            <div class="row">
                                <div class="col">
                                    <app-bill-to-pay-year-preview></app-bill-to-pay-year-preview>
                                </div>
                                <div class="col">
                                    <app-bill-to-pay-month-prview></app-bill-to-pay-month-prview>
                                </div>
                                <div class="col">
                                    <app-bill-to-pay-year-pending></app-bill-to-pay-year-pending>
                                </div>
                                <div class="col">
                                    <app-bill-to-pay-month-pending></app-bill-to-pay-month-pending>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <app-bill-to-pay-by-month></app-bill-to-pay-by-month>
                                </div>
                                <div class="col">
                                    <app-bill-to-pay-by-client></app-bill-to-pay-by-client>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #tab3>
                        Tab 3
                        Morbi turpis dolor, vulputate vitae felis non, tincidunt congue mauris.
                        Phasellus volutpat augue id mi placerat mollis. Vivamus faucibus eu
                        massa eget condimentum. Fusce nec hendrerit sem, ac tristique nulla.
                        Integer vestibulum orci odio. Cras nec augue ipsum. Suspendisse ut velit
                        condimentum, mattis urna a, malesuada nunc. Curabitur eleifend facilisis
                        velit finibus tristique. Nam vulputate, eros non luctus efficitur, ipsum
                        odio volutpat massa, sit amet sollicitudin est libero sed ipsum. Nulla
                        lacinia, ex vitae gravida fermentum, lectus ipsum gravida arcu, id
                        fermentum metus arcu vel metus. Curabitur eget sem eu risus tincidunt
                        eleifend ac ornare magna.
                    </template>
                </app-tab>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>
<!-- /.content -->