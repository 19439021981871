/* eslint-disable */
import { IntegrationData, IntegrationObject } from "@/@types";
import { MutationTree } from "vuex";
import { GetSystemInformationMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [GetSystemInformationMutationTypes.SET_INFORMATION_DATA](
    state: S,
    payload: IntegrationObject
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [GetSystemInformationMutationTypes.SET_INFORMATION_DATA](
    state: State,
    params: any
  ) {
    state.informationData = params;
  },
};
