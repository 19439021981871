/* eslint-disable */
import { FinanceBankAccountObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceBankAccountActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_LIST]( 
    { commit }: 
    AugmentedActionContext, params: any 
  ): Promise<any>;
  [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_CREATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceBankAccountObject
  ): Promise<boolean>;
  [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_EDIT]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_UPDATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceBankAccountObject 
  ): Promise<any>;
  [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_DELETE]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        responsible: params.responsible,
        type       : params.type,
        bank       : params.bank
      }
      ApiService.post(`/finance/bank-account/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_CREATE]({ commit }, params: FinanceBankAccountObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        responsible   : params.responsible,
        type          : params.type,
        bank          : params.bank,
        agency        : params.agency,
        account       : params.account,
        openingBalance: params.openingBalance,
        description   : params.description,
        matter        : params.matter,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/finance/bank-account", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/bank-account/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_UPDATE]({ commit }, params: FinanceBankAccountObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        responsible   : params.responsible,
        type          : params.type,
        bank          : params.bank,
        agency        : params.agency,
        account       : params.account,
        openingBalance: params.openingBalance,
        description   : params.description,
        matter        : params.matter,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/finance/bank-account/${params.id}`, payload).then(({ data }) => {
          const result = data.data;
          resolve(result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/bank-account/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_RESPONSIBLE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/bank-account/responsible`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_ACCOUNT_TYPE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/bank-account/types`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_BANKS]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/bank-account/banks`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
