

<div class="logo-center mb-1">
    
    <div class="image-input" @click="chooseImage">
        <img :src="imageData" v-if="imageData" alt="" class="logo"/>
        <span v-if="!imageData" class="placeholder">
            Escolha uma imagem
        </span>
        <input 
            class="file-input" 
            ref="fileInput" 
            type="file" 
            @input="onSelectFile"
        />

    </div>
</div>
<div class="d-flex justify-content-center mb-2">
    <div class="button-remove-picture">
    <button 
        type="button" class="btn btn-danger mt-1 btn-block" 
        v-if="(imageData || url)"
        @click="onRemovePicture"
    >
        Excluir
    </button>
</div>
</div>