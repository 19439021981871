/* eslint-disable */
import { calculateWindowSize } from "@/utils/helpers";
import axios from "axios";
import { Options, Vue } from "vue-class-component";

@Options({
  watch: {
    currentWindowSize: (value) => {
      return;
    },
  },
  mounted() {
    if(this.$router.currentRoute.value.path == "/login") {
      this.ip();
    }
  },
})
export default class App extends Vue {
  $windowWidth!: number;
  get currentWindowSize() {
    if (this.$store.getters["ui/screenSize"] !== this.windowSize) {
      this.$store.dispatch("ui/setWindowSize", this.windowSize);
    }
    return this.windowSize;
  }

  get windowSize() {
    return calculateWindowSize(this.$windowWidth);
  }

  ip() {
    axios.get("https://api.ipify.org?format=json").then((response) => {
      sessionStorage.setItem("ip", response.data.ip);
    })
    .catch((error) => {
      return;
    });
  }
}
