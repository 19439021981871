/* eslint-disable */
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Button from "@/components/ui/button/button.vue";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { useStore } from "vuex";
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import Swal from "sweetalert2";
import rightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import RadioGroupButton from "@/components/form/radio-group-button/radio-group-button.vue";
import { useLoading } from "vue-loading-overlay";
import { current_date_time } from "@/classes/current_date_time";

export default defineComponent({
    name: "IssueForm",
    components: {
        "app-button": Button,
        "app-panel": Panel,
        "issue-form-edit": rightCurtain,
        "s-input": inputDefault,
        "s-radio-button": RadioGroupButton,
    },
    setup(props, { emit }) {
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const editFormCurtain = ref();
        const formTitle = ref("Editar Chamado");
        const clients = ref({});
        const states = ref({});
        const problemTypes = ref({});
        const solutionTypes = ref({});
        const projects = ref<{id: number; key: number; text: string; contractNumber: string; active: string}[]>([]);
        const priorities = ref({});
        const categories = ref({});
        const users = ref({});
        const validateUsedTime = ref();
        const validateStartDate = ref();
        const fileInput = ref();
        const form = reactive({
            form: {
                id: "",
                openingDate: new Date(),
                closingDate: "",
                startDate: "",
                usedTime: "",
                client: "",
                project: "",
                category: "",
                priority: "",
                situation: "",
                observation: "",
                problemType: "",
                solutionType: "",
                recurring: "N",
                finalObservation: "",
                attachment: [],
                user: ""
            }
        });

        const radioOptions = ref([
            { label: "Sim", value: "Y" },
            { label: "Não", value: "N" }
        ]);

        const rules = computed(() => {
            return {
                form: {
                    client: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    project: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    category: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    priority: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    situation: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    observation: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    problemType: { 
                        required: helpers.withMessage("Este campo é obrigatório!", required)  
                    },
                    usedTime: { 
                        required: helpers.withMessage('Este campo é obrigatório!', requiredIf(() => {
                          return validateUsedTime.value === 'Encerrado' ? true : false;
                        })),
                    },
                    solutionType: { 
                        required: helpers.withMessage('Este campo é obrigatório!', requiredIf(() => {
                          return validateUsedTime.value === 'Encerrado' ? true : false;
                        })),
                    },
                    finalObservation: { 
                        required: helpers.withMessage('Este campo é obrigatório!', requiredIf(() => {
                          return validateUsedTime.value === 'Encerrado' ? true : false;
                        })),
                    },
                    user: { 
                        required: helpers.withMessage('Este campo é obrigatório!', requiredIf(() => {
                            if(validateUsedTime.value === 'Encerrado'){
                                return true;
                            } else if(validateStartDate.value !== 'Aberto' && validateStartDate.value !== 'Encerrado') {
                                return true;
                            } else {
                                return false;
                            }                        
                        })),
                    },
                }
            }
        });

        const v$ = useVuelidate(rules, form);

        onMounted(() => {
            getClients();
            getState();
            getProblemType();
            getSolutionType();
            getPriorities();
            getCategories();
            getUsers();
        });

        /* GETs */
        const getClients = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_CLIENT).then((data) => {
                clients.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getState = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_STATE).then((data) => {
                states.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getProblemType = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PROBLEM_TYPE).then((data) => {
                problemTypes.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getSolutionType = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_SOLUTION_TYPE).then((data) => {
                solutionTypes.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getProjects = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PROJECT, {client: form.form.client}).then((data) => {
                projects.value = data.projects;
            }).catch((error) => {
                return;
            });
        };

        const getPriorities = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_PRIORITY).then((data) => {
                priorities.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getCategories = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_CATEGORY).then((data) => {
                categories.value = data;
            }).catch((error) => {
                return;
            });
        };

        const getUsers = () => {
            store.dispatch(IssueActionTypes.ISSUE_GET_USERS).then((data) => {
                users.value = data;
            }).catch((error) => {
                return;
            });
        };

        const onStore = () => {
            if (form.form.id) {
                onUpdate();
            } else {
                onCreate();
            }
        };

        const situationSelectEvent = (data: any) => {
            if(data.text === "Encerrado" && form.form.closingDate !== undefined) {
                validateUsedTime.value = data.text;
                const currentDate: any = new Date();
                form.form.closingDate = currentDate;
            }
            console.log(data.text, form.form.startDate);
            if(data.text !== "Aberto" && data.text !== "Encerrado" && form.form.startDate === null) {
                validateStartDate.value = data.text;
                const currentStartDate: any = new Date();
                form.form.startDate = currentStartDate;
            } else {
                return false;
            }
        };

        const onCreate = async () => {
            const result = await v$.value.$validate();
            if (!result) {
                return;
            }

            const loader = $loading.show({
              loader: "dots",
            });

            form.form.attachment = fileInput.value.files;

            store.dispatch(IssueActionTypes.ISSUE_CREATE, form.form).then(() => {
                form.form.openingDate = new Date();
                form.form.closingDate = "";
                form.form.client = "";
                form.form.project = "";
                form.form.category = "";
                form.form.priority = "";
                form.form.situation = "";
                form.form.observation = "";
                form.form.problemType = "";
                form.form.solutionType = "";
                form.form.recurring = "N";
                form.form.finalObservation = "";
                form.form.attachment = [];
                form.form.user = "",
                loader.hide();
                emit('reload');
                Swal.fire({
                    title: "Bom Trabalho!",
                    text: "Categoria cadastrada com sucesso!",
                    icon: "success",
                    allowOutsideClick: false,
                });
            }).catch(() => {
                loader.hide();
            });
        };

        const onUpdate = async () => {
            const result = await v$.value.$validate();
            if (!result) {
                return;
            }

            const loader = $loading.show({
                loader: "dots",
            });

            form.form.attachment = fileInput.value.files;
            store.dispatch(IssueActionTypes.ISSUE_UPDATE, form.form).then(() => {
                form.form.client = "";
                form.form.id = "";
                emit('reload');
                editFormCurtain.value.closeNav();
                loader.hide();
                Swal.fire({
                    title: "Bom Trabalho!",
                    text: "Categoria atualizada com sucesso!",
                    icon: "success",
                    allowOutsideClick: false,
                });
            }).catch((err) => {
                console.log(err);
                emit('reload');
                Swal.fire({
                    title: "Ooops!",
                    text: "Registro não atualizado, fale com o seu administrador!",
                    icon: "error",
                    allowOutsideClick: false,
                });
                loader.hide();
            });
        };

        const onEdit = (id: any) => {
            const loader = $loading.show({
                loader: "dots",
            });

            formTitle.value = "Editar Chamado";
            store.dispatch(IssueActionTypes.ISSUE_EDIT, { id: id }).then((data) => {
                form.form.id          = data.id;
                form.form.openingDate = data.createdAt;
                let start_date: any = data.start_date ? new Date(data.start_date) : data.start_date;
                form.form.startDate   = start_date;
                form.form.closingDate = data.closingDate;
                form.form.client      = data.client;
                setTimeout(() => {
                    getProjects();
                }, 200);
                
                form.form.project          = data.project;
                form.form.category         = data.category;
                form.form.priority         = data.priority;
                form.form.situation        = data.situation;
                form.form.observation      = data.description;
                form.form.problemType      = data.problemType;
                form.form.solutionType     = data.solutionType;
                form.form.recurring        = data.recurring;
                form.form.finalObservation = data.descriptionSolution;
                form.form.user             = data.member;
                openForm();
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
        };

        const format = (date: any) => {
            //const day = date.getDate();
            //const month = date.getMonth() + 1;
            //const year = date.getFullYear();
            //
            //const formattedDate = new Date(year, month - 1, day);

            const dateOptions: any = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            };

            const timeOptions: any = {
                hour: '2-digit',
                minute: '2-digit'
            };

            const localeDateString = date.toLocaleDateString('pt-BR', dateOptions);
            const localeTimeString = date.toLocaleTimeString('pt-BR', timeOptions);

            const formattedDateTime = `${localeDateString} ${localeTimeString}`;

            return formattedDateTime;
        };

        const onNew = () => {
            form.form.id          = "";
            form.form.openingDate = new Date();
            form.form.closingDate = "";
            form.form.client      = "";
            getProjects();
            form.form.project          = "";
            form.form.category         = "";
            form.form.priority         = "";
            form.form.situation        = "";
            form.form.observation      = "";
            form.form.problemType      = "";
            form.form.solutionType     = "";
            form.form.recurring        = "N";
            form.form.finalObservation = "";
            form.form.user             = "";
            openForm();
        };

        const openForm = () => {
            editFormCurtain.value.openNav();
        };

        const onClienteSelected = () => {
            getProjects();
        };

        function checkArray(variable: any) {
            return Array.isArray(variable);
        }

        return {
            checkArray,
            clients,
            projects,
            categories,
            priorities,
            states,
            users,
            radioOptions,
            formTitle,
            form,
            onEdit,
            onClienteSelected,
            editFormCurtain,
            openForm,
            v$,
            onStore,
            format,
            problemTypes,
            solutionTypes,
            fileInput,
            onNew,
            situationSelectEvent,
        };
    },
});
