import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67b95734"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "right fas fa-angle-left"
}
const _hoisted_2 = {
  key: 1,
  class: "nav nav-treeview"
}

export function render(_ctx, _cache) {
  const _component_app_menu_item = _resolveComponent("app-menu-item")

  return (_ctx.menuItem)
    ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        class: _normalizeClass(["nav-item", {'menu-open': _ctx.isMenuExtended}])
      }, [
        (_ctx.visibility(_ctx.menuItem.navi))
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: _normalizeClass(["nav-link", {'active': _ctx.isMainActive || _ctx.isOneOfChildrenActive}]),
              onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.handleMainMenuAction && _ctx.handleMainMenuAction(...args)))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(`nav-icon ${ _ctx.menuItem.icon}`)
              }, null, 2),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.menuItem.name)), 1),
              (_ctx.isExpandable)
                ? (_openBlock(), _createElementBlock("i", _hoisted_1))
                : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.visibility(_ctx.menuItem.navi))
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem.children, (item) => {
                return (_openBlock(), _createBlock(_component_app_menu_item, { menuItem: item }, null, 8, ["menuItem"]))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}