import { IssueSolutionTypeData, IssueSolutionTypeObject } from "@/@types";

export type State = {
  data: IssueSolutionTypeObject | null;
  issueSolutionTypeData: IssueSolutionTypeData;
};

export const state: State = {
  data: null,
  issueSolutionTypeData: [],
};
