/* eslint-disable */
import { Options, Vue } from "vue-class-component";
import { loginByAuth, loginByGoogle, loginByFacebook } from "@/services/auth";

import Input from "@/components/form/input/input.vue";
import { useToast } from "vue-toastification";
import { PfButton } from "@profabric/vue-components";
import { SignInActionsTypes } from "@/store/auth/action-types";
import Checkbox from "@/components/form/inputs/checkbox/checkbox.vue";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';


export default defineComponent({
  name: "SignIn",
  components: {
    "app-input": Input,
    "pf-checkbox": Checkbox,
    "pf-button": PfButton,
  },
  setup() {
    const { t } = useI18n();
    const appElement = ref<HTMLElement | null>(null);
    const email = ref("");
    const password = ref("");
    const rememberMe = ref(false);
    const isAuthLoading = ref(false);
    const isFacebookLoading = ref(false);
    const isGoogleLoading = ref(false);
    const toast = useToast();
    const ipAddress = ref("");
    const store = useStore();
    const apiIpUrl = process.env.VUE_APP_GEOLOCATION_IP ?? "";
    const usernamePlaceholder = ref(t("labels.username"));
    const passwordPlaceholder = ref(t("labels.password"));

    onMounted(() => {
      appElement.value = document.getElementById("app");
      appElement.value?.classList.add("login-page");
    });

    onUnmounted(() => {
      appElement.value?.classList.remove("login-page");
    });

    const loginByAuth = async () => {
      const apiResponse = await fetch(apiIpUrl);
      const data = await apiResponse.json();
      const {ip} = data;
      isAuthLoading.value = true;
      try{
        const data = {
          username: email.value,
          password: password.value,
          remember: rememberMe.value,
          ip: ip
        };

        const token = await store.dispatch(SignInActionsTypes.signInWithCredentials, data);
        const params = {
          hash: localStorage.getItem("id_token"),
          page: token?.homepage
        };

        toast.success("Login succeeded");
        isAuthLoading.value = false;

        store.dispatch(SignInActionsTypes.setToken, params);
      } catch (error: any) {
        toast.error(error.response.data.message);
        isAuthLoading.value = false;
      }
    };

    const loginByFacebook = async () => {
      try {
        isFacebookLoading.value = true;
        const token = await loginByFacebook();
        store.dispatch("auth/login", token);
        toast.success("Login succeeded");
        isFacebookLoading.value = false;
      } catch (error: any) {
        toast.error(error);
        isFacebookLoading.value = false;
      }
    }

    const loginByGoogle = async () => {
      try {
        isGoogleLoading.value = true;
        // get Token
        const token = await loginByGoogle();
        store.dispatch("auth/login", token);
        toast.success("Login succeeded");
        isGoogleLoading.value = false;
      } catch (error: any) {
        toast.error(error.message);
        isGoogleLoading.value = false;
      }
    }

    return {
      email,
      usernamePlaceholder,
      password,
      passwordPlaceholder,
      rememberMe,
      loginByAuth,
      loginByFacebook,
      loginByGoogle,
      isAuthLoading,
      isFacebookLoading,
      isGoogleLoading,
    };
  },
});
