import { defineComponent } from "vue";
/* import { Options, Vue } from "vue-class-component";

@Options({
  name: "Dropdown",
  props: {
    modelValue: [Boolean, String, Number],
    items: Array,
  },
})
export default class Dropdown extends Vue {
  private modelValue!: ["", false];
  private items!: [];

  public onValueChange(event: any) {
    this.$emit("update:modelValue", event.target.value);
  }
} */

export default defineComponent({
  name: "Dropdown",
  props: {
    modelValue: [Boolean, String, Number],
    items: Array,
  },
  setup(props, { emit }) {
    function onValueChange(event: any) {
      emit("update:modelValue", event.target.value);
    }

    return {
      onValueChange,
    };
  },
});
