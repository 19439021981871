/* eslint-disable */
import { ProjectStateData, ProjectStateObject } from "@/@types";
import { MutationTree } from "vuex";
import { ProjectStateMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ProjectStateMutationTypes.SET_INSERT_PROJECT_STATE_DATA](
    state: S,
    payload: ProjectStateObject
  ): void;
  [ProjectStateMutationTypes.SET_PROJECT_STATE_DATA](
    state: S,
    payload: ProjectStateData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProjectStateMutationTypes.SET_INSERT_PROJECT_STATE_DATA](
    state: State,
    projectState: ProjectStateObject
  ) {
    state.data = projectState;
  },
  [ProjectStateMutationTypes.SET_PROJECT_STATE_DATA](
    state: State,
    projectStateData: ProjectStateData
  ) {
    state.projectStateData = projectStateData;
  },
};
