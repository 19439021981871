/* eslint-disable */
import { IssueCategoryData, IssueCategoryObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueCategoryMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueCategoryMutationTypes.SET_INSERT_ISSUE_CATEGORY_DATA](
    state: S,
    payload: IssueCategoryObject
  ): void;
  [IssueCategoryMutationTypes.SET_ISSUE_CATEGORY_DATA](
    state: S,
    payload: IssueCategoryData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueCategoryMutationTypes.SET_INSERT_ISSUE_CATEGORY_DATA](
    state: State,
    issueCategory: IssueCategoryObject
  ) {
    state.data = issueCategory;
  },
  [IssueCategoryMutationTypes.SET_ISSUE_CATEGORY_DATA](
    state: State,
    issueCategoryData: IssueCategoryData
  ) {
    state.issueCategoryData = issueCategoryData;
  },
};
