import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Buttons",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-button": Button,
  },
});
