import { RoleData, RoleObject } from "@/@types";

export type State = {
  data: RoleObject | null;
  roleData: RoleData;
};

export const state: State = {
  data: null,
  roleData: [],
};
