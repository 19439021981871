/* eslint-disable */
import { InvoiceData, InvoiceObject } from "@/@types";
import { MutationTree } from "vuex";
import { InvoiceMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [InvoiceMutationTypes.SET_INVOICE_DATA_INSERT]( state: S, payload: InvoiceObject ): void;
  [InvoiceMutationTypes.SET_INVOICE_DATA]( state: S, payload: InvoiceData ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [InvoiceMutationTypes.SET_INVOICE_DATA_INSERT]( state: State, invoice: InvoiceObject ) {
    state.data = invoice;
  },
  [InvoiceMutationTypes.SET_INVOICE_DATA]( state: State, invoiceData: InvoiceData ) {
    state.invoiceData = invoiceData;
  },
};
