/* eslint-disable */
import { ServiceData, ServiceObject } from "@/@types";
import { MutationTree } from "vuex";
import { ServiceMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ServiceMutationTypes.SET_SERVICE_DATA_INSERT]( state: S, payload: ServiceObject ): void;
  [ServiceMutationTypes.SET_SERVICE_DATA]( state: S, payload: ServiceData ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ServiceMutationTypes.SET_SERVICE_DATA_INSERT]( state: State, service: ServiceObject ) {
    state.data = service;
  },
  [ServiceMutationTypes.SET_SERVICE_DATA]( state: State, serviceData: ServiceData ) {
    state.serviceData = serviceData;
  },
};
