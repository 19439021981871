/* eslint-disable */
import { ProjectData, ProjectObject } from "@/@types";
import { MutationTree } from "vuex";
import { ProjectMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ProjectMutationTypes.SET_INSERT_PROJECT_DATA](
    state: S,
    payload: ProjectObject
  ): void;
  [ProjectMutationTypes.SET_PROJECT_DATA](
    state: S,
    payload: ProjectData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProjectMutationTypes.SET_INSERT_PROJECT_DATA](
    state: State,
    project: ProjectObject
  ) {
    state.data = project;
  },
  [ProjectMutationTypes.SET_PROJECT_DATA](
    state: State,
    projectData: ProjectData
  ) {
    state.projectData = projectData;
  },
};
