/* eslint-disable */
export enum FinanceAccountActionTypes {
  FINANCE_ACCOUNT_LIST                = "FinanceAccountList",
  FINANCE_ACCOUNT_CREATE              = "FinanceAccountCreate",
  FINANCE_ACCOUNT_EDIT                = "FinanceAccountEdit",
  FINANCE_ACCOUNT_UPDATE              = "FinanceAccountUpdate",
  FINANCE_ACCOUNT_DELETE              = "FinanceAccountDelete",
  FINANCE_ACCOUNT_GET_COMPANY         = "FinanceAccountGetCompany",
  FINANCE_ACCOUNT_GET_TO_CARE         = "FinanceAccountGetToCare",
  FINANCE_ACCOUNT_GET_FORM_OF_PAYMENT = "FinanceAccountGetFormOfPayment",
  FINANCE_ACCOUNT_GET_ACCOUNT_SOURCE  = "FinanceAccountGetAccountSource",
  FINANCE_ACCOUNT_GET_ACCOUNT_PLAN    = "FinanceAccountGetAccountPlan",
  FINANCE_ACCOUNT_GET_COST_CENTER     = "FinanceAccountGetCostCenter",
  FINANCE_ACCOUNT_GET_STATUS          = "FinanceAccountStatus",
  FINANCE_ACCOUNT_GET_GENERATE_CHARGE = "FinanceAccountGenerateCharge",
}
