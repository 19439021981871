/* eslint-disable */
import { CountryData, CountryObject } from "@/@types";
import { MutationTree } from "vuex";
import { CountryMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [CountryMutationTypes.SET_INSERT_COUNTRY_DATA](
    state: S,
    payload: CountryObject
  ): void;
  [CountryMutationTypes.SET_COUNTRY_DATA](
    state: S,
    payload: CountryData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CountryMutationTypes.SET_INSERT_COUNTRY_DATA](
    state: State,
    country: CountryObject
  ) {
    state.data = country;
  },
  [CountryMutationTypes.SET_COUNTRY_DATA](
    state: State,
    countryData: CountryData
  ) {
    state.countryData = countryData;
  },
};
