import { FinanceBanksData, FinanceBanksObject } from "@/@types";

export type State = {
  financeBanks: FinanceBanksObject | null;
  financeBanksData: FinanceBanksData;
};

export const state: State = {
  financeBanks: null,
  financeBanksData: [],
};
