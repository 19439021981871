/* eslint-disable */
import { FinanceAccountObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceAccountActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import axios from "axios";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_LIST]( 
    { commit }: 
    AugmentedActionContext, params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_CREATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceAccountObject
  ): Promise<boolean>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_EDIT]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_UPDATE]( 
    { commit }: AugmentedActionContext, 
    params: FinanceAccountObject 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_DELETE]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COMPANY]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_FORM_OF_PAYMENT]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_SOURCE]( 
    { commit }: AugmentedActionContext, 
    params: any 
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_STATUS]( 
    { commit }: AugmentedActionContext
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_PLAN]( 
    { commit }: AugmentedActionContext
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COST_CENTER]( 
    { commit }: AugmentedActionContext
  ): Promise<any>;
  [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_GENERATE_CHARGE]( 
    { commit }: AugmentedActionContext,
    params: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COMPANY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/company`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_PLAN]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/plan`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_FORM_OF_PAYMENT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/form-of-payment`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_SOURCE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/source`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        company: params.company,
        month: params.month,
        year: params.year,
        type: params.type,
      }
      ApiService.post(`/finance/account/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_CREATE]({ commit }, params: FinanceAccountObject) {
    return new Promise<boolean>((resolve, reject) => {
      let formattedDate = '';
      if(params.competition){
        const originalDate = new Date(params.competition);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        formattedDate = `${year}-${month}`;
      }

      /* DUE Date */
      let formattedDueDate = '';
      if(params.due){
        const originalDueDate = new Date(params.due);
        const dueYear = originalDueDate.getFullYear();
        const dueMonth = (originalDueDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const dueDay = originalDueDate.getDate().toString().padStart(2, '0');
        formattedDueDate = `${dueYear}-${dueMonth}-${dueDay}`;
      }

      /* Received Payd date */
      let formattedreceivedPaydDateDate = '';
      if(params.receivedPaydDate){
        const originalreceivedPaydDateDate  = new Date(params.receivedPaydDate);
        const receivedPaydDateYear          = originalreceivedPaydDateDate.getFullYear();
        const receivedPaydDateMonth         = (originalreceivedPaydDateDate.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-based
        const receivedPaydDateDay           = originalreceivedPaydDateDate.getDate().toString().padStart(2, '0');
        formattedreceivedPaydDateDate = `${receivedPaydDateYear}-${receivedPaydDateMonth}-${receivedPaydDateDay}`;
      }

      const formData = new FormData();
      formData.append('type', params.type);
      formData.append('client', params.client);
      formData.append('accountPlan', params.accountPlan);
      formData.append('costCenter', params.costCenter);
      formData.append('accountStatus', params.accountStatus);
      formData.append('receiptOrPaymentMethod', params.receiptOrPaymentMethod);
      formData.append('accountForReceiptOrPayment', params.accountForReceiptOrPayment);
      formData.append('transactionCode', params.transactionCode);
      formData.append('due', formattedDueDate);
      formData.append('amount', params.amount);
      formData.append('receivedPaydDate', formattedreceivedPaydDateDate);
      formData.append('interestPercent', params.interestPercent);
      formData.append('observation', params.observation);
      formData.append('competition', formattedDate);
      formData.append('fine', params.fine);
      formData.append('finalAmount', params.finalAmount);
      formData.append('numberOfInstallments', params.numberOfInstallments);

      for(let i = 0; i < params.files.length; i++){
        formData.append('attachment[]', params.files[i]);
      }
      
      ApiService.setHeader();
      axios.post(
        "/finance/account", 
        formData, { 
          headers: { 
            "Content-Type": "multipart/form-data", 
          } 
        }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_UPDATE]({ commit }, params: FinanceAccountObject) {
    return new Promise<boolean>((resolve, reject) => {
      let formattedDate = '';
      if(params.competition){
        const originalDate = new Date(params.competition);
        const year = originalDate.getFullYear();
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        formattedDate = `${year}-${month}`;
      }

      /* DUE Date */
      let formattedDueDate = '';
      if(params.due){
        const originalDueDate = new Date(params.due);
        const dueYear = originalDueDate.getFullYear();
        const dueMonth = (originalDueDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const dueDay = originalDueDate.getDate().toString().padStart(2, '0');
        formattedDueDate = `${dueYear}-${dueMonth}-${dueDay}`;
      }

      /* Received Payd date */
      let formattedreceivedPaydDateDate = '';
      if(params.receivedPaydDate){
        const originalreceivedPaydDateDate  = new Date(params.receivedPaydDate);
        const receivedPaydDateYear          = originalreceivedPaydDateDate.getFullYear();
        const receivedPaydDateMonth         = (originalreceivedPaydDateDate.getMonth() + 1).toString().padStart(2, '0');  // Months are zero-based
        const receivedPaydDateDay           = originalreceivedPaydDateDate.getDate().toString().padStart(2, '0');
        formattedreceivedPaydDateDate = `${receivedPaydDateYear}-${receivedPaydDateMonth}-${receivedPaydDateDay}`;
      }

      const formData = new FormData();
      formData.append('type', params.type);
      formData.append('client', params.client);
      formData.append('accountPlan', params.accountPlan);
      formData.append('costCenter', params.costCenter);
      formData.append('accountStatus', params.accountStatus);
      formData.append('receiptOrPaymentMethod', params.receiptOrPaymentMethod);
      formData.append('accountForReceiptOrPayment', params.accountForReceiptOrPayment);
      formData.append('transactionCode', params.transactionCode);
      formData.append('due', formattedDueDate);
      formData.append('amount', params.amount);
      formData.append('receivedPaydDate', formattedreceivedPaydDateDate);
      formData.append('interestPercent', params.interestPercent);
      formData.append('observation', params.observation);
      formData.append('competition', formattedDate);
      formData.append('fine', params.fine);
      formData.append('finalAmount', params.finalAmount);
      formData.append('numberOfInstallments', params.numberOfInstallments);

      for(let i = 0; i < params.files.length; i++){
        formData.append('attachment[]', params.files[i]);
      }
      
      //const dataForm = data;
      ApiService.setHeader();
      axios.post(
        `/finance/account/${params.id}`, 
        formData, { 
          headers: { 
            "Content-Type": "multipart/form-data", 
          } 
        }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/bank-account/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_TO_CARE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/to-care/${params}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_STATUS]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/status`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COST_CENTER]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/cost-center`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_GENERATE_CHARGE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/account/generate-charge/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
