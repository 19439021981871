import { IntegrationData, IntegrationObject } from "@/@types";

export type State = {
  data: IntegrationObject | null;
  integrationData: IntegrationData;
};

export const state: State = {
  data: null,
  integrationData: [],
};
