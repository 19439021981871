/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Iradiobox from "@/components/form/iradio/iradio.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { useLoading } from 'vue-loading-overlay';
import ExtraLargeModal from "@/components/ui/modal/extra-large-modal/extra-large-modal.vue";
import { InvoiceActionTypes } from "@/store/services/invoice/action-types";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "InvoiceGrid",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-button": Button,
    "app-iradiobox": Iradiobox,
    "modal": ExtraLargeModal,
  },
  setup() {
    const store = useStore();
    const services = ref({});
    /* Form */
    const items: any = ref([{
      service: "",
      value: "",
      quantity: ""
    }]);

    const configValor = computed(() => ({
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
      shouldRound: true,
      focusOnRight: true,
    }));

    const getServiceValue = async (index: any, event: any) => {
      try {
        await store.dispatch(InvoiceActionTypes.INVOICE_SERVICE_VALUE, { id: event.id }).then((data) => {
          items.value[index].value = data.price;
          items.value[index].quantity = 1;
          //services.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const addService = () => {
      items.value.push({ service: "", value: "", quantity: "" })
    };

    const removeService = (index: any) => {
      if (items.value.length === 1) {
        return;
      } else {
        items.value.splice(index, 1);
      }
    }

    const onGetService = async () => {
      try {
        await store.dispatch(InvoiceActionTypes.INVOICE_SERVICE).then((data) => {
          services.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    /* FIM */
    const form: any = ref({
      form: {
        id: "",
        client: "",
        contract: "",
        invoiceDate: "",
        observation: "",
        services: {}
      }
    });

    const rules = computed(() => {
      return {
        form: {
          client: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          contract: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          invoiceDate: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          services: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
        }
      }
    });

    const v$ = useVuelidate(rules, form);

    const currentDate = new Date();
    const currentYear = new Date().getFullYear();
    const currentMonthNumeric = currentDate.getMonth() + 1;
    const modalWindow = ref();

    const filterRule = ref({
      client: "",
      contract: "",
      financial: "",
      month: `${currentMonthNumeric.toString()}`,
      year: currentYear
    });
    let filterContracts = ref({});

    onMounted(() => {
      onListInvoice();
      onGetClents();
      onGetService();
    });

    watch(() => form.value.form.client, () => {
      contracts.value = [];
      onGetContracts();
    });

    watch(() => filterRule.value.client, () => {
      filterContracts.value = [];
      onGetFilterContracts();
    });

    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 9999,
    });

    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "client",
        text: "Cliente",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "contract",
        text: "Contrato",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "invoiceDate",
        text: "Data da fatura",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "financial",
        text: "Financeiro",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "total",
        text: "Total",
        thClass: "text-right",
        thWidth: "",
        tdClass: "text-right",
        tdWidth: "",
      }
    ];

    const clients = ref({});
    const contracts = ref({});

    const financialItems = ref([
      { text: "Sim", value: "Y" },
      { text: "Não", value: "N" },
      { text: "Ambos", value: "" },
    ]);

    const monthsItems = ref([
      { text: "Jan", value: "1" },
      { text: "Fev", value: "2" },
      { text: "Mar", value: "3" },
      { text: "Abr", value: "4" },
      { text: "Mai", value: "5" },
      { text: "Jun", value: "6" },
      { text: "Jul", value: "7" },
      { text: "Ago", value: "8" },
      { text: "Set", value: "9" },
      { text: "Out", value: "10" },
      { text: "Nov", value: "11" },
      { text: "Dez", value: "12" },
    ]);

    const yearsItems: any = ref([]);

    for (let year = currentYear - 5; year <= currentYear; year++) {
      yearsItems.value.push({ text: year.toString(), value: year });
    }

    const onFilterInvoice = () => {
      onListInvoice();
    };

    const onGetClents = async () => {
      try {
        await store.dispatch(InvoiceActionTypes.INVOICE_CLIENT).then((data) => {
          clients.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetContracts = async () => {
      try {
        let filter = { client: form.value.form.client };
        await store.dispatch(InvoiceActionTypes.INVOICE_CONTRACT, filter).then((data) => {
          contracts.value = data;
        })
      } catch (error) {
        return;
      }
    }

    const onGetFilterContracts = async () => {
      try {
        let filter = { client: filterRule.value.client };
        await store.dispatch(InvoiceActionTypes.INVOICE_CONTRACT, filter).then((data) => {
          filterContracts.value = data;
        })
      } catch (error) {
        return;
      }
    }

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onListInvoice();
    };

    const onListInvoice = async () => {

      const loader = $loading.show({
        loader: "dots",
      });

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        formData: {
          client: filterRule.value.client,
          contract: filterRule.value.contract,
          financial: filterRule.value.financial,
          month: filterRule.value.month,
          year: filterRule.value.year
        }
      };
      await store.dispatch(InvoiceActionTypes.INVOICE_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });

    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onInvoiceChangeState = async (invoice: number) => {
      Swal.fire({
        title: "Tem certeza que deseja cancelar?",
        text: "Não será possivel reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, pode cancelar!",
        cancelButtonText: "Destistí da ideia",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });

          store.dispatch(InvoiceActionTypes.INVOICE_STATE, { id: invoice }).then(() => {
            onListInvoice();
            loader.hide();
            Swal.fire({
              title: "Estado!",
              text: "O estado do registro foi cancelado com sucesso.",
              icon: "success",
              allowOutsideClick: false,
            });
          }).catch(() => {
            loader.hide();
          });
        }
      });
    };

    const open = () => {
      modalWindow.value.openModal();
    };

    const newData = () => {
      form.value.form.id = "";
      form.value.form.client = "";
      form.value.form.contract = "";
      form.value.form.invoiceDate = "";
      form.value.form.observation = "";
      items.value = [{ service: "", value: "", quantity: "" }];
      open();
    };

    const closeForm = () => {
      form.value.form.id = "";
      form.value.form.client = "";
      form.value.form.contract = "";
      form.value.form.invoiceDate = "";
      form.value.form.observation = "";
      items.value = [{ service: "", value: "", quantity: "" }];
      modalWindow.value.closeModal();
    };

    const dueDateInputOptions = (date: any) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = new Date(year, month - 1, day);
      const formattedString = formattedDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

      return formattedString;
    };

    const onCreate = () => {
      if (form.value.form.id !== undefined && form.value.form.id !== "") {
        onUpdate();
      } else {
        onInsert();
      }
    };

    const onInsert = async () => {
      if (items.value[0].service) {
        form.value.form.services = items.value;
      }

      const result = await v$.value.$validate();
      if (!result) {
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(InvoiceActionTypes.INVOICE_CREATE, form.value.form).then((data) => {
        closeForm();
        onListInvoice();
        loader.hide();
        Swal.fire({
          title: "Bom trabalho!",
          text: "A fatura criada!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch((error) => {
        closeForm();
        onListInvoice();
        loader.hide();
        Swal.fire({
          title: "Opssss!",
          text: "A fatura não foi criada, contate o seu administrador!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onEdit = (invoice: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(InvoiceActionTypes.INVOICE_EDIT, { id: invoice }).then((data) => {
        const invoiceDate = new Date(data.invoiceDate + 'T00:00:00');
        form.value.form.id = data.id;
        form.value.form.client = data.client;
        form.value.form.contract = data.contract;
        form.value.form.invoiceDate = invoiceDate;
        form.value.form.observation = data.observation;
        items.value = JSON.parse(data.services);
        open();
        loader.hide();
      }).catch(() => {
        loader.hide();
      });

    };

    const onUpdate = async () => {
      if (items.value[0].service) {
        form.value.form.services = items.value;
      }

      const result = await v$.value.$validate();
      if (!result) {
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(InvoiceActionTypes.INVOICE_UPDATE, form.value.form).then((data) => {
        closeForm();
        onListInvoice();
        loader.hide();
        Swal.fire({
          title: "Bom trabalho!",
          text: "A fatura atualizada com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch((error) => {
        closeForm();
        onListInvoice();
        loader.hide();
        Swal.fire({
          title: "Opssss!",
          text: "A fatura não foi atualizada, contate o seu administrador!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    function checkArray(variable: any) {
      return Array.isArray(variable);
    };

    return {
      checkArray,
      v$,
      filterRule,
      gridColumns,
      rows,
      onFilterInvoice,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onListInvoice,
      visibility,
      financialItems,
      onInvoiceChangeState,
      onGetClents,
      onGetContracts,
      clients,
      contracts,
      filterContracts,
      monthsItems,
      yearsItems,
      open,
      modalWindow,
      form,
      items,
      configValor,
      onGetService,
      services,
      getServiceValue,
      addService,
      removeService,
      dueDateInputOptions,
      onCreate,
      closeForm,
      onEdit,
      newData,
      //loader,
      //fullPage
    };
  },
});
