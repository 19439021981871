/* eslint-disable */
import { ServiceObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { ServiceActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>( key: K, payload: Parameters<Mutations[K]>[1] ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ServiceActionTypes.SERVICE_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ServiceActionTypes.SERVICE_CREATE]( { commit }: AugmentedActionContext, params: ServiceObject ): Promise<boolean>;
  [ServiceActionTypes.SERVICE_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ServiceActionTypes.SERVICE_UPDATE]( { commit }: AugmentedActionContext, params: ServiceObject ): Promise<any>;
  [ServiceActionTypes.SERVICE_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ServiceActionTypes.SERVICE_GET_TYPE](): Promise<any>;
  [ServiceActionTypes.SERVICE_STATE]({ commit }: AugmentedActionContext, params: any): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [ServiceActionTypes.SERVICE_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`services/service/${params.limit}/${params.offset}`, params.formData).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_CREATE]( { commit }, params: ServiceObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        cnae: params.cnae,
        description: params.description,
        serviceType: params.serviceType,
        price: params.price,
        active: params.active
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("services/service", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`services/service/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_UPDATE]( { commit }, params: ServiceObject ) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        cnae: params.cnae,
        description: params.description,
        serviceType: params.serviceType,
        price: params.price,
        active: params.active
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`services/service/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`services/service/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_GET_TYPE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`services/service/type`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ServiceActionTypes.SERVICE_STATE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`services/service/state/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
