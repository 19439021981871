import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "custom-file" }
const _hoisted_2 = ["multiple"]
const _hoisted_3 = {
  for: "custom-file",
  class: "custom-file-label"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "file",
      ref: "fileInput",
      onChange: _cache[0] || (_cache[0] = (...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
      multiple: _ctx.multiple,
      class: "custom-file-input",
      id: "custom-file"
    }, null, 40, _hoisted_2),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ]))
}