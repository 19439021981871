import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_table = _resolveComponent("app-table")
  const _component_app_panel = _resolveComponent("app-panel")

  return (_openBlock(), _createBlock(_component_app_panel, null, {
    header: _withCtx(() => [
      _createTextVNode(" CREDITO EM ABERTO POR CLIENTE ")
    ]),
    content: _withCtx(() => [
      _createVNode(_component_app_table, {
        columns: _ctx.gridColumns,
        rows: _ctx.allClients,
        "table-class": "table table-striped"
      }, null, 8, ["columns", "rows"])
    ]),
    _: 1
  }))
}