import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "logo-center mb-1" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_4 = { class: "d-flex justify-content-center mb-2" }
const _hoisted_5 = { class: "button-remove-picture" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "image-input",
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.chooseImage && _ctx.chooseImage(...args)))
      }, [
        (_ctx.imageData)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageData,
              alt: "",
              class: "logo"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (!_ctx.imageData)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Escolha uma imagem "))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          class: "file-input",
          ref: "fileInput",
          type: "file",
          onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.onSelectFile && _ctx.onSelectFile(...args)))
        }, null, 544)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        ((_ctx.imageData || _ctx.url))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "btn btn-danger mt-1 btn-block",
              onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.onRemovePicture && _ctx.onRemovePicture(...args)))
            }, " Excluir "))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}