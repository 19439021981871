<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.program") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-green">
            <template v-slot:title>
                Pesquisar programas
            </template>
        </app-panel>

        <app-panel class="card-primary">
            <template v-slot:title>
                Listagem de programas
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="data" table-class="table-bordered"></app-table>
            </template>
            <template v-slot:footer>
                <app-pagination></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->