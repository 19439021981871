import router from "@/router";

/* eslint-disable */
export const isVisible = (name: any) => {
  let navigations = localStorage.getItem('navigation');
  if (navigations !== undefined && navigations !== null) {
    const navigation: any = localStorage.getItem("navigation")?.split(",");
    if (name !== "" && name !== undefined) {
      let contains: boolean | undefined = false;

      contains = navigation?.includes(name);

      if (navigation !== undefined && !contains) {
        for (var i = 0; i < navigation.length; i++) {
          if (navigation[i].startsWith(name)) {
            return true;
            break;
          }
        }
        return contains;
      } else if (contains) {
        return contains
      }
    }
    return false;
  } else {
    router.replace('/login');
  }
};
