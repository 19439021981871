/* eslint-disable */
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import TreeView from "@/components/ui/treeview/treeview.vue";
import { defineComponent, onMounted, ref, onUpdated, computed } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { MenuActionTypes } from "@/store/admin/menu/action-types";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Button from "@/components/ui/button/button.vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import $ from "jquery";
import "jstree";
import "jstree/src/themes/default/style.css";
import { isVisible } from "@/classes/is_visible";
import { useToast } from "vue-toastification";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "Menu",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel-secondary": PanelSecondary,
    "app-treeview": TreeView,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const treeinput = ref();
    const menucurtain: any = ref(null);
    const formCreate = ref({
      form: {
        id: "",
        parent: "",
        name: "",
        text: "",
        uri_name: "",
        class_namespace: "",
      }
    });

    const rules = computed(() => {
      return {
        form:{
          name: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          text: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          uri_name: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          class_namespace: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          }
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const treeData = ref({});
    const categoryOptions = ref({});

    const makeFolder = (item: { children: never[] }) => {
      menucurtain.value.openNav();
      item.children = [];
      addItem(item);
    };

    onMounted(() => {
      onList();
      allCategories();
    });

    const allCategories = async () => {
      await store.dispatch(MenuActionTypes.ALL_NAVIGATION).then((data) => {
        categoryOptions.value = data;
      })
      .catch((error) => {
        return;
      });
    };

    const onList = async () => {
      const loader = $loading.show({
        loader: "dots",
      });
      
        await store.dispatch(MenuActionTypes.TREE_NAVIGATION).then((data) => {
          treeData.value = data;

          $("#tree-container").jstree("destroy").jstree({
              core: {
                data: [data],
              },
              types: {
                default: {
                  icon: "fa fa-folder text-warning",
                },
                file: {
                  icon: "fa fa-file  text-warning",
                },
              },
              plugins: ["types", "contextmenu", "wholerow"],
              contextmenu: {
                items: function ($node: any) {
                  const tree = $("#tree-container").jstree(true);
                  return {
                    Create: {
                      separator_before: false,
                      separator_after: true,
                      label: "Novo",
                      action: function (obj: any) {
                        if(visibility('navigation.insert')){
                          addItem($node);
                        } else {
                          toast.error("Você não tem permissão para acessar essa funcionalidade");
                        }
                      },
                    },
                    Rename: {
                      separator_before: false,
                      separator_after: false,
                      label: "Editar",
                      action: function (obj: any) {
                        if(visibility('navigation.edit')){
                          onEdit($node);
                        } else {
                          toast.error("Você não tem permissão para acessar essa funcionalidade");
                        }
                      },
                    },
                    Remove: {
                      separator_before: false,
                      separator_after: false,
                      label: "Excluir",
                      action: function (obj: any) {
                        if(visibility('navigation.delete')){
                          onDelete($node);
                        } else {
                          toast.error("Você não tem permissão para acessar essa funcionalidade");
                        }
                      },
                    },
                  };
                },
              },
            })
            .bind("ready.jstree", function (event: any, data: any) {
              if(data.instance._model.data[1] !== undefined){
                const id = data.instance._model.data[1].id ?? null;
                $("#tree-container").jstree("open_node", $("#" + id));
              }
            });
            loader.hide();
        }).catch(() => {
          loader.hide();
        });

    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
        if(!result){
          return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(MenuActionTypes.UPDATE_NAVIGATION, formCreate.value.form).then(() => {
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
        formCreate.value.form.id = "";
        formCreate.value.form.name = "";
        formCreate.value.form.parent = "";
        formCreate.value.form.text = "";
        formCreate.value.form.uri_name = "";
        formCreate.value.form.class_namespace = "";
        onList();
        loader.hide();
      }).catch((err) => {
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
      });
    };

    const onCreate = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      }

      const loader = $loading.show({
        loader: "dots",
      });
      
      store.dispatch(MenuActionTypes.CREATE_NAVIGATION, formCreate.value.form).then(() => {
        formCreate.value.form.parent = "";
        formCreate.value.form.name = "";
        formCreate.value.form.text = "";
        formCreate.value.form.uri_name = "";
        formCreate.value.form.class_namespace = "";
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        allCategories();
        menucurtain.value.closeNav();
        loader.hide();
      }).catch(() => {
        loader.hide();
      });          
      
    };

    const onDelete = (item: any) => {
      
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(MenuActionTypes.DELETE_NAVIGATION, { id: item.id, }).then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Your record has been deleted.",
              icon: "success",
              allowOutsideClick: false,
            });
            onList();
            loader.hide();
          }).catch(() => {
            loader.hide();
          })
        }
      });
      
    };

    const onEdit = (item: any) => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(MenuActionTypes.EDIT_NAVIGATION, { id: item.id }).then((data) => {
        formCreate.value.form.id = data.id;
        formCreate.value.form.parent = data.parent;
        formCreate.value.form.name = data.name;
        formCreate.value.form.text = data.text;
        formCreate.value.form.uri_name = data.uriName;
        formCreate.value.form.class_namespace = data.namespace;
        loader.hide();
        menucurtain.value.openNav();
      }).catch((error) => {
        loader.hide();
      });
    };

    const addItem = (item: any) => {
      formCreate.value.form.parent = item.id;
      formCreate.value.form.id = "";
      formCreate.value.form.name = "";
      formCreate.value.form.text = "";
      formCreate.value.form.uri_name = "";
      formCreate.value.form.class_namespace = "";
      menucurtain.value.openNav();
    };

    const visibility = (name: any) => {
      return isVisible(name);
    }

    return {
      treeData,
      makeFolder,
      addItem,
      formCreate,
      menucurtain,
      onList,
      allCategories,
      categoryOptions,
      onStore,
      onEdit,
      treeinput,
      onDelete,
      v$
    };
  },
});
