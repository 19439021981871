import { AccountPlanData, AccountPlanObject } from "@/@types";

export type State = {
  data: AccountPlanObject | null;
  accountPlanData: AccountPlanData;
};

export const state: State = {
  data: null,
  accountPlanData: [],
};
