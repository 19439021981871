import { MenuData, MenuObject } from "@/@types";
import { MutationTree } from "vuex";
import { MenuMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [MenuMutationTypes.SET_INSERT_MENU_DATA](state: S, payload: MenuObject): void;
  [MenuMutationTypes.SET_MENU_DATA](state: S, payload: MenuData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MenuMutationTypes.SET_INSERT_MENU_DATA](state: State, menu: MenuObject) {
    state.data = menu;
  },
  [MenuMutationTypes.SET_MENU_DATA](state: State, menuData: MenuData) {
    state.menuData = menuData;
  },
};
