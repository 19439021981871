import { IntegrationData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getIntegration(state: State): IntegrationData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIntegration: (state) => state.integrationData,
};
