/* eslint-disable */
import { defineComponent, onMounted, ref, watch } from "vue";

export default defineComponent({
    name: "RadioGroupButton",
    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
        },
        divClass: {
            type: String,
            default: "",
        },
    },
    setup(props, { emit }) {
        const modelValue = ref(props.modelValue);
        const handleOptionChange = (value: any) => {
            modelValue.value = value;
            emit("update:modelValue", modelValue.value);
        };

        watch(() => props.modelValue, (newValue) => {
            modelValue.value = newValue;
        });

        return {
            modelValue,
            handleOptionChange
        };
    },
});
