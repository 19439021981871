/* eslint-disable */
import { ContractData, ContractObject } from "@/@types";
import { MutationTree } from "vuex";
import { ContractMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ContractMutationTypes.SET_CONTRACT_DATA_INSERT]( state: S, payload: ContractObject ): void;
  [ContractMutationTypes.SET_CONTRACT_DATA]( state: S, payload: ContractData ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ContractMutationTypes.SET_CONTRACT_DATA_INSERT]( state: State, contract: ContractObject ) {
    state.data = contract;
  },
  [ContractMutationTypes.SET_CONTRACT_DATA]( state: State, contractData: ContractData ) {
    state.contractData = contractData;
  },
};
