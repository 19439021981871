export const date_br_format = (inputDate: Date) => {
  let date,
    month,
    year = 0;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");
  month = month.toString().padStart(2, "0");
  return `${date}/${month}/${year}`;
};
