/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { AccessLogActionTypes } from "@/store/admin/log/access/action-types";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "Group",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-table": grid,
    "app-pagination": Paginate,
    "app-button": Button,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const rows = ref([]);
    const description = ref("");
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref();
    const perPage = ref(10);

    const gridColumns = [
      {
        key: "user",
        text: "User",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "session",
        text: "Token",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "key",
        text: "Key",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "loggedIn",
        text: "Loged In",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "loggedOut",
        text: "Loged Out",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    onMounted(() => {
      onList();
    });

    const onSearch = () => {
      return;
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onList = async () => {
      
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        user: "",
      };

      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(AccessLogActionTypes.LOG_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    return {
      gridColumns,
      rows,
      description,
      onSearch,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
    };
  },
});
