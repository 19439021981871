/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Iradiobox from "@/components/form/iradio/iradio.vue";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { ContractActionTypes } from "@/store/services/contract/action-types";
import { useLoading } from 'vue-loading-overlay';
import InputInline from "@/components/form/input-inline/input-inline.vue";

export default defineComponent({
  name: "ContractGrid",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-iradiobox": Iradiobox,
    "input-inline": InputInline
  },
  setup() {
    const store = useStore();
    const filterRule = ref({
      client: "",
      contractType: "",
      contractNumber: "",
      active: ""
    });

    onMounted(() => {
      onListContract();
      onGetContractType();
      onGetClents();
    });

    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 9999,
    });

    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "client",
        text: "Cliente",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "contractNumber",
        text: "Nº",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "type",
        text: "Tipo de contrato",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "startDate",
        text: "Data de início",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "endDate",
        text: "Data de vencimento",
        thClass: "text-right",
        thWidth: "",
        tdClass: "text-right",
        tdWidth: "",
      },
      {
        key: "active",
        text: "Ativo",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      }
    ];

    const contractTypes = ref({});
    const clients = ref({});

    const activeItems = ref([
      { text: "Sim", value: 'Y' },
      { text: "Não", value: 'N' },
      { text: "Ambos", value: "" },
    ]);

    const onFilterContract = () => {
      onListContract();
    };

    const onGetContractType = () => {
      try {
        store.dispatch(ContractActionTypes.CONTRACT_TYPE_GET).then((data) => {
          contractTypes.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetClents = async () => {
      try {
        await store.dispatch(ContractActionTypes.CONTRACT_CLIENT).then((data) => {
          clients.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onListContract();
    };

    const onListContract = async () => {

      const loader = $loading.show({
        loader: "dots",
      });

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        formData: {
          client: filterRule.value.client,
          contractType: filterRule.value.contractType,
          contractNumber: filterRule.value.contractNumber,
          active: filterRule.value.active
        }
      };
      await store.dispatch(ContractActionTypes.CONTRACT_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });

    };

    const onDeleteContract = (id: number) => {
      Swal.fire({
        title: "Tem certeza?",
        text: "Não poderá reverter depois!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, exclua!",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(ContractActionTypes.CONTRACT_DELETE, { id: id }).then(() => {
            onListContract();
            loader.hide();
            Swal.fire({
              title: "Excluido!",
              text: "Seu registro foi excluido.",
              icon: "success",
              allowOutsideClick: false,
            });
          }).catch(() => {
            loader.hide();
          });
        }
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onContractChangeState = async (contract: number) => {
      Swal.fire({
        title: "Tem certeza?",
        text: "Não poderá reverter!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, pode alterar!",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(ContractActionTypes.CONTRACT_STATE, { id: contract }).then(() => {
            onListContract();
            loader.hide();
            Swal.fire({
              title: "Estado!",
              text: "O estado do registro foi alterado.",
              icon: "success",
              allowOutsideClick: false,
            });
          });
        }
      });
    }

    return {
      filterRule,
      gridColumns,
      rows,
      onFilterContract,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onListContract,
      onDeleteContract,
      visibility,
      onGetContractType,
      contractTypes,
      activeItems,
      onContractChangeState,
      onGetClents,
      clients,
      //loader,
      //fullPage
    };
  },
});
