import { UserData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../../..";
import { State } from "./state";

export type Getters = {
  getUser(state: State): UserData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUser: (state) => state.userData,
};
