<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.dashboard", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <div class="info-box shadow rounded-0">
                    <span class="info-box-icon bg-success">
                        <i class="fas fa-calendar-check"></i>
                    </span>

                    <div class="info-box-content">
                        <span class="info-box-text">VENDAS NO MÊS</span>
                        <span class="info-box-number">{{ monthSell }}</span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
            <div class="col-sm">
                <div class="info-box shadow rounded-0">
                    <span class="info-box-icon bg-primary">
                        <i class="fas fa-calendar-check"></i>
                    </span>

                    <div class="info-box-content">
                        <span class="info-box-text">VENDAS NO ANO</span>
                        <span class="info-box-number">{{ yearSell }}</span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="card shadow rounded-0">
                    <div class="card-body">
                        <h5 class="card-title text-center mb-5">
                            <strong>Faturado por mês</strong>
                        </h5>
                        <p class="card-text">
                            <apexchart 
                                width="100%" 
                                height="400" 
                                type="bar" 
                                :options="monthChartOptions" 
                                :series="monthSeries"
                            >
                            </apexchart>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="card shadow rounded-0">
                    <div class="card-body">
                        <h5 class="card-title text-center mb-5">
                            <strong>Faturado por ano</strong>
                        </h5>
                        <p class="card-text">
                            <apexchart 
                                width="100%" 
                                height="400" 
                                type="bar" 
                                :options="yearChartOptions" 
                                :series="yearSeries"
                            >
                            </apexchart>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>