/* eslint-disable */
import { FinanceBankAccountTypeData, FinanceBankAccountTypeObject } from "@/@types";

export type State = {
  financeBankAccountType: FinanceBankAccountTypeObject | null;
  financeBankAccountTypeData: FinanceBankAccountTypeData;
};

export const state: State = {
  financeBankAccountType: null,
  financeBankAccountTypeData: [],
};
