import { IssueTaskData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getIssueTask(state: State): IssueTaskData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIssueTask: (state) => state.issueTaskData,
};
