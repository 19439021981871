<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.contract", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">

        <app-panel class="card-default rounded-0 shadow">
            <template v-slot:header>
                <div class="container-full">
                    <div class="row">
                        <div class="col-sm">
                            <h3 class="card-title mt-2">
                                GERAR FATURAS
                            </h3>
                        </div>
                        <div class="col-sm">
                            <app-button type="button" class="btn btn-success rounded-0 shadow float-right"
                                @click="submitForm">
                                <i class="fa fa-clipboard-check"></i>
                                Gerar
                            </app-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="container-full">
                    <div class="row">
                        <div class="col-sm text-left">
                            Contratos
                        </div>
                        <div class="col-sm text-right">
                            <input 
                                type="text" 
                                name="filter" 
                                class="form-control form-control-border border-width-2"
                                placeholder="Buscar"
                                v-model="filtering"
                                @keyup="filterContract"
                                style="background-color: #C5C6D0;color: black;"
                            />
                        </div>
                    </div>
                </div>
                <table class="table table-striped invoice">
                    <thead>
                        <tr>
                            <th width="1%">
                                <input 
                                    type="checkbox" 
                                    v-model="checkAll"
                                    class="form-check mb-2 check-invoice" 
                                    name="" 
                                    id=""
                                />
                            </th>
                            <th>Cliente</th>
                            <th>Contrato</th>
                            <th class="text-center">Última fatura</th>
                            <th class="text-right">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr v-for="(contract, index) in contracts" :key="index">
                            <td v-if="!contract.state">
                                <input type="checkbox" v-model="checked" :value="contract.id" />
                            </td>
                            <td v-else></td>
                            <td>{{ contract.client }}</td>
                            <td>{{ contract.contract }}</td>
                            <td class="text-center">
                                <h5>
                                    <span class="badge bge badge-warning rounded-0 text-white">
                                        {{ contract.lastInvoice }}
                                    </span>
                                </h5>
                            </td>
                            <td class="text-right">{{ contract.total }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->