/* eslint-disable */
import { IntegrationData, IntegrationObject } from "@/@types";

export type State = {
  data: any;
  informationData: any;
};

export const state: State = {
  data: null,
  informationData: [],
};
