<div class="form-group">
    <label :for="labelFor" :class="labelClass" class="col-form-label" :id="labelFor">{{ label }}</label>
    <input 
        :type="inputType" 
        :value="modelValue"
        :name="inputName"
        @input="updateInput"
        class="form-control rounded-0" 
        :id="inputId" 
        :placeholder="placeHolder"
        :class="{'is-valid': isActive, 'is-invalid': hasError, [inputClass]: true}"
        :readonly="readonly"
        @keyup="onKeypress(modelValue)"
        @blur="onBlur(modelValue)"
        :maxlength="maxlength"
        :size="size"
        v-maska:mask 
        :data-maska="maskara"
    />
    <span v-if="checkArray(error)">
        <small 
            class="text-error form-text" 
            v-for="(errorMessage, index) of error" 
            :key="index"
        >
            {{ errorMessage.$message }}
        </small>
    </span>
    <span v-if="!checkArray(error)">
        <small class="text-error form-text" v-if="!!error">{{ error }}</small>
    </span>
</div>