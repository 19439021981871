<nav aria-label="Page navigation example">
  <ul class="pagination pagination-sm justify-content-center">
    <li
      class="page-item"
      :class="{ disabled: isInFirstPage }"
      @click="onClickFirstPage"
      :disabled="isInFirstPage"
    >
      <a
        class="page-link"
        href="javascript:;"
        tabindex="-1"
        :disabled="isInFirstPage"
        :aria-disabled="isInFirstPage"
        >Primeira</a
      >
    </li>
    <li
      class="page-item"
      @click="onClickPreviousPage"
      :class="{ disabled: isInFirstPage }"
    >
      <a
        class="page-link"
        href="javascript:;"
        tabindex="-1"
        :disabled="isInFirstPage"
        :aria-disabled="isInFirstPage"
        >Anterior</a
      >
    </li>

    <li
      class="page-item"
      :class="{ active: isPageActive(page.name) }"
      :aria-current="isPageActive(page.name)"
      :disabled="page.isDisabled"
      v-for="page in pages"
      :key="page.name"
    >
      <a
        class="page-link"
        href="javascript:;"
        @click="onClickPage(page.name)"
      >
        {{ page.name }}
      </a>
    </li>

    <li
      class="page-item"
      :class="{ disabled: isInLastPage }"
      @click="onClickNextPage"
    >
      <a 
        class="page-link" 
        :aria-disabled="isInLastPage" 
        :disabled="isInLastPage" 
        href="javascript:;"
        >Próxima</a
      >
    </li>
    <li
      class="page-item"
      :class="{ disabled: isInLastPage }"
      @click="onClickLastPage"
    >
      <a 
        class="page-link" 
        :aria-disabled="isInLastPage" 
        :disabled="isInLastPage" 
        href="javascript:;"
      >Última</a
      >
    </li>
  </ul>
</nav>