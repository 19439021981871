/* eslint-disable */
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import TreeView from "@/components/ui/treeview/treeview.vue";
import { defineComponent, onMounted, ref, onUpdated, computed } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Button from "@/components/ui/button/button.vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import $ from "jquery";
import "jstree";
import "jstree/src/themes/default/style.css";
import { isVisible } from "@/classes/is_visible";
import { useToast } from "vue-toastification";
import { AccountPlanActionTypes } from "@/store/finance/accountplan/action-types";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "AccountPlan",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel-secondary": PanelSecondary,
    "app-treeview": TreeView,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const treeinput = ref();
    const accountplancurtain: any = ref(null);
    const formCreate = ref({
      form: {
        id: "",
        parent: "",
        code: "",
        description: ""
      }
    });

    const rules = computed(() => {
      return {
        form:{
          code: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(1)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(10)
            )
          },
          description: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(255)
            )
          },
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const treeData = ref({});
    const categoryOptions = ref({});

    const makeFolder = (item: { children: never[] }) => {
      accountplancurtain.value.openNav();
      item.children = [];
      addItem(item);
    };

    onMounted(() => {
      onList();
    });

    const onList = async () => {  
     
      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(AccountPlanActionTypes.TREE_ACCOUNT_PLAN).then((data) => {
        treeData.value = data;
        $("#tree-container").jstree("destroy").jstree({
            core: {
              data: [data],
            },
            types: {
              default: {
                icon: "fa fa-folder text-warning",
              },
              file: {
                icon: "fa fa-file  text-warning",
              },
            },
            plugins: ["types", "contextmenu", "wholerow"],
            contextmenu: {
              items: function ($node: any) {
                const tree = $("#tree-container").jstree(true);
                return {
                  Create: {
                    separator_before: false,
                    separator_after: true,
                    label: "Novo",
                    action: function (obj: any) {
                      if(visibility('navigation.insert')){
                        addItem($node);
                      } else {
                        toast.error("Você não tem permissão para acessar essa funcionalidade");
                      }
                    },
                  },
                  Rename: {
                    separator_before: false,
                    separator_after: false,
                    label: "Editar",
                    action: function (obj: any) {
                      if(visibility('navigation.edit')){
                        onEdit($node);
                      } else {
                        toast.error("Você não tem permissão para acessar essa funcionalidade");
                      }
                    },
                  },
                  Remove: {
                    separator_before: false,
                    separator_after: false,
                    label: "Excluir",
                    action: function (obj: any) {
                      if(visibility('navigation.delete')){
                        onDelete($node);
                      } else {
                        toast.error("Você não tem permissão para acessar essa funcionalidade");
                      }
                    },
                  },
                };
              },
            },
          })
          .bind("ready.jstree", function (event: any, data: any) {
            const id = data.instance._model.data[1]?.id ?? null;
            $("#tree-container").jstree("open_node", $("#" + id));
          });
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = () => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(AccountPlanActionTypes.UPDATE_ACCOUNT_PLAN, formCreate.value.form).then(() => {
        formCreate.value.form.id = "";
        formCreate.value.form.description = "";
        formCreate.value.form.parent = "";
        formCreate.value.form.code = "";
        onList();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
      })
      .catch((err) => {
        onList();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onCreate = async () => {
      
      const result = await v$.value.$validate();
      if(!result){
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(AccountPlanActionTypes.CREATE_ACCOUNT_PLAN, formCreate.value.form).then(() => {
        formCreate.value.form.parent = "";
        formCreate.value.form.description = "";
        formCreate.value.form.code = "";
        accountplancurtain.value.closeNav();
        onList();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        accountplancurtain.value.closeNav();
      });          
      
    };

    const onDelete = (item: any) => {
      
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });

          store.dispatch(AccountPlanActionTypes.DELETE_ACCOUNT_PLAN, { id: item.id, }).then(() => {
            onList();
            loader.hide();
            Swal.fire({
              title: "Deleted!",
              text: "Your record has been deleted.",
              icon: "success",
              allowOutsideClick: false,
            });
          }).catch(() => {
            loader.hide();
          });
        }
      });

    };

    const onEdit = (item: any) => {

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(AccountPlanActionTypes.EDIT_ACCOUNT_PLAN, { id: item.id }).then((data) => {
        formCreate.value.form.id = data.id;
        formCreate.value.form.parent = data.parent;
        formCreate.value.form.code = data.code;
        formCreate.value.form.description = data.description;
        accountplancurtain.value.openNav();
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const addItem = (item: any) => {
      formCreate.value.form.parent = item.id;
      formCreate.value.form.id = "";
      formCreate.value.form.description = "";
      formCreate.value.form.code = "";
      accountplancurtain.value.openNav();
    };

    const visibility = (name: any) => {
      return isVisible(name);
    }

    return {
      treeData,
      makeFolder,
      addItem,
      formCreate,
      v$,
      accountplancurtain,
      onList,
      categoryOptions,
      onStore,
      onEdit,
      treeinput,
      onDelete,
    };
  },
});
