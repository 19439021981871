<div class="d-inline" :class="iradioClass">
    <input 
        :id="id"
        v-model="computedValue"
        type="radio"
        :name="name"
        :disabled="disabled"
        :indeterminate="indeterminate"
        :true-value="trueValue"
        :false-value="falseValue"
        :value="value"
        :checked="checked"
        class="iradio-custom"
    />
    <label :for="id">
     <slot />
    </label>
</div>