<app-panel>
    <template #header>
        A PAGAR POR MÊS
    </template>
    <template #content>
        <app-table 
            :columns="gridColumns" 
            :rows="allMonths" 
            table-class="table table-striped"
        >
        </app-table>
    </template>
</app-panel>