/* eslint-disable */
import { PfDropdown } from "@profabric/vue-components";
import { defineComponent, watch } from "vue";
import { 
  changeFlag, 
  changeLanguage, 
  languages, 
  selectedLanguage, 
  flagIcon 
} from "@/classes/languageUtils";

export default defineComponent({
  name: "languages-dropdown",
  components: {
    "pf-dropdown": PfDropdown,
  },
  setup() {

    // eslint-disable-next-line
    watch(selectedLanguage, (newLocale: any, oldLocale: any) => {
      changeFlag(newLocale);
    });

    return {
      changeLanguage,
      changeFlag,
      flagIcon,
      languages,
    };
  },
});
