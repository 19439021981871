<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.accountPlan") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel-secondary class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Arvore de plano de contas
                </h3>
            </template>
            <template v-slot:content>
                <div class="col-sm text-center menu-guide-user mb-3">
                    <h2>Como usar essa página?</h2>
                    <p>Para cadastrar, editar ou excluir registro nesta página clique com botão direito do mouse em uma das APIs descritas</p>
                </div>
                <div id="tree-container"></div>
            </template>
        </app-panel-secondary>
        <form action="javascript:;" @submit.prevent="onStore">
            <app-right-curtain 
                right-curtain-name="right" 
                ref="accountplancurtain" 
                card-class="card-default"
            >
                <template v-slot:title>
                    Formulário
                </template>
                <template v-slot:content>
                    <input type="hidden" name="id" v-model="formCreate.form.id" />
                    <input type="hidden" name="parent" v-model="formCreate.form.parent" />
                    <app-input-default 
                        label-for="Code" 
                        label="Código" 
                        input-name="code"
                        input-type="text" 
                        place-holder="" 
                        v-model="v$.form.code.$model" 
                        input-id="code"
                        :has-error="v$.form.code.$error" 
                        :is-active="v$.form.code.$error" 
                        :error="v$.form.code.$errors"
                    >
                    </app-input-default>
                    <app-input-default 
                        label-for="Nome" 
                        label="Nome" 
                        input-name="name"
                        input-type="text" 
                        place-holder="Nome" 
                        v-model="v$.form.description.$model" 
                        input-id="nome"
                        :has-error="v$.form.description.$error" 
                        :is-active="v$.form.description.$error" 
                        :error="v$.form.description.$errors"
                    >
                    </app-input-default>
                </template>
                <template v-slot:footer>
                    <app-button 
                        type="submit" 
                        class="btn btn-default rounded-0"
                        :disabled="v$.form.$invalid"
                    >
                        <i class="fa fa-save"></i>
                        Gravar
                    </app-button>
                </template>
            </app-right-curtain>
        </form>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->