import { FinanceMovementData, FinanceMovementObject } from "@/@types";

export type State = {
  financeMovement: FinanceMovementObject | null;
  financeMovementData: FinanceMovementData;
};

export const state: State = {
  financeMovement: null,
  financeMovementData: [],
};
