<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.invoice", 2) }} {{ loading }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    FATURA
                </h3>
            </template>
            <template v-slot:content>
                <div class="row mb-3" id="cliente">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Cliente:
                        </label>
                    </div>
                    <div class="col-sm-8">
                        <Select2 v-model="filterRule.client" :options="clients" :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <div class="row mb-3" id="contract">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Contrato:
                        </label>
                    </div>
                    <div class="col-sm-4">
                        <Select2 v-model="filterRule.contract" :options="filterContracts" :settings="{ 
                                dropdownParent: '#contract', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"/>
                    </div>
                </div>
                <div class="row mb-3" id="active">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Financeiro Gerado:
                        </label>
                    </div>
                    <div class="col mt-2">
                        <app-iradiobox v-for="financial in financialItems" :value="financial.value" name="active"
                            v-model="filterRule.financial" :checked="(filterRule.financial === financial.value)">
                            {{ financial.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
                <div class="row mb-3" id="active">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="mt-1">
                            Mês:
                        </label>
                    </div>
                    <div class="col mt-1">
                        <app-iradiobox v-for="months in monthsItems" :value="months.value" name="month"
                            v-model="filterRule.month" :checked="filterRule.month === months.value">
                            {{ months.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
                <div class="row mb-3" id="anos">
                    <div class="col-sm-2 text-right">
                        <label for="Year" class="">
                            Ano:
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <app-iradiobox v-for="years in yearsItems" :value="years.value" name="year"
                            v-model="filterRule.year" :checked="filterRule.year === years.value">
                            {{ years.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onFilterInvoice">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0 shadow"
                    v-if="visibility('services.invoice.invoice.insert')" @click=" newData">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    &nbsp;
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: invoice }">
                        <a href="javascript:;"
                            v-if="visibility('services.invoice.invoice.edit') && invoice.financial !== 'Cancelado'"
                            @click="onEdit(invoice.id)">
                            <i class="far fa-edit blue"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onInvoiceChangeState(invoice.id)"
                            v-if="visibility('services.invoice.invoice.state') && invoice.financial !== 'Cancelado'">
                            <i class="fa fa-power-off orange"></i>
                        </a>
                    </template>
                    <template v-slot:cell-financial="{ row: invoice }">
                        <h5 v-if="(invoice.financial === 'Não gerado')">
                            <span class="badge bg-danger rounded-0">{{ invoice.financial }}</span>
                        </h5>
                        <h5 v-else-if="(invoice.financial === 'Aguardando')">
                            <span class="badge bg-warning rounded-0">{{ invoice.financial }}</span>
                        </h5>
                        <h5 v-else-if="(invoice.financial === 'Pago')">
                            <span class="badge bg-success rounded-0">{{ invoice.financial }}</span>
                        </h5>
                        <h5 v-else-if="(invoice.financial === 'Recebido')">
                            <span class="badge bg-success rounded-0">{{ invoice.financial }}</span>
                        </h5>
                        <h5 v-else="(invoice.financial === 'Cancelado')">
                            <span class="badge bg-gray rounded-0">{{ invoice.financial }}</span>
                        </h5>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
<form action="javascript:;" @submit.prevent="onCreate">
    <modal identity-name="test" ref="modalWindow">
        <template v-slot:title>
            FATURA
        </template>
        <template v-slot:content>
            <div class="row mb-3" id="cliente-form-2">
                <div class="col-sm-2 text-right">
                    <label for="Responsável" class="bills-to-pay-label-margin">
                        Cliente:
                    </label>
                </div>
                <div class="col-sm-8">
                    <Select2 v-model="v$.form.client.$model" :options="clients" :settings="{ 
                            dropdownParent: '#cliente-form-2', 
                            width: '100%',
                            multiple: false,
                            allowClear: true
                        }" />
                    <input type="hidden" name="id" v-model="form.id" />
                    <span v-if="checkArray(v$.form.client.$errors)">
                        <small id="emailHelp" class="form-text text-danger"
                            v-for="(errorMessage, index) of v$.form.client.$errors" :key="index">
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                </div>
            </div>
            <div class="row mb-3" id="contract-form-2">
                <div class="col-sm-2 text-right">
                    <label for="Responsável" class="bills-to-pay-label-margin">
                        Contrato:
                    </label>
                </div>
                <div class="col-sm-8">
                    <Select2 v-model="v$.form.contract.$model" :options="contracts" :settings="{ 
                            dropdownParent: '#contract-form-2', 
                            width: '100%',
                            multiple: false,
                            allowClear: true
                        }" />
                    
                    <span v-if="checkArray(v$.form.contract.$errors)">
                        <small id="emailHelp" class="form-text text-danger"
                            v-for="(errorMessage, index) of v$.form.contract.$errors" :key="index">
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-2 text-right">
                    <label for="Responsável" class="bills-to-pay-label-margin mt-3">
                        Data da fatura:
                    </label>
                </div>
                <div class="col mt-2">
                    <VueDatePicker v-model="v$.form.invoiceDate.$model" :format="dueDateInputOptions" locale="pt-BR"
                        :clearable="true" auto-apply :enable-time-picker="false" class="col-sm-4">
                    </VueDatePicker>
                    <span v-if="checkArray(v$.form.invoiceDate.$errors)">
                        <small id="emailHelp" class="form-text text-danger"
                            v-for="(errorMessage, index) of v$.form.invoiceDate.$errors" :key="index">
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                </div>
            </div>

            <div class="bg-gray">
                <h4 class="ml-1 mt-1 mb-1">Itens do contrato</h4>
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th width="50%">Serviço</th>
                        <th>Valor</th>
                        <th colspan="2">Quantidade</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td :id="'service-'+index">
                            <Select2 v-model="item.service" :options="services" @select="getServiceValue(index, $event)"
                                @change="clientChange($event)" :settings="{ 
                                    dropdownParent: '#service-'+index, 
                                    width: '100%',
                                    multiple: false,
                                    allowClear: true
                            }" />
                        </td>
                        <td>
                            <money3 v-model="item.value" v-bind="configValor"
                                class="form-control rounded-0 input-money" />
                        </td>
                        <td>
                            <input type="number" name="quantity" v-model="item.quantity" class="form-control text-right"
                                min="0" />
                        </td>
                        <td class="text-right" width="1%">
                            <button type="button" class="btn btn-default rounded-0 btn-sm text-danger mt-1"
                                @click="removeService(index)">
                                <i class="fas fa-times"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4" class="text-right">
                            <button type="button" class="btn btn-default rounded-0 btn-sm text-success pull-right"
                                @click="addService()">
                                <i class="fas fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <span v-if="checkArray(v$.form.services.$errors)">
                <small id="emailHelp" class="form-text text-danger"
                    v-for="(errorMessage, index) of v$.form.services.$errors" :key="index">
                    {{ errorMessage.$message }}
                </small>
            </span>
        </template>
        <template v-slot:footer>
            <app-button type="button" class="btn btn-warning ml-2 shadow rounded-0" @click="closeForm">
                <i class="fa fa-table"></i>
                Cancelar
            </app-button>

            <app-button type="submit" class="btn btn-success ml-2 shadow rounded-0 pull-left">
                <i class="fa fa-save warning"></i>
                Gravar
            </app-button>
        </template>
    </modal>
</form>