import { IssueTaskData, IssueTaskObject } from "@/@types";

export type State = {
  data: IssueTaskObject | null;
  issueTaskData: IssueTaskData;
};

export const state: State = {
  data: null,
  issueTaskData: [],
};
