import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "Iradiobox",
  props: {
    modelValue: [
      Boolean,
      String,
      Number,
      Object,
      Array as () => Array<string | number>,
    ],
    value: [String, Number, Array as () => Array<string>],
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    checked: Boolean,
    id: String,
    name: String,
    round: Boolean,
    iradioClass: {
      type: String,
      default: "icheck-primary",
    },
    disabled: Boolean,
    indeterminate: Boolean,
  },
  emits: ["update:modelValue"],
  setup: (props: any, { emit }) => {
    const computedValue = computed({
      get(): boolean | string | number | (string | number)[] | undefined {
        return props.modelValue;
      },
      set(value: boolean | string | number | (string | number)[] | undefined) {
        emit("update:modelValue", value);
      },
    });
    return { computedValue };
  },
});
