/* eslint-disable */
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceDashboardActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_BILL_BY_CLIENT]( 
    { commit }: 
    AugmentedActionContext, params: any 
  ): Promise<any>;
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_BILL_BY_MONTH]( 
    { commit }: AugmentedActionContext, 
    params: any
  ): Promise<any>;
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PREVIEW_YEAR]( 
    { commit }: AugmentedActionContext, 
    params: any
  ): Promise<any>;
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PREVIEW_MONTH]( 
    { commit }: AugmentedActionContext, 
    params: any
  ): Promise<any>;
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PENDING_MONTH]( 
    { commit }: AugmentedActionContext, 
    params: any
  ): Promise<any>;
  [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PENDING_MONTH]( 
    { commit }: AugmentedActionContext, 
    params: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_BILL_BY_CLIENT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
        accountStatus: params.accountStatus
      }
      ApiService.post(`/finance/dashboard/sum-by-client`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_BILL_BY_MONTH]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
        accountStatus: params.accountStatus
      }
      ApiService.post(`/finance/dashboard/sum-to-pay-month`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PREVIEW_YEAR]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
      }
      ApiService.post(`/finance/dashboard/sum-preview-year`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PENDING_MONTH]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
        accountStatus: params.accountStatus,
      }
      ApiService.post(`/finance/dashboard/sum-pending-month`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PENDING_YEAR]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
        accountStatus: params.accountStatus,
      }
      ApiService.post(`/finance/dashboard/sum-pending-year`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PREVIEW_MONTH]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        type: params.type,
        key: params.key,
      }
      ApiService.post(`/finance/dashboard/sum-preview-month`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
