<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.state") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Estado
                </h3>
            </template>
            <template v-slot:content>
                <input type="text" class="form-control rounded-0" name="description" v-model="formFilter.description" />
                {{ description }}
                <form action="javascript:;" @submit.prevent="onStore">
                    <app-right-curtain right-curtain-name="right" ref="formcurtain" card-class="card-default">
                        <template v-slot:title>
                            Formulário
                        </template>
                        <template v-slot:content>
                            <input type="hidden" name="id" v-model="form.form.id" />
                            <app-input-inline label-for="Descrição" label="Descrição:" name="description" type="text"
                                place-holder="" v-model="v$.form.description.$model" input-id="description"
                                input-class="is-valid" div-class="col-sm-6" label-class="col-sm-2 text-right"
                                :has-error="v$.form.description.$error" :is-active="!v$.form.description.$error"
                                :error="v$.form.description.$errors"></app-input-inline>

                            <div class="row mb-3" id="active">
                                <div class="col-sm-2 text-right">
                                    <label for="Estado Inicial" class="bills-to-pay-label-margin">
                                        Estado inicial:
                                    </label>
                                </div>
                                <div class="col mt-2">
                                    <app-iradiobox v-for="initial in initialStates" :value="initial.value"
                                        name="initialState" v-model="form.form.initialState"
                                        :checked="form.form.initialState === initial.value">
                                        {{ initial.text }} &nbsp;
                                    </app-iradiobox>
                                </div>
                            </div>

                            <div class="row mb-3" id="active">
                                <div class="col-sm-2 text-right">
                                    <label for="Responsável" class="bills-to-pay-label-margin">
                                        Estado final:
                                    </label>
                                </div>
                                <div class="col mt-2">
                                    <app-iradiobox v-for="final in finalStates" :value="final.value" name="state"
                                        v-model="form.form.finalState" :checked="form.form.finalState === final.value">
                                        {{ final.text }} &nbsp;
                                    </app-iradiobox>
                                </div>
                            </div>

                            <app-input-inline label-for="Cor" label="Cor:" name="color" type="color"
                                place-holder="ex: #ffffff" v-model="form.form.color" input-id="description"
                                input-class="is-valid" div-class="col-sm-2"
                                label-class="col-sm-2 text-right"></app-input-inline>
                        </template>
                        <template v-slot:footer>
                            <app-button type="submit" class="btn btn-default rounded-0 shadow"
                                :disabled="v$.form.$invalid">
                                <i class="fa fa-save"></i>
                                Gravar
                            </app-button>
                        </template>
                    </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onNew"
                    v-if="visibility('services.issue.state.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    &nbsp;
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: state }">
                        <a href="javascript:;" @click="onDelete(state.id)"
                            v-if="visibility('services.issue.state.delete')">
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(state.id)" v-if="visibility('services.issue.state.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                    <template v-slot:cell-initialState="{ row: state }">
                        <span v-if="state.initialState === 'Y'">Sim</span>
                        <span v-if="state.initialState === 'N'">Não</span>
                    </template>
                    <template v-slot:cell-finalState="{ row: state }">
                        <span v-if="state.finalState === 'Y'">Sim</span>
                        <span v-if="state.finalState === 'N'">Não</span>
                    </template>
                    <template v-slot:cell-color="{ row: state }">
                        <div class="label text-center state-div-color" :style="`background-color: ${state.color} ;`">
                            {{ state.color }}
                        </div>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->