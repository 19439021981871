<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.button", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <app-panel class="card-green">
                    <template v-slot:title>
                        Botões
                    </template>
                    <template v-slot:content>
                        <app-button type="button" class="btn btn-success">Sucesso</app-button>
                        &nbsp;
                        <app-button type="button" class="btn btn-primary">Primário</app-button>
                        &nbsp;
                        <app-button type="button" class="btn btn-danger">Perigo</app-button>
                        &nbsp;
                        <app-button type="button" class="btn btn-warning">Aviso</app-button>
                        &nbsp;
                        <app-button type="button" class="btn btn-default">Padrão</app-button>
                    </template>
                </app-panel>
            </div>
        </div>
    </div>
</section>