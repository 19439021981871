import { IssueActionTypes } from "@/store/issue/issue/action-types";
import { PfDropdown } from "@profabric/vue-components";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "notifications-dropdown",
  components: {
    "pf-dropdown": PfDropdown,
  },
  setup() {
    const store = useStore();
    const notifications = ref({});
    const totalRecords = ref(0);

    onMounted(() => {
      setInterval(() => {
        getNotifications();
      }, 300000);
    });

    function getNotifications() {
      try {
        store.dispatch(IssueActionTypes.ISSUE_GET_NOTIFICATION).then((data) => {
          notifications.value = data.data;
          totalRecords.value = data.filteredRecords;
        });
      } catch (error) {
        return;
      }
    }

    return {
      notifications,
      totalRecords,
    };
  },
});
