/* eslint-disable */
import { createApp, onMounted, ref } from "vue";
import App from "./app/app.vue";
import router from "./router";
import { store } from "./store";
import { i18n } from "./translation";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import Toast, { PluginOptions } from "vue-toastification";
//import { VueWindowSizePlugin } from "vue-window-size/option-api";
import { ProfabricComponents } from "@profabric/vue-components";
import { Money3Component } from "v-money3";
import CKEditor from '@ckeditor/ckeditor5-vue';

//imports for app initialization
import ApiService from "@/services/ApiService";

library.add(
  faTwitter,
  faLock,
  faEnvelope,
  faPuzzlePiece,
  faLifeRing,
  faCircle,
  faHome,
  faLockOpen
);

import "./index.scss";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  faCircle,
  faEnvelope,
  faHome,
  faLifeRing,
  faLock,
  faLockOpen,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Select2 from "vue3-select2-component";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const options: PluginOptions = {
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

(window as any).PF = {
  config: {
    mode: "bs4",
  },
};

import JwPagination from "jw-vue-pagination";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import VueApexCharts from "vue3-apexcharts";
import { getCookie } from "./classes/cookieUtils";
import { changeFlag, changeLanguage } from "./classes/languageUtils";
type SupportedLanguage = "en" | "ptBR" | "es" | "tr";

// Verifies languages for all application
let locale = "en" as SupportedLanguage;
const selectedLanguage = ref<string | null>(null);
const cookie = getCookie("locale");
if (cookie) {
  locale = getCookie("locale") as SupportedLanguage;
}
selectedLanguage.value = locale;
changeFlag(locale);
changeLanguage(locale);

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("Select2", Select2);
app.component("jw-pagination", JwPagination);
app.component("money3", Money3Component);
app.component("VueDatePicker", VueDatePicker);
app.use(store);
app.use(router);
ApiService.init(app);
//app.use(VueWindowSizePlugin);
app.use(Toast, options);
app.use(i18n as any);
app.use(ProfabricComponents);
app.use(VueSweetalert2);
app.use(LoadingPlugin);
app.use(VueApexCharts);
app.use(CKEditor);
router.isReady().then(function () {
  app.mount("#app");
});
