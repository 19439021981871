<div class="wrapper">
    <app-header></app-header>
    <menu-sidebar></menu-sidebar>
    <div class="content-wrapper">
        <router-view></router-view>
    </div>
    <app-footer></app-footer>
    <control-sidebar></control-sidebar>
    <div
        id="sidebar-overlay"
        v-if="uiValues.screenSize === 'xs' && uiValues.menuSidebarCollapsed"
        @click="toggleMenuSidebar"
    ></div>
    <!-- <div
        id="sidebar-overlay"
        v-if="screenSize === 'xs' && isSidebarMenuCollapsed"
        @click="toggleMenuSidebar"
    ></div> -->
</div>