import { FinanceAccountStatusData, FinanceAccountStatusObject } from "@/@types";

export type State = {
  financeAccountStatus: FinanceAccountStatusObject | null;
  financeAccountStatusData: FinanceAccountStatusData;
};

export const state: State = {
  financeAccountStatus: null,
  financeAccountStatusData: [],
};
