/* eslint-disable */
import inputDefault from "@/components/form/input-default/input-default.vue";
import inputDropDown from "@/components/form/dropdown/dropdown.vue";
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { FinanceBankAccountActionTypes } from "@/store/finance/bankaccount/action-types";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "BankAccount",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-input-dropdown": inputDropDown
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });

    const formSearch = ref({
      responsible: "",
      type: "",
      bank: ""
    });

    const formCreate = ref({
      form: {
        id: "",
        responsible: "",
        type: "",
        bank: "",
        agency: "",
        account: "",
        openingBalance: "0.00",
        description: "",
        matter: ""
      }
    });

    const rules = computed(() => {
      return {
        form:{
          responsible: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
          },
          bank: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
          },
          type: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
          },
          matter: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
          },
          agency: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(10)
            )
          },
          account: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(10)
            )
          },
          openingBalance: { 
            required: helpers.withMessage('Este campo é obrigatório!', required),
            minLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o minimo de ${$params.min} caracteres!`, minLength(3)
            ),
            maxLength: helpers.withMessage(
              ({
                $pending, 
                $invalid, 
                $params, 
                $model
              }) => `Este campo deve ter o máximo de ${$params.max} caracteres!`, maxLength(10)
            )
          },
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const responsibles = ref({});
    const banks = ref({});
    const accountTypes = ref({});

    const bAccountCurtain = ref();
    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "type",
        text: "Tipo",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "bank",
        text: "Banco",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "agency",
        text: "Agência",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "account",
        text: "Conta",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "responsible",
        text: "Responsável",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      }
    ];

    const matters = ref([
      {key: "PRINCIPAL", text: 'Principal'},
      {key: "SECUNDÁRIO", text: 'Secundário'},
      {key: "TERCIÁRIO", text: 'Terciário'},
    ]);

    const onSearch = () => {
      onList();
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onFormOpen = () => {
      formCreate.value.form.id = "";
      formCreate.value.form.responsible = "";
      formCreate.value.form.type = "";
      formCreate.value.form.bank = "";
      formCreate.value.form.agency = "";
      formCreate.value.form.account = "";
      formCreate.value.form.openingBalance = "";
      formCreate.value.form.description = "";
      formCreate.value.form.matter = "";
      bAccountCurtain.value.openNav();
    };

    onMounted(() => {
      onList();
      getAccountTypes();
      getResponsible();
      getBanks();
    });

    const onList = async () => {
      
        const params = {
          limit: perPage.value,
          offset: (currentPage.value - 1) * perPage.value,
          responsible: formSearch.value.responsible,
          type: formSearch.value.type,
          bank: formSearch.value.bank
        };

        const loader = $loading.show({
          loader: "dots",
        });

        await store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_LIST, params).then((data) => {
          rows.value = data.data;
          total.value = data.totalRecords;
          totalPages.value = data.totalPages;
          loader.hide();
        }).catch(() => {
          loader.hide();
        });
      
    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = () => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_UPDATE, formCreate.value.form).then(() => {
        formCreate.value.form.responsible = "";
        formCreate.value.form.id = "";
        onList();
        bAccountCurtain.value.closeNav();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
      })
      .catch((err) => {
        onList();
        bAccountCurtain.value.closeNav();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onCreate = async () => {
      
        const result = await v$.value.$validate();
        if(!result){
          return;
        };

        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch( FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_CREATE, formCreate.value.form ).then(() => {
          onList();
          bAccountCurtain.value.closeNav();
          loader.hide();
          Swal.fire({
            title: "Good job!",
            text: "Record stored!",
            icon: "success",
            allowOutsideClick: false,
          });
        }).catch(() => {
          bAccountCurtain.value.closeNav();
          loader.hide();
        });

    };

    const onDelete = (id: number) => {

        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_DELETE, { id: id }).then(() => {
              onList();
              loader.hide();
              Swal.fire({
                title: "Deleted!",
                text: "Your record has been deleted.",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
            });
          }
        });
    };

    const onEdit = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_EDIT, { id: id }).then((data) => {
        formCreate.value.form.responsible = data.responsible;
        formCreate.value.form.type = data.type;
        formCreate.value.form.bank = data.bank;
        formCreate.value.form.agency = data.agency;
        formCreate.value.form.account = data.account;
        formCreate.value.form.openingBalance = data.openingBalance;
        formCreate.value.form.description = data.description;
        formCreate.value.form.matter = data.matter;
        formCreate.value.form.id = data.id;
        bAccountCurtain.value.openNav();
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const getAccountTypes = () => {
      store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_ACCOUNT_TYPE).then((data) => {
        accountTypes.value = data;
      });
    };

    const getResponsible = () => {
      store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_RESPONSIBLE).then((data) => {
        responsibles.value = data;
      });
    };

    const getBanks = () => {
      store.dispatch(FinanceBankAccountActionTypes.FINANCE_BANK_ACCOUNT_GET_BANKS).then((data) => {
        banks.value = data;
      });
    };

    function checkArray(variable: any) {
      return Array.isArray(variable);
    };

    const config = computed(() => ({
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false /* doesn't work with directive */,
      focusOnRight: true,
      minimumNumberOfCharacters: 0,
    }));
  

    return {
      getAccountTypes,
      getResponsible,
      getBanks,
      formSearch,
      formCreate,
      gridColumns,
      rows,
      onSearch,
      onCreate,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onFormOpen,
      v$,
      onList,
      onDelete,
      bAccountCurtain,
      onEdit,
      onStore,
      visibility,
      accountTypes,
      responsibles,
      banks,
      matters,
      checkArray,
      config,
    };
  },
});
