<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.dashboard", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm">
                <div class="info-box shadow rounded-0">
                    <span class="info-box-icon bg-info">
                        <i class="fas fa-check-double"></i>
                    </span>

                    <div class="info-box-content">
                        <span class="info-box-text">CONTRATOS ATIVOS</span>
                        <span class="info-box-number">{{ activeContracts }}</span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
            <div class="col-sm">
                <div class="info-box shadow rounded-0">
                    <span class="info-box-icon bg-warning">
                        <i class="fas fa-hourglass-start"></i>
                    </span>

                    <div class="info-box-content">
                        <span class="info-box-text">RENOVAÇÕES PENDENTES</span>
                        <span class="info-box-number">{{ pendingRenewalContracts }}</span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="card shadow rounded-0">
                    <div class="card-body">
                        <h5 class="card-title text-center mb-5">
                            <strong>Contratos por nicho</strong>
                        </h5>
                        <p class="card-text">
                            <apexchart 
                                width="500" 
                                height="300" 
                                type="pie" 
                                :options="nicheChartOptions" 
                                :series="nicheSeries"
                            >
                            </apexchart>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="card shadow rounded-0">
                    <div class="card-body">
                        <h5 class="card-title text-center mb-5">
                            <strong>Contratos por Tipo</strong>
                        </h5>
                        <p class="card-text">
                            <apexchart 
                                width="500" 
                                height="300" 
                                type="pie" 
                                :options="chartOptions" 
                                :series="series"
                            >
                            </apexchart>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <app-panel class="card-default rounded-0 shadow">
                    <template v-slot:header>
                        <div class="container-full">
                            <div class="row">
                                <div class="col-sm">
                                    <h3 class="card-title mt-2 text-bold">
                                        RANKING TOP 5 CLIENTES
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th width="5%">Quantidade</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="top in topFiveClients" :key="index">
                                    <td>{{ top.client }}</td>
                                    <td class="text-right">{{ top.total }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                </app-panel>
            </div>
            <div class="col-sm">
                <app-panel class="card-default rounded-0 shadow">
                    <template v-slot:header>
                        <div class="container-full">
                            <div class="row">
                                <div class="col-sm">
                                    <h3 class="card-title mt-2 text-bold">
                                        RANKING 5 CLIENTES MAIS ANTIGOS
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="oldClient in topFiveOldClients" :key="index">
                                    <td>{{ oldClient.firstName }}</td>
                                    <td>{{ oldClient.createdAt }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                </app-panel>
            </div>
        </div>
    </div>
</section>