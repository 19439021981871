<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.financeBankAccountType") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Conta bancária
                </h3>
            </template>
            <template v-slot:content>
                <app-input-default 
                    label-for="Empresa" 
                    label="Empresa" 
                    input-name="responsibleSearch" 
                    input-type="text"
                    place-holder="Empresa" 
                    v-model="formSearch.responsible" 
                    input-id="text"
                >
                </app-input-default>
                <form action="javascript:;" @submit.prevent="onStore">
                    <app-right-curtain right-curtain-name="right" ref="bAccountCurtain" card-class="card-default">
                        <template v-slot:title>
                            Formulário
                        </template>
                        <template v-slot:content>
                            <input type="hidden" name="id" v-model="formCreate.form.id" />
                            <div class="row">
                                <div class="col-sm">
                                    <div class="form-group">
                                        <label for="Responsável">Responsável</label>
                                        <Select2 
                                            v-model="v$.form.responsible.$model" 
                                            :options="responsibles"
                                            :settings="{ 
                                                dropdownParent: '#right', 
                                                width: '100%',
                                                multiple: false,
                                                allowClear: true
                                            }"
                                        />
                                        <span v-if="checkArray(v$.form.responsible.$errors)">
                                            <small 
                                                id="emailHelp" 
                                                class="form-text text-danger"
                                                v-for="(errorMessage, index) of v$.form.responsible.$errors" 
                                                :key="index"
                                            >
                                                {{ errorMessage.$message }}
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm" id="bank">
                                    <div class="form-group">
                                        <label for="inputBanks">Banco</label>
                                        <Select2 
                                            v-model="v$.form.bank.$model" 
                                            :options="banks"
                                            :settings="{ 
                                                dropdownParent: '#bank', 
                                                width: '100%',
                                                multiple: false,
                                                allowClear: true
                                            }"
                                        />
                                        <span v-if="checkArray(v$.form.bank.$errors)">
                                            <small 
                                                id="emailHelp" 
                                                class="form-text text-danger"
                                                v-for="(errorMessage, index) of v$.form.bank.$errors" 
                                                :key="index"
                                            >
                                                {{ errorMessage.$message }}
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <label for="inputAccountType">Tipo de Conta</label>
                                    <app-input-dropdown
                                        :items="accountTypes"
                                        v-model="v$.form.type.$model"
                                    >
                                    </app-input-dropdown>
                                    <span v-if="checkArray(v$.form.type.$errors)">
                                        <small 
                                            id="emailHelp" 
                                            class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.type.$errors" 
                                            :key="index"
                                        >
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                                <div class="col-sm" id="bank">
                                    <div class="form-group">
                                        <label for="inputBanks">Grau de importância</label>
                                        <app-input-dropdown
                                            :items="matters"
                                            v-model="v$.form.matter.$model"
                                        >
                                        </app-input-dropdown>
                                        <span v-if="checkArray(v$.form.matter.$errors)">
                                            <small 
                                                id="emailHelp" 
                                                class="form-text text-danger"
                                                v-for="(errorMessage, index) of v$.form.matter.$errors" 
                                                :key="index"
                                            >
                                                {{ errorMessage.$message }}
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm">
                                    <app-input-default 
                                        label-for="agency" 
                                        label="Agency"
                                        input-name="agency" 
                                        input-type="text"
                                        place-holder="Agência" 
                                        v-model="v$.form.agency.$model"
                                        input-id="agency" 
                                        :has-error="v$.form.agency.$error"
                                        :is-active="v$.form.agency.$error" 
                                        :error="v$.form.agency.$errors"
                                    >
                                    </app-input-default>
                                </div>
                                <div class="col-sm">
                                    <app-input-default 
                                        label-for="conta" 
                                        label="Conta"
                                        input-name="conta" 
                                        input-type="text"
                                        place-holder="Conta" 
                                        v-model="v$.form.account.$model"
                                        input-id="account" 
                                        :has-error="v$.form.account.$error"
                                        :is-active="v$.form.account.$error" 
                                        :error="v$.form.account.$errors"
                                    >
                                    </app-input-default>
                                </div>
                                <div class="col-sm" style="margin-top: 2px;">
                                    <div class="col mb-1 text-right">
                                        <label for="saldo">
                                            Saldo Inicial:
                                        </label>
                                    </div>
                                    <div class="col">
                                        <money3 
                                            v-model="v$.form.openingBalance.$model" 
                                            v-bind="config"
                                            class="form-control rounded-0 input-money" 
                                        />
                                        <span v-if="checkArray(v$.form.openingBalance.$errors)">
                                            <small 
                                                id="emailHelp" 
                                                class="form-text text-danger"
                                                v-for="(errorMessage, index) of v$.form.openingBalance.$errors" 
                                                :key="index"
                                            >
                                                {{ errorMessage.$message }}
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                
                                <div class="col-sm">
                                    <label for="infoObservation">Observação</label>
                                    <textarea 
                                        name="observation" 
                                        class="form-control rounded-0" 
                                        placeholder="Informações que queira adicionar e que seja de relevância" 
                                        id="" 
                                        cols="30" 
                                        rows="10"
                                        v-model="formCreate.form.description"
                                    ></textarea>
                                </div>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <app-button 
                                type="submit" 
                                class="btn btn-default rounded-0"
                                :disabled="v$.form.$invalid"
                            >
                                <i class="fa fa-save"></i>
                                Gravar
                            </app-button>
                        </template>
                    </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0" id="open" @click="onFormOpen"
                    v-if="visibility('person.type.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de Conta bancária
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: account }">
                        <a href="javascript:;" @click="onDelete(account.id)"
                            v-if="visibility('finance.bank.account.delete')">
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(account.id)"
                            v-if="visibility('finance.bank.account.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->