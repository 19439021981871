import { UserData, UserObject } from "@/@types";
import { MutationTree } from "vuex";
import { UserMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [UserMutationTypes.SET_INSERT_USER_DATA](state: S, payload: UserObject): void;
  [UserMutationTypes.SetUserPersonalData](state: S, payload: UserData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_INSERT_USER_DATA](state: State, user: UserObject) {
    state.data = user;
  },
  [UserMutationTypes.SetUserPersonalData](state: State, userData: UserData) {
    state.userData = userData;
  },
};
