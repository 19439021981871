/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { ProjectActionTypes } from "@/store/project/project/action-types";
import { useLoading } from 'vue-loading-overlay';
import Dropdown from "@/components/form/dropdown/dropdown.vue";

export default defineComponent({
  name: "ProjectGrid",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-curtain": RightCurtain,
    "app-button": Button,
    "app-dropdown": Dropdown,
  },
  setup() {
    const store = useStore();
    const filterRule = ref({
      client: "",
      project: "",
      state: "",
      contract: "",
      clientLeader: "",
      companyLeader: ""
    });

    onMounted(() => {
      onListProject();
      onGetState();
      onGetClent();
      onGetCompanyLeader();
    });

    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 9999,
    });

    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref(0);
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "project",
        text: "Projeto",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "client",
        text: "Cliente",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "leader",
        text: "Lider",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "startDate",
        text: "Data de início",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "endDate",
        text: "Data de Encerramento",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "active",
        text: "Estado",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      }
    ];

    const states: any       = ref([]);
    const clients           = ref({});
    const contracts         = ref({});
    const clientTeamLeader  = ref({});
    const companyTeamLeader = ref({});
    const projectDetail     = ref();

    const activeItems = ref([
      { text: "Sim", value: 'Y' },
      { text: "Não", value: 'N' },
      { text: "Ambos", value: "" },
    ]);

    const onFilterProject = () => {
      onListProject();
    };

    // GET INFO BASED IN CLIENT
    const onClientChange = () => {
      onGetClientLeader();
      onGetContract();
    };

    // START GETs
    const onGetState = () => {
      try {
        store.dispatch(ProjectActionTypes.PROJECT_STATE).then((data) => {
          states.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetClent = () => {
        try {
          store.dispatch(ProjectActionTypes.PROJECT_CLIENT).then((data) => {
            clients.value = data;
          });
        } catch (err: any) {
          return;
        }
    };

    const onGetContract = async () => {
      try {
        const client = filterRule.value.client;
        await store.dispatch(ProjectActionTypes.PROJECT_CONTRACT, client).then((data) => {
          contracts.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetClientLeader = async () => {
      try {
        const client = filterRule.value.client;
        await store.dispatch(ProjectActionTypes.PROJECT_CLIENT_LEADER, client).then((data) => {
          clientTeamLeader.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetCompanyLeader = async () => {
      try {
        await store.dispatch(ProjectActionTypes.PROJECT_COMPANY_LEADER).then((data) => {
          companyTeamLeader.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    // END GETs

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onListProject();
    };

    const onListProject = async () => {

      const loader = $loading.show({
        loader: "dots",
      });
      
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        formData: {
          client: filterRule.value.client,
          state: filterRule.value.state,
          contract: filterRule.value.contract,
          clientLeader: filterRule.value.clientLeader,
          companyLeader: filterRule.value.companyLeader
        }
      };
      await store.dispatch(ProjectActionTypes.PROJECT_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const onDeleteProject = (id: number) => {
      
        Swal.fire({
          title: "Tem certeza?",
          text: "Não poderá reverter depois!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, exclua!",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(ProjectActionTypes.PROJECT_DELETE, { id: id }).then(() => {
              onListProject();
              loader.hide();
              Swal.fire({
                title: "Excluido!",
                text: "Seu registro foi excluido.",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
            });
          }
        });
      
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const detailInfo = ref({
      estimatedBudget: "R$ 0,00",
      totalAmountSpent: "R$ 0,00",
      estimatedDuration: 0,
      projectName: "",
      projectDescription: "",
      projectClient: "",
      projectClientLeader: "",
      projectTeamLeader: "",
      files: []
    });

    const onProjectDetail = async (project: number) => {

      const loader = $loading.show({
        loader: "dots",
      });
      
      store.dispatch(ProjectActionTypes.PROJECT_DETAIL, project).then((data) => {
        detailInfo.value.estimatedBudget     = data.project.estimatedBudget;
        detailInfo.value.totalAmountSpent    = data.project.totalAmountSpent;
        detailInfo.value.estimatedDuration   = data.project.estimatedDuration;
        detailInfo.value.projectName         = data.project.name;
        detailInfo.value.projectDescription  = data.project.description;
        detailInfo.value.projectClient        = data.project.client;
        detailInfo.value.projectClientLeader = data.project.leader;
        detailInfo.value.projectTeamLeader   = data.project.companyLeader;
        detailInfo.value.files = data.files;
        projectDetail.value.openNav();
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    }

    const fileName = (filename: string) => {
      return filename.split('/').pop();
    }

    const onFileDownload = async (id: number, filename: any) => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(ProjectActionTypes.PROJECT_FILE_DOWNLOAD, id).then((data) => {
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        loader.hide();
      }).catch(() => {
        loader.hide();
      }); 
    };

    return {
      filterRule,
      gridColumns,
      rows,
      onFilterProject,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onListProject,
      onDeleteProject,
      visibility,
      // GETs
      onGetState,
      onGetClent,
      onClientChange,
      onGetCompanyLeader,
      states,
      activeItems,
      onProjectDetail,
      clients,
      companyTeamLeader,
      clientTeamLeader,
      contracts,
      projectDetail,
      detailInfo,
      fileName,
      onFileDownload,
      //loader,
      //fullPage
    };
  },
});
