<div class="input-group">
    <input
        :type="type"
        class="form-control rounded-0"
        :placeholder="placeholder"
        :value="modelValue"
        :autocomplete="autocomplete"
        @change="onValueChange($event)"
    />
    <div v-if="icon" class="input-group-append" @click="onEvent()">
        <div class="input-group-text">
            <i :class="icon"></i>
            <font-awesome-icon :icon="icon" />
        </div>
    </div>
</div>