/* eslint-disable */
import { defineComponent, onMounted, ref } from "vue";
import Panel from "@/components/ui/panel/panel.vue";
import { useStore } from "vuex";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import { InvoiceActionTypes } from "@/store/services/invoice/action-types";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
    name: "InvoiceDashboard",
    components: {
        "app-panel": Panel,
        "app-breadcrumb": Breadcrumb,
    },
    setup() {
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const monthSell = ref(0);
        const yearSell = ref(0);
        const contractsByGroup = ref([]);
        const contractsByTypes = ref({
            "label": "",
            "serie": ""
        });
        const topFiveClients = ref({});
        const topFiveOldClients = ref({});

        onMounted(() => {
            getDatas();
        });

        const monthChartOptions: any = ref({
            chart: {
                id: "vuechart-example",
            },
            xaxis: {
                categories: [],
                //categories: [],
            },
        });

        const monthSeries: any = ref([
            {
                name: "Vendas",
                //data: ["0", 0, 0, 0, 0, 0, 0, 0, 0, "600", 0, 0],
                data: [],
            },
        ]);

        const yearChartOptions: any = ref({
            chart: {
                id: "vuechart-example-2",
            },
            xaxis: {
                categories: [],
                //categories: [],
            },
        });

        const yearSeries: any = ref([
            {
                name: "Vendas",
                //data: ["0", 0, 0, 0, 0, 0, 0, 0, 0, "600", 0, 0],
                data: [],
            },
        ]);

        const getDatas = () => {
            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(InvoiceActionTypes.INVOICE_DASHBOARD).then((data) => {
                monthSell.value = data.monthsell;
                yearSell.value = data.yearSell;

                data.byMonth.xaxis.forEach((item: any, index: any) => {
                    monthChartOptions.value.xaxis.categories.push(item.xaxis);
                });

                data.byMonth.series.forEach((item: any, index: any) => {
                    monthSeries.value[0].data.push(item.series)
                });
                
                yearChartOptions.value.xaxis.categories.push(data.invoiceYearYear);
                yearSeries.value[0].data.push(data.invoiceYearTotal);

                //
                //data.byNiche.labels.forEach((item:any, index: any) => {
                //    nicheChartOptions.value.labels.push(item.label);
                //});                
                //data.byNiche.series.forEach((item: any, index: any) => {
                //    nicheSeries.value.push(item.serie)
                //});
                loader.hide();

            }).catch((error) => {
                loader.hide();
            });
        };

        return {
            monthSell,
            yearSell,
            topFiveOldClients,
            topFiveClients,
            monthChartOptions,
            monthSeries,
            yearChartOptions,
            yearSeries,
            contractsByTypes
        };
    }
});