/* eslint-disable */
import { InvoiceObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { InvoiceActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>( key: K, payload: Parameters<Mutations[K]>[1] ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [InvoiceActionTypes.INVOICE_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_CREATE]( { commit }: AugmentedActionContext, params: InvoiceObject ): Promise<boolean>;
  [InvoiceActionTypes.INVOICE_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_UPDATE]( { commit }: AugmentedActionContext, params: InvoiceObject ): Promise<any>;
  [InvoiceActionTypes.INVOICE_CLIENT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_CONTRACT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_STATE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_FOR_BILL]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_TO_BILL]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [InvoiceActionTypes.INVOICE_DASHBOARD]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [InvoiceActionTypes.INVOICE_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`invoice/invoice/${params.limit}/${params.offset}`, params.formData ).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_CREATE]( { commit }, params: InvoiceObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        client: params.client,
        contract: params.contract,
        invoiceDate: params.invoiceDate,
        observation: params.observation,
        services: params.services
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("invoice/invoice", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_CONTRACT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/contract/${params.client}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_UPDATE]( { commit }, params: InvoiceObject ) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        client: params.client,
        contract: params.contract,
        invoiceDate: params.invoiceDate,
        observation: params.observation,
        services: params.services
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`invoice/invoice/${params.id}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_CLIENT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/client`).then(({ data }) => {
        const result = data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_STATE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`invoice/invoice/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_SERVICE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/service`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_SERVICE_VALUE]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/service/${params.id}/value`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_FOR_BILL]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`invoice/invoice/for/bill/${params.limit}/${params.offset}`, params.formData).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_TO_BILL]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      const data: any = {
        invoices: params
      }
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`invoice/invoice/to/bill`, data).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [InvoiceActionTypes.INVOICE_DASHBOARD]({ commit }, params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`invoice/invoice/dashboard`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
