<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.integration") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <!-- START: -->
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            Emails
                        </h3>                       
                    </template>
                    <template v-slot:content>
                        <div class="text-center">
                            <a href="javascript:;" class="btn btn-app rounded-0"
                             @click="onOpenSmtpForm();"
                             v-if="visibility('integration.create.update')"
                            >
                                <i class="fas fa-mail-bulk"></i>
                                SMTP
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fas fa-bullhorn"></i>
                                SendGrid
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                Mailchimp
                            </a>
                        </div>
                        <!-- START: SMTP INTEGRATION -->
                        <app-right-curtain right-curtain-name="right" ref="smtpcurtain" card-class="card-default">
                            <template v-slot:title>
                                Formulário
                            </template>
                            <template v-slot:content>

                                <div class="row">
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="E-mail de origem"
                                            input-name="from" 
                                            input-type="email" 
                                            place-holder=""
                                            v-model="formSmtp.from" 
                                            input-id="from"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col-sm">
                                        <div class="form-group">
                                            <label for="Autentica" class="col-form-label">Autentica SMTP</label>
                                            <app-selectbox :items="authItems" v-model="formSmtp.auth"></app-selectbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="Host SMTP"
                                            input-name="from" 
                                            input-type="text" 
                                            place-holder="ssl://smtp.gmail.com, tls://server.company.com"
                                            v-model="formSmtp.host" 
                                            input-id="host"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="Porta SMTP"
                                            input-name="from" 
                                            input-type="number" 
                                            place-holder=""
                                            v-model="formSmtp.port" 
                                            input-id="port"
                                        >
                                        </app-input-default>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="Usuário SMTP"
                                            input-name="from" 
                                            input-type="email" 
                                            place-holder=""
                                            v-model="formSmtp.user" 
                                            input-id="user"
                                        >
                                        </app-input-default>
                                    </div>
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="Senha SMTP"
                                            input-name="from" 
                                            input-type="password" 
                                            place-holder=""
                                            v-model="formSmtp.pass" 
                                            input-id="pass"
                                        >
                                        </app-input-default>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm">
                                        <app-input-default 
                                            label-for="From" 
                                            label="E-mail de suporte"
                                            input-name="from" 
                                            input-type="email" 
                                            place-holder=""
                                            v-model="formSmtp.supp" 
                                            input-id="supp"
                                        >
                                        </app-input-default>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm">
                                        <label for="exampleInputEmail1">Termos de uso e política de privacidade</label>
                                        <ckeditor 
                                            :editor="editorIntegration" 
                                            v-model="formSmtp.terms" 
                                            :config="editorIntegrationConfig"
                                        ></ckeditor>
                                    </div>
                                </div>

                            </template>
                            <template v-slot:footer>
                                <app-button type="submit" class="btn btn-default rounded-0" @click="onSubmitSmtp">
                                    <i class="fa fa-save"></i>
                                    Gravar
                                </app-button>
                            </template>
                        </app-right-curtain>
                        <!-- END: SMTP CONNECTION -->
                    </template>
                </app-panel-secondary>
            </div>
            <!-- END: -->
            <!-- START: -->
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            Gateways de pagamento
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="text-center">
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fas fa-mail-bulk"></i>
                                PagSeguro
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fas fa-bullhorn"></i>
                                Hotmart
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                PayPal
                            </a>
                            <a href="" class="btn btn-app btn rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                Mercado Pago
                            </a>
                            <a href="" class="btn btn-app btn rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                AsaaS
                            </a>
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
            <!-- END: -->
            <!-- START: -->
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            API Fiscais
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="text-center">
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fas fa-mail-bulk"></i>
                                Cupom Fiscal
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fas fa-bullhorn"></i>
                                NFS-e
                            </a>
                            <a href="" class="btn btn-app rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                NF-e
                            </a>
                            <a href="" class="btn btn-app btn rounded-0">
                                <i class="fab fa-mailchimp"></i>
                                Boleto
                            </a>
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
            <!-- END: -->
        </div>


    </div>
    <!-- /.card -->
</section>
<!-- /.content -->