<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.project", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <form action="javascript:;" @submit.prevent="onCreate">
            <div class="row">
                <div class="col-sm">
                    <app-panel class="card-primary">
                        <template v-slot:header>
                            <h3 class="card-title">
                                Geral
                            </h3>
                        </template>
                        <template v-slot:content>
                            <div class="form-group" id="client">
                                <label for="Responsável" class="">
                                    Cliente:
                                </label>
                            
                                <Select2 
                                    v-model="v$.form.client.$model" 
                                    :options="clients"
                                    :settings="{ 
                                        dropdownParent: '#client',
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }" 
                                    @change="onClientChange($evt)" 
                                    @select="onClientChange($evt)"
                                />
                                <input type="hidden" name="id" v-model="form.form.id" />
                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.client.$error"  
                                    v-for="content in v$.form.client.$errors"
                                >
                                    {{ content.$message }}
                                </small>
                            </div>

                            <div class="form-group" id="contract">
                                <label for="Contrato" class="">
                                    Contrato:
                                </label>
                            
                                <Select2 
                                    v-model="v$.form.contract.$model" 
                                    :options="contracts"
                                    :settings="{ 
                                        dropdownParent: '#lider-tecnico', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }"
                                />
                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.contract.$error"  
                                    v-for="contract in v$.form.contract.$errors"
                                >
                                    {{ contract.$message }}
                                </small>
                            </div>
                            <app-input-default 
                                label-for="Nome" 
                                label="Nome do Projeto" 
                                input-name="name"
                                input-type="text" 
                                place-holder="Informe o nome do projeto" 
                                v-model="v$.form.name.$model" 
                                input-id="text"
                                :has-error="v$.form.name.$error" 
                                :is-active="!v$.form.name.$error"
                                :error="v$.form.name.$errors" 
                            >
                            </app-input-default>
                            <div class="form-group">
                                <label for="exampleFormControlTextarea1">Descrição do projeto</label>
                                <textarea 
                                    class="form-control rounded-0" 
                                    id="description" 
                                    rows="3"
                                    v-model="v$.form.description.$model"
                                    :class="{
                                        'is-invalid': v$.form.name.$error, 
                                        'is-valid': !v$.form.name.$error
                                    }"
                                ></textarea>
                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.description.$error"  
                                    v-for="description in v$.form.description.$errors"
                                >
                                    {{ description.$message }}
                                </small>
                            </div>
                    
                            <div class="form-group" id="estado">
                                <label for="Estado" class="">
                                    Estado:
                                </label>

                                <app-dropdown 
                                    v-model="v$.form.state.$model"
                                    :items="states"
                                >
                                </app-dropdown>
                            
                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.state.$error"  
                                    v-for="statex in v$.form.state.$errors"
                                >
                                    {{ statex.$message }}
                                </small>
                            </div>

                            <div class="form-group" id="responsible">
                                <label for="Responsável" class="">
                                    Responsável pelo projeto:
                                </label>
                            
                                <Select2 
                                    v-model="form.form.clientTeamLeader" 
                                    :options="clientTeamLeaders"
                                    :settings="{ 
                                        dropdownParent: '#responsible', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }" 
                                />
                            </div>

                            <div class="form-group" id="lider-tecnico">
                                <label for="LiderTecnico" class="">
                                    Lider Técnico:
                                </label>
                            
                                <Select2 
                                    v-model="form.form.companyTeamLeader" 
                                    :options="companyTeamLeaders"
                                    :settings="{ 
                                        dropdownParent: '#lider-tecnico', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }"
                                />
                            </div>

                        </template>
                        <template v-slot:footer>
                            
                        </template>
                    </app-panel>
                </div>
                <div class="col-sm">
                    <app-panel class="card-secondary">
                        <template v-slot:header>
                            <h3 class="card-title">
                                Adicionais
                            </h3>
                        </template>
                        <template v-slot:content>
                            <div class="form-group">
                                <label for="Responsável" class="">
                                    Budget estimado:
                                </label>
                            
                                <money3 
                                    v-model="v$.form.estimatedBudget.$model" 
                                    v-bind="moneyConfig"
                                    class="form-control rounded-0 input-money" 
                                />
                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.estimatedBudget.$error"  
                                    v-for="estimatedBudget in v$.form.estimatedBudget.$errors"
                                >
                                    {{ estimatedBudget.$message }}
                                </small>
                            </div>
                            <div class="form-group">
                                <label for="Responsável" class="">
                                    Montante Usado até o momento:
                                </label>
                            
                                <money3 
                                    v-model="v$.form.totalAmountSpent.$model" 
                                    v-bind="moneyConfig"
                                    class="form-control rounded-0 input-money" 
                                />

                                <small 
                                    class="text-error form-text" 
                                    v-if="!!v$.form.totalAmountSpent.$error"  
                                    v-for="totalAmountSpent in v$.form.totalAmountSpent.$errors"
                                >
                                    {{ totalAmountSpent.$message }}
                                </small>
                            </div>
                            <div class="form-group">
                                <label for="Responsável" class="">
                                    Duração estimada (Horas):
                                </label>
                                <input 
                                    type="number" 
                                    id="duration" 
                                    v-model="form.form.estimatedDuration"  
                                    class="form-control text-right rounded-0"
                                    min="0"
                                />
                            </div>

                            <div class="form-group">
                                <div class="input_container">
                                    <input type="file" 
                                        multiple 
                                        ref="file" 
                                    />
                                </div>
                            </div>
                            
                        </template>
                    </app-panel>
                    <app-panel class="card-info" v-if="serverFiles.length > 0">
                        <template v-slot:header>
                            <h3 class="card-title">
                                Anexos
                            </h3>
                        </template>
                        <template v-slot:content>
                            <table class="table">
                                <thead>
                                    <tr>
                                      <th>Nome do arquivo</th>
                                      <th class="text-center">Tamanho</th>
                                      <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="serverFile in serverFiles" :key="serverFile.id">
                                        <td>{{ fileName(serverFile.file) }}</td>
                                        <td class="text-center">{{ serverFile.size}} kb</td>
                                        <td class="text-right py-0 align-middle">
                                          <div class="btn-group btn-group-sm">
                                            <a 
                                                href="javascript:;" 
                                                @click="onFileDownload(serverFile.id, fileName(serverFile.file))" 
                                                v-if="visibility('project.file.download')"
                                                class="btn btn-info"
                                            >
                                                <i class="fas fa-download"></i>
                                            </a>
                                            <a 
                                                href="javascript:;" 
                                                @click="onFileDelete(serverFile.id)" 
                                                v-if="visibility('project.file.delete')"
                                                class="btn btn-danger"
                                            >
                                                <i class="fas fa-trash"></i>
                                            </a>
                                          </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </app-panel>
                </div>
            </div>
            <div class="">
                <a href="/service/project" class="btn btn-warning shadow mb-5 rounded-0">
                    <i class="fa fa-arrow-left"></i>
                    Voltar
                </a>
                <app-button 
                    type="submit" 
                    class="btn btn-success ml-2 shadow mb-5 rounded-0" 
                    :disabled="v$.form.$invalid"
                >
                    <i class="fa fa-save warning"></i>
                    Salvar
                </app-button>
            </div>
        </form>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
