import { Options, Vue } from "vue-class-component";

@Options({
  name: "ExtraLargeModal",
  props: {
    identityName: {
      type: String,
      required: true,
    },
  },
})
export default class ExtraLargeModal extends Vue {
  identityName!: string;
  show = false;
  closeModal() {
    this.show = false;
    const app = document.querySelector("body") as HTMLElement;
    app.classList.remove("modal-open");
    app.style.height = "";
    app.style.paddingRight = "";

    const modal = document.getElementById(this.identityName) as HTMLElement;
    modal.classList.remove("show");
    modal.style.display = "none";
    modal.ariaHidden = "true";
    modal.style.paddingRight = "";
    modal.ariaModal = "";
  }
  openModal() {
    this.show = true;
    const app = document.querySelector("body") as HTMLElement;
    app.classList.add("modal-open");
    app.style.height = "auto";
    app.style.paddingRight = "15px";

    const modal = document.getElementById(this.identityName) as HTMLElement;
    modal.classList.add("show");
    modal.style.display = "block";
    modal.style.paddingRight = "15px";
    modal.ariaModal = "true";
    //modal.role = "dialog";
  }
}
