/* eslint-disable */
import { FinanceDashboardData, FinanceDashboardObject } from "@/@types";

export type State = {
  financeDashboard: FinanceDashboardObject | null;
  financeDashboardData: FinanceDashboardData;
};

export const state: State = {
  financeDashboard: null,
  financeDashboardData: [],
};
