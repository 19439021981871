import { Options, Vue } from "vue-class-component";

@Options({
  name: "Panel",
  props: {
    class: {
      type: String,
    },
  },
})
export default class Panel extends Vue {}
