<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.service") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Serviços
                </h3>
            </template>
            <template v-slot:content>
                <app-input-inline label-for="CNAE" label="CNAE:" name="cnae" type="text" place-holder=""
                    v-model="filterRule.cnae" input-id="text" input-class="is-valid" div-class="col-sm-2"
                    label-class="col-sm-2 text-right"></app-input-inline>
                <app-input-inline label-for="Nome" label="Nome:" name="name" type="text" place-holder=""
                    v-model="filterRule.description" input-id="text" input-class="is-valid" div-class="col-sm-10"
                    label-class="col-sm-2 text-right"></app-input-inline>
                <div class="row mb-3" id="serviceType">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Tipo de serviço:
                        </label>
                    </div>
                    <div class="col-sm-4">
                        <Select2 v-model="filterRule.serviceType" :options="serviceTypes" :settings="{ 
                                dropdownParent: '#serviceType', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <div class="row mb-3" id="company">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Ativo:
                        </label>
                    </div>
                    <div class="col mt-2">
                        <app-iradiobox v-for="item in filterRadioGroupItems" :value="item.value" name="active"
                            v-model="filterRule.active" :checked="filterRule.active === item.value">
                            {{ item.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
                <form action="javascript:;" @submit.prevent="onStoreService">
                    <app-right-curtain right-curtain-name="right" ref="form" card-class="card-default">
                        <template v-slot:title>
                            Formulário
                        </template>
                        <template v-slot:content>
                            <input type="hidden" name="id" v-model="formCreate.id" />
                            <app-input-inline label-for="CNAE" label="CNAE:" name="cnae" type="text" place-holder=""
                                v-model="v$.form.cnae.$model" input-id="cnae" input-class="is-valid"
                                div-class="col-sm-2" label-class="col-sm-2 text-right" :has-error="v$.form.cnae.$error"
                                :is-active="v$.form.cnae.$error" :error="v$.form.cnae.$errors"></app-input-inline>
                            <app-input-inline label-for="Nome" label="Nome:" name="name" type="text" place-holder=""
                                v-model="v$.form.description.$model" input-id="description" input-class="is-valid"
                                div-class="col-sm-10" label-class="col-sm-2 text-right"
                                :has-error="v$.form.description.$error" :is-active="v$.form.description.$error"
                                :error="v$.form.description.$errors"></app-input-inline>
                            <div class="row mb-3">
                                <div class="col-sm-2 text-right">
                                    <label for="inputBanks" class="bills-to-pay-label-margin">
                                        Valor:
                                    </label>
                                </div>
                                <div class="col-sm-3">
                                    <money3 v-model="v$.form.price.$model" v-bind="moneyConfig"
                                        class="form-control rounded-0 input-money" :class="{
                                            'is-valid': v$.form.price.$error, 
                                            'is-invalid': v$.form.price.$error
                                        }" />
                                    <span v-if="checkArray(v$.form.price.$errors)">
                                        <small id="emailHelp" class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.price.$errors" :key="index">
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3" id="serviceTypeForm">
                                <div class="col-sm-2 text-right">
                                    <label for="Responsável" class="bills-to-pay-label-margin">
                                        Tipo de serviço:
                                    </label>
                                </div>
                                <div class="col">
                                    <Select2 v-model="v$.form.serviceType.$model" :options="serviceTypes" :settings="{ 
                                            dropdownParent: '#serviceTypeForm', 
                                            width: '100%',
                                            multiple: false,
                                            allowClear: true
                                        }" />
                                    <span v-if="checkArray(v$.form.serviceType.$errors)">
                                        <small id="emailHelp" class="form-text text-danger"
                                            v-for="(errorMessage, index) of v$.form.serviceType.$errors" :key="index">
                                            {{ errorMessage.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                            <div class="row mb-3" id="company">
                                <div class="col-sm-2 text-right">
                                    <label for="Responsável" class="bills-to-pay-label-margin">
                                        Ativo:
                                    </label>
                                </div>
                                <div class="col mt-2">
                                    <app-iradiobox v-for="item in radioGroupItems" :value="item.value" name="active"
                                        v-model="formCreate.form.active"
                                        :checked="formCreate.form.active === item.value">
                                        {{ item.text }} &nbsp;
                                    </app-iradiobox>
                                </div>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <app-button type="submit" class="btn btn-default rounded-0 shadow"
                                :disabled="v$.form.$invalid">
                                <i class="fa fa-save"></i>
                                Gravar
                            </app-button>
                        </template>
                    </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onSearchService">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0 shadow" id="open" @click="onServiceFormOpen"
                    v-if="visibility('services.registration.servicetype.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de serviços
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: service }">
                        <a href="javascript:;" @click="onDeleteService(service.id)"
                            v-if="visibility('services.registration.servicetype.delete')"><i
                                class="fas fa-trash-alt red"></i></a>
                        &nbsp;
                        <a href="javascript:;" @click="onServiceChangeStatus(service.id)"
                            v-if="visibility('services.registration.service.state')">
                            <i class="fa fa-power-off orange"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEditService(service.id)"
                            v-if="visibility('services.registration.servicetype.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                    <template v-slot:cell-active="{ row: service }">
                        <h5 v-if="service.active === 'Y'"><span class="badge bg-success rounded-0">Sim</span></h5>
                        <h5 v-else="!service.active === 'N'"><span class="badge bg-danger rounded-0">Não</span></h5>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->