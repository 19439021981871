<div class="login-box">
    <div class="card rounded-0 card-outline card-primary">
        <div class="card-header text-center">
            <router-link to="/" class="h1"><b>Admin</b>LTE</router-link>
        </div>
        <div class="card-body">
            <p class="login-box-msg">
                {{ $t("messages.recoverYourPasswordNow") }}
            </p>
            <form @submit.prevent="newPassword">
                <div class="input-group mb-3">
                    <input 
                        type="password" 
                        class="form-control rounded-0" 
                        autocomplete="off" 
                        v-model="password" 
                        @keyup="getPasswordStrength"
                        placeholder="Senha"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <i :class="icon"></i>
                            <font-awesome-icon :icon="icon" />
                        </div>
                    </div>
                </div>

                <div class="input-group mb-3">
                    <input 
                        type="password" 
                        class="form-control rounded-0" 
                        autocomplete="off" 
                        v-model="confirmPassword" 
                        @keyup="checkPasswordsMatch"
                        placeholder="Confirme a senha"
                    />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <i :class="icon"></i>
                            <font-awesome-icon :icon="icon" />
                        </div>
                    </div>
                </div>
                <div class="progress progress-sm active mb-3">
                    <div 
                        class="progress-bar progress-bar-striped" 
                        :class="bg"
                        role="progressbar"
                        aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" :style="width"
                        >
                      <!-- <span class="sr-only">20% Complete</span> -->
                    </div>
                </div>

                <p id="exampleInputEmail1-error" class="error invalid text-red mt-3" v-if="show && !passwordsMatch">As Senhas não são iguais</p>

                <div class="row">
                    <div class="col-12">
                        <button 
                            type="submit" 
                            class="btn btn-primary d-block rounded-0" 
                            style="width: 100%;" 
                            :disabled='!passwordsMatch'
                        >Enviar</button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>

            <p class="mt-3 mb-1">
                <router-link to="/login" class="text-center">
                    {{ $t("labels.login") }}
                </router-link>
            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>