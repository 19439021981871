/* eslint-disable */
import { computed, defineComponent, onMounted, ref } from "vue";
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { FinanceAccountActionTypes } from "@/store/finance/account/action-types";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import inputDefault from "@/components/form/input-default/input-default.vue";
import { PFormListLabel, PGrid, RFormListLabel, RGrid } from "../content/table";
import { useLoading } from "vue-loading-overlay";
import Iradiobox from "@/components/form/iradio/iradio.vue";

export default defineComponent({
    name: "AccountList",
    props: {
        accountType: {
            type: String,
            default: "R"
        },
        newRecord: {
            type: Boolean,
            default: false
        }
    },
    components: {
        "app-panel": Panel,
        "app-pagination": Paginate,
        "app-table": grid,
        "app-button": Button,
        "app-input-default": inputDefault,
        "app-iradiobox": Iradiobox,
    },
    setup(props, { emit }){
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const companies = ref({});
        const accountStatuses = ref({});
        const rows: any = ref({});
        const currentPage = ref(1);
        const totalPages = ref(1);
        const total = ref(0);
        const perPage = ref(10);
        const currentDate = new Date();
        const currentYear = new Date().getFullYear();
        const currentMonthNumeric = currentDate.getMonth() + 1;
        const formattedMonth = currentMonthNumeric < 10 ? '0' + currentMonthNumeric : currentMonthNumeric;

        const monthsItems = ref([
            { text: "Jan", value: "01" },
            { text: "Fev", value: "02" },
            { text: "Mar", value: "03" },
            { text: "Abr", value: "04" },
            { text: "Mai", value: "05" },
            { text: "Jun", value: "06" },
            { text: "Jul", value: "07" },
            { text: "Ago", value: "08" },
            { text: "Set", value: "09" },
            { text: "Out", value: "10" },
            { text: "Nov", value: "11" },
            { text: "Dez", value: "12" },
        ]);

        const yearsItems: any = ref([]);

        for (let year = currentYear - 5; year <= currentYear + 3; year++) {
          yearsItems.value.push({ text: year.toString(), value: year });
        }

        onMounted(() => {
            getStatus();
            getEmpresa();
            onList();
        });

        const gridColumns = computed(() => {
            if(props.accountType === 'P'){
                return PGrid;
            }
            if(props.accountType === 'R'){
                return RGrid;
            }
        });

        const formSearchLabel = computed(() => {
            if(props.accountType === 'P'){
                return PFormListLabel;
            }
            if(props.accountType === 'R'){
                return RFormListLabel;
            }
        });

        const title = computed(() => {
            if(props.accountType === 'P'){
                return 'Contas a Pagar';
            }
            if(props.accountType === 'R'){
                return 'Contas a Receber';
            }
        });

        const formSearch = ref({
            company: "",
            month: `${formattedMonth.toString()}`,
            year: currentYear,
            state: ""            
        });

        const dueDateInputOptions = (date: any) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
    
            const formattedDate   = new Date(year, month - 1, day);
            const formattedString = formattedDate.toLocaleDateString('pt-BR', { 
                day: '2-digit', 
                month: '2-digit', 
                year: 'numeric' 
            });
    
            return formattedString;
        };

        const onSearch = () => {
            onList();
        };

        const onNew = () => {
            emit('new');
        };

        const onList = async () => {
            
            const params = {
                limit: perPage.value,
                offset: (currentPage.value - 1) * perPage.value,
                company: formSearch.value.company,
                month: formSearch.value.month,
                year: formSearch.value.year,
                type: props.accountType,
            };

            const loader = $loading.show({
              loader: "dots",
            });

            await store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_LIST, params).then((data) => {
                rows.value = data.data;
                total.value = data.totalRecords;
                totalPages.value = data.totalPages;
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
            
        };

        const edit = (id: any) => {
            emit('edit', id);
        };

        const onDelete = (id: number) => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    const loader = $loading.show({
                      loader: "dots",
                    });

                    store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_DELETE, { id: id }).then(() => {
                        onList();
                        loader.hide();
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your record has been deleted.",
                            icon: "success",
                            allowOutsideClick: false,
                        });
                    }).catch(() => {
                        loader.hide();
                    });
                }
            });
        };

        const getEmpresa = () => {
            store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COMPANY).then((data) => {
              companies.value = data;
            });
        };

        const getStatus = () => {
            store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_STATUS).then((data) => {
              accountStatuses.value = data;
            });
        };

        const visibility = (name: any) => {
            return isVisible(name);
        };

        function generateCharge(account: number, rpdate: any)
        {
            if(rpdate){
                Swal.fire({
                    title: "Info!",
                    text: "Fatura paga, não há necessidade de gerar outra cobrança!",
                    icon: "info",
                    allowOutsideClick: false,
                });
            } else {
                store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_GENERATE_CHARGE, { id: account }).then((data) => {
                    Swal.fire({
                        title: "Info!",
                        text: "Cobrança gerada com sucesso!",
                        icon: "success",
                        allowOutsideClick: false,
                    });
                    onList();
                });
                  
            }
        }
      
        return {
            title,
            getEmpresa,
            formSearch,
            gridColumns,
            formSearchLabel,
            rows,
            onSearch,
            currentPage,
            totalPages,
            total,
            perPage,
            onList,
            edit,
            onDelete,
            visibility,
            accountStatuses,
            companies,
            dueDateInputOptions,
            onNew,
            yearsItems,
            monthsItems,
            generateCharge,
        };
    }
});
