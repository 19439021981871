/* eslint-disable */
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Accordion from "@/components/ui/accordion/accordion.vue";
import BootstrapTabComponent from "@/components/ui/tabs/tab/tab.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "General",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-accordion": Accordion,
    "app-tab": BootstrapTabComponent
  },
  setup() {
    const tabs = ref([
      { 
        name: "tab1", 
        label: "Tab 1",
        id: "custom-tabs-four-one-tab",
        ariaControls: "custom-tabs-four-one"
      },
      { 
        name: "tab2", 
        label: "Tab 2",
        id: "custom-tabs-four-two-tab",
        ariaControls: "custom-tabs-four-two"
      },
      { 
        name: "tab3", 
        label: "Tab 3",
        id: "custom-tabs-four-tree-tab",
        ariaControls: "custom-tabs-four-tree"
      },
    ]);

    return { tabs };
  },
});
