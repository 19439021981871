<pf-dropdown hide-arrow>
    <div slot="button">
        <i class="flag-icon" :class="flagIcon"></i>
    </div>
    <div slot="menu">
        <a
            class="dropdown-item"
            :class="{'active': selectedLanguage === language.key}"
            @click="changeLanguage(language.key)"
            v-for="language in languages"
        >
            <i class="flag-icon mr-2" :class="language.flag"></i>
            {{ $t(language.label) }}
        </a>
    </div>
</pf-dropdown>