/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import * as yup from "yup";
import Swal from "sweetalert2";
import { RoleActionTypes } from "@/store/admin/person/roles/action-types";
import inputDefault from "@/components/form/input-default/input-default.vue";
import { MenuActionTypes } from "@/store/admin/menu/action-types";
import Input from "@/components/form/input/input.vue";
import ExtraLargeModal from "@/components/ui/modal/extra-large-modal/extra-large-modal.vue";
import access from "@/pages/logs/access/access";
import { isVisible } from "@/classes/is_visible";
import $ from "jquery";
import "jstree";
import "jstree/src/themes/default/style.css";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "Role",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-input": Input,
    "app-extra-large-modal": ExtraLargeModal,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });

    const dynamicTabs = ["Descrição de Papel", "Permissões de acesso"];
    const rolecurtain = ref();
    const formSearch = ref({
      description: "",
    });
    const formCreate = ref({
      form: {
        id: "",
        description: "",
        menus: [],
      }
    });

    const rules = computed(() => {
      return {
        form:{
          description: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          }
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const rows: any   = ref({});
    const programRows = ref({});
    const treeinput   = ref([]);
    const treeData    = ref({});
    /* ROLE */
    const currentPage = ref(1);
    const totalPages  = ref(1);
    const total       = ref();
    const perPage     = ref(10);
    /*###################### PROGRAM #########################*/
    const programCurrentPage= ref(1);
    const programTotalPages = ref(1);
    const programTotal      = ref();
    const programPerPage    = ref(10);
    /*--------------------- FORM ----------------------------  */
    const programsToInsert              = ref({ id: "", text: "" });
    const array: any                    = ref([]);
    const inputProgramIdSearchInsert    = ref("");
    const inputProgramDescriptionInsert = ref("");
    const inputProgramTextSearch        = ref("");
    const extralargemodal: any          = ref(null);
    const searchProgram                 = ref("");

    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "description",
        text: "Description",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const gridAccessColumn = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "text",
        text: "Programa",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const programColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "text",
        text: "Descrição",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const onSearch = () => {
      onList();
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onAdd = () => {
      programsToInsert.value.id = inputProgramIdSearchInsert.value;
      programsToInsert.value.text = inputProgramDescriptionInsert.value;
      if (programsToInsert.value.text) {
        array.value.push({
          id: inputProgramIdSearchInsert.value,
          text: inputProgramDescriptionInsert.value,
        });
        array.value = uniqueElementsBy(
          array.value,
          (a: any, b: any) => a.id == b.id
        );

        inputProgramDescriptionInsert.value = "";
        inputProgramIdSearchInsert.value = "";
      }
    };

    const onAccessDelete = (id: any) => {
      array.value.splice(id, 1);
    };

    const uniqueElementsBy = (arr: any, fn: any) =>
      arr.reduce((acc: any, v: any) => {
        if (!acc.some((x: any) => fn(v, x))) acc.push(v);
        return acc;
      }, 
    []);

    const onCurtainOpen = () => {
      formCreate.value.form.id = "";
      formCreate.value.form.description = "";
      formCreate.value.form.menus = [];
      $('#permission-container').jstree(true).deselect_all();
      rolecurtain.value.openNav();
    };

    onMounted(() => {
      onList();
      //programmList();
      getTree();
    });

    const onList = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        description: formSearch.value.description,
      };
      await store.dispatch(RoleActionTypes.LIST_ROLE, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    const onChange = (event: any) => {
      return;
    };

    const onStore = () => {
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      }

      const checked_ids: any = []; 
      const ids = $("#permission-container").jstree("get_checked",true);
      $.each(ids, function() {
        checked_ids.push({id: parseInt(this.id)});
      });

      const loader = $loading.show({
        loader: "dots",
      });

      formCreate.value.form.menus = checked_ids;
      store.dispatch(RoleActionTypes.UPDATE_ROLE, formCreate.value.form).then(() => {
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
        formCreate.value.form.description = "";
        formCreate.value.form.id = "";
        formCreate.value.form.menus = [];
        array.value = [];
        onList();
        loader.hide();
        rolecurtain.value.closeNav();
      }).catch((err) => {
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
        rolecurtain.value.closeNav();
      });
    };

    const onCreate = async () => {
      
      const result = await v$.value.$validate();
      if(!result){
        return;
      }
      const loader = $loading.show({
        loader: "dots",
      });
      formCreate.value.form.menus = array.value;
      
      store.dispatch(RoleActionTypes.CREATE_ROLE, formCreate.value.form).then((data) => {
        formCreate.value.form.description = "";
        formCreate.value.form.menus = [];
        array.value = [];
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
        rolecurtain.value.closeNav();
      }).catch(() => {
        loader.hide();
        rolecurtain.value.closeNav();
      });
      
    };

    const onDelete = (id: number) => {
      
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            
            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(RoleActionTypes.DELETE_ROLE, { id: id }).then(() => {
              Swal.fire({
                title: "Deleted!",
                text: "Your record has been deleted.",
                icon: "success",
                allowOutsideClick: false,
              });
              onList();
              loader.hide();
            }).catch(() => {
              loader.hide();
              Swal.fire({
                title: "Not Deleted!",
                html: "Your record could not be deleted. <br> Contact your Manager!",
                icon: "error",
                allowOutsideClick: false,
              });
            });
          }
        });
      
    };

    const onTree = async () => {
      const loader = $loading.show({
        loader: "dots",
      });
      await store.dispatch(MenuActionTypes.TREE_NAVIGATION).then((data) => {
        treeData.value = data;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const onEdit = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(RoleActionTypes.EDIT_ROLE, { id: id }).then((data) => {
        onCurtainOpen();
        formCreate.value.form.description = data.role.description;
        formCreate.value.form.id = data.role.id;
        $('#permission-container').jstree(true).deselect_all();
        //$("#permission-container").jstree('check_node', data.navigations);
        $("#permission-container").jstree('select_node', [data.navigations]);
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    }

    const getTree = () => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(MenuActionTypes.TREE_NAVIGATION).then((data) => {
        //treeData.value = data;

        $("#permission-container").jstree("destroy").jstree({
          core: {
            data: [data],
          },
          types: {
            default: {
              icon: "fa fa-folder text-warning",
            },
            file: {
              icon: "fa fa-file  text-warning",
            },
          },
          plugins: ["types", "checkbox", "wholerow"],
        })
        .bind("ready.jstree", function (event: any, data: any) {
          const id = data.instance._model.data[1].id ?? null;
          $("#permission-container").jstree("open_node", $("#" + id));
        });
        loader.hide();
      }).catch(() => {
        loader.hide();
      })
    };

    return {
      formSearch,
      formCreate,
      gridColumns,
      rows,
      dynamicTabs,
      onSearch,
      onCreate,
      onPaginate,
      /* ROLE */
      currentPage,
      totalPages,
      total,
      perPage,
      /* PROGRAMS */
      programCurrentPage,
      programTotalPages,
      programTotal,
      programPerPage,
      /* ====================== */
      onPageChange,
      onCurtainOpen,
      onList,
      onDelete,
      onEdit,
      onStore,
      treeData,
      onTree,
      treeinput,
      onChange,
      gridAccessColumn,
      programColumns,
      programRows,
      inputProgramIdSearchInsert,
      inputProgramDescriptionInsert,
      inputProgramTextSearch,
      extralargemodal,
      onAdd,
      array,
      onAccessDelete,
      searchProgram,
      visibility,
      getTree,
      v$,
      rolecurtain,
    };
  },
});
