<div class="login-box">
    <div class="card rounded-0 shadow card-outline card-primary">
        <div class="card-header text-center">
            <a href="/" class="h1"><b>{{ $t("labels.admin") }}</b>{{ $t("labels.lte") }}</a>
        </div>
        <div class="card-body">
            <p class="login-box-msg">
                {{ $t("messages.retrieveNewPassword") }}
            </p>
            <form @submit.prevent="forgotPassword">
                <div class="input-group mb-3">
                    <input
                        type="email"
                        class="form-control rounded-0"
                        placeholder="Email"
                        v-model="email"
                    />
                    <div class="input-group-append rounded-0">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button type="submit" class="btn rounded-0 btn-primary btn-block">
                            {{ $t("buttons.requestNewPassword") }}
                        </button>
                    </div>
                    <!-- /.col -->
                </div>
            </form>
            <p class="mt-3 mb-1">
                <router-link to="/login">{{ $t("labels.login") }}</router-link>
            </p>
        </div>
        <!-- /.login-card-body -->
    </div>
</div>
<!-- /.login-box -->