/* eslint-disable */
import { PeopleObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../..";
import { PeopleActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import axios from "axios";
import { date_br_format } from "@/classes/format_date_br";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [PeopleActionTypes.ALL_PEOPLE]({ commit, }: AugmentedActionContext): Promise<any>;
  [PeopleActionTypes.LIST_PEOPLE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.CREATE_PEOPLE]( { commit }: AugmentedActionContext, params: PeopleObject ): Promise<boolean>;
  [PeopleActionTypes.EDIT_PEOPLE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.UPDATE_PEOPLE]( { commit }: AugmentedActionContext, params: PeopleObject ): Promise<any>;
  [PeopleActionTypes.DELETE_PEOPLE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.DELETE_PEOPLE_CONTACT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.GET_PEOPLE_INFO]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.GET_PEOPLE_ADDRESS]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [PeopleActionTypes.GET_ALL_COMPANIES](): Promise<any>;
  [PeopleActionTypes.GET_ALL_CONTACTS]( { commit }: AugmentedActionContext, param: any ): Promise<any>;
  [PeopleActionTypes.GET_CONTACT_PAGING]( { commit }: AugmentedActionContext, param: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [PeopleActionTypes.ALL_PEOPLE]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/system/person/all").then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.LIST_PEOPLE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        cnpj_cpf     : params.document,
        nome_fantasia: params.nome_fantasia,
        razao_social : params.razao_social,
        email        : params.email
      };
      ApiService.post(`/system/person/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.CREATE_PEOPLE]({ commit }, params: PeopleObject) {
    return new Promise<boolean>((resolve, reject) => {
      const formdata = new FormData();

      formdata.append("picture", params.picture);
      formdata.append("country", params.country);
      formdata.append("type", params.type);
      formdata.append("document", params.document);
      formdata.append("identity", params.identity);
      const date = date_br_format(params.dtOfBirth);
      formdata.append("dtOfBirth", date);
      formdata.append("niche", params.niche);
      formdata.append("firstname", params.firstname);
      formdata.append("lastname", params.lastname);
      formdata.append("roles", JSON.stringify(params.role));
      formdata.append("phone", params.phone);
      formdata.append("email", params.email);
      formdata.append("observation", params.observation);
      formdata.append("zipcode", params.zipcode);
      formdata.append("address", params.address);
      formdata.append("number", params.number);
      formdata.append("complement", params.complement);
      formdata.append("district", params.district);
      formdata.append("state", params.state);
      formdata.append("city", params.city);
      formdata.append("contacts", JSON.stringify(params.contacts));
      formdata.append("asaas", params.asaas);

      ApiService.setHeader();
      axios.post("/system/person", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  async [PeopleActionTypes.EDIT_PEOPLE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/person/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.UPDATE_PEOPLE]({ commit }, params: PeopleObject) {
    return new Promise<boolean>((resolve, reject) => {
      const formdata = new FormData();
      const state = params.state === null ? '' : params.state;

      formdata.append("country", params.country);
      formdata.append("picture", params.picture);
      formdata.append("type", params.type);
      formdata.append("document", params.document);
      formdata.append("identity", params.identity);
      const date = date_br_format(params.dtOfBirth);
      formdata.append("dtOfBirth", date);
      formdata.append("firstname", params.firstname);
      formdata.append("lastname", params.lastname);
      formdata.append("niche", params.niche);
      formdata.append("roles", JSON.stringify(params.role));
      formdata.append("phone", params.phone);
      formdata.append("email", params.email);
      formdata.append("observation", params.observation);
      formdata.append("zipcode", params.zipcode);
      formdata.append("address", params.address);
      formdata.append("number", params.number);
      formdata.append("complement", params.complement);
      formdata.append("district", params.district);
      formdata.append("state", state);
      formdata.append("city", params.city);
      formdata.append("contacts", JSON.stringify(params.contacts));
      formdata.append("asaas", params.asaas);

      ApiService.setHeader();
      axios.post(`/system/person/${params.id}`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  },

  async [PeopleActionTypes.DELETE_PEOPLE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/person/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.DELETE_PEOPLE_CONTACT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/person/contact/${params.id}`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_PEOPLE_INFO]({ commit }, params: any) {
    return new Promise<boolean>((resolve, reject) => {
      const payload:any = {
        document: params.document,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/person/info", payload).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_PEOPLE_ADDRESS]({ commit }, params: any) {
    return new Promise<boolean>((resolve, reject) => {
      const payload:any = {
        zipcode: params.zipcode,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/person/getaddress", payload).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_ALL_COMPANIES]() {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/person/companies").then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_ALL_CONTACTS]({ commit }, param: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/person/contacts/${param.id}`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_CONTACT_PAGING]({ commit }, param: any) {
    return new Promise<any>((resolve, reject) => {
      const payload: any = {
        company: param.company,
      };

      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/system/person/contact/${param.limit}/${param.offset}`, payload).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_ALL_NICHE]({ commit }, param: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/person/niche`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [PeopleActionTypes.GET_ALL_COUNTRIES]({ commit }, param: any) {
    return new Promise<any>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/person/country`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
