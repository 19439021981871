import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "container-fluid" }

export function render(_ctx, _cache) {
  const _component_app_breadcrumb = _resolveComponent("app-breadcrumb")
  const _component_app_panel = _resolveComponent("app-panel")
  const _component_app_table = _resolveComponent("app-table")
  const _component_app_pagination = _resolveComponent("app-pagination")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t("labels.program")), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_breadcrumb, { class: "float-sm-right" })
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_app_panel, { class: "card-green" }, {
          title: _withCtx(() => [
            _createTextVNode(" Pesquisar programas ")
          ]),
          _: 1
        }),
        _createVNode(_component_app_panel, { class: "card-primary" }, {
          title: _withCtx(() => [
            _createTextVNode(" Listagem de programas ")
          ]),
          content: _withCtx(() => [
            _createVNode(_component_app_table, {
              columns: _ctx.gridColumns,
              rows: _ctx.data,
              "table-class": "table-bordered"
            }, null, 8, ["columns", "rows"])
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_app_pagination)
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}