/* eslint-disable */
import Tile from "@/components/ui/tile/tile.vue";
import { FinanceDashboardActionTypes } from "@/store/finance/dashboard/action-types";
import { defineComponent, onMounted, ref } from "vue";
import { useLoading } from "vue-loading-overlay";
import { useStore } from "vuex";

export default defineComponent({
    name: "BillToReceivePendingMonth",
    components: {
        "app-tile": Tile,
    },
    setup(){
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const month = ref(0);

        onMounted(() => {
            onList();
        });

        const onList = async () => {

            const params = {
              type: 'R',
              key: '--receive-sum-pending-month--',
              accountStatus: 'Recebido'
            };
      
            const loader = $loading.show({
              loader: "dots",
            });
      
            await store.dispatch(FinanceDashboardActionTypes.FINANCE_DASHBOARD_SUM_PENDING_MONTH, params).then((data) => {
                month.value = data[0].amount;
                loader.hide();
            }).catch(() => {
                loader.hide();
            });
            
        };

        return {
            month,
        };
    }
});
