import { CountryData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../../..";
import { State } from "./state";

export type Getters = {
  getCountry(state: State): CountryData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getCountry: (state) => state.countryData,
};
