import { defineComponent, ref } from "vue";
import BootstrapTabComponent from "@/components/ui/tabs/tab/tab.vue";
import { isVisible } from "@/classes/is_visible";
import BillToPayYearPending from "../components/billstopay/pending/year/year.vue";
import BillToPayMonthPending from "../components/billstopay/pending/month/month.vue";
import BillToPayYearPreview from "../components/billstopay/preview/year/year.vue";
import BillToPayMonthPreview from "../components/billstopay/preview/month/month.vue";
import BillToPayByMonth from "../components/billstopay/bymonth/bymonth.vue";
import BillToPayByClient from "../components/billstopay/byclient/byclient.vue";

import BillToReceiveYearPending from "../components/billstoreceive/pending/year/year.vue";
import BillToReceiveMonthPending from "../components/billstoreceive/pending/month/month.vue";
import BillToReceiveYearPreview from "../components/billstoreceive/preview/year/year.vue";
import BillToReceiveMonthPreview from "../components/billstoreceive/preview/month/month.vue";
import BillToReceiveByMonth from "../components/billstoreceive/bymonth/bymonth.vue";
import BillToReceiveByClient from "../components/billstoreceive/byclient/byclient.vue";

export default defineComponent({
  name: "FinanceDashboard",
  components: {
    "app-tab": BootstrapTabComponent,
    "app-bill-to-pay-year-pending": BillToPayYearPending,
    "app-bill-to-pay-month-pending": BillToPayMonthPending,
    "app-bill-to-pay-year-preview": BillToPayYearPreview,
    "app-bill-to-pay-month-prview": BillToPayMonthPreview,
    "app-bill-to-pay-by-month": BillToPayByMonth,
    "app-bill-to-pay-by-client": BillToPayByClient,
    "app-bill-to-receive-year-pending": BillToReceiveYearPending,
    "app-bill-to-receive-month-pending": BillToReceiveMonthPending,
    "app-bill-to-receive-year-preview": BillToReceiveYearPreview,
    "app-bill-to-receive-month-prview": BillToReceiveMonthPreview,
    "app-bill-to-receive-by-month": BillToReceiveByMonth,
    "app-bill-to-receive-by-client": BillToReceiveByClient,
  },
  setup() {
    const active = ref(0);
    const bToReceive = isVisible("finance.dashboard.billtoreceive");
    const bToPay = isVisible("finance.dashboard.billtopay");
    const previews = isVisible("finance.dashboard.previews");
    const charts = isVisible("finance.dashboard.charts");
    const cashFlow = isVisible("finance.dashboard.cashflow");

    if (!bToReceive && bToPay) {
      active.value = 1;
    } else if (!bToPay && previews) {
      active.value = 2;
    } else if (!previews && charts) {
      active.value = 3;
    } else if (!charts && cashFlow) {
      active.value = 4;
    }

    const tabs = ref([
      {
        name: "tab1",
        label: "Contas a Receber",
        id: "custom-tabs-four-one-tab",
        ariaControls: "custom-tabs-four-one",
        disabled: !bToReceive,
      },
      {
        name: "tab2",
        label: "Contas a Pagar",
        id: "custom-tabs-four-two-tab",
        ariaControls: "custom-tabs-four-two",
        disabled: !bToPay,
      },
      {
        name: "tab3",
        label: "Resumos",
        id: "custom-tabs-four-tree-tab",
        ariaControls: "custom-tabs-four-tree",
        disabled: !previews,
      },
      {
        name: "tab4",
        label: "Gráficos",
        id: "custom-tabs-four-tree-tab",
        ariaControls: "custom-tabs-four-tree",
        disabled: !charts,
      },
      {
        name: "tab5",
        label: "Fluxo de Caixa",
        id: "custom-tabs-four-tree-tab",
        ariaControls: "custom-tabs-four-tree",
        disabled: !cashFlow,
      },
      {
        name: "tab5",
        label: "Crescimento",
        id: "custom-tabs-four-tree-tab",
        ariaControls: "custom-tabs-four-tree",
        disabled: !cashFlow,
      },
    ]);

    return { tabs, active };
  },
});
