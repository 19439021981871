/* eslint-disable */
import { IUser } from "@/interfaces/user";
import { Options, Vue } from "vue-class-component";
import MenuItem from "@/components/menu-item/menu-item.vue";
import { PfImage } from "@profabric/vue-components";
import SidebarSearch from "@/components/ui/sidebar-search/sidebar-search.vue";
import { i18n } from "@/translation";

@Options({
  name: "app-menu-sidebar",
  components: {
    "app-menu-item": MenuItem,
    "app-sidebar-search": SidebarSearch,
    "pf-image": PfImage,
  },
})
export default class MenuSidebar extends Vue {
  public menu = MENU;
  public name = "";

  get user(): any {
    //return this.$store.getters["auth/user"];
    const username = localStorage.getItem("name");
    return username;
  }

  get sidebarSkin() {
    return this.$store.getters["ui/sidebarSkin"];
  }

  get userPicture() {
    let image: any = "";
    if (
      localStorage.getItem('picture') !== undefined &&
      localStorage.getItem('picture') !== '' &&
      localStorage.getItem('picture') !== 'null'
    ) {
      image = localStorage.getItem('picture');
    } else {
      image = require('@/assets/img/default-profile.png');
    }
    return image;
  }
}

export const MENU = [
  {
    name: "labels.dashboard",
    path: "/",
    icon: "fas fa-tachometer-alt success",
    navigation: "general.dashboard"
  },
  {
    name: "labels.administration",
    icon: "fas fa-user-cog success",
    navi: "admin",
    children: [
      {
        name: "labels.dashboard",
        path: "/administration",
        icon: "fas fa-tachometer-alt nav-icon",
        navi: "general.dashboard"
      },
      {
        name: "labels.country",
        path: "/administration/country",
        icon: "far fa-user nav-icon",
        navi: "country.list"
      },
      {
        name: "labels.menu",
        path: "/administration/menu",
        icon: "fas fa-map-marked nav-icon",
        navi: "navigation.tree"
      },
      {
        name: "labels.personType",
        path: "/administration/person-type",
        icon: "fas fa-user-clock nav-icon",
        navi: "person.type.list"
      },
      {
        name: "labels.niche",
        path: "/administration/niche",
        icon: "far fa-user nav-icon",
        navi: "niche.list"
      },
      {
        name: "labels.role",
        path: "/administration/roles",
        icon: "far fa-user nav-icon",
        navi: "role.list"
      },
      {
        name: "labels.user",
        path: "/administration/user",
        icon: "fas fa-user-shield nav-icon",
        navi: "usuarios"
      },
      {
        name: "labels.integration",
        path: "/administration/integration",
        icon: "fas fa-toggle-on nav-icon",
        navi: "integration.create.update"
      },
      {
        name: "labels.systemInformation",
        path: "/administration/system-information",
        icon: "fas fa-info nav-icon",
        navi: "server.information"
      },
    ],
  },
  {
    name: "labels.registration",
    icon: "fas fa-registered success",
    navi: "cadastro",
    children: [
      {
        name: "labels.people",
        path: "/registration/people",
        icon: "fas fa-building",
        navi: "person.list"
      },
      {
        name: "labels.contact",
        path: "/registration/contact",
        icon: "fas fa-users nav-icon",
        navi: "person.list.paginated.contact"
      },
    ],
  },
  {
    name: "labels.finance",
    icon: "fas fa-money-bill success",
    navi: "finance.",
    children: [
      {
        name: 'labels.dashboard',
        path: "/finance/dashboard",
        icon: "fas fa-tachometer-alt",
        navi: "finance.dashboard."
      },
      {
        name: 'labels.chart',
        path: "/finance/chart",
        icon: "fas fa-chart-line",
        navi: "finance.charts"
      },
      {
        name: 'labels.cashflow',
        path: "/finance/cash-flow",
        icon: "fas fa-funnel-dollar",
        navi: "finance.cashflow"
      },
      {
        name: 'labels.accountPlan',
        path: "/finance/account-plan",
        icon: "fas fa-street-view",
        navi: "finance.account.plan.tree"
      },
      {
        name: 'labels.costCenter',
        path: "/finance/cost-center",
        icon: "fas fa-building",
        navi: "finance.cost.center.tree"
      },
      {
        name: 'labels.status',
        path: "/finance/status",
        icon: "fas fa-check-circle",
        navi: "finance.account.status"
      },
      {
        name: 'labels.bankAccountType',
        path: "/finance/bank-account-type",
        icon: "fas fa-piggy-bank",
        navi: "finance.bank.account.type"
      },
      {
        name: 'labels.bank',
        path: "/finance/bank",
        icon: "fas fa-university",
        navi: "finance.banks"
      },
      {
        name: 'labels.financeMovementType',
        path: "/finance/movement-type",
        icon: "fas fa-receipt",
        navi: "finance.movement.type",
      },
      {
        name: 'labels.bankAccount',
        path: "/finance/bank-account",
        icon: "fas fa-wallet",
        navi: "finance.bank.account"
      },
      {
        name: 'labels.billsToPay',
        path: "/finance/bills-to-pay",
        icon: "fas fa-barcode",
        navi: "finance.accounts.to.pay"
      },
      {
        name: 'labels.billsToReceive',
        path: "/finance/bills-to-receive",
        icon: "fas fa-wallet",
        navi: "finance.accounts.to.receive"
      },
      {
        name: 'labels.batchDischarge',
        path: "/finance/bills-to-receive",
        icon: "fas fa-wallet",
        navi: "finance.batch.discharge"
      },
    ]
  },
  {
    name: 'labels.service',
    icon: "fas fa-puzzle-piece success",
    navi: "services.",
    children: [
      {
        name: "labels.registration",
        icon: "far fa-circle nav-icon",
        navi: "services.registration.",
        children: [
          {
            name: "labels.serviceType",
            path: "/service/registration/type",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.registration.servicetype.",
          },
          {
            name: "labels.service",
            path: "/service/registration/service",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.registration.service.",
          }
        ]
      },
      {
        name: "labels.contract",
        icon: "far fa-circle",
        navi: "services.contracts.",
        children: [
          {
            name: "labels.dashboard",
            path: "/service/contract/contract/dashboard",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.contracts.contract.dashboard.data",
          },
          {
            name: "labels.contractType",
            path: "/service/contract/type",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.contracts.contracttype.list",
          },
          {
            name: "labels.contract",
            path: "/service/contract/contract",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.contracts.contract.list",
          },
          {
            name: "labels.generateInvoice",
            path: "/service/contract/invoice",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.contracts.contractinvoice.",
          }
        ]
      },
      {
        name: 'labels.invoice',
        icon: "far fa-circle",
        navi: "services.invoice.invoice.",
        children: [
          {
            name: "labels.dashboard",
            path: "/service/invoice/invoice/dashboard",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.invoice.invoice.dashboard",
          },
          {
            name: "labels.invoice",
            path: "/service/invoice/invoice",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.invoice.invoice.list",
          },
          {
            name: "labels.generateAccountReceivable",
            path: "/service/invoice/invoice/put-to-bills",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.invoice.invoice.for.bills",
          }
        ]
      },
      {
        name: "labels.issue",
        icon: "far fa-circle",
        navi: "services.issue.",
        children: [
          {
            name: "labels.issue",
            path: "/service/issue",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.issue.list",
          },
          {
            name: "labels.myIssue",
            path: "/service/issue/mine",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.my.issue.list",
          },
          {
            name: "labels.category",
            path: "/service/issue/category",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.category.list",
          },
          {
            name: "labels.state",
            path: "/service/issue/state",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.state.list",
          },
          {
            name: "labels.problemType",
            path: "/service/issue/problem-type",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.problemtype.list",
          },
          {
            name: "labels.solutionType",
            path: "/service/issue/solution-type",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.solutiontype.list",
          },
          {
            name: "labels.priority",
            path: "/service/issue/priority",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.issue.priority.list",
          }
        ]
      },
      {
        name: "labels.project",
        icon: "far fa-circle",
        navi: "services.project.",
        children: [
          {
            name: "labels.state",
            path: "/service/project/state",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.project.state.list",
          },
          {
            name: "labels.project",
            path: "/service/project",
            icon: "far fa-dot-circle nav-icon",
            navi: "services.project.project.list",
          }
        ]
      },
    ]
  },
  {
    name: "labels.log",
    icon: "fas fa-history success",
    navi: "logs",
    children: [
      {
        name: "labels.access",
        path: "/log/access",
        icon: "fas fa-flag orange",
        navi: "log.access"
      },
      {
        name: "labels.database",
        path: "/log/database",
        icon: "fas fa-database red",
        navi: "log.sql"
      },
    ],
  },
  {
    name: "labels.component",
    icon: "fas fa-puzzle-piece success",
    navi: "component.",
    children: [
      {
        name: "labels.general",
        path: "/components/general",
        icon: "far fa-circle",
        navi: "component.general",
      },
      {
        name: "labels.widgets",
        path: "/components/widgets",
        icon: "far fa-circle",
        navi: "component.widget",
      },
      {
        name: "labels.charts",
        path: "/components/charts",
        icon: "far fa-circle",
        navi: "component.chart",
      },
      {
        name: "labels.button",
        path: "/components/buttons",
        icon: "far fa-circle",
        navi: "component.button",
      },
      {
        name: "labels.slider",
        path: "/components/sliders",
        icon: "far fa-circle",
        navi: "component.slider",
      },
      {
        name: "labels.modal",
        path: "/components/modals",
        icon: "far fa-circle",
        navi: "component.modal",
      },
      {
        name: "labels.timeline",
        path: "/components/timelines",
        icon: "far fa-circle",
        navi: "component.timeline",
      },
      {
        name: "labels.generalElement",
        path: "/components/general-elements",
        icon: "far fa-circle",
        navi: "component.general.element",
      },
      {
        name: "labels.advancedElement",
        path: "/components/advanced-elements",
        icon: "far fa-circle",
        navi: "component.advanced.element",
      },
      {
        name: "labels.editor",
        path: "/components/editors",
        icon: "far fa-circle",
        navi: "component.editor",
      },
      {
        name: "labels.table",
        path: "/components/tables",
        icon: "far fa-circle",
        navi: "component.table",
      },
      {
        name: "labels.calendar",
        path: "/components/calendar",
        icon: "far fa-circle",
        navi: "component.calendar",
      },
      {
        name: "labels.kanban",
        path: "/components/kanban",
        icon: "far fa-circle",
        navi: "component.kanban",
      },
    ],
  },
];
