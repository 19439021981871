import { ServiceData, ServiceObject } from "@/@types";

export type State = {
  data: ServiceObject | null;
  serviceData: ServiceData;
};

export const state: State = {
  data: null,
  serviceData: [],
};
