import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-2" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = { class: "m-0" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = { class: "breadcrumb float-sm-right" }
const _hoisted_8 = { class: "breadcrumb-item" }
const _hoisted_9 = { class: "breadcrumb-item" }
const _hoisted_10 = { class: "breadcrumb-item active" }
const _hoisted_11 = { class: "content" }
const _hoisted_12 = { class: "container-fluid" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "container-fluid" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "col" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "col" }
const _hoisted_24 = { class: "container-fluid" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { class: "col" }
const _hoisted_28 = { class: "col" }
const _hoisted_29 = { class: "col" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col" }
const _hoisted_32 = { class: "col" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_bill_to_receive_year_preview = _resolveComponent("app-bill-to-receive-year-preview")
  const _component_app_bill_to_receive_month_prview = _resolveComponent("app-bill-to-receive-month-prview")
  const _component_app_bill_to_receive_year_pending = _resolveComponent("app-bill-to-receive-year-pending")
  const _component_app_bill_to_receive_month_pending = _resolveComponent("app-bill-to-receive-month-pending")
  const _component_app_bill_to_receive_by_month = _resolveComponent("app-bill-to-receive-by-month")
  const _component_app_bill_to_receive_by_client = _resolveComponent("app-bill-to-receive-by-client")
  const _component_app_bill_to_pay_year_preview = _resolveComponent("app-bill-to-pay-year-preview")
  const _component_app_bill_to_pay_month_prview = _resolveComponent("app-bill-to-pay-month-prview")
  const _component_app_bill_to_pay_year_pending = _resolveComponent("app-bill-to-pay-year-pending")
  const _component_app_bill_to_pay_month_pending = _resolveComponent("app-bill-to-pay-month-pending")
  const _component_app_bill_to_pay_by_month = _resolveComponent("app-bill-to-pay-by-month")
  const _component_app_bill_to_pay_by_client = _resolveComponent("app-bill-to-pay-by-client")
  const _component_app_tab = _resolveComponent("app-tab")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("labels.financeDashboard")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("ol", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("labels.home")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.$t("labels.finance")), 1),
              _createElementVNode("li", _hoisted_10, _toDisplayString(_ctx.$t("labels.dashboard")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_app_tab, {
              tabs: _ctx.tabs,
              active: _ctx.active
            }, {
              tab1: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_app_bill_to_receive_year_preview)
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_app_bill_to_receive_month_prview)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_app_bill_to_receive_year_pending)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_app_bill_to_receive_month_pending)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_app_bill_to_receive_by_month)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_app_bill_to_receive_by_client)
                    ])
                  ])
                ])
              ]),
              tab2: _withCtx(() => [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_app_bill_to_pay_year_preview)
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_app_bill_to_pay_month_prview)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_app_bill_to_pay_year_pending)
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_app_bill_to_pay_month_pending)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createVNode(_component_app_bill_to_pay_by_month)
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(_component_app_bill_to_pay_by_client)
                    ])
                  ])
                ])
              ]),
              tab3: _withCtx(() => [
                _createTextVNode(" Tab 3 Morbi turpis dolor, vulputate vitae felis non, tincidunt congue mauris. Phasellus volutpat augue id mi placerat mollis. Vivamus faucibus eu massa eget condimentum. Fusce nec hendrerit sem, ac tristique nulla. Integer vestibulum orci odio. Cras nec augue ipsum. Suspendisse ut velit condimentum, mattis urna a, malesuada nunc. Curabitur eleifend facilisis velit finibus tristique. Nam vulputate, eros non luctus efficitur, ipsum odio volutpat massa, sit amet sollicitudin est libero sed ipsum. Nulla lacinia, ex vitae gravida fermentum, lectus ipsum gravida arcu, id fermentum metus arcu vel metus. Curabitur eget sem eu risus tincidunt eleifend ac ornare magna. ")
              ]),
              _: 1
            }, 8, ["tabs", "active"])
          ])
        ])
      ])
    ])
  ], 64))
}