import { FinanceAccountStatusData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getFinanceAccountStatus(state: State): FinanceAccountStatusData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getFinanceAccountStatus: (state) => state.financeAccountStatusData,
};
