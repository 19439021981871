/* eslint-disable */
import { ContractTypeObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { ContractTypeActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>( key: K, payload: Parameters<Mutations[K]>[1] ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ContractTypeActionTypes.CONTRACT_TYPE_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ContractTypeActionTypes.CONTRACT_TYPE_CREATE]( { commit }: AugmentedActionContext, params: ContractTypeObject ): Promise<boolean>;
  [ContractTypeActionTypes.CONTRACT_TYPE_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [ContractTypeActionTypes.CONTRACT_TYPE_UPDATE]( { commit }: AugmentedActionContext, params: ContractTypeObject ): Promise<any>;
  [ContractTypeActionTypes.CONTRACT_TYPE_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [ContractTypeActionTypes.CONTRACT_TYPE_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`contracts/contract-type/${params.limit}/${params.offset}`, params).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractTypeActionTypes.CONTRACT_TYPE_CREATE]( { commit }, params: ContractTypeObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        description: params.description,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("contracts/contract-type", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractTypeActionTypes.CONTRACT_TYPE_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`contracts/contract-type/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractTypeActionTypes.CONTRACT_TYPE_UPDATE]( { commit }, params: ContractTypeObject ) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        description: params.description,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`contracts/contract-type/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [ContractTypeActionTypes.CONTRACT_TYPE_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`contracts/contract-type/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      }).catch(({ response }) => {
        reject(response);
      });
    });
  },
};
