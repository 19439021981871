import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_tile = _resolveComponent("app-tile")

  return (_openBlock(), _createBlock(_component_app_tile, {
    content: _ctx.year,
    text: "EM ABERTO NO ANO",
    fa: "ion ion-cash",
    class: "small-box bg-warning shadow-lg"
  }, null, 8, ["content"]))
}