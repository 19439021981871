/* eslint-disable */
export enum FinanceBankAccountActionTypes {
  FINANCE_BANK_ACCOUNT_LIST             = "FinanceBankAccountList",
  FINANCE_BANK_ACCOUNT_CREATE           = "FinanceBankAccountCreate",
  FINANCE_BANK_ACCOUNT_EDIT             = "FinanceBankAccountEdit",
  FINANCE_BANK_ACCOUNT_UPDATE           = "FinanceBankAccountUpdate",
  FINANCE_BANK_ACCOUNT_DELETE           = "FinanceBankAccountDelete",
  FINANCE_BANK_ACCOUNT_GET_RESPONSIBLE  = "FinanceBankAccountGetResponsible",
  FINANCE_BANK_ACCOUNT_GET_ACCOUNT_TYPE = "FinanceBankAccountGetAccountTypes",
  FINANCE_BANK_ACCOUNT_GET_BANKS        = "FinanceBankAccountGetBanks",
}
