<form action="javascript:;" @submit.prevent="onStore">
    <issue-create-form
        right-curtain-name="right" 
        ref="createForm" 
        card-class="card-secondary"
        style-width-opened="87%"
    >
        <template v-slot:title>
            {{ formTitle }}
        </template>
        <template v-slot:content>
            <div class="row">
                <div class="col-sm">
                    <div class="form-group" id="contract-product">
                        <label for="exampleInputEmail1" class="col-form-label">Contrato - Projeto:</label>
                        <Select2 
                            v-model="v$.form.project.$model" 
                            :options="projects"
                            :settings="{ 
                                dropdownParent: '#contract-product', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                            @select="verifyContract($event)"
                        />
                        <span v-if="checkArray(v$.form.project.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.project.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="category">
                        <label for="exampleInputEmail1" class="col-form-label">Categoria:</label>
                        <Select2 
                            v-model="v$.form.category.$model" 
                            :options="categories"
                            :settings="{ 
                                dropdownParent: '#category', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                        />
                        <span v-if="checkArray(v$.form.category.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.category.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="problem-types">
                        <label for="exampleInputEmail1" class="col-form-label">Tipo de problema:</label>
                        <Select2 
                            v-model="v$.form.problemType.$model" 
                            :options="problemTypes"
                            :settings="{ 
                                dropdownParent: '#problem-types', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" 
                        />
                        <span v-if="checkArray(v$.form.problemType.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.problemType.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group" id="recurring">
                        <label for="exampleInputEmail1" class="col-form-label">Recorrente?:</label>
                        <div class="col">
                            <s-radio-button 
                                :options="radioOptions" 
                                v-model="form.form.recurring"
                                div-class=""
                            ></s-radio-button>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group" id="priority">
                        <label for="exampleInputEmail1" class="col-form-label">Prioridade:</label>
                        <Select2 
                            v-model="v$.form.priority.$model" 
                            :options="priorities"
                            :settings="{ 
                                dropdownParent: '#priority', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }"
                        />
                        <span v-if="checkArray(v$.form.priority.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.priority.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
                <div class="col-sm"></div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <div class="form-group">
                        <label for="exampleInputEmail1" class="col-form-label">Observação:</label>
                        <textarea 
                            name="" 
                            class="form-control rounded-0   " 
                            id="" 
                            cols="30" 
                            rows="10"
                            v-model="v$.form.observation.$model" 
                            placeholder="Descreva em detalhes o que está acontecendo, lembrando que não será possovel editar essa informação, qualquer dados adicionais será feito via nota."
                        ></textarea>
                        <span v-if="checkArray(v$.form.observation.$errors)">
                            <small 
                                id="emailHelp" 
                                class="form-text text-danger"
                                v-for="(errorMessage, index) of v$.form.observation.$errors" 
                                :key="index"
                            >
                                {{ errorMessage.$message }}
                            </small>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label for="arquivos" class="col-form-label">Arquivo:</label>
                        <div class="input_container">
                            <input type="file" id="fileUpload" multiple ref="fileInput"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <app-button 
                type="submit" 
                class="btn btn-default rounded-0 shadow" 
                :disabled="v$.form.$invalid"
            >
                <i class="fa fa-save"></i>
                Gravar
            </app-button>
        </template>
    </issue-create-form>
</form>
