<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.kanban") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">

</section>