import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { "aria-label": "Page navigation example" }
const _hoisted_2 = { class: "pagination pagination-sm justify-content-center" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled", "aria-disabled"]
const _hoisted_5 = ["disabled", "aria-disabled"]
const _hoisted_6 = ["aria-current", "disabled"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["aria-disabled", "disabled"]
const _hoisted_9 = ["aria-disabled", "disabled"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", {
        class: _normalizeClass(["page-item", { disabled: _ctx.isInFirstPage }]),
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onClickFirstPage && _ctx.onClickFirstPage(...args))),
        disabled: _ctx.isInFirstPage
      }, [
        _createElementVNode("a", {
          class: "page-link",
          href: "javascript:;",
          tabindex: "-1",
          disabled: _ctx.isInFirstPage,
          "aria-disabled": _ctx.isInFirstPage
        }, "Primeira", 8, _hoisted_4)
      ], 10, _hoisted_3),
      _createElementVNode("li", {
        class: _normalizeClass(["page-item", { disabled: _ctx.isInFirstPage }]),
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onClickPreviousPage && _ctx.onClickPreviousPage(...args)))
      }, [
        _createElementVNode("a", {
          class: "page-link",
          href: "javascript:;",
          tabindex: "-1",
          disabled: _ctx.isInFirstPage,
          "aria-disabled": _ctx.isInFirstPage
        }, "Anterior", 8, _hoisted_5)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["page-item", { active: _ctx.isPageActive(page.name) }]),
          "aria-current": _ctx.isPageActive(page.name),
          disabled: page.isDisabled,
          key: page.name
        }, [
          _createElementVNode("a", {
            class: "page-link",
            href: "javascript:;",
            onClick: $event => (_ctx.onClickPage(page.name))
          }, _toDisplayString(page.name), 9, _hoisted_7)
        ], 10, _hoisted_6))
      }), 128)),
      _createElementVNode("li", {
        class: _normalizeClass(["page-item", { disabled: _ctx.isInLastPage }]),
        onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.onClickNextPage && _ctx.onClickNextPage(...args)))
      }, [
        _createElementVNode("a", {
          class: "page-link",
          "aria-disabled": _ctx.isInLastPage,
          disabled: _ctx.isInLastPage,
          href: "javascript:;"
        }, "Próxima", 8, _hoisted_8)
      ], 2),
      _createElementVNode("li", {
        class: _normalizeClass(["page-item", { disabled: _ctx.isInLastPage }]),
        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.onClickLastPage && _ctx.onClickLastPage(...args)))
      }, [
        _createElementVNode("a", {
          class: "page-link",
          "aria-disabled": _ctx.isInLastPage,
          disabled: _ctx.isInLastPage,
          href: "javascript:;"
        }, "Última", 8, _hoisted_9)
      ], 2)
    ])
  ]))
}