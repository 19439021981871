/* eslint-disable */
export enum IssueActionTypes {
  ISSUE_LIST              = "IssueList",
  ISSUE_MINE              = "MyIssue",
  ISSUE_CREATE            = "IssueCreate",
  ISSUE_EDIT              = "IssueEdit",
  ISSUE_UPDATE            = "IssueUpdate",
  ISSUE_FILE_GET          = "IssueFileGet",
  ISSUE_FILE_DELETE       = "IssueFileDelete",
  ISSUE_GET_USERS         = "IssueGetUsers",
  ISSUE_GET_CLIENT        = "IssueGetClient",
  ISSUE_GET_PROJECT       = "IssueGetProject",
  ISSUE_GET_PRIORITY      = "IssueGetPriority",
  ISSUE_GET_CATEGORY      = "IssueGetCategory",
  ISSUE_GET_STATE         = "IssueGetState",
  ISSUE_GET_PROBLEM_TYPE  = "IssueGetProblemType",
  ISSUE_GET_SOLUTION_TYPE = "IssueGetSolutionType",
  ISSUE_VIEW              = "IssueView",
  ISSUE_GET_NOTE          = "IssueGetNote",
  ISSUE_POST_NOTE         = "IssuePostNote",
  ISSUE_DELETE_FILE_NOTE  = "IssueDeleteNote",
  ISSUE_GET_NOTE_FILE     = "IssueGetNoteFile",
  ISSUE_GET_NOTIFICATION  = "IssueGetNotification",
  ISSUE_NOTE_VIEWED       = "IssueNoteViewed"
}
