/* eslint-disable */
import { FinanceBanksData, FinanceBanksObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceBanksMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceBanksMutationTypes.SET_INSERT_FINANCE_BANKS_DATA](state: S, payload: FinanceBanksObject): void;
  [FinanceBanksMutationTypes.SET_FINANCE_BANKS_DATA](state: S, payload: FinanceBanksData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceBanksMutationTypes.SET_INSERT_FINANCE_BANKS_DATA](state: State, financeBanks: FinanceBanksObject) {
    state.financeBanks = financeBanks;
  },
  [FinanceBanksMutationTypes.SET_FINANCE_BANKS_DATA](state: State, financeBanksData: FinanceBanksData) {
    state.financeBanksData = financeBanksData;
  },
};
