<select 
    class="form-control rounded-0" 
    :value="modelValue"
    @change="onValueChange($event)"
>
    <option value="">&nbsp;</option>
    <option 
        v-for="(item, id) in items" 
        :key="id" 
        :value="item.key"
    >
        {{ $t(item.text) }}
    </option>
</select>