/* eslint-disable */
import { IssueProblemTypeData, IssueProblemTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueProblemTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueProblemTypeMutationTypes.SET_INSERT_ISSUE_PROBLEM_TYPE_DATA](
    state: S,
    payload: IssueProblemTypeObject
  ): void;
  [IssueProblemTypeMutationTypes.SET_ISSUE_PROBLEM_TYPE_DATA](
    state: S,
    payload: IssueProblemTypeData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueProblemTypeMutationTypes.SET_INSERT_ISSUE_PROBLEM_TYPE_DATA](
    state: State,
    issueProblemType: IssueProblemTypeObject
  ) {
    state.data = issueProblemType;
  },
  [IssueProblemTypeMutationTypes.SET_ISSUE_PROBLEM_TYPE_DATA](
    state: State,
    issueProblemTypeData: IssueProblemTypeData
  ) {
    state.issueProblemTypeData = issueProblemTypeData;
  },
};
