/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "@/store/index";

import Main from "@/modules/main/main.vue";
import Login from "@/modules/login/login.vue";
import Register from "@/modules/register/register.vue";

import Dashboard from "@/pages/dashboard/dashboard.vue";
import Profile from "@/pages/profile/profile.vue";
import ForgotPassword from "@/modules/forgot-password/forgot-password.vue";
import RecoverPassword from "@/modules/recover-password/recover-password.vue";
import PrivacyPolicy from "@/modules/privacy-policy/privacy-policy.vue";
import SubMenu from "@/pages/main-menu/sub-menu/sub-menu.vue";
import Blank from "@/pages/blank/blank.vue";
import Components from "@/pages/components/components.vue";

import AdminDashboard from "@/pages/admin/dashboard/dashboard.vue";
import AdminMain from "@/pages/admin/main.vue";
import Integration from "@/pages/admin/integration/integration.vue";
import Program from "@/pages/admin/program/program.vue";
import Menu from "@/pages/admin/menu/menu.vue";
import Role from "@/pages/admin/role/role.vue";
import Niche from "@/pages/admin/niche/niche.vue";
import PersonType from "@/pages/admin/person-type/person-type.vue";
import User from "@/pages/admin/user/user.vue";
import SystemInformation from "@/pages/admin/system-information/system-information.vue";

import { i18n } from "@/translation";

import LogMain from "@/pages/logs/main.vue";
import LogDashboard from "@/pages/logs/dashboard/dashboard.vue";
import Access from "@/pages/logs/access/access.vue";
import Database from "@/pages/logs/database/database.vue";
import General from "@/pages/components/general/general.vue";
import Widgets from "@/pages/components/widgets/widgets.vue";
import Charts from "@/pages/components/charts/charts.vue";
import Buttons from "@/pages/components/buttons/buttons.vue";
import Sliders from "@/pages/components/sliders/sliders.vue";
import Modals from "@/pages/components/modals/modals.vue";
import Timeline from "@/pages/components/timeline/timeline.vue";
import GeneralElements from "@/pages/components/general-elements/general-elements.vue";
import AdvancedElements from "@/pages/components/advanced-elements/advanced-elements.vue";
import Editor from "@/pages/components/editor/editor.vue";
import Tables from "@/pages/components/table/tables.vue";
import Calendars from "@/pages/components/calendar/calendars.vue";
import Kanban from "@/pages/components/kanban/kanban.vue";
import Registration from "@/pages/register/register.vue";
import People from "@/pages/admin/people/people.vue";
import PersonContact from "@/pages/admin/contact/contact.vue";

/* FINANCE */
import FinanceMain from "@/pages/finance/main.vue";
import AccountPlan from "@/pages/finance/AccountPlan/accountplan.vue";
import FinanceDashboard from "@/pages/finance/dashboard/dashboard.vue";
import CostCenter from "@/pages/finance/CostCenter/costcenter.vue";
import FinanceAccountStatus from "@/pages/finance/Status/status.vue";
import FinanceBankAccountType from "@/pages/finance/BankAccountType/type.vue";
import FinanceBank from "@/pages/finance/Bank/bank.vue";
import FinanceMovement from "@/pages/finance/Movement/movement.vue";
import FinanceBankAccount from "@/pages/finance/BankAccount/bank-account.vue";
import FinanceBillsToPay from "@/pages/finance/BillsToPay/bills-to-pay.vue";
import FinanceBillsToReceive from "@/pages/finance/BillsToReceive/bills-to-receive.vue";

/* Blog */
import BlogView from "@/pages/blog/BlogView.vue";
import PostCategory from "@/pages/blog/category/CategoryView.vue";
import PostStatus from "@/pages/blog/status/StatusView.vue";
import Post from "@/pages/blog/post/PostView.vue";
import SeoTypeView from "@/pages/blog/seo-type/SeoTypeView.vue";
import SeoMetaIdentifierView from "@/pages/blog/seo-meta-identifier/SeoMetaIdentifierView.vue";
import SeoMetaNameView from "@/pages/blog/seo-meta-name/SeoMetaNameView.vue";

/* SERVICE */
import ServicesMain from "@/pages/services/ServicesView.vue";
import ServiceRegistrationView from "@/pages/services/registration/RegistrationView.vue";
import ServiceType from "@/pages/services/registration/type/type.vue";
import Service from "@/pages/services/registration/service/service.vue";
import ContractType from "@/pages/services/contract-type/contracttype.vue";
/* CONTRACT */
import ContractView from "@/pages/services/contract/ContractView.vue";
import ContractGrid from "@/pages/services/contract/list/grid.vue";
import ContractInsert from "@/pages/services/contract/create/create.vue";
import ContractInvoice from "@/pages/services/contract/invoice/contractinvoice.vue"
import ContractDashboard from "@/pages/services/contract/dashboard/dashboard.vue"
/* INVOICE */
import InvoiceView from "@/pages/services/invoice/InvoiceView.vue";
import InvoiceGrid from "@/pages/services/invoice/list/grid.vue";
import InvoiceBills from "@/pages/services/invoice/put-to-bills/invoice-bills.vue";
import InvoiceDashboard from "@/pages/services/invoice/dashboard/dashboard.vue";
/* ISSUE */
import IssueView from "@/pages/services/issue/IssueView.vue";
import IssueCategory from "@/pages/services/issue/category/category.vue"
import IssueState from "@/pages/services/issue/state/state.vue"
import IssuePriority from "@/pages/services/issue/priority/priority.vue"
import IssueProblemType from "@/pages/services/issue/problem-type/problem-type.vue"
import IssueSolutionType from "@/pages/services/issue/solution-type/solution-type.vue"
import Issue from "@/pages/services/issue/issue/list/issue.vue"
import MyIssue from "@/pages/services/issue/issue/mine/myissue.vue"
import IssueNotification from "@/pages/services/issue/issue/notifications/view.vue";
/* PROJECT */
import ProjectView from "@/pages/services/project/ProjectView.vue";
import ProjectState from "@/pages/services/project/state/state.vue";
import Project from "@/pages/services/project/project/list/grid.vue";
import ProjectInsert from "@/pages/services/project/project/create/create.vue";
/* COUNTRY */
import Country from "@/pages/admin/country/country.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "general.dashboard",
    component: Main,
    meta: {
      title: "Dashboard",
      requiresAuth: true,
      breadcrumb: [{ name: "labels.home", link: "/" }],
    },
    children: [
      {
        path: "profile",
        name: "profile.get.personal.data",
        component: Profile,
        meta: {
          title: "Perfil",
          requiresAuth: true,
          breadcrumb: [
            { name: "labels.home", link: "/" },
            { name: "labels.profile" },
          ],
        },
      },
      {
        path: "blank",
        name: "Blank",
        component: Blank,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "components",
        name: "Components",
        component: Components,
        meta: {
          title: "Componentes",
          requiresAuth: true,
          breadcrumb: [
            { name: "labels.home", link: "/" },
            { name: "labels.component" },
          ],
        },
      },
      {
        path: "sub-menu-1",
        name: "Sub Menu 1",
        component: SubMenu,
        meta: {
          title: "Sub Menu",
          requiresAuth: true,
        },
      },
      {
        path: "sub-menu-2",
        name: "Sub Menu 2",
        component: Blank,
        meta: {
          title: "Sub Menu 2",
          requiresAuth: true,
        },
      },
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
          requiresAuth: true,
          breadcrumb: [{ name: "labels.home", link: "/" }],
        },
      },
      /* ADMINISTRATION */
      {
        path: "/administration",
        name: "administration",
        component: AdminMain,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "settings",
            component: AdminDashboard,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration" },
              ],
            },
          },
          {
            path: "integration",
            name: "integration.edit",
            component: Integration,
            meta: {
              requiresAuth: true,
              title: "Integrações",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.integration" },
              ],
            },
          },
          {
            path: "programs",
            name: "Programas",
            component: Program,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.program" },
              ],
            },
          },
          {
            path: "menu",
            name: "navigation.list",
            component: Menu,
            meta: {
              requiresAuth: true,
              title: "Menu",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.menu" },
              ],
            },
          },
          {
            path: "country",
            name: "country.list",
            component: Country,
            meta: {
              requiresAuth: true,
              title: "Menu",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.country" },
              ],
            },
          },
          {
            path: "niche",
            name: "niche.list",
            component: Niche,
            meta: {
              requiresAuth: true,
              title: "Nicho",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.niche" },
              ],
            },
          },
          {
            path: "roles",
            name: "role.list",
            component: Role,
            meta: {
              requiresAuth: true,
              title: "Papel",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.role" },
              ],
            },
          },
          {
            path: "person-type",
            name: "person.type.list",
            component: PersonType,
            meta: {
              requiresAuth: true,
              title: "Tipo de pessoa",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.personType" },
              ],
            },
          },
          {
            path: "user",
            name: "user.list",
            component: User,
            meta: {
              requiresAuth: true,
              title: "Usuários",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.user" },
              ],
            },
          },
          {
            path: "system-information",
            name: "system.information",
            component: SystemInformation,
            meta: {
              requiresAuth: true,
              title: "System Infos",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.administration", link: "/administration" },
                { name: "labels.systemInformation" },
              ],
            },
          },
        ],
      },
      /* REGISTRATION */
      {
        path: "/registration",
        name: "pessoa",
        component: Registration,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            name: "person.list",
            path: "people",
            component: People,
            meta: {
              requiresAuth: true,
              title: "Pessoas",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.people" },
              ],
            },
          },
          {
            name: "person.list.paginated.contact",
            path: "contact",
            component: PersonContact,
            meta: {
              requiresAuth: true,
              title: "Contatos",
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.contact" },
              ],
            },
          },
        ],
      },
      /* FINANCE */
      {
        path: "/finance",
        name: "finance.",
        component: FinanceMain,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            name: "finance.dashboard",
            path: "dashboard",
            component: FinanceDashboard,
            meta: {
              title: "Painel Geral",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.accountPlan" },
              ]
            },
          },
          {
            name: "finance.account.plan.tree",
            path: "account-plan",
            component: AccountPlan,
            meta: {
              title: "Plano de Contas",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.accountPlan" },
              ]
            },
          },
          {
            name: "finance.cost.center.tree",
            path: "cost-center",
            component: CostCenter,
            meta: {
              title: "Centros de Custos",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.costCenter" },
              ]
            }
          },
          {
            name: "finance.account.status.list",
            path: "status",
            component: FinanceAccountStatus,
            meta: {
              title: "Estados",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.financeAccountStatus" },
              ]
            }
          },
          {
            name: "finance.bank.account.type.list",
            path: "bank-account-type",
            component: FinanceBankAccountType,
            meta: {
              title: "Tipo de Conta Bancária",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.accountType" },
              ]
            }
          },
          {
            name: "finance.movement.type.list",
            path: "movement-type",
            component: FinanceMovement,
            meta: {
              title: "Tipo de Movimento",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.movementType" },
              ]
            }
          },
          {
            name: "finance.banks.list",
            path: "bank",
            component: FinanceBank,
            meta: {
              title: "Instituições Bancárias",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.bank" },
              ]
            }
          },
          {
            name: "finance.bank.account.list",
            path: "bank-account",
            component: FinanceBankAccount,
            meta: {
              title: "Conta Bancária",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.bankAccount" },
              ]
            }
          },
          {
            name: "finance.accounts.to.pay.list",
            path: "bills-to-pay",
            component: FinanceBillsToPay,
            meta: {
              title: "Contas a Pagar",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.finance", link: "/" },
                { name: "labels.billsToPay" },
              ]
            }
          },
          {
            name: "finance.accounts.to.receive.list",
            path: "bills-to-receive",
            component: FinanceBillsToReceive,
            meta: {
              title: "Contas a Receber",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.finance", link: "/" },
                { name: "labels.billsToReceive" },
              ]
            }
          }
        ]
      },
      /* SERVICE */
      {
        path: "/service",
        name: "services",
        component: ServicesMain,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "registration",
            name: "services.registration",
            component: ServiceRegistrationView,
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: "type",
                name: "services.registration.servicetype.list",
                component: ServiceType,
                meta: {
                  title: "Tipo de Serviços",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/service" },
                    { name: "labels.registration", link: "/registration" },
                    { name: "labels.typeOfService" },
                  ],
                },
              },
              {
                path: "service",
                name: "services.registration.service.list",
                component: Service,
                meta: {
                  title: "Serviços",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/service" },
                    { name: "labels.registration", link: "/registration" },
                    { name: "labels.service" },
                  ],
                },
              },
            ]
          },
          /* CONTRACT */
          {
            path: "contract",
            name: "contracts",
            component: ServicesMain,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "type",
                name: "services.contracts.contracttype.list",
                component: ContractType,
                meta: {
                  title: "Tipos de Contrato",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.contract", link: "/" },
                    { name: "labels.type", link: "" },
                  ],
                },
              },
              {
                path: "contract",
                name: "contracts.contract",
                component: ContractView,
                meta: {
                  title: "Contratos",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.contract", link: "/contract", },
                    { name: "labels.contract" },
                  ],
                },
                children: [
                  {
                    path: "",
                    name: "services.contracts.contract.list",
                    component: ContractGrid,
                    meta: {
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.contract" },
                      ],
                    },
                  },
                  {
                    path: "create",
                    name: "services.contracts.contract.insert",
                    component: ContractInsert,
                    meta: {
                      title: "Cadastrar Contrato",
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.register" },
                      ],
                    },
                  },
                  {
                    path: ":id",
                    name: "services.contracts.contract.edit",
                    component: ContractInsert,
                    meta: {
                      title: "Editar Contrato",
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.update" },
                      ],
                    },
                  },
                  {
                    path: "dashboard",
                    name: "services.contracts.contract.dashboard.data",
                    component: ContractDashboard,
                    meta: {
                      title: "Painel de Contratos",
                      requiresAuth: true,
                      breadcrumb: [
                        { name: 'labels.service', link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.contract" },
                      ],
                    },
                  },
                ]
              },
              {
                path: "invoice",
                name: "services.contracts.contract.for.invoice",
                component: ContractInvoice,
                meta: {
                  title: "Faturas",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.contract", link: "/" },
                    { name: "labels.type", link: "" },
                  ],
                },
              },
            ],
          },
          /* INVOICE */
          {
            path: "invoice",
            name: "invoices",
            component: InvoiceView,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: "invoice",
                name: "services.invoice",
                component: InvoiceView,
                meta: {
                  title: "Listagem de Faturas Geradas",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.invoice", link: "/invoice", },
                    { name: "labels.invoice" },
                  ],
                },
                children: [
                  {
                    path: "",
                    name: "services.invoice.invoice.list",
                    component: InvoiceGrid,
                    meta: {
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.invoice", link: "/invoice/dashboard", },
                        { name: "labels.invoice" },
                      ],
                    },
                  },
                  {
                    path: "put-to-bills",
                    name: "services.invoice.invoice.to.bills",
                    component: InvoiceBills,
                    meta: {
                      title: "Gerar Contas a Receber",
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.register" },
                      ],
                    },
                  },
                  {
                    path: "dashboard",
                    name: "services.invoice.invoice.dashboard",
                    component: InvoiceDashboard,
                    meta: {
                      title: "Painel de Faturas",
                      requiresAuth: true,
                      breadcrumb: [
                        { name: "labels.service", link: "/" },
                        { name: "labels.contract", link: "/contract", },
                        { name: "labels.contract" },
                      ],
                    },
                  },

                ]
              },
            ],
          },
          /* ISSUE */
          {
            path: 'issue',
            name: 'services.issue.',
            component: IssueView,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: '',
                name: 'services.issue.issue.list',
                component: Issue,
                meta: {
                  title: "Todos os Tickets",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.issue", link: "/issue/issue", },
                    { name: "labels.issue" },
                  ],
                },
              },
              {
                path: 'mine',
                name: 'services.issue.my.issue.list',
                component: MyIssue,
                meta: {
                  title: "Meus Tickets",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/issue", },
                    { name: "labels.issue" },
                  ],
                },
              },
              {
                path: 'category',
                name: 'services.issue.category.list',
                component: IssueCategory,
                meta: {
                  title: "Categoria de Tickets",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/category", },
                    { name: "labels.category" },
                  ],
                },
              },
              {
                path: 'state',
                name: 'services.issue.state.list',
                component: IssueState,
                meta: {
                  title: "Estado de Tickets",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/state", },
                    { name: "labels.state" },
                  ],
                },
              },
              {
                path: 'priority',
                name: 'services.issue.priority.list',
                component: IssuePriority,
                meta: {
                  title: "Prioridade de Tickets",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/priority", },
                    { name: "labels.priority" },
                  ],
                },
              },
              {
                path: 'problem-type',
                name: 'services.issue.problemtype.list',
                component: IssueProblemType,
                meta: {
                  title: "Tipo de Problema",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/problem-type", },
                    { name: "labels.problemType" },
                  ],
                },
              },
              {
                path: 'solution-type',
                name: 'services.issue.solutiontype.list',
                component: IssueSolutionType,
                meta: {
                  title: "Tipos de Soluções",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/solution-type", },
                    { name: "labels.solutionType" },
                  ],
                },
              },
              {
                path: 'notification/:id',
                name: 'services.issue.notification.get',
                component: IssueNotification,
                meta: {
                  title: "Notificação do Ticket",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.home", link: "/" },
                    { name: "labels.issue", link: "/issue/notification", },
                    { name: "labels.notification" },
                  ],
                },
              }
            ]
          },
          /* PROJECT */
          {
            path: 'project',
            name: 'services.project.',
            component: ProjectView,
            meta: {
              requiresAuth: true,
            },
            children: [
              {
                path: 'state',
                name: 'services.project.state.list',
                component: ProjectState,
                meta: {
                  title: "Estado do Projeto",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.project", link: "/project/state", },
                    { name: "labels.state" },
                  ],
                },
              },
              {
                path: '',
                name: 'services.project.project.list',
                component: Project,
                meta: {
                  title: "Todos os Projetos",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.project", link: "/project", },
                    { name: "labels.project" },
                  ],
                },
              },
              {
                path: 'create',
                name: 'services.project.project.insert',
                component: ProjectInsert,
                meta: {
                  title: "Cadastro de Projetos",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.project", link: "/project", },
                    { name: "labels.create" },
                  ],
                },
              },
              {
                path: ':id',
                name: 'services.project.project.edit',
                component: ProjectInsert,
                meta: {
                  title: "Editar Projeto",
                  requiresAuth: true,
                  breadcrumb: [
                    { name: "labels.service", link: "/" },
                    { name: "labels.project", link: "/project", },
                    { name: "labels.create" },
                  ],
                },
              },
            ]
          },
        ],
      },

      /* COMPONENTS */
      {
        path: "/components",
        name: "Component",
        component: Components,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "general",
            name: "General",
            component: General,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.general" },
              ],
            },
          },
          {
            path: "widgets",
            name: "Widgets",
            component: Widgets,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.widget" },
              ],
            },
          },
          {
            path: "charts",
            name: "Charts",
            component: Charts,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.chart" },
              ],
            },
          },
          {
            path: "buttons",
            name: "Buttons",
            component: Buttons,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.button" },
              ],
            },
          },
          {
            path: "sliders",
            name: "Sliders",
            component: Sliders,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.slider" },
              ],
            },
          },
          {
            path: "modals",
            name: "Modals",
            component: Modals,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.modal" },
              ],
            },
          },
          {
            path: "timelines",
            name: "Timelines",
            component: Timeline,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.timeline" },
              ],
            },
          },
          {
            path: "general-elements",
            name: "GeneralElements",
            component: GeneralElements,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.generalElement" },
              ],
            },
          },
          {
            path: "advanced-elements",
            name: "AdvancedElements",
            component: AdvancedElements,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.advancedElement" },
              ],
            },
          },
          {
            path: "editors",
            name: "Editor",
            component: Editor,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.editor" },
              ],
            },
          },
          {
            path: "tables",
            name: "Tables",
            component: Tables,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general", },
                { name: "labels.table" },
              ],
            },
          },
          {
            path: "calendar",
            name: "Calendars",
            component: Calendars,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.calendar" },
              ],
            },
          },
          {
            path: "kanban",
            name: "Kanban",
            component: Kanban,
            meta: {
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.component", link: "/components/general" },
                { name: "labels.kanban" },
              ],
            },
          },
        ],
      },
      /* LOGS */
      {
        path: "/log",
        name: "Log",
        component: LogMain,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "LogDashboard",
            component: LogDashboard,
            meta: {
              title: "Painel de Logs",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.log" },
              ],
            },
          },
          {
            path: "access",
            name: "log.access.list",
            component: Access,
            meta: {
              title: "Logs de Acesso",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.log", link: "/log" },
                { name: "labels.access" },
              ],
            },
          },
          {
            path: "database",
            name: "log.sql.list",
            component: Database,
            meta: {
              title: "Logs de banco de dados",
              requiresAuth: true,
              breadcrumb: [
                { name: "labels.home", link: "/" },
                { name: "labels.log", link: "/log" },
                { name: "labels.database" },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
      requiresUnauth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/new-password/:token",
    name: "RecoverPassword",
    component: RecoverPassword,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const pageTitle: string = (to.meta.title as string) || 'SOLLUZI';
  document.title = pageTitle;
  if (to.meta.requiresAuth && !store.getters["token"]) {
    next("/login");
  } else if (to.meta.requiresUnauth && !!store.getters["token"]) {
    next("/");
  } else {
    next();
  }
});

export default router;
