<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.systemInformation") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-8">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            MÉMORIA RAM
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="table-responsive text-nowrap">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Tipo</th>
                                        <th scope="col">Usando/Total</th>
                                        <th scope="col">Disponível</th>
                                        <th scope="col">Percentual usado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in ram" :key="index">
                                        <td scope="row">{{ item.type }}</td>
                                        <td><b>{{ item.used }} MB </b>/ {{ item.total }}</td>
                                        <td>{{ item.free }}</td>
                                        <td>
                                            <app-progress-bar :bgcolor="'#dc3545'" :completed="item.percent_used" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            CPU
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="d-flex justify-content-center">
                            <app-progress-circle :percent="cpu.percentage" :size="146" :viewport="true" :show-percent="true" />
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
        </div>

        <app-panel-secondary class="card-secondary">
            <template v-slot:header>
                <h3 class="card-title">CPU</h3>
            </template>
            <template v-slot:content>
                <div class="table-responsive text-nowrap">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Fornecedor</th>
                                <th scope="col">Modelo</th>
                                <th scope="col">Frequência atual</th>
                                <th scope="col">Percentual usado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{{ cpu.manufacturer }}</th>
                                <td>{{ cpu.model }}</td>
                                <td>{{ cpu.frequency }}</td>
                                <td>
                                    <app-progress-bar :bgcolor="'#dc3545'" :completed="cpu.percentage" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </app-panel-secondary>

        <div class="row">
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            INFORMAÇÕES DO SISTEMA
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="table-responsive text-nowrap">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th colspan="2">System</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Operating system</th>
                                        <td>{{ info.os }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Distribution</th>
                                        <td>{{ info.distro }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Accessed IP</th>
                                        <td colspan="2">{{ info.ip }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Load average</th>
                                        <td colspan="2">{{ info.load }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
            <div class="col-sm">
                <app-panel-secondary class="card-secondary">
                    <template v-slot:header>
                        <h3 class="card-title">
                            SERVIDOR WEB E PROCESSOS
                        </h3>
                    </template>
                    <template v-slot:content>
                        <div class="table-responsive text-nowrap">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" colspan="2">Info</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Uptime</th>
                                        <td>{{ webServer.uptime }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">HTTP server</th>
                                        <td>{{ webServer.http_server }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">PHP version</th>
                                        <td colspan="2">{{ webServer.php_version }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Process</th>
                                        <td colspan="2">{{ webServer.process_info }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </app-panel-secondary>
            </div>
        </div>
    </div>
    <app-panel-secondary class="card-secondary">
        <template v-slot:header>
            <h3 class="card-title">
                DISPOSITIVOS DE DISCO
            </h3>
        </template>
        <template v-slot:content>
            <div class="table-responsive text-nowrap">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Dispositivo</th>
                            <th scope="col">Ponto de mensagem</th>
                            <th scope="col">Sistema de arquivos</th>
                            <th scope="col">Usando/Total</th>
                            <th scope="col">Disponível</th>
                            <th scope="col">Percentual usado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in hardDisk" :key="index">
                            <td scope="row">{{ item.device }}</td>
                            <td>{{ item.mount }}</td>
                            <td>{{ item.filesystem }}</td>
                            <td><b>{{ item.in_use }}</b> / {{ item.total }}</td>
                            <td>{{ item.available }}</td>
                            <td>
                                <app-progress-bar :bgcolor="'#dc3545'" :completed="item.used_percentage" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </app-panel-secondary>

    <app-panel-secondary class="card-secondary">
        <template v-slot:header>
            <h3 class="card-title">
                DISPOSITIVOS DE REDE
            </h3>
        </template>
        <template v-slot:content>
            <div class="table-responsive text-nowrap">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Nome do dispositivo</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Velocidade da porta</th>
                            <th scope="col">Enviados</th>
                            <th scope="col">Recebidos</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in networkDevice" :key="index">
                            <td scope="row">{{ index }}</td>
                            <td>{{ item.type }}</td>
                            <td>{{ item.speed }}</td>
                            <td>{{ item.sent }}</td>
                            <td>{{ item.recv }}</td>
                            <td>{{ item.status }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </app-panel-secondary>
    <!-- /.card -->
</section>
<!-- /.content -->