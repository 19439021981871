import { ContractData, ContractObject } from "@/@types";

export type State = {
  data: ContractObject | null;
  contractData: ContractData;
};

export const state: State = {
  data: null,
  contractData: [],
};
