<div>
    <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    &nbsp;
                </h3>
            </template>
            <template v-slot:content>
                <div class="row mb-3" id="cliente">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            {{ formSearchLabel.company }}:
                        </label>
                    </div>
                    <div class="col-sm-8">
                        <Select2 
                            v-model="formSearch.company" 
                            :options="companies" 
                            :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" 
                        />
                    </div>
                </div>
                
                <div class="row mb-3" id="active">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="mt-1">
                            Mês:
                        </label>
                    </div>
                    <div class="col mt-1">
                        <app-iradiobox 
                            v-for="months in monthsItems" :value="months.value" 
                            name="month"
                            v-model="formSearch.month" 
                            :checked="formSearch.month === months.value"
                        >
                            {{ months.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
                <div class="row mb-3" id="anos">
                    <div class="col-sm-2 text-right">
                        <label for="Year" class="">
                            Ano:
                        </label>
                    </div>
                    <div class="col-sm-6">
                        <app-iradiobox 
                            v-for="years in yearsItems" :value="years.value" 
                            name="year"
                            v-model="formSearch.year" 
                            :checked="formSearch.year === years.value"
                        >
                            {{ years.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button 
                    type="button" 
                    class="btn btn-default rounded-0" 
                    id="open" 
                    @click="onNew" 
                    v-if="visibility('person.type.insert') && newRecord"
                >
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    {{ title }}
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows"
                    table-class="table shadow table-striped">
                    <template v-slot:cell-id="{ row: account }">
                        <a 
                            href="javascript:;" 
                            @click="edit(account.id)"
                            v-if="visibility('finance.bank.account.edit')"
                        >
                            <i class="far fa-edit blue"></i>
                        </a>
                        &nbsp;
                        <a 
                            href="javascript:;" 
                            @click="generateCharge(account.id, account.rpdate)"
                            v-if="visibility('finance.bank.account.edit') && accountType === 'R'"

                        >
                            <i class="fas fa-money-bill-alt fa-lg" style="color: #57c193;" v-if="account.asaas"></i>
                            <i class="fas fa-money-bill-alt fa-lg" style="color: #aba6a6;" v-if="!account.asaas"></i>
                        </a>
                    </template>
                    <template v-slot:cell-situation="{ row: account }">
                        <h5 v-if="account.rpdate !== null">
                            <span class="badge rounded-0"  :style="`background-color: ${account.color};`">
                                {{ account.rpdate }}
                            </span>
                        </h5>
                        <h5 v-else-if="account.rpdescription">
                            <span class="badge rounded-0" :style="`background-color: ${account.color};`">
                                {{ account.rpdescription }}
                            </span>
                        </h5>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination 
                    :total-pages="totalPages" 
                    :total="total" 
                    :per-page="perPage" 
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
</div>