/* eslint-disable */
import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import JwtService from "./JwtService";
import { isTokenExpired } from "@/classes/jwtHelper";
import router from "@/router";
import { store } from "@/store";
import { SignInActionsTypes } from "@/store/auth/action-types";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    ApiService.vueInstance.axios.interceptors.request.use( (config) => {
        config.url = config.url?.replace(/\/$/, '');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    ApiService.vueInstance.axios.interceptors.response.use((response) => {
      
      if(response.headers.token) {
        JwtService.saveToken(response.headers.token);
      }
      
      const token = JwtService.getToken();

      if (isTokenExpired(token)){
        localStorage.clear();
        store.dispatch(SignInActionsTypes.setToken, null);
      }

      return response;
    }, error => {
      if(error.response.status == 401) {
        localStorage.clear();
        store.dispatch(SignInActionsTypes.setToken, {hash: null});  
        router.replace('/login');      
      }
      return Promise.reject(error);
    });
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    //ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
  }

  public static urlEncoded(): void {
    ApiService.vueInstance.axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
  }

  public static formData(): void {
    ApiService.vueInstance.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  }

  public static json(): void {
    ApiService.vueInstance.axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
