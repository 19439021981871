/* eslint-disable */
import { IssueData, IssueObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueMutationTypes.SET_INSERT_ISSUE_DATA](
    state: S,
    payload: IssueObject
  ): void;
  [IssueMutationTypes.SET_ISSUE_DATA](
    state: S,
    payload: IssueData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueMutationTypes.SET_INSERT_ISSUE_DATA](
    state: State,
    issue: IssueObject
  ) {
    state.data = issue;
  },
  [IssueMutationTypes.SET_ISSUE_DATA](
    state: State,
    issueData: IssueData
  ) {
    state.issueData = issueData;
  },
};
