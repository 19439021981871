<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.contract", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <form action="javascript:;" @submit.prevent="onCreate">
            <app-panel class="card-warning">
                <template v-slot:header>
                    <h3 class="card-title">
                        Formulário de contratos
                    </h3>
                </template>
                <template v-slot:content>
                    <div class="row mb-3" id="cliente">
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin">
                                Cliente:
                            </label>
                        </div>
                        <div class="col-sm-4">
                            <Select2 v-model="v$.form.client.$model" :options="clients" :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                            <input type="hidden" name="id" v-model="form.id" />
                            <span v-if="checkArray(v$.form.client.$errors)">
                                <small id="emailHelp" class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.client.$errors" :key="index">
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <app-input-inline label-for="Nº do Contrato" label="Nº do Contrato:" name="contractNumber"
                        type="text" place-holder="" v-model="v$.form.contractNumber.$model" input-id="text"
                        :has-error="v$.form.contractNumber.$error" :is-active="v$.form.contractNumber.$error"
                        :error="v$.form.contractNumber.$errors" div-class="col-sm-3" label-class="col-sm-2 text-right">
                    </app-input-inline>
                    <div class="row mb-3" id="contractType">
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin">
                                Tipo de contrato:
                            </label>
                        </div>
                        <div class="col-sm-2">
                            <Select2 v-model="v$.form.type.$model" :options="contractTypes" :settings="{ 
                                dropdownParent: '#contractType', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                            <span v-if="checkArray(v$.form.type.$errors)">
                                <small id="emailHelp" class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.type.$errors" :key="index">
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3" id="active">
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin">
                                Ativo:
                            </label>
                        </div>
                        <div class="col mt-2">
                            <app-iradiobox v-for="item in activeItems" :value="item.value" name="active"
                                v-model="v$.form.active.$model" :checked="form.active === item.value">
                                {{ item.text }} &nbsp;
                            </app-iradiobox>
                            <span v-if="checkArray(v$.form.active.$errors)">
                                <small id="emailHelp" class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.active.$errors" :key="index">
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin mt-3">
                                Data de inicio:
                            </label>
                        </div>
                        <div class="col mt-2">
                            <VueDatePicker v-model="v$.form.startDate.$model" :format="dueDateInputOptions"
                                locale="pt-BR" :clearable="true" auto-apply :min-date="new Date()"
                                :enable-time-picker="false" @keypress="validate('startDate')"
                                @blur="validate('startDate')" @change="validate('startDate')">
                            </VueDatePicker>
                            <span v-if="checkArray(v$.form.startDate.$errors)">
                                <small id="emailHelp" class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.startDate.$errors" :key="index">
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin mt-3">
                                Fim:
                            </label>
                        </div>
                        <div class="col mt-2">
                            <VueDatePicker v-model="v$.form.endDate.$model" :format="dueDateInputOptions" locale="pt-BR"
                                :clearable="true" auto-apply :min-date="new Date()" :enable-time-picker="false">
                            </VueDatePicker>
                            <span v-if="checkArray(v$.form.endDate.$errors)">
                                <small id="emailHelp" class="form-text text-danger"
                                    v-for="(errorMessage, index) of v$.form.endDate.$errors" :key="index">
                                    {{ errorMessage.$message }}
                                </small>
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3" id="obs">
                        <div class="col-sm-2 text-right">
                            <label for="Responsável" class="bills-to-pay-label-margin">
                                Obs:
                            </label>
                        </div>
                        <div class="col mt-2">
                            <textarea name="observation" v-model="form.form.obs" id="" cols="30" rows="5"
                                class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="bg-gray">
                        <h4 class="ml-1 mt-1 mb-1">Itens do contrato</h4>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="50%">Serviço</th>
                                <th>Valor</th>
                                <th colspan="2">Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td :id="'service-'+index">
                                    <Select2 v-model="item.service" :options="services"
                                        @select="getServiceValue(index, $event)" :settings="{ 
                                    dropdownParent: '#service-'+index, 
                                    width: '100%',
                                    multiple: false,
                                    allowClear: true
                                }" />
                                </td>
                                <td>
                                    <money3 v-model="item.value" v-bind="configValor"
                                        class="form-control rounded-0 input-money" />
                                </td>
                                <td>
                                    <input type="number" name="quantity" v-model="item.quantity"
                                        class="form-control text-right" min="0" />
                                </td>
                                <td class="text-right" width="1%">
                                    <button type="button" class="btn btn-default rounded-0 btn-sm text-danger mt-1"
                                        @click="remove(index)">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" class="text-right">
                                    <button type="button"
                                        class="btn btn-default rounded-0 btn-sm text-success pull-right"
                                        @click="addMore()">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </template>
                <template v-slot:footer>
                    <a href="/service/contract/contract" class="btn btn-warning shadow rounded-0">
                        <i class="fa fa-arrow-left"></i>
                        Voltar
                    </a>
                    <app-button type="submit" class="btn btn-success ml-2 shadow rounded-0">
                        <i class="fa fa-save warning"></i>
                        Salvar
                    </app-button>
                </template>
            </app-panel>
        </form>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->