<ol class="breadcrumb" :class="class">
    <li 
        v-for="(breadcrumb, index) in breadcrumb.route" 
        :key="index" 
        @click="routeTo(index)"
        :class="{'active': !breadcrumb.link}" 
        class="breadcrumb-item"
    >
        <a :href="breadcrumb.link" v-if="breadcrumb.link">{{ $t(breadcrumb.name) }} </a>
        <span v-else>{{ $t(breadcrumb.name) }}</span>
    </li>
</ol>