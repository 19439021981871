/* eslint-disable */
import { MenuObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { MenuActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [MenuActionTypes.ALL_NAVIGATION]({ commit, }: AugmentedActionContext): Promise<any>;
  [MenuActionTypes.TREE_NAVIGATION]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [MenuActionTypes.LIST_PROGRAM_NAVIGATION]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [MenuActionTypes.CREATE_NAVIGATION]( { commit }: AugmentedActionContext, params: MenuObject ): Promise<boolean>;
  [MenuActionTypes.EDIT_NAVIGATION]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [MenuActionTypes.UPDATE_NAVIGATION]( { commit }: AugmentedActionContext, params: MenuObject ): Promise<any>;
  [MenuActionTypes.DELETE_NAVIGATION]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MenuActionTypes.ALL_NAVIGATION]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/navigation/all").then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [MenuActionTypes.LIST_PROGRAM_NAVIGATION]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        description: params.description
      }
      ApiService.post(`/system/navigation/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [MenuActionTypes.TREE_NAVIGATION]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/system/navigation/tree").then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [MenuActionTypes.CREATE_NAVIGATION]({ commit }, params: MenuObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        parent   : params.parent,
        name     : params.name,
        text     : params.text,
        uri_name : params.uri_name,
        namespace: params.class_namespace,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/navigation", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [MenuActionTypes.EDIT_NAVIGATION]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/navigation/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [MenuActionTypes.UPDATE_NAVIGATION]({ commit }, params: MenuObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        parent   : params.parent,
        name     : params.name,
        text     : params.text,
        uri_name : params.uri_name,
        namespace: params.class_namespace,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/system/navigation/${params.id}`, payload).then(({ data }) => {
          const result = data.data;
          resolve(result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [MenuActionTypes.DELETE_NAVIGATION]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/navigation/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
