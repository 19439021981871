<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.role") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar papeis
                </h3>
            </template>
            <template v-slot:content>
                <app-input-default label-for="Descrição" label="Descrição" input-name="descriptionSearch"
                    input-type="text" place-holder="Papel desempenhdo (ex: Administrador)"
                    v-model="formSearch.description" input-id="text">
                </app-input-default>                             
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button
                    type="button" 
                    id="open" 
                    class="btn btn-default rounded-0" 
                    @click="onCurtainOpen"
                    v-if="visibility('role.insert')"
                >
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de papeis
                </h3>
            </template>
            <template v-slot:content>
                <app-table 
                    :columns="gridColumns" 
                    :rows="rows" 
                    table-class="table table-striped"
                >
                    <template v-slot:cell-id="{ row: role }">
                        <a href="javascript:;" 
                            @click="onDelete(role.id)" 
                            v-if="visibility('role.delete')"
                        >
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(role.id)" v-if="visibility('role.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination 
                    :total-pages="totalPages" 
                    :total="total" 
                    :per-page="perPage" 
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
    </div>
    <form action="javascript:;" @submit.prevent="onStore">
    <app-right-curtain right-curtain-name="right" ref="rolecurtain" card-class="card-default">
        
        <template v-slot:title>
            Formulário
        </template>
        <template v-slot:content>
            
            
            <div class="container">
                <input type="hidden" name="id" v-model="formCreate.id" />
                <app-input-default 
                    label-for="Descrição" 
                    label="Descrição"
                    input-name="descriptionCreate" 
                    input-type="text" 
                    place-holder="Papel"
                    v-model="v$.form.description.$model" 
                    input-id="text" 
                    :has-error="v$.form.description.$error"
                    :is-active="v$.form.description.$error" 
                    :error="v$.form.description.$errors"
                >
                </app-input-default>
                <hr>
                <div id="permission-container" style="font-size: 1rem!important;"></div>
            </div>
            
        </template>
        <template v-slot:footer>
            <app-button 
                type="submit" 
                class="btn btn-default rounded-0"
                :disabled="v$.form.$invalid"
            >
                <i class="fa fa-save"></i>
                Gravar
            </app-button>
        
        </template>
   
    </app-right-curtain>
</form>   
    <!-- /.card -->
</section>
<!-- /.content -->