<div id="accordion">
    <div 
        class="card"
        :class="cardClass"
    >
        <div class="card-header">
            <h4 class="card-title w-100">
                <a 
                    class="d-block w-100" 
                    :class="{ collapsed: !isOpen }"
                    data-toggle="collapse" 
                    @click="toggleAccordion()" 
                    :aria-expanded="isOpen"
                    :href="`#`+id"
                >
                    <slot name="title"></slot>
                </a>
            </h4>
        </div>
        <div 
            id="collapseOne" 
            class="collapse"
            :class="{ show: isOpen }"
            :data-parent="`#`+id"
        >
            <div class="card-body">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</div>