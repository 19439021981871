import { IssueCategoryData, IssueCategoryObject } from "@/@types";

export type State = {
  data: IssueCategoryObject | null;
  issueCategoryData: IssueCategoryData;
};

export const state: State = {
  data: null,
  issueCategoryData: [],
};
