import { PersonTypeData, PersonTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { PersonTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [PersonTypeMutationTypes.SET_INSERT_PERSON_TYPE_DATA](
    state: S,
    payload: PersonTypeObject
  ): void;
  [PersonTypeMutationTypes.SET_PERSON_TYPE_DATA](
    state: S,
    payload: PersonTypeData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [PersonTypeMutationTypes.SET_INSERT_PERSON_TYPE_DATA](
    state: State,
    personType: PersonTypeObject
  ) {
    state.data = personType;
  },
  [PersonTypeMutationTypes.SET_PERSON_TYPE_DATA](
    state: State,
    personTypeData: PersonTypeData
  ) {
    state.personTypeData = personTypeData;
  },
};
