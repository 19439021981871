/* eslint-disable */
import { computed, defineComponent, reactive, ref } from "vue";
import Curtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Button from "@/components/ui/button/button.vue";
import { useStore } from "vuex";
import { IssueActionTypes } from "@/store/issue/issue/action-types";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import Swal from "sweetalert2";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
    name: "IssueNoteForm",
    components: {
        "curtain": Curtain,
        "s-button": Button
    },
    setup(props, { emit }) {
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const issueInsertNote = ref();
        const noteFile = ref();

        const issueNoteForm = reactive({
            issue: "",
            observation: "",
            attachment: [],
        });

        const rules = computed(() => {
            return {
                observation: { 
                    required: helpers.withMessage("Este campo é obrigatório!", required)  
                }
            }
        });

        const v$ = useVuelidate(rules, issueNoteForm);

        const insertIssueNote = async () => {
            const result = await v$.value.$validate();
            if (!result) {
                return;
            }

            issueNoteForm.attachment = noteFile.value.files;

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(IssueActionTypes.ISSUE_POST_NOTE, issueNoteForm).then(() => {
                issueInsertNote.value.closeNav();
                emit("reload", issueNoteForm.issue);
                issueNoteForm.issue = "";
                issueNoteForm.observation = "";
                loader.hide();
                Swal.fire({
                    title: "Bom Trabalho!",
                    text: "Nota informada com sucesso!",
                    icon: "success",
                    allowOutsideClick: false,
                });
            }).catch(() => {
                loader.hide();
                Swal.fire({
                    title: "Ooops!",
                    text: "Registro não inserido, fale com o seu administrador!",
                    icon: "error",
                    allowOutsideClick: false,
                });
            });

        };

        const openIssueNoteForm = (issue: any) => {
            issueNoteForm.issue = "";
            issueNoteForm.observation = "";
            issueNoteForm.attachment = [];
            noteFile.value.value = "";
            issueNoteForm.issue = issue;
            issueInsertNote.value.openNav();
        }

        function checkArray(variable: any) {
            return Array.isArray(variable);
        }

        return {
            insertIssueNote,
            openIssueNoteForm,
            issueInsertNote,
            issueNoteForm,
            noteFile,
            v$,
            checkArray,
        };
    },
});
