import {
  UserCredentials,
  UserSignInWithCredentials,
} from "@/@types/app/SignInWithCredentials";

export type State = {
  data: UserSignInWithCredentials | null;
  userCredentials: UserCredentials;
  token: "";
};

export const state: State = {
  data: null,
  userCredentials: [],
  token: "",
};
