import { Options, Vue } from "vue-class-component";

@Options({
  name: "Paginate",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  beforeUpdate() {
    if (this.forcePage === undefined) return;
    if (this.forcePage !== this.selected) {
      this.selected = this.forcePage;
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        //return this.totalPages - this.maxVisibleButtons + 1;
        const start = this.totalPages - (this.maxVisibleButtons - 1);

        if (start === 0) {
          return 1;
        } else {
          return start;
        }
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
})
export default class Paginate extends Vue {
  maxVisibleButtons!: number;
  totalPages!: number;
  total!: number;
  currentPage!: number;

  onClickFirstPage() {
    this.$emit("pagechanged", 1);
  }

  onClickPreviousPage() {
    if (this.currentPage >= 2) {
      this.$emit("pagechanged", this.currentPage - 1);
    }
  }

  onClickPage(page: any) {
    this.$emit("pagechanged", page);
  }

  onClickNextPage() {
    const remnant = this.totalPages - this.currentPage;
    if (remnant > 0) {
      this.$emit("pagechanged", this.currentPage + 1);
    }
  }

  onClickLastPage() {
    this.$emit("pagechanged", this.totalPages);
  }

  isPageActive(page: any) {
    return this.currentPage === page;
  }
}
