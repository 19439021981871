import { Options, Vue } from "vue-class-component";

@Options({
  name: "Accordion",
  props: {
    id: {
      type: String,
    },
    cardClass: {
      type: String,
    },
  },
})
export default class Accordion extends Vue {
  isOpen = false;

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
