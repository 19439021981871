<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.solutionType") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Tipo de solução
                </h3>
            </template>
            <template v-slot:content>
                <input type="text" class="form-control col-sm-4 rounded-0" name="description"
                    v-model="formFilter.description" />
                <form action="javascript:;" @submit.prevent="onStore">
                    <app-right-curtain right-curtain-name="right" ref="formcurtain" card-class="card-default">
                        <template v-slot:title>
                            Formulário
                        </template>
                        <template v-slot:content>
                            <input type="hidden" name="id" v-model="form.id" />
                            <div class="row">
                                <div class="col-sm">
                                    <app-input-default label-for="Descrição" label="Descrição" input-name="description"
                                        input-type="text" place-holder="" v-model="v$.description.$model"
                                        input-id="text" :has-error="v$.description.$error"
                                        :is-active="!v$.description.$error"
                                        :error="v$.description.$errors"></app-input-default>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group" id="recurring">
                                        <label for="exampleInputEmail1" class="col-form-label">Ativo:</label>
                                        <s-radio-button :options="activeOptions" v-model="form.active"
                                            div-class=""></s-radio-button>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:footer>
                            <app-button type="submit" class="btn btn-default rounded-0 shadow" :disabled="v$.$invalid">
                                <i class="fa fa-save"></i>
                                Gravar
                            </app-button>
                        </template>
                    </app-right-curtain>
                </form>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onNew"
                    v-if="visibility('services.issue.solutiontype.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    &nbsp;
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: solutionType }">
                        <a href="javascript:;" @click="onDelete(solutionType.id)"
                            v-if="visibility('services.issue.solutiontype.delete')">
                            <i class="fas fa-trash-alt red"></i>
                        </a>
                        &nbsp;
                        <a href="javascript:;" @click="onEdit(solutionType.id)"
                            v-if="visibility('services.issue.solutiontype.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                    <template v-slot:cell-active="{ row: solutionType }">
                        <span v-if="solutionType.active === 'Y'">Sim</span>
                        <span v-if="solutionType.active === 'N'">Não</span>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->