<div 
    class="btn-group btn-group-toggle" 
    data-toggle="buttons"
    :class="divClass"
>
    <label class="btn s-button-group" 
        v-for="option in options" 
        :key="option.value"
        :class="{ 
            active: option.value === modelValue,
            'btn-orange': option.value === modelValue,
            'btn-default': option.value !== modelValue,
        }"
    >
        <input 
            type="radio" 
            class="form-control"
            name="options" 
            :value="option.value" 
            v-model="modelValue" 
            @input="handleOptionChange(option.value)"
            id="option1" 
            autocomplete="off" 
            :checked="option.value === modelValue"
        > 
        {{ option.label }}
    </label>
</div>