<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.myIssue") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar meus Chamado
                </h3>
            </template>
            <template v-slot:content>
                <!-- <input 
                    type="text" 
                    class="form-control rounded-0" 
                    name="description" 
                    v-model="formFilter.description"
                /> -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <input-inline label-for="Cod" label="Código:" name="code" type="text"
                                place-holder="Número do chamado" v-model="formFilter.code" input-id="text"
                                div-class="col-sm-3" label-class="col-sm-4 text-right"></input-inline>
                        </div>
                    </div>
                    <div class="col-sm-6" :class="{ hide: clientHide }">
                        <div class="form-group row" id="client">
                            <label for="inputPassword" class="col-sm-4 col-form-label text-right">Cliente:</label>
                            <div class="col-sm-8">
                                <Select2 v-model="formFilter.client" :options="clients" :settings="{ 
                                        dropdownParent: '#client', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }" @change="onClientChange" @select="onClientChange" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label text-right">Situação:</label>
                            <div class="col-sm-8">
                                <input-dropdown :items="states" v-model="formFilter.state"></input-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group row">
                            <label for="inputPassword"
                                class="col-sm-4 col-form-label text-right">Projeto - Contrato:</label>
                            <div class="col-sm-8">
                                <input-dropdown 
                                    :items="projects" 
                                    v-model="formFilter.project"
                                ></input-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label text-right">Prioridade:</label>
                            <div class="col-sm-8">
                                <input-dropdown :items="priorities" v-model="formFilter.priorities"></input-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group row">
                            <label for="inputPassword" class="col-sm-4 col-form-label text-right">Categoria:</label>
                            <div class="col-sm-8">
                                <input-dropdown :items="categories" v-model="formFilter.category"></input-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group row" id="problem-type">
                            <label for="inputPassword" class="col-sm-4 col-form-label text-right">Tipo de
                                problema:</label>
                            <div class="col-sm-8">
                                <Select2 v-model="formFilter.problemType" :options="problemTypes" :settings="{ 
                                        dropdownParent: '#problem-type', 
                                        width: '100%',
                                        multiple: false,
                                        allowClear: true
                                    }" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                </div>
                <div class="row">

                </div>
                <div class="row">

                    <div class="col-sm">

                    </div>
                </div>

            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onNew"
                    v-if="visibility('services.issue.issue.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    &nbsp;
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: issue }">
                        <div class="row">
                            <div class="col-sm-3" v-if="visibility('services.issue.issue.edit') && (issue.closingDate === null)">
                                <tooltip>
                                    <template #content>
                                        <a href="javascript:;" @click="onEdit(issue.id)">
                                            <i class="far fa-edit blue"></i>
                                        </a>
                                    </template>
                                    <template #content-text>
                                        Editar
                                    </template>
                                </tooltip>
                            </div>
                            <div class="col-sm-3" v-if="visibility('services.issue.view.get')">
                                <tooltip>
                                    <template #content>
                                        <a href="javascript:;" @click="onView(issue.id)">
                                            <i class="fas fa-eye success"></i>
                                        </a>
                                    </template>
                                    <template #content-text>
                                        Visualizar
                                    </template>
                                </tooltip>
                            </div>
                            <div class="col-sm-3">
                                <tooltip>
                                    <template #content>
                                        <div :style="`background-color: ${issue.priority}; `" class="issue-priority"
                                            title="" :data-original-title="issue.priorityText"></div>
                                    </template>
                                    <template #content-text>
                                        {{ issue.priorityText }}
                                    </template>
                                </tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell-code="{ row: issue }">
                        #{{ issue.code }}
                    </template>
                    <template v-slot:cell-situation="{ row: issue }">
                        <div class="label text-center label-issue-situation text-bold"
                            :style="`background: ${issue.situationColor} ;`">
                            {{ issue.situation }}
                        </div>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->
<issue-edit-form ref="issueEditForm" @reload="onList" v-if="displayComponent === 'issueEditForm'" />
<issue-viewer ref="issueViewer" />
<issue-create-form ref="issueCreateForm" v-if="displayComponent === 'issueCreateForm'" @reload="onList" />