import { ProjectStateData, ProjectStateObject } from "@/@types";

export type State = {
  data: ProjectStateObject | null;
  projectStateData: ProjectStateData;
};

export const state: State = {
  data: null,
  projectStateData: [],
};
