/* eslint-disable */
import { ContractTypeData, ContractTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { ContractTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ContractTypeMutationTypes.SET_CONTRACT_TYPE_DATA_INSERT]( state: S, payload: ContractTypeObject ): void;
  [ContractTypeMutationTypes.SET_CONTRACT_TYPE_DATA]( state: S, payload: ContractTypeData ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ContractTypeMutationTypes.SET_CONTRACT_TYPE_DATA_INSERT]( state: State, contractType: ContractTypeObject ) {
    state.data = contractType;
  },
  [ContractTypeMutationTypes.SET_CONTRACT_TYPE_DATA]( state: State, contractTypeData: ContractTypeData ) {
    state.contractTypeData = contractTypeData;
  },
};
