/* eslint-disable */
import { FinanceBankAccountTypeData, FinanceBankAccountTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceBankAccountTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceBankAccountTypeMutationTypes.SET_INSERT_FINANCE_BANK_ACCOUNT_TYPE_DATA](state: S, payload: FinanceBankAccountTypeObject): void;
  [FinanceBankAccountTypeMutationTypes.SET_FINANCE_BANK_ACCOUNT_TYPE_DATA](state: S, payload: FinanceBankAccountTypeData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceBankAccountTypeMutationTypes.SET_INSERT_FINANCE_BANK_ACCOUNT_TYPE_DATA](state: State, financeBankAccountType: FinanceBankAccountTypeObject) {
    state.financeBankAccountType = financeBankAccountType;
  },
  [FinanceBankAccountTypeMutationTypes.SET_FINANCE_BANK_ACCOUNT_TYPE_DATA](state: State, financeBankAccountTypeData: FinanceBankAccountTypeData) {
    state.financeBankAccountTypeData = financeBankAccountTypeData;
  },
};
