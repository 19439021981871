import { IssuePriorityData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getIssuePriority(state: State): IssuePriorityData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIssuePriority: (state) => state.issuePriorityData,
};
