/* eslint-disable */


import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Iradiobox from "@/components/form/iradio/iradio.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { ContractActionTypes } from "@/store/services/contract/action-types";
import inputInline from "@/components/form/input-inline/input-inline.vue";
import { useRoute } from "vue-router";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "ContractInsert",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-button": Button,
    "app-iradiobox": Iradiobox,
    "app-input-inline": inputInline,
  },
  setup() {
    const form: any = ref({
      form: {
        id: "",
        client: "",
        type: "",
        contractNumber: "",
        active: 'N',
        startDate: "",
        endDate: "",
        services: {},
        obs: ""
      }
    });

    const rules = computed(() => {
      return {
        form: {
          client: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          type: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          contractNumber: {
            required: helpers.withMessage("Este Campo é Obrigatório", required),
          },
          active: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          startDate: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          },
          endDate: {
            required: helpers.withMessage("Este campo é obrigatório!", required)
          }
        }
      }
    });

    const v$ = useVuelidate(rules, form);

    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const contractTypes = ref({});
    const clients = ref({});
    const services = ref({});
    const route = useRoute();

    const activeItems = ref([
      { text: "Sim", value: 'Y' },
      { text: "Não", value: 'N' },
    ]);

    const items: any = ref([{
      service: "",
      value: "",
      quantity: ""
    }]);

    const configValor = computed(() => ({
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      disableNegative: false,
      disabled: false,
      min: null,
      max: null,
      allowBlank: false,
      minimumNumberOfCharacters: 0,
      shouldRound: true,
      focusOnRight: true,
    }));

    onMounted(() => {
      onGetContractType();
      onGetClents();
      onGetService();
      onEdit();
    });

    const dueDateInputOptions = (date: any) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const formattedDate = new Date(year, month - 1, day);
      const formattedString = formattedDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });

      return formattedString;
    };

    const onCreate = async () => {
      if (route.params.id !== undefined && route.params.id !== "") {
        onUpdate();
      } else {

        const result = await v$.value.$validate();
        if (!result) {
          return;
        };

        const loader = $loading.show({
          loader: "dots",
        });

        form.value.form.services = items.value;
        store.dispatch(ContractActionTypes.CONTRACT_CREATE, form.value.form).then(data => {
          form.value.form.client = "";
          form.value.form.type = "";
          form.value.form.active = 'N';
          form.value.form.contractNumber = "";
          form.value.form.startDate = "";
          form.value.form.endDate = "";
          form.value.form.obs = "";
          form.value.form.services = [];
          items.value = [{ service: "", value: "", quantity: "" }];
          loader.hide();
          Swal.fire({
            title: "Good job!",
            text: "Record stored!",
            icon: "success",
            allowOutsideClick: false,
          });
        }).catch(() => {
          loader.hide();
        });
      }
    };

    const onEdit = () => {
      if (route.params.id !== undefined && route.params.id !== "") {
        const loader = $loading.show({
          loader: "dots",
        });

        store.dispatch(ContractActionTypes.CONTRACT_EDIT, { id: route.params.id }).then((data) => {
          const startDate = new Date(data.startDate + 'T00:00:00');
          const endDate = new Date(data.endDate + 'T00:00:00');
          form.value.form.id = data.id;
          form.value.form.client = data.client;
          form.value.form.contractNumber = data.contractNumber;
          form.value.form.type = data.type;
          form.value.form.active = data.active;
          form.value.form.startDate = startDate;
          form.value.form.endDate = endDate;
          form.value.form.obs = data.obs;
          items.value = JSON.parse(data.services);
          loader.hide();
        }).catch(() => {
          loader.hide();
        });

      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      form.value.form.services = items.value;
      form.value.form.id = route.params.id;
      store.dispatch(ContractActionTypes.CONTRACT_UPDATE, form.value.form).then(data => {
        loader.hide();
        Swal.fire({
          title: "Bom Trabalho!",
          text: "Dados atualizados com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Registro não foi atualizado, Verifique com o administrador!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const getServiceValue = async (index: any, event: any) => {
      try {
        await store.dispatch(ContractActionTypes.CONTRACT_SERVICE_VALUE, { id: event.id }).then((data) => {
          items.value[index].value = data.price;
          items.value[index].quantity = 1;
          //services.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const changeContractClient = (event: any) => {
      return;
    }

    const changeQuantity = async (index: any) => {
      try {
        const id = items.value[index].service;
        await store.dispatch(ContractActionTypes.CONTRACT_SERVICE_VALUE, { id: id }).then((data) => {
          const total: any = parseFloat(data.price) * items.value[index].quantity
          items.value[index].value = total;
        });
      } catch (err: any) {
        return;
      }

    };

    const addMore = () => {
      items.value.push({ service: "", value: "", quantity: "" })
    };

    const remove = (index: any) => {
      if (items.value.length === 1) {
        return;
      } else {
        items.value.splice(index, 1);
      }
    }

    const onGetContractType = async () => {
      try {
        await store.dispatch(ContractActionTypes.CONTRACT_TYPE_GET).then((data) => {
          contractTypes.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetClents = async () => {
      try {
        await store.dispatch(ContractActionTypes.CONTRACT_CLIENT).then((data) => {
          clients.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onGetService = async () => {
      try {
        await store.dispatch(ContractActionTypes.CONTRACT_SERVICE).then((data) => {
          services.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    function checkArray(variable: any) {
      return Array.isArray(variable);
    };

    return {
      checkArray,
      form,
      v$,
      dueDateInputOptions,
      configValor,
      items,
      addMore,
      remove,
      getServiceValue,
      changeQuantity,
      visibility,
      onGetContractType,
      contractTypes,
      activeItems,
      onGetClents,
      onGetService,
      services,
      clients,
      onCreate,
      changeContractClient,
    };
  },
});
