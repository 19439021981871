import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "progress-bar",
  props: {
    bgcolor: String,
    completed: Number,
  },
  setup(props) {
    const fillerStyles = computed(() => {
      return {
        height: "100%",
        width: `${props.completed}%`,
        backgroundColor: props.bgcolor,
        transition: "width 1s ease-in-out",
        borderRadius: "inherit",
        textAlign: "right",
      };
    });
    return { fillerStyles };
  },
});
