/* eslint-disable */
export enum ServiceActionTypes {
  SERVICE_LIST     = "ServiceList",
  SERVICE_CREATE   = "ServiceCreate",
  SERVICE_EDIT     = "ServiceEdit",
  SERVICE_UPDATE   = "ServiceUpdate",
  SERVICE_DELETE   = "ServiceDelete",
  SERVICE_GET_TYPE = "ServiceGetType",
  SERVICE_STATE    = "ServiceState",
}
