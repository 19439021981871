import { PersonTypeData, PersonTypeObject } from "@/@types";

export type State = {
  data: PersonTypeObject | null;
  personTypeData: PersonTypeData;
};

export const state: State = {
  data: null,
  personTypeData: [],
};
