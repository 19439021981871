import { UserCredentials } from "@/@types/app/SignInWithCredentials";
import { GetterTree } from "vuex";
import { RootState } from "..";
import { State } from "./state";

export type Getters = {
  getProfile(state: State): UserCredentials;
  token(state: State): string;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProfile: (state) => state.userCredentials,
  token: (state) => state.token,
};

/* import { IAuthState } from "@/interfaces/state";
import { IUser } from "@/interfaces/user";

export default {
  user: (state: IAuthState): IUser => state.user,
  token: (state: IAuthState): string => state.token,
};
 */
