import { RoleData, RoleObject } from "@/@types";
import { MutationTree } from "vuex";
import { RoleMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [RoleMutationTypes.SET_INSERT_ROLE_DATA](state: S, payload: RoleObject): void;
  [RoleMutationTypes.SET_ROLE_DATA](state: S, payload: RoleData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [RoleMutationTypes.SET_INSERT_ROLE_DATA](state: State, role: RoleObject) {
    state.data = role;
  },
  [RoleMutationTypes.SET_ROLE_DATA](state: State, roleData: RoleData) {
    state.roleData = roleData;
  },
};
