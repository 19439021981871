import { FinanceAccountData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getFinanceAccount(state: State): FinanceAccountData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getFinanceAccount: (state) => state.financeAccountData,
};
