/* eslint-disable */
import { PropType, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Tab",
  props: {
    tabs: {
      type: Array as PropType<{
        name: string; 
        label: string;
        id: string;
        ariaControls: string;
      }[]>,
      required: true
    },
    active: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }){
    const activeTab = ref(props.active);

    const changeTab = (index: number) => {
      activeTab.value = index;
      emit("change", index);
    }

    return { activeTab, changeTab };
  }
});
