/* eslint-disable */
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import PanelSecondary from "@/components/ui/panel-secondary/panel-secondary.vue";
import progressBar from "@/components/ui/progress-bar/progress-bar.vue";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { SystemInformationActionTypes } from "@/store/admin/system-info/action-types";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "SystemInformation",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel-secondary": PanelSecondary,
    "app-progress-bar": progressBar,
    "app-progress-circle": CircleProgress,
  },
  setup(props) {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });

    const cpu: any = ref({});
    const hardDisk: any = ref({});
    const info: any = ref({});
    const networkDevice: any = ref({});
    const ram: any = ref({});
    const webServer: any = ref({});
    const completed = ref(0);

    onMounted(() => {
      information();
    });

    const information = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(SystemInformationActionTypes.GET_SYSTEM_INFORMATION, {}).then((data) => {
        ram.value = data.ram;
        cpu.value = data.cpu;
        info.value = data.info;
        webServer.value = data.web_server;
        hardDisk.value = data.hard_disk;
        networkDevice.value = data.network_device;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    return { cpu, hardDisk, info, networkDevice, ram, webServer, completed };
  },
});
