/* eslint-disable */
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import inputDefault from "@/components/form/input-default/input-default.vue";
import inputDropDown from "@/components/form/dropdown/dropdown.vue";
import inputInline from "@/components/form/input-inline/input-inline.vue";
import inputFile from "@/components/form/upload-files/upload.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import BootstrapTabComponent from '@/components/ui/tabs/tab/tab.vue';
import { useStore } from "vuex";
import Swal from "sweetalert2";
import $ from "jquery";
import "jstree";
import "jstree/src/themes/default/style.css";
import { FinanceAccountActionTypes } from "@/store/finance/account/action-types";
import { helpers, maxLength, minLength, required, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";
import { PForm, RForm } from "../content/form";

export default defineComponent({
    name: "AccountForm",
    components: {
        "app-panel": Panel,
        "app-right-curtain": RightCurtain,
        "app-button": Button,
        "app-input-default": inputDefault,
        "app-input-dropdown": inputDropDown,
        "app-input-inline": inputInline,
        "file-input": inputFile,
        "app-tab": BootstrapTabComponent,
    },
    props: {
        accountType: {
            type: String,
            default: "R"
        }
    },
    setup (props, { emit }) {
        const formTitle = ref("Cadastrar conta");
        const store = useStore();
        const $loading = useLoading({
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999999,
        });
        const companies = ref({});
        const formOfPayments = ref({});
        const accountSource = ref({});
        const accountStatuses = ref({});
        const destinyAccount = ref({});
        const costCenter = ref({});
        const accountPlan = ref({});
        const bAccountCurtain = ref();
        const labelFormInstallments = ref('Quantidade de Parcelas:');

        onMounted(() => {
            getEmpresa();
            getFormOfPayment();
            getAccountSource();
            getStatus();
            getAccountPlan();
            getCostCenter();
        });

        const tabs = ref([
            { 
              name: "tab1", 
              label: "DADOS DE PAGAMENTO",
              id: "custom-tabs-four-one-tab",
              ariaControls: "custom-tabs-four-one"
            },
            { 
              name: "tab2", 
              label: "PLANOS DE CONTA",
              id: "custom-tabs-four-two-tab",
              ariaControls: "custom-tabs-four-two"
            },
            { 
              name: "tab3", 
              label: "CENTROS DE CUSTOS",
              id: "custom-tabs-four-tree-tab",
              ariaControls: "custom-tabs-four-tree"
            },
            { 
              name: "tab4", 
              label: "ARQUIVOS",
              id: "custom-tabs-four-four-tab",
              ariaControls: "custom-tabs-four-four"
            },
        ]);

        const activeTab = ref(1);
      
        const tabbs = ref({tabOne: 1, tabTwo: 2, tabTree: 3, tabFour: 4 });
      
        const selectedFiles = ref<File[]>([]);

        const formCreate: any = ref({
          form: {
            id: "",
            type: props.accountType,
            client: "",
            accountPlan: "",
            costCenter: "",
            accountStatus: "",
            receiptOrPaymentMethod: "",
            accountForReceiptOrPayment: "",
            transactionCode: "",
            due: "",
            amount: "0.00",
            receivedPaydDate: "",
            interestPercent: "",
            interestAmount: "",
            observation: "",
            competition: "",
            fine: "",
            finalAmount: "",
            files: "",
            numberOfInstallments: "",
          }
        });

        const formLabel = computed(() => {
          if(props.accountType === 'P'){
              return PForm;
          }
          if(props.accountType === 'R'){
              return RForm;
          }
        });

        const rules = computed(() => {
          return {
            form:{
              client: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              accountPlan: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              costCenter: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              accountStatus: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              competition: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              due: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              amount: { 
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              numberOfInstallments: {
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              receiptOrPaymentMethod: {
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              accountForReceiptOrPayment: {
                required: helpers.withMessage('Este campo é obrigatório!', required),
              },
              finalAmount: {
                requiredIf: helpers.withMessage('Por favor informe o valor pago!', requiredIf(() => {
                  return (
                    formCreate.value.form.accountStatus === '3'
                    ||
                    formCreate.value.form.accountStatus === 3
                  );
                }))
              }
            }
          }
        });
    
        const v$ = useVuelidate(rules, formCreate);

        const onFilesSelected = (files: File[]) => {
            selectedFiles.value = files;
        }
      
        const dueDateInputOptions = (date: any) => {
          
            const day   = date.getDate();
            const month = date.getMonth() + 1;
            const year  = date.getFullYear();
      
            const formattedDate   = new Date(year, month - 1, day);
            const formattedString = formattedDate.toLocaleDateString('pt-BR', { 
                day: '2-digit', 
                month: '2-digit', 
                year: 'numeric' 
            });
      
            return formattedString;
          
          
        };

        const onFormOpen = () => {
          formCreate.value.form.id = "";
          formCreate.value.form.client = "";
          formCreate.value.form.accountPlan = "";
          formCreate.value.form.costCenter = "";
          formCreate.value.form.accountStatus = "";
          formCreate.value.form.receiptOrPaymentMethod = "";
          formCreate.value.form.accountForReceiptOrPayment = "";
          formCreate.value.form.transactionCode = "";
          formCreate.value.form.due = "";
          formCreate.value.form.amount = "";
          formCreate.value.form.receivedPaydDate = "";
          formCreate.value.form.interestPercent = "";
          formCreate.value.form.interestAmount = "";
          formCreate.value.form.observation = "";
          formCreate.value.form.competition = "";
          formCreate.value.form.fine = "";
          formCreate.value.form.finalAmount = "";
          formCreate.value.form.files = "";
          formCreate.value.form.numberOfInstallments = 0;
          bAccountCurtain.value.openNav();
        };

        const onStore = () => {
          const accountPlan = $("#account-plan-container").jstree("get_selected",true)[0];
          if(accountPlan !== undefined){
            formCreate.value.form.accountPlan = accountPlan.id;
          }

          const costCenter = $("#account-cost-center-container").jstree("get_selected",true)[0];
          if(costCenter !== undefined) {
            formCreate.value.form.costCenter = costCenter.id;
          }
          
          if (formCreate.value.form.id) {
            onUpdate();
          } else {
            onCreate();
          }
        };

        const onUpdate = async () => {
          const result = await v$.value.$validate();
          if(!result){
            return;
          };

          const loader = $loading.show({
            loader: "dots",
          });

          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_UPDATE, formCreate.value.form).then(() => {
              Swal.fire({
                title: "Good job!",
                text: "Record Updated!",
                icon: "success",
                allowOutsideClick: false,
              });
              loader.hide();
              emit('aftersave');
          })
          .catch((err) => {
            Swal.fire({
              title: "Bad job!",
              text: "Record not updated!",
              icon: "error",
              allowOutsideClick: false,
            });
            loader.hide();
            emit('aftersave');
          });
        };

        const onCreate = async () => {
            
            const result = await v$.value.$validate();
            if(!result){
              return;
            };

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_CREATE, formCreate.value.form).then(() => {
              emit('aftersave');
              loader.hide();
              Swal.fire({
                title: "Good job!",
                text: "Record stored!",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
              emit('aftersave');
            });
            
        };

        const onEdit = (id: number) => {
          const loader = $loading.show({
            loader: "dots",
          });

          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_EDIT, { id: id }).then((data) => {
            const competition: any = new Date(data.competition + 'T00:00:00');
            const due: any = new Date(data.due + 'T00:00:00');
            let receivedPaydDate: any = '';
            if(data.receivedPaydDate){
              receivedPaydDate = new Date(data.receivedPaydDate + 'T00:00:00');
            }
            onFormOpen();
            formCreate.value.form.id = data.id;
            formCreate.value.form.competition = data.competition;
            formCreate.value.form.client = data.client;
            formCreate.value.form.receiptOrPaymentMethod = data.receiptOrPaymentMethod;
            formCreate.value.form.accountForReceiptOrPayment = data.accountForReceiptOrPayment;              
            formCreate.value.form.competition = competition;
            formCreate.value.form.due = due;
            formCreate.value.form.amount = data.amount;
            formCreate.value.form.accountStatus = data.accountStatus;
            formCreate.value.form.receivedPaydDate = receivedPaydDate;
            formCreate.value.form.interestPercent = data.interestPercent;
            formCreate.value.form.numberOfInstallments = data.numberOfInstallments;
            formCreate.value.form.fine = data.fine;
            formCreate.value.form.finalAmount = (data.finalAmount !== '0.00') ? data.finalAmount : "";
            formCreate.value.form.observation = data.observation;
            labelFormInstallments.value = 'Nº da Parcela:';
            $('#account-plan-container').jstree(true).deselect_all();
            $("#account-plan-container").jstree('select_node', data.accountPlan);
            $('#account-cost-center-container').jstree(true).deselect_all();
            $("#account-cost-center-container").jstree('select_node', data.costCenter);
            loader.hide();
          }).catch(() => {
            loader.hide();
          });
        };

        const getEmpresa = () => {
          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COMPANY).then((data) => {
            companies.value = data;
          });
        };
      
        const getFormOfPayment = () => {
          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_FORM_OF_PAYMENT).then((data) => {
            formOfPayments.value = data;
          });
        };
      
        const getAccountSource = () => {
          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_SOURCE).then((data) => {
            accountSource.value = data;
          });
        }
      
        const getAccountPlan = async () => {
          await store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_ACCOUNT_PLAN).then((data) => {
            accountPlan.value = data;
            $("#account-plan-container").jstree("destroy").jstree({
              core: {
                data: [data],
                multiple: false
              },
              types: {
                default: {
                  icon: "fa fa-folder text-warning",
                },
                file: {
                  icon: "fa fa-file  text-warning",
                },
              },
              plugins: ["types", "checkbox", "wholerow"],
            })
            .bind("ready.jstree", function (event: any, data: any) {
              const id = data.instance._model.data[1].id ?? null;
              
              $("#account-plan-container").jstree("open_node", $("#" + id));
            });
            setTimeout(() => {
              
            }, 3000);   
          });
        };
      
        const getCostCenter = () => {
          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_COST_CENTER).then((data) => {
            $("#account-cost-center-container").jstree("destroy").jstree({
              core: {
                data: [data],
                multiple: false
              },
              types: {
                default: {
                  icon: "fa fa-folder text-warning",
                },
                file: {
                  icon: "fa fa-file  text-warning",
                },
              },
              plugins: ["types", "checkbox", "wholerow"],
            })
            .bind("ready.jstree", function (event: any, data: any) {
              const id = data.instance._model.data[1].id ?? null;
              
              $("#account-cost-center-container").jstree("open_node", $("#" + id));
            });
            setTimeout(() => {
              
            }, 3000);   
          });
        };
      
        const getStatus = () => {
          store.dispatch(FinanceAccountActionTypes.FINANCE_ACCOUNT_GET_STATUS).then((data) => {
            accountStatuses.value = data;
          });
        };
      
        const config = computed(() => ({
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          suffix: "",
          precision: 2,
          masked: false /* doesn't work with directive */,
          focusOnRight: true,
          minimumNumberOfCharacters: 0,
        }));
      
        const configInterest = computed(() => ({
          prefix: 'R$ ',
          suffix: '',
          thousands: '.',
          decimal: ',',
          precision: 2,
          disableNegative: false,
          disabled: false,
          min: null,
          max: null,
          allowBlank: false,
          minimumNumberOfCharacters: 0,
          shouldRound: true,
          focusOnRight: true,
        }));
      
        const changeTab = (index: any) => {
          activeTab.value = index;
        }

        function checkArray(variable: any) {
          return Array.isArray(variable);
        };

        function verifyState() {
          return formCreate.value.form.accountStatus === 3;
        }

        //watch(() => formCreate.value.form.accountStatus, () => {
        //  setTimeout(() => {
        //    verifyState()
        //  }, 3000);
        //  
        //});
      
        return {
            getEmpresa,
            changeTab,
            getFormOfPayment,
            getAccountSource,
            formCreate,
            onCreate,
            onFormOpen,
            bAccountCurtain,
            onEdit,
            onStore,
            accountSource,
            accountStatuses,
            companies,
            formOfPayments,
            config,
            v$,
            checkArray,
            configInterest,
            dueDateInputOptions,
            tabs,
            getAccountPlan,
            accountPlan,
            tabbs,
            activeTab,
            selectedFiles,
            onFilesSelected,
            formTitle,
            formLabel,
            labelFormInstallments,
        };
        
    }
});
