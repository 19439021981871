<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.billsToPay", 2) }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <grid 
            account-type="P" 
            @edit="edit" 
            @new="onNew" 
            :new-record="true"
            ref="accountGrid"
        />
        <account-form
            ref="accountForm"
            @aftersave="onGrid"
            account-type="P"
        />
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->