import { PfDropdown, PfImage } from "@profabric/vue-components";
import { defineComponent } from "vue";

export default defineComponent({
  name: "messages-dropdown",
  components: {
    "pf-dropdown": PfDropdown,
    "pf-image": PfImage,
  },
});
