/* eslint-disable */
import { defineComponent } from "vue";
import { vMaska } from "maska";

export default defineComponent({
  name: "inputDefault",
  props: {
    labelFor: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    inputClass: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      default: ""
    },
    isActive: {
      type: Boolean,
      required: false,
    },
    hasError: {
      type: Boolean,
      required: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      required: false,
    },
    error: {
      type: [String, Array],
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    maskara: {
      type: String,
      required: false,
      default: "",
    },
    maxlength: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
  },
  directives: { maska: vMaska },
  methods: {
    updateInput(event: any) {
      this.$emit("update:modelValue", event.target.value);
    },

    onKeypress(value: any) {
      this.$emit("keypress", value);
    },

    onBlur(value: any) {
      this.$emit("onblur", value);
    },

    checkArray(variable: any) {
      return Array.isArray(variable);
    }
  },
});
