/* eslint-disable */
import { IssueObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { IssueActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import axios from "axios";
import { format_date_to_us } from "@/classes/format_date_to_us";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [IssueActionTypes.ISSUE_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_MINE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_CREATE]( { commit }: AugmentedActionContext, params: IssueObject ): Promise<boolean>;
  [IssueActionTypes.ISSUE_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_UPDATE]( { commit }: AugmentedActionContext, params: IssueObject ): Promise<any>;
  [IssueActionTypes.ISSUE_FILE_GET]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_FILE_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_USERS]( { commit }: AugmentedActionContext): Promise<any>;
  [IssueActionTypes.ISSUE_GET_CLIENT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_PROJECT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_PRIORITY]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_CATEGORY]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_STATE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_PROBLEM_TYPE]( { commit }: AugmentedActionContext): Promise<any>;
  [IssueActionTypes.ISSUE_GET_SOLUTION_TYPE]( { commit }: AugmentedActionContext): Promise<any>;
  [IssueActionTypes.ISSUE_VIEW]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_NOTE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_POST_NOTE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_DELETE_FILE_NOTE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_NOTE_FILE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [IssueActionTypes.ISSUE_GET_NOTIFICATION]( { commit }: AugmentedActionContext, params: any): Promise<any>;
  [IssueActionTypes.ISSUE_NOTE_VIEWED]( { commit }: AugmentedActionContext, params: any): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [IssueActionTypes.ISSUE_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      const formPayload: any = {
        number: params.number,
        client: params.client,
        situation: params.state,
        project: params.project,
        priority: params.priority,
        category: params.category,
        problemType: params.problemType,
        user: params.user,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/issue/${params.limit}/${params.offset}`, formPayload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_MINE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      const formPayload: any = {
        number: params.number,
        client: params.client,
        situation: params.state,
        project: params.project,
        priority: params.priority,
        category: params.category,
        problemType: params.problemType,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/issue/mine/${params.limit}/${params.offset}`, formPayload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_CREATE]( { commit }, params: IssueObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const formdata = new FormData();
      formdata.append('project'         , params.project);
      formdata.append('category'        , params.category);
      formdata.append('priority'        , params.priority);
      formdata.append("observation"     , params.observation);
      formdata.append('problemType'     , params.problemType);
      formdata.append('recurring'       , params.recurring);

      for(let i = 0; i < params.attachment.length; i++){
        formdata.append('attachment[]', params.attachment[i]);
      }
      
      //const dataForm = data;
      ApiService.setHeader();
      axios.post("/issue", formdata, { headers: { "Content-Type": "multipart/form-data", } }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_UPDATE]( { commit }, params: IssueObject ) {
    return new Promise<boolean>((resolve, reject) => {
      let closingDateFormated = "";
      if(params.closingDate){
        closingDateFormated = format_date_to_us(params.closingDate);
      }
      
      let startDate = "";
      if(params.startDate){
        startDate = format_date_to_us(params.startDate);
      }

      const formdata = new FormData();
      formdata.append('client'          , params.client);
      formdata.append('project'         , params.project);
      formdata.append('category'        , params.category);
      formdata.append('priority'        , params.priority);
      formdata.append('situation'       , params.situation);
      formdata.append("observation"     , params.observation);
      formdata.append('problemType'     , params.problemType);
      formdata.append('solutionType'    , params.solutionType);
      formdata.append('recurring'       , params.recurring);
      formdata.append('finalObservation', params.finalObservation);
      formdata.append('user'            , params.user);
      formdata.append('startDate'       , startDate);
      formdata.append('closingDate'     , closingDateFormated);
      formdata.append('hours'           , params.usedTime);

      for(let i = 0; i < params.attachment.length; i++){
        formdata.append('attachment[]', params.attachment[i]);
      }
      
      //const dataForm = data;
      ApiService.setHeader();
      axios.post(`/issue/${params.id}`, formdata, { headers: { "Content-Type": "multipart/form-data", } }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_FILE_GET]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      axios.get(`/issue/file/${params.file}`, { responseType: 'blob'}).then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  async [IssueActionTypes.ISSUE_FILE_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/issue/state/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_USERS]({ commit } ) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/users`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_CLIENT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/client`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_PROJECT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/issue/project`, params).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_PRIORITY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/priority`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_CATEGORY]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/category`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_STATE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/state`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_PROBLEM_TYPE]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/problem-type`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_SOLUTION_TYPE]({ commit }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/solution-type`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_VIEW]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/view/${params.issue}`).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_NOTE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/issue/note/${params.issue}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_POST_NOTE]({ commit }, params: any) {
    return new Promise<boolean>((resolve, reject) => {
      const formdata = new FormData();
      formdata.append('issue', params.issue);
      formdata.append('observation', params.observation);

      for(let i = 0; i < params.attachment.length; i++){
        formdata.append('attachment[]', params.attachment[i]);
      }
      
      //const dataForm = data;
      ApiService.setHeader();
      axios.post(
        "/issue/note", 
        formdata, 
        { 
          headers: 
          { 
            "Content-Type": "multipart/form-data", 
          } 
        }).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_DELETE_FILE_NOTE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/issue/state/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_NOTE_FILE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      axios.get(`/issue/note/file/${params.file}`, { responseType: 'blob'}).then((response: any) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  async [IssueActionTypes.ISSUE_GET_NOTIFICATION]({ commit }, params: any ) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/issue/notification`, params).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [IssueActionTypes.ISSUE_NOTE_VIEWED]({ commit }, params: any ) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/issue/note/viewed`, params).then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
