/* eslint-disable */
import inputInline from "@/components/form/input-inline/input-inline.vue";
import Button from "@/components/ui/button/button.vue";
import { UserActionTypes } from "@/store/admin/person/user/action-types";
import { PfImage } from "@profabric/vue-components";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { isValidCPF } from "@/classes/valid_cpf";
import { isValidDate } from "@/classes/format_date_us_yup";
import imageInput from "@/components/form/image-input/image-input.vue";
import { useLoading } from "vue-loading-overlay";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required, sameAs } from '@vuelidate/validators';

export default defineComponent({
  name: "app-profile",
  components: {
    PfImage: PfImage,
    InputInline: inputInline,
    "app-button": Button,
    "app-image-input": imageInput,
  },
  setup() {
    const store           = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const imageUrl        = ref();
    const user            = ref();
    const role            = ref();
    const activeTab       = ref("ACTIVITY");
    const baseUrl         = process.env.VUE_APP_API_DOMAIN;
    /* PERSONAL DATA */
    const personalDataForm = reactive({
      form: {
        firstname: "",
        lastname: "",
        phone: "",
        email: ""
      }
    });

    const pdrules = computed(() => {
      return {
        form: {
          firstname: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          lastname: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          phone: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          email: { 
            required: helpers.withMessage("Este campo é obrigatório!", required),
            email: helpers.withMessage("E-mail é invalido!", email), 
          },
        }
      }
    });

    const pdv$ = useVuelidate(pdrules, personalDataForm);

    /* DOCUMENT DATA */
    const documentDataForm = reactive({
      form: {
        document: "",
        identity: "",
        birthdate: ""
      }
    });

    const drules = computed(() => {
      return {
        form: {
          document: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          identity: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          birthdate: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
        }
      }
    });

    const dv$ = useVuelidate(drules, documentDataForm);

    /* ADDRESS DATA */
    const addressDataForm  = reactive({
      form: {
        zipcode: "",
        address: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
      }
    });

    const arules = computed(() => {
      return {
        form: {
          zipcode: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          address: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          number: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          complement: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          district: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          city: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          state: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
        }
      }
    });

    const av$ = useVuelidate(arules, addressDataForm);

    /* PASSWORD DATA */
    const passwordDataForm = reactive({
      form: {
        current: "",
        newpassword: "",
        confirmpassword: ""
      }
    });

    const prules = computed(() => {
      return {
        form: {
          current: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          newpassword: { 
            required: helpers.withMessage("Este campo é obrigatório!", required)  
          },
          confirmpassword: { 
            required: helpers.withMessage("Este campo é obrigatório!", required),
            sameAsPassword: helpers.withMessage("As senhas não conferem!", sameAs(passwordDataForm.form.newpassword)),
            $lazy: true
          },
        }
      }
    });

    const pv$ = useVuelidate(prules, passwordDataForm);

    const setActiveTab = (tab: string) => {
      activeTab.value = tab;
    };

    onMounted(() => {
      getPersonalData();
      getDocumentData();
      getAddressData();
      
      let image: any = "";
      if(
        localStorage.getItem('picture') !== undefined && 
        localStorage.getItem('picture') !== '' && 
        localStorage.getItem('picture') !== 'null'
      ){
        image = localStorage.getItem('picture');
      } else {
        image = require('@/assets/img/default-profile.png');
      }

      imageUrl.value = image;
      user.value = localStorage.getItem('name');
      role.value = localStorage.getItem('role');
    });

    const getPersonalData = () => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(UserActionTypes.getPersonalData).then((data) => {
        personalDataForm.form.firstname = data.firstname;
        personalDataForm.form.lastname  = data.lastname;
        personalDataForm.form.phone     = data.phone;
        personalDataForm.form.email     = data.email;
        loader.hide();
      }).catch(error => {
        loader.hide();
      });
    };

    const updatePersonalData = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      const result = await pdv$.value.$validate();
      if (!result) {
        return;
      }

      store.dispatch(UserActionTypes.updatePersonalData, personalDataForm.form).then(() => {
        loader.hide();
        Swal.fire({
          title: "Sucesso!",
          text: "Seus dados foram atualizados com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        Swal.fire({
          title: "Ooops!",
          text: "Seus dados não foram atualizados! Por favor contate seu administrador",
          icon: "error",
          allowOutsideClick: false,
        });
      });
      
    };

    const getDocumentData = () => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(UserActionTypes.getDocumentData).then((data) => {
        documentDataForm.form.document  = data.document;
        documentDataForm.form.identity  = data.identity;
        documentDataForm.form.birthdate = data.birthdate;
        loader.hide();
      }).catch(error => {
        loader.hide();
      });
    };

    const updateDocumentData = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      const result = await dv$.value.$validate();
      if (!result) {
        return;
      }
      
      store.dispatch(UserActionTypes.updateDocumentData, documentDataForm.form).then(() => {
        loader.hide();
        Swal.fire({
          title: "Sucesso!",
          text: "Suas informações de documentos foram atualizados com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        Swal.fire({
          title: "Ooops!",
          text: "Suas informações de documentos não foram atualizados! Por favor contate seu administrador",
          icon: "error",
          allowOutsideClick: false,
        });
      });
      
    };

    const getAddressData = () => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(UserActionTypes.getAddressData).then((data) => {
        addressDataForm.form.zipcode     = data.zipcode;
        addressDataForm.form.address     = data.address;
        addressDataForm.form.number      = data.number;
        addressDataForm.form.complement  = data.complement;
        addressDataForm.form.district    = data.district;
        addressDataForm.form.city        = data.city;
        addressDataForm.form.state       = data.state;
        loader.hide();
      }).catch(error => {
        loader.hide();
      });
    };

    const updateAddressData = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      const result = await av$.value.$validate();
      if (!result) {
        return;
      }
      
      store.dispatch(UserActionTypes.updateAddressData, addressDataForm.form).then(() => {
        loader.hide();
        Swal.fire({
          title: "Sucesso!",
          text: "Suas informações de endereço foram atualizadas com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        Swal.fire({
          title: "Ooops!",
          text: "Suas informações de endereço não foram atualizadas! Por favor contate seu administrador",
          icon: "error",
          allowOutsideClick: false,
        });
      });
          
    };

    const updatePasswordData = async () => {
      const loader = $loading.show({
        loader: "dots",
      }); 
      
      const result = await pv$.value.$validate();
      if (!result) {
        loader.hide();
        return;
      }

      store.dispatch(UserActionTypes.updatePasswordData, passwordDataForm.form).then(() => {
        loader.hide();
        Swal.fire({
          title: "Sucesso!",
          text: "Suas informações de senha foram atualizadas com sucesso!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
        Swal.fire({
          title: "Ooops!",
          text: "Suas informações de senha não foram atualizadas! Por favor contate seu administrador",
          icon: "error",
          allowOutsideClick: false,
        });
      });
      
    };

    const getAddress = () => {
      if(addressDataForm.form.zipcode !== undefined && addressDataForm.form.zipcode !== ""){
        const zip: any = addressDataForm.form.zipcode.replace(/\D/g, "");

        if ( zip.length === 8 && (addressDataForm.form.address !== undefined || addressDataForm.form.address !== null)) {
          const loader = $loading.show({
            loader: "dots",
          });

          store.dispatch(UserActionTypes.getAddressByZipData, { zipcode: zip, }).then((data) => {
            addressDataForm.form.address  = data.logradouro;
            addressDataForm.form.district = data.bairro;
            addressDataForm.form.state    = data.uf;
            addressDataForm.form.city     = data.localidade;
            loader.hide();
          }).catch(() => {
            loader.hide();
          });
        }
      }
    }

    const updatePicture = (event: any) => {
      const loader = $loading.show({
        loader: "dots",
      });
      store.dispatch(UserActionTypes.updatePicture, event).then((data) => {
        if(data.data[0] !== undefined){
          localStorage.setItem('picture', baseUrl + '/' +data.data[0]);
          location.reload();
        }
        loader.hide();
      }).catch((error) => {
        loader.hide();
      })
    };

    const onRemovePicture = () => {
      imageUrl.value = require('@/assets/img/default-profile.png');
    };

    return { 
      setActiveTab, 
      activeTab,
      personalDataForm,
      documentDataForm,
      addressDataForm,
      passwordDataForm,
      getPersonalData,
      updatePersonalData,
      getDocumentData,
      updateDocumentData,
      getAddressData,
      updateAddressData,
      updatePasswordData,
      getAddress,
      imageUrl,
      updatePicture,
      onRemovePicture,
      user,
      role,
      pdv$,
      dv$,
      av$,
      pv$,
    };
  },
});
