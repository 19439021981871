/* eslint-disable */
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { UserActionTypes } from "@/store/admin/person/user/action-types";
import inputDefault from "@/components/form/input-default/input-default.vue";
import { PeopleActionTypes } from "@/store/admin/person/people/action-types";
import { RoleActionTypes } from "@/store/admin/person/roles/action-types";
import { MenuActionTypes } from "@/store/admin/menu/action-types";
import { isVisible } from "@/classes/is_visible";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import "jstree";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "User",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const usercurtain = ref();
    const formSearch = reactive({
      company: "",
    });
    const formCreate = ref({
      form: {
        id: "",
        company: "",
        contact: "",
        roles: [],
        homepage: "",
      }
    });

    const rules = computed(() => {
      return {
        form:{
          company: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          contact: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          roles: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          homepage: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          }
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const rows: any = ref({});
    const currentPage = ref(1);
    const totalPages = ref(1);
    const total = ref();
    const perPage = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "10%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "name",
        text: "Name",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "login",
        text: "Username",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "active",
        text: "Active",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
    ];

    const companies= ref([]);
    const contacts = ref([]);
    const roles    = ref([]);
    const programs = ref([]);

    const onSearch = () => {
      onList();
    };

    const onPaginate = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onList();
    };

    const onCurtainOpen = () => {
      return;
    };

    onMounted(() => {
      onList();
      getCompany();
      getRoles();
      getMenu();
      getTree();
    });

    const onList = async () => {
      const loader = $loading.show({
        loader: "dots",
      });

      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        company: formSearch.company,
      };
      await store.dispatch(UserActionTypes.LIST_USER, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    const onStore = () => {
      const homepage = $("#page-permission-container").jstree("get_selected",true)[0];
      if(homepage !== undefined){
        formCreate.value.form.homepage = homepage.id;
      }
      
      if (formCreate.value.form.id) {
        onUpdate();
      } else {
        onCreate();
      }
    };

    const onUpdate = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      }

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(UserActionTypes.UPDATE_USER, formCreate.value.form).then(() => {
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
      }).catch((err) => {
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
      });
    };

    const onCreate = async () => {
      
      const result = await v$.value.$validate();
      if(!result){
        return;
      }

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(UserActionTypes.CREATE_USER, formCreate.value.form).then(() => {
        formCreate.value.form.company  = "";
        formCreate.value.form.contact  = "";
        formCreate.value.form.roles    = [];
        formCreate.value.form.homepage = "";
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
        onList();
        loader.hide();
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Record not stored!",
          icon: "error",
          allowOutsideClick: false,
        });
        loader.hide();
      });
    };

    const onDelete = (id: number) => {

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(UserActionTypes.DELETE_USER, { id: id }).then(() => {
            Swal.fire({
              title: "Deleted!",
              text: "Your record has been deleted.",
              icon: "success",
              allowOutsideClick: false,
            });
            onList();
            loader.hide();
          })
          .catch(() => {
            Swal.fire({
              title: "Error!",
              text: "Could not be deleted",
              icon: "error",
              allowOutsideClick: false,
            });
            loader.hide();
          });
        }
      });
      
    };

    const onEdit = (id: number) => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(UserActionTypes.EDIT_USER, { id: id }).then((data) => {
        usercurtain.value.openNav();
        formCreate.value.form.company = data.company;
        getContacts();
        formCreate.value.form.contact = data.contact;
        formCreate.value.form.roles = JSON.parse(data.roles);
        formCreate.value.form.id = data.id;
        $('#page-permission-container').jstree(true).deselect_all();
        $("#page-permission-container").jstree('select_node', [data.homepage]);
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const onStatus = (id: number) => {
     
      Swal.fire({
        title: "Are you sure?",
        text: "You can revert it after!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Status!",
            text: "Your record status has been changed.",
            icon: "success",
            allowOutsideClick: false,
          });
          const loader = $loading.show({
            loader: "dots",
          });
          store.dispatch(PeopleActionTypes.DELETE_PEOPLE, { id: id, }).then(() => {
            onList();
            loader.hide();
          }).catch(() => {
            loader.hide();
          });
        }
      });
      
    };

    const selected = (param: any) => {
      return;
    };

    /* FORM */
    const getCompany = async () => {
      try {
        await store.dispatch(PeopleActionTypes.GET_ALL_COMPANIES).then((data) => {
          companies.value = data;
        });
      } catch (err) {
        return;
      }
    };

    const openForm = () => {
      formCreate.value.form.id = "";
      formCreate.value.form.company = "";
      formCreate.value.form.contact = "";
      formCreate.value.form.roles = [];
      formCreate.value.form.homepage = "";
      $('#page-permission-container').jstree(true).deselect_all();

      usercurtain.value.openNav();
    };

    const getContacts = async () => {
      try {
        const id = formCreate.value.form.company;
        contacts.value = [];
        await store.dispatch(PeopleActionTypes.GET_ALL_CONTACTS, { id: id }).then((data) => {
            contacts.value = data;
          });
      } catch (err) {
        return;
      }
    };

    const getRoles = async () => {
      try {
        await store.dispatch(RoleActionTypes.ALL_ROLE).then((data) => {
          roles.value = data;
        });
      } catch (err) {
        return;
      }
    };

    const getMenu = async () => {
      try {
        await store.dispatch(MenuActionTypes.ALL_NAVIGATION).then((data) => {
          programs.value = data;
        });
      } catch (err) {
        return;
      }
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    function checkArray(variable: any) {
      return Array.isArray(variable);
    };

    const getTree = () => {
      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(MenuActionTypes.TREE_NAVIGATION).then((data) => {
        //treeData.value = data;

        $("#page-permission-container").jstree("destroy").jstree({
          core: {
            data: [data],
          },
          types: {
            default: {
              icon: "fa fa-folder text-warning",
            },
            file: {
              icon: "fa fa-file  text-warning",
            },
          },
          plugins: ["types", "checkbox", "wholerow"],
        })
        .bind("ready.jstree", function (event: any, data: any) {
          const id = data.instance._model.data[1].id ?? null;
          $("#page-permission-container").jstree("open_node", $("#" + id));
        });
        loader.hide();       
      }).catch(() => {
        loader.hide();
      });
    };

    return {
      formSearch,
      formCreate,
      gridColumns,
      rows,
      onSearch,
      onCreate,
      onPaginate,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onCurtainOpen,
      onList,
      onDelete,
      onEdit,
      onStore,
      companies,
      roles,
      contacts,
      programs,
      getContacts,
      onStatus,
      usercurtain,
      openForm,
      visibility,
      selected,
      v$,
      checkArray,
    };
  },
});
