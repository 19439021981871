<form action="javascript:;" @submit.prevent="insertIssueNote">
    <curtain 
        right-curtain-name="issue-insert-note" 
        ref="issueInsertNote" 
        card-class="card-primary"
    >

        <template v-slot:title>
            Inserir nova nota
        </template>
        <template v-slot:content>
                <input type="hidden" v-model="issue" />
                <div class="form-group">
                    <label for="exampleInputEmail1">Observação:</label>
                    <textarea 
                        class="form-control rounded-0" 
                        id="note-observation" 
                        v-model="v$.observation.$model"
                        rows="10"
                        cols="5"
                        placeholder="Exponha a sua duvida, pedido de mais informações ou informe o cliente">
                    </textarea>
                    <span v-if="checkArray(v$.observation.$errors)">
                        <small 
                            id="emailHelp" 
                            class="form-text text-danger"
                            v-for="(errorMessage, index) of v$.observation.$errors" 
                            :key="index"
                        >
                            {{ errorMessage.$message }}
                        </small>
                    </span>
                </div>
                <div class="form-group">
                    <label for="Anexos">Anexos</label>
                    <div class="input_container">
                        <input type="file" ref="noteFile" multiple class="form-control-file" id="note-files">
                    </div>
                </div>
        </template>
        <template v-slot:footer>
            <s-button 
                type="submit" 
                class="btn btn-default rounded-0 shadow" 
                :disabled="v$.$invalid"
            >
                <i class="fa fa-save"></i>
                Gravar
            </s-button>
        </template>
    </curtain>
</form>