/* eslint-disable */
import { FinanceMovementObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { FinanceMovementActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [FinanceMovementActionTypes.FINANCE_MOVEMENT_LIST]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [FinanceMovementActionTypes.FINANCE_MOVEMENT_CREATE]( { commit }: AugmentedActionContext, params: FinanceMovementObject): Promise<boolean>;
  [FinanceMovementActionTypes.FINANCE_MOVEMENT_EDIT]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [FinanceMovementActionTypes.FINANCE_MOVEMENT_UPDATE]( { commit }: AugmentedActionContext, params: FinanceMovementObject ): Promise<any>;
  [FinanceMovementActionTypes.FINANCE_MOVEMENT_DELETE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [FinanceMovementActionTypes.FINANCE_MOVEMENT_LIST]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        description: params.description
      }
      ApiService.post(`/finance/movement-type/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceMovementActionTypes.FINANCE_MOVEMENT_CREATE]({ commit }, params: FinanceMovementObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        description: params.description
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/finance/movement-type", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceMovementActionTypes.FINANCE_MOVEMENT_EDIT]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/movement-type/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [FinanceMovementActionTypes.FINANCE_MOVEMENT_UPDATE]({ commit }, params: FinanceMovementObject) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        description: params.description
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/finance/movement-type/${params.id}`, payload).then(({ data }) => {
          const result = data.data;
          resolve(result);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },

  async [FinanceMovementActionTypes.FINANCE_MOVEMENT_DELETE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/movement-type/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
