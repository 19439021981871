<li v-if="menuItem" class="nav-item" :class="{'menu-open': isMenuExtended}">
    <a
        class="nav-link"
        :class="{'active': isMainActive || isOneOfChildrenActive}"
        @click="handleMainMenuAction"
        v-if="visibility(menuItem.navi)"
    >
        <i :class="`nav-icon ${ menuItem.icon}`" />
        <p>{{ $t(menuItem.name) }}</p>
        <i v-if="isExpandable" class="right fas fa-angle-left"></i>
    </a>
    <ul class="nav nav-treeview" v-if="visibility(menuItem.navi)">
        <app-menu-item
            v-for="item in menuItem.children"
            :menuItem="item"
        ></app-menu-item>
        <!-- <li class="nav-item" v-if="visibility(item.navi)">
           <router-link
                :to="item.path"
                class="nav-link"
                exact
                exact-active-class="active"
            >
                <i :class="`nav-icon ${ item.icon}`" />
                <p>{{ $t(item.name) }}</p>
            </router-link>
        </li> -->        
    </ul>
</li>