
<div class="modal fade rounded-0 extra" :id="identityName">
    <div class="modal-dialog modal-xl">
        <div class="modal-content rounded-0">
            <div class="modal-header shadow-sm">
                <h4 class="modal-title">
                    <slot name="title"></slot>
                </h4>
                <!-- <button type="button" @click="closeModal" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button> -->
                <button 
                    type="button" 
                    :id="`${identityName}-modal`" 
                    @click="closeModal" 
                    class="btn btn-default rounded-0 shadow"
                    data-dismiss="modal"
                >Fechar</button>
            </div>
            <div class="modal-body">
                <slot name="content"></slot>
            </div>
            <div class="modal-footer justify-content-between">
                <slot name="footer"></slot>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->