import { Options, Vue } from "vue-class-component";

@Options({
  name: "PanelSecondary",
  props: {
    class: {
      type: String,
    },
  },
})
export default class PanelSecondary extends Vue {}
