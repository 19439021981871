import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["value", "onInput", "checked"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn-group btn-group-toggle", _ctx.divClass]),
    "data-toggle": "buttons"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass(["btn s-button-group", { 
            active: option.value === _ctx.modelValue,
            'btn-orange': option.value === _ctx.modelValue,
            'btn-default': option.value !== _ctx.modelValue,
        }]),
        key: option.value
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          class: "form-control",
          name: "options",
          value: option.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.modelValue) = $event)),
          onInput: $event => (_ctx.handleOptionChange(option.value)),
          id: "option1",
          autocomplete: "off",
          checked: option.value === _ctx.modelValue
        }, null, 40, _hoisted_1), [
          [_vModelRadio, _ctx.modelValue]
        ]),
        _createTextVNode(" " + _toDisplayString(option.label), 1)
      ], 2))
    }), 128))
  ], 2))
}