<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.access") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-blue">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar usuário e data
                </h3>
            </template>
            <template v-slot:content>
                <input type="text" class="form-control rounded-0" name="description" v-model="description"/>
                <app-right-curtain right-curtain-name="right" ref="rightcurtain"></app-right-curtain>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0" @click="onSearch">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
            </template>
        </app-panel>

        <app-panel class="card-gray">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de usuários / Login / Logout
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped"></app-table>
            </template>
            <template v-slot:footer>
                <app-pagination
                    :total-pages="totalPages"
                    :total="total"
                    :per-page="perPage"
                    :current-page="currentPage"
                    @pagechanged="onPageChange"
                ></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->