import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getSystemInformation(state: State): any;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getSystemInformation: (state) => state.informationData,
};
