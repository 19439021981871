/* eslint-disable */
import { FinanceDashboardData, FinanceDashboardObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceDashboardMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceDashboardMutationTypes.SET_INSERT_FINANCE_DASHBOARD_DATA](state: S, payload: FinanceDashboardObject): void;
  [FinanceDashboardMutationTypes.SET_FINANCE_DASHBOARD_DATA](state: S, payload: FinanceDashboardData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceDashboardMutationTypes.SET_INSERT_FINANCE_DASHBOARD_DATA](state: State, financeDashboard: FinanceDashboardObject) {
    state.financeDashboard = financeDashboard;
  },
  [FinanceDashboardMutationTypes.SET_FINANCE_DASHBOARD_DATA](state: State, financeDashboardData: FinanceDashboardData) {
    state.financeDashboardData = financeDashboardData;
  },
};
