/* eslint-disable */
import { NicheObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../..";
import { NicheActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [NicheActionTypes.LIST_NICHE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [NicheActionTypes.CREATE_NICHE]( { commit }: AugmentedActionContext, params: NicheObject ): Promise<boolean>;
  [NicheActionTypes.EDIT_NICHE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [NicheActionTypes.UPDATE_NICHE]( { commit }: AugmentedActionContext, params: NicheObject ): Promise<any>;
  [NicheActionTypes.DELETE_NICHE]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [NicheActionTypes.LIST_NICHE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      const payload: any = {
        description: params.description
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.post(`/system/niche/${params.limit}/${params.offset}`, payload).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [NicheActionTypes.CREATE_NICHE]( { commit }, params: NicheObject ) {
    return new Promise<boolean>((resolve, reject) => {
      const payload:any = {
        description: params.description,
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/system/niche", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [NicheActionTypes.EDIT_NICHE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/system/niche/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [NicheActionTypes.UPDATE_NICHE]( { commit }, params: NicheObject ) {
    return new Promise<void>((resolve, reject) => {
      const payload:any = {
        description: params.description,
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/system/niche/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [NicheActionTypes.DELETE_NICHE]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/system/niche/${params.id}`).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
