export const PGrid = [
  {
    key: "id",
    text: "",
    thClass: "",
    thWidth: "5%",
    tdClass: "",
    tdWidth: "",
  },
  {
    key: "company",
    text: "Fornecedor",
    thClass: "",
    thWidth: "",
    tdClass: "",
    tdWidth: "",
  },
  {
    key: "createdAt",
    text: "Emissão",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "due",
    text: "Vencimento",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "situation",
    text: "Pagamento",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "amount",
    text: "Valor",
    thClass: "text-right",
    thWidth: "",
    tdClass: "text-right",
    tdWidth: "",
  },
];

export const PFormListLabel = {
  company: "Fornecedor",
};

export const RGrid = [
  {
    key: "id",
    text: "",
    thClass: "",
    thWidth: "5%",
    tdClass: "",
    tdWidth: "",
  },
  {
    key: "company",
    text: "Cliente",
    thClass: "",
    thWidth: "",
    tdClass: "",
    tdWidth: "",
  },
  {
    key: "createdAt",
    text: "Emissão",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "due",
    text: "Vencimento",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "situation",
    text: "Recebimento",
    thClass: "text-center",
    thWidth: "",
    tdClass: "text-center",
    tdWidth: "",
  },
  {
    key: "amount",
    text: "Valor",
    thClass: "text-right",
    thWidth: "",
    tdClass: "text-right",
    tdWidth: "",
  },
];

export const RFormListLabel = {
  company: "Cliente",
};
