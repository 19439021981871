import { FinanceMovementData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getFinanceMovement(state: State): FinanceMovementData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getFinanceMovement: (state) => state.financeMovementData,
};
