import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.class),
    type: _ctx.type
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}