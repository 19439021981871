/* eslint-disable */
import { ServiceTypeData, ServiceTypeObject } from "@/@types";
import { MutationTree } from "vuex";
import { ServiceTypeMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [ServiceTypeMutationTypes.SET_SERVICE_TYPE_DATA_INSERT]( state: S, payload: ServiceTypeObject ): void;
  [ServiceTypeMutationTypes.SET_SERVICE_TYPE_DATA]( state: S, payload: ServiceTypeData ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ServiceTypeMutationTypes.SET_SERVICE_TYPE_DATA_INSERT]( state: State, serviceType: ServiceTypeObject ) {
    state.data = serviceType;
  },
  [ServiceTypeMutationTypes.SET_SERVICE_TYPE_DATA]( state: State, serviceTypeData: ServiceTypeData ) {
    state.serviceTypeData = serviceTypeData;
  },
};
