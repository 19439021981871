/* eslint-disable */
import { computed, defineComponent, onMounted, reactive, ref } from "vue";

export default defineComponent({
  name: "tree-item",
  props: {
    item: Object,
    modelValue: [
      Boolean,
      String,
      Number,
      Object,
      Array as () => Array<string | number>,
    ],
    value: [String, Number, Array as () => Array<string>],
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    checked: Boolean,
    showInput: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    buttonAdd: {
      type: Boolean,
      default: false,
    },
    buttonEdit: {
      type: Boolean,
      default: false,
    },
    buttonRemove: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const values = ref([]);

    const toggle = () => {
      if (isFolder.value) {
        isOpen.value = !isOpen.value;
      }
    };

    const makeFolder = () => {
      if (!isFolder.value) {
        emit("make-folder", props.item);
        isOpen.value = true;
      }
    };

    const onNew = (id: any) => {
      emit("new-tree", id);
      isOpen.value = true;
    };

    const isFolder = computed(() => {
      return props.item?.children && props.item.children.length;
    });

    const computedValue = computed({
      get(): boolean | string | number | (string | number)[] | any | undefined {
        return props.modelValue;
      },
      set(
        value: boolean | string | number | (string | number)[] | any | undefined
      ) {
        emit("update:modelValue", value);
      },
    });

    return {
      isOpen,
      isFolder,
      makeFolder,
      toggle,
      onNew,
      computedValue,
      values,
    };
  },
});
