/* eslint-disable */
export enum UserActionTypes {
  ALL_USER           = "AllUser",
  LIST_USER          = "ListUser",
  CREATE_USER        = "CreateUser",
  EDIT_USER          = "EditUser",
  UPDATE_USER        = "UpdateUser",
  DELETE_USER        = "DeleteUser",
  getPersonalData    = "USER_PROFILE",
  getDocumentData    = "GET_DOCUMENT_DATA",
  getAddressData     = "GET_ADDRESS_DATA",
  updatePersonalData = "UPDATE_PERSONAL_DATA",
  updateDocumentData = "UPDATE_DOCUMENT_DATA",
  updateAddressData  = "UPDATE_ADDRESS_DATA",
  updatePasswordData = "UPDATE_PASSWORD_DATA",
  getAddressByZipData= "GET_ADDRESS_BY_ZIP_DATA",
  updatePicture      = "UPDATE_USER_PICTURE",
}
