import { ContractTypeData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../..";
import { State } from "./state";

export type Getters = {
  getContractType(state: State): ContractTypeData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getContractType: (state) => state.contractTypeData,
};
