/* eslint-disable */
import { IssueStateData, IssueStateObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueStateMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueStateMutationTypes.SET_INSERT_ISSUE_STATE_DATA](
    state: S,
    payload: IssueStateObject
  ): void;
  [IssueStateMutationTypes.SET_ISSUE_STATE_DATA](
    state: S,
    payload: IssueStateData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueStateMutationTypes.SET_INSERT_ISSUE_STATE_DATA](
    state: State,
    issueState: IssueStateObject
  ) {
    state.data = issueState;
  },
  [IssueStateMutationTypes.SET_ISSUE_STATE_DATA](
    state: State,
    issueStateData: IssueStateData
  ) {
    state.issueStateData = issueStateData;
  },
};
