/* eslint-disable */
import { FinanceBankAccountData, FinanceBankAccountObject } from "@/@types";
import { MutationTree } from "vuex";
import { FinanceBankAccountMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [FinanceBankAccountMutationTypes.SET_INSERT_FINANCE_BANK_ACCOUNT_DATA](state: S, payload: FinanceBankAccountObject): void;
  [FinanceBankAccountMutationTypes.SET_FINANCE_BANK_ACCOUNT_DATA](state: S, payload: FinanceBankAccountData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [FinanceBankAccountMutationTypes.SET_INSERT_FINANCE_BANK_ACCOUNT_DATA](state: State, financeBankAccount: FinanceBankAccountObject) {
    state.financeBankAccount = financeBankAccount;
  },
  [FinanceBankAccountMutationTypes.SET_FINANCE_BANK_ACCOUNT_DATA](state: State, financeBankAccountData: FinanceBankAccountData) {
    state.financeBankAccountData = financeBankAccountData;
  },
};
