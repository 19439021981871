import { PeopleData, PeopleObject } from "@/@types";
import { MutationTree } from "vuex";
import { PeopleMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [PeopleMutationTypes.SET_INSERT_PEOPLE_DATA](
    state: S,
    payload: PeopleObject
  ): void;
  [PeopleMutationTypes.SET_PEOPLE_DATA](state: S, payload: PeopleData): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [PeopleMutationTypes.SET_INSERT_PEOPLE_DATA](
    state: State,
    people: PeopleObject
  ) {
    state.data = people;
  },
  [PeopleMutationTypes.SET_PEOPLE_DATA](state: State, peopleData: PeopleData) {
    state.peopleData = peopleData;
  },
};
