/* eslint-disable */
import { CostCenterObject } from "@/@types";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../..";
import { CostCenterActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [CostCenterActionTypes.TREE_COST_CENTER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [CostCenterActionTypes.CREATE_COST_CENTER]( { commit }: AugmentedActionContext, params: CostCenterObject ): Promise<boolean>;
  [CostCenterActionTypes.EDIT_COST_CENTER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
  [CostCenterActionTypes.UPDATE_COST_CENTER]( { commit }: AugmentedActionContext, params: CostCenterObject ): Promise<any>;
  [CostCenterActionTypes.DELETE_COST_CENTER]( { commit }: AugmentedActionContext, params: any ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CostCenterActionTypes.TREE_COST_CENTER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get("/finance/cost-center").then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CostCenterActionTypes.CREATE_COST_CENTER]({ commit }, params: CostCenterObject) {
    return new Promise<boolean>((resolve, reject) => {
      const payload: any = {
        parent     : params.parent,
        description: params.description,
        name       : params.name
      };
      //const dataForm = data;
      ApiService.json();
      ApiService.setHeader();
      ApiService.post("/finance/cost-center", payload).then(({ data }) => {
        resolve(true);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CostCenterActionTypes.EDIT_COST_CENTER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.get(`/finance/cost-center/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CostCenterActionTypes.UPDATE_COST_CENTER]({ commit }, params: CostCenterObject) {
    return new Promise<void>((resolve, reject) => {
      const payload :any = {
        parent     : params.parent,
        description: params.description,
        name       : params.name
      };
      ApiService.json();
      ApiService.setHeader();
      ApiService.put(`/finance/cost-center/${params.id}`, payload).then(({ data }) => {
        const result = data.data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },

  async [CostCenterActionTypes.DELETE_COST_CENTER]({ commit }, params: any) {
    return new Promise<void>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      ApiService.delete(`/finance/cost-center/${params.id}`).then(({ data }) => {
        const result = data;
        resolve(result);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  },
};
