import { IssuePriorityData, IssuePriorityObject } from "@/@types";

export type State = {
  data: IssuePriorityObject | null;
  issuePriorityData: IssuePriorityData;
};

export const state: State = {
  data: null,
  issuePriorityData: [],
};
