/* eslint-disable */
import { AccessLogData, AccessLogObject } from "@/@types/app/accesslog";
import { MutationTree } from "vuex";
import { AccessLogMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [AccessLogMutationTypes.SET_ACCESS_LOG_DATA](
    state: S,
    payload: AccessLogObject
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AccessLogMutationTypes.SET_ACCESS_LOG_DATA]( state: State, accessLogData: AccessLogData ) {
    state.accessLogData = accessLogData;
  },
};
