import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-209b34d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-tooltip" }
const _hoisted_2 = { class: "top-tooltip-text" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "content", {}, undefined, true),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content-text", {}, undefined, true)
    ])
  ]))
}