import { MenuData, MenuObject } from "@/@types";

export type State = {
  data: MenuObject | null;
  menuData: MenuData;
};

export const state: State = {
  data: null,
  menuData: [],
};
