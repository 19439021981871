/* eslint-disable */
export enum ProjectActionTypes {
  PROJECT_LIST           = "ProjectList",
  PROJECT_CREATE         = "ProjectCreate",
  PROJECT_EDIT           = "ProjectEdit",
  PROJECT_UPDATE         = "ProjectUpdate",
  PROJECT_DELETE         = "ProjectDelete",
  PROJECT_STATE          = "ProjectState",
  PROJECT_CLIENT         = "ProjectClient",
  PROJECT_CONTRACT       = "ProjectContract",
  PROJECT_CLIENT_LEADER  = "ProjectClientLeader",
  PROJECT_COMPANY_LEADER = "ProjectCompanyLeader",
  PROJECT_DETAIL         = "ProjectDetail",
  PROJECT_FILE_DOWNLOAD  = "ProjectFileDownload",
  PROJECT_FILE_DELETE    = "ProjectFileDelete",
}
