/* eslint-disable */
import { FinanceAccountData, FinanceAccountObject } from "@/@types";

export type State = {
  financeAccount: FinanceAccountObject | null;
  financeAccountData: FinanceAccountData;
};

export const state: State = {
  financeAccount: null,
  financeAccountData: [],
};
