<li>
    <div :class="{bold: isFolder}">
        <span v-if="isFolder" @click="toggle">[{{ isOpen ? '-' : '+' }}]</span>&nbsp;
        <i :class="icon">&nbsp;</i>
        {{ item.name }}
        <i class="fas fa-plus-circle blue" @click="$emit('add-item', item)" v-if="buttonAdd">&nbsp;</i>
        <i class="fas fa-edit orange" @click="$emit('edit-item', item)"  v-if="buttonEdit">&nbsp;</i>
        <i class="fas fa-trash-alt red" @click="$emit('remove-item', item)" v-if="buttonRemove"></i>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
        <tree-item 
            class="item"
            v-for="(child, index) in item.children"
            :key="index"
            :item="child"
            :icon="icon"
            :name="name"
            :button-add="buttonAdd"
            :button-edit="buttonEdit"
            :button-remove="buttonRemove"
            :show-input="showInput"
            @click="onNew(child.id)"
            @make-folder="$emit('make-folder', $event)"
            @add-item="$emit('add-item', $event)"
            @edit-item="$emit('edit-item', $event)"
            @remove-item="$emit('remove-item', $event)"
        ></tree-item>
    </ul>
</li>