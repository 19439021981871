/* eslint-disable */
import { AccessLogObject } from "@/@types/app/accesslog";
import ApiService from "@/services/ApiService";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../../..";
import { AccessLogActionTypes } from "./action-types";
import { Mutations } from "./mutations";
import { State } from "./state";
import { param } from "jquery";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [AccessLogActionTypes.LOG_LIST](
    { commit }: AugmentedActionContext,
    params: AccessLogObject
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AccessLogActionTypes.LOG_LIST]({ commit }, params: AccessLogObject) {
    return new Promise<boolean>((resolve, reject) => {
      ApiService.json();
      ApiService.setHeader();
      const payload: any = {
        user: params.user
      } 
      ApiService.post(`/system/access/log/${params.limit}/${params.offset}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
