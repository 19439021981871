import { PeopleData, PeopleObject } from "@/@types";

export type State = {
  data: PeopleObject | null;
  peopleData: PeopleData;
};

export const state: State = {
  data: null,
  peopleData: [],
};
