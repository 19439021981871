<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.profile") }}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                        <router-link to="/"
                            >{{ $t("labels.home") }}</router-link
                        >
                    </li>
                    <li class="breadcrumb-item active">
                        {{ $t("labels.userProfile") }}
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <!-- Profile Image -->
                <div class="card card-primary card-outline">
                    <div class="card-body box-profile">
                        <div class="text-center">
                            <app-image-input 
                                @removepicture="onRemovePicture"
                                :url="imageUrl"
                                @input="updatePicture"
                            ></app-image-input>
                        </div>

                        <h3 class="profile-username text-center">
                            {{user}}
                        </h3>

                        <p class="text-muted text-center">{{ role }}</p>

                        <!-- <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                                <b>Followers</b>
                                <a class="float-right">1,322</a>
                            </li>
                            <li class="list-group-item">
                                <b>Following</b> <a class="float-right">543</a>
                            </li>
                            <li class="list-group-item">
                                <b>Friends</b> <a class="float-right">13,287</a>
                            </li>
                        </ul>

                        <button class="btn btn-primary btn-block">
                            <b>Follow</b>
                        </button> -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->

                <!-- About Me Box -->
                <div class="card card-primary">
                    <!-- <div class="card-header">
                        <h3 class="card-title">About Me</h3>
                    </div> -->
                    <!-- /.card-header -->
                    <!-- <div class="card-body">
                        <strong
                            ><i class="fas fa-book mr-1"></i> Education</strong
                        >

                        <p class="text-muted">
                            B.S. in Computer Science from the University of
                            Tennessee at Knoxville
                        </p>

                        <hr />

                        <strong
                            ><i class="fas fa-map-marker-alt mr-1"></i>
                            Location</strong
                        >

                        <p class="text-muted">Malibu, California</p>

                        <hr />

                        <strong
                            ><i class="fas fa-pencil-alt mr-1"></i>
                            Skills</strong
                        >

                        <p class="text-muted">
                            <span class="tag tag-danger">UI Design</span>
                            <span class="tag tag-success">Coding</span>
                            <span class="tag tag-info">Javascript</span>
                            <span class="tag tag-warning">PHP</span>
                            <span class="tag tag-primary">Node.js</span>
                        </p>

                        <hr />

                        <strong
                            ><i class="far fa-file-alt mr-1"></i> Notes</strong
                        >

                        <p class="text-muted">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Etiam fermentum enim neque.
                        </p>
                    </div> -->
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
            <div class="col-md-9">
                <div class="card">
                    <div class="card-header p-2">
                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a
                                    class="nav-link rounded-0 pointer"
                                    :class="{'active' : activeTab === 'ACTIVITY'}"
                                    @click="setActiveTab('ACTIVITY')"
                                >
                                    DADOS PESSOAIS
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link rounded-0 pointer"
                                    :class="{'active' : activeTab === 'TIMELINE'}"
                                    @click="setActiveTab('TIMELINE')"
                                >
                                    DOCUMENTO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link rounded-0 pointer"
                                    :class="{'active' : activeTab === 'SETTINGS'}"
                                    @click="setActiveTab('SETTINGS')"
                                >
                                    ENDEREÇO
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    class="nav-link rounded-0 pointer"
                                    :class="{'active' : activeTab === 'PASSWORD'}"
                                    @click="setActiveTab('PASSWORD')"
                                >
                                    SENHA
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                        <div class="tab-content">
                            <div
                                class="tab-pane"
                                :class="{'active' : activeTab === 'ACTIVITY'}"
                            >
                                <form class="form-horizontal" @submit.prevent="updatePersonalData">
                                    <input-inline 
                                        label-for="firstName"
                                        label-class="col-sm-2 text-right"
                                        label="Nome: "
                                        v-model="pdv$.form.firstname.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="firstname"
                                        input-id="firstname"
                                        :has-error="pdv$.form.firstname.$error" 
                                        :is-active="!pdv$.form.firstname.$error"
                                        :error="pdv$.form.firstname.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="lastName"
                                        label-class="col-sm-2 text-right"
                                        label="Sobrenome: "
                                        v-model="pdv$.form.lastname.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="lastname"
                                        input-id="lastname"
                                        :has-error="pdv$.form.lastname.$error" 
                                        :is-active="!pdv$.form.lastname.$error"
                                        :error="pdv$.form.lastname.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="Phone"
                                        label-class="col-sm-2 text-right"
                                        label="Telefone: "
                                        v-model="pdv$.form.phone.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="phone"
                                        input-id="phone"
                                        maskara="['(##) ####-####', '(##) #####-####']"
                                        :has-error="pdv$.form.phone.$error" 
                                        :is-active="!pdv$.form.phone.$error"
                                        :error="pdv$.form.phone.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="email"
                                        label-class="col-sm-2 text-right"
                                        label="E-mail: "
                                        v-model="pdv$.form.email.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="email"
                                        input-id="email"
                                        :has-error="pdv$.form.email.$error" 
                                        :is-active="!pdv$.form.email.$error"
                                        :error="pdv$.form.email.$errors"
                                    ></input-inline>
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <app-button type="submit" class="btn btn-success rounded-0">
                                                <i class="fa fa-save"></i>
                                                Atualizar
                                            </app-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->
                            <div
                                class="tab-pane"
                                :class="{'active' : activeTab === 'TIMELINE'}"
                            >
                                <!-- The timeline -->
                                <form class="form-horizontal" @submit.prevent="updateDocumentData">
                                    <input-inline 
                                        label-for="document"
                                        label-class="col-sm-2 text-right"
                                        label="CPF: "
                                        v-model="dv$.form.document.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="cpf"
                                        input-id="cpf"
                                        maskara="###.###.###-##"
                                        :has-error="dv$.form.document.$error" 
                                        :is-active="!dv$.form.document.$error"
                                        :error="dv$.form.document.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="rg"
                                        label-class="col-sm-2 text-right"
                                        label="RG: "
                                        v-model="dv$.form.identity.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="rg"
                                        input-id="rg"
                                        :has-error="dv$.form.identity.$error" 
                                        :is-active="!dv$.form.identity.$error"
                                        :error="dv$.form.identity.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="birthdate"
                                        label-class="col-sm-2 text-right"
                                        label="Data de Nascimento: "
                                        v-model="dv$.form.birthdate.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="birthday"
                                        input-id="birthday"
                                        maskara="##/##/####"
                                        :has-error="dv$.form.birthdate.$error" 
                                        :is-active="!dv$.form.birthdate.$error"
                                        :error="dv$.form.birthdate.$errors"
                                    ></input-inline>
                                    
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <app-button type="submit" class="btn btn-success rounded-0">
                                                <i class="fa fa-save"></i>
                                                Atualizar
                                            </app-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->

                            <div
                                class="tab-pane"
                                :class="{'active' : activeTab === 'SETTINGS'}"
                            >
                                <form class="form-horizontal" @submit.prevent="updateAddressData">
                                    <input-inline 
                                        label-for="zipcode"
                                        label-class="col-sm-2 text-right"
                                        label="CEP: "
                                        v-model="av$.form.zipcode.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="zipcode"
                                        input-id="zipcode"
                                        maskara="#####-###"
                                        maxlength="9"
                                        :has-error="av$.form.zipcode.$error" 
                                        :is-active="!av$.form.zipcode.$error"
                                        :error="av$.form.zipcode.$errors"
                                        @keyup="getAddress"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="address"
                                        label-class="col-sm-2 text-right"
                                        label="Endereço: "
                                        v-model="av$.form.address.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="address"
                                        input-id="address"
                                        :has-error="av$.form.address.$error" 
                                        :is-active="!av$.form.address.$error"
                                        :error="av$.form.address.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="number"
                                        label-class="col-sm-2 text-right"
                                        label="Número: "
                                        v-model="av$.form.number.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="number"
                                        input-id="number"
                                        :has-error="av$.form.number.$error" 
                                        :is-active="!av$.form.number.$error"
                                        :error="av$.form.number.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="complement"
                                        label-class="col-sm-2 text-right"
                                        label="Complemento: "
                                        v-model="av$.form.complement.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="complement"
                                        input-id="complement"
                                        :has-error="av$.form.complement.$error" 
                                        :is-active="!av$.form.complement.$error"
                                        :error="av$.form.complement.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="district"
                                        label-class="col-sm-2 text-right"
                                        label="Bairro: "
                                        v-model="av$.form.district.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="district"
                                        input-id="district"
                                        :has-error="av$.form.district.$error" 
                                        :is-active="!av$.form.district.$error"
                                        :error="av$.form.district.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="city"
                                        label-class="col-sm-2 text-right"
                                        label="Cidade: "
                                        v-model="av$.form.city.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="city"
                                        input-id="city"
                                        :has-error="av$.form.city.$error" 
                                        :is-active="!av$.form.city.$error"
                                        :error="av$.form.city.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="state"
                                        label-class="col-sm-2 text-right"
                                        label="Estado: "
                                        v-model="av$.form.state.$model"
                                        div-class="col-sm-10"
                                        type="text"
                                        name="state"
                                        input-id="state"
                                        size="2"
                                        maxlength="2"
                                        :has-error="av$.form.state.$error" 
                                        :is-active="!av$.form.state.$error"
                                        :error="av$.form.state.$errors"
                                    ></input-inline>
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <app-button type="submit" class="btn btn-success rounded-0">
                                                <i class="fa fa-save"></i>
                                                Atualizar
                                            </app-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->

                            <div
                                class="tab-pane"
                                :class="{'active' : activeTab === 'PASSWORD'}"
                            >
                                <form class="form-horizontal" @submit.prevent="updatePasswordData">
                                    <input-inline 
                                        label-for="current-password"
                                        label-class="col-sm-2 text-right"
                                        label="Senha Atual: "
                                        v-model="pv$.form.current.$model"
                                        div-class="col-sm-10"
                                        type="password"
                                        name="currentpassword"
                                        input-id="current-password"
                                        :has-error="pv$.form.current.$error" 
                                        :is-active="!pv$.form.current.$error"
                                        :error="pv$.form.current.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="new-password"
                                        label-class="col-sm-2 text-right"
                                        label="Nova Senha: "
                                        v-model="pv$.form.newpassword.$model"
                                        div-class="col-sm-10"
                                        type="password"
                                        name="newpassword"
                                        input-id="new-password"
                                        :has-error="pv$.form.newpassword.$error" 
                                        :is-active="!pv$.form.newpassword.$error"
                                        :error="pv$.form.newpassword.$errors"
                                    ></input-inline>
                                    <input-inline 
                                        label-for="confirm-password"
                                        label-class="col-sm-2 text-right"
                                        label="Confirme a senha: "
                                        v-model="pv$.form.confirmpassword.$model"
                                        div-class="col-sm-10"
                                        type="password"
                                        name="confirmpassword"
                                        input-id="confirm-password"
                                        :has-error="pv$.form.confirmpassword.$error" 
                                        :is-active="!pv$.form.confirmpassword.$error"
                                        :error="pv$.form.confirmpassword.$errors"
                                    ></input-inline>
                                    <div class="form-group row">
                                        <div class="offset-sm-2 col-sm-10">
                                            <app-button type="submit" class="btn btn-success rounded-0">
                                                <i class="fa fa-save"></i>
                                                Atualizar
                                            </app-button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- /.tab-pane -->
                        </div>
                        <!-- /.tab-content -->
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>