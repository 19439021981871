/* eslint-disable */
import { SignInActionsTypes } from "@/store/auth/action-types";
import { Options, Vue } from "vue-class-component";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { useLoading } from "vue-loading-overlay";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ForgorPassword",
  setup(){
  let appElement: HTMLElement | null = null;
  const email = ref("");
  const os = ref("");
  const store = useStore();
  const browser = ref("");
  const toast = useToast();
  const route = useRouter();
  const $loading = useLoading({
    width: 64,
    height: 64,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999999,
  });

  onMounted(() => {
    appElement = document.getElementById("app") as HTMLElement;
    appElement.classList.add("login-page");
  });

  onUnmounted(() => {
    (appElement as HTMLElement).classList.remove("login-page");
  });

  //onst getUserLocation = async (): Promise<LocationData> => {
  // const position = await getCurrentPosition();
  // const { latitude, longitude } = position.coords;
  // const address = await reverseGeocode(latitude, longitude);
  // const { country, state, city } = address.components;
  // return { country, state, city };
  //
//
  //onst getCurrentPosition = (): Promise<GeolocationPosition> => {
  // return new Promise((resolve, reject) => {
  //   navigator.geolocation.getCurrentPosition(resolve, reject);
  // });
  //
//
  //onst reverseGeocode = async (latitude: number, longitude: number): Promise<any> => {
  // const apiKey = process.env.VUE_APP_GEOCODE_KEY;
  // const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;
  // const response = await fetch(url);
  // const data = await response.json();
  // return data.results[0];
  //

  const forgotPassword = async () => {
    const loader = $loading.show({
      loader: "dots",
    });

    const API_KEY = process.env.VUE_APP_GEOCODE_KEY;
    const API_URL = 'https://ipgeolocation.abstractapi.com/v1/?api_key=' + API_KEY;
    const apiResponse = await fetch(API_URL);
    const data = await apiResponse.json();
    const {city, country, region} = data;

    store.dispatch(SignInActionsTypes.passwordRecovery, {
      email: email.value,
      operating_system: getOs(),
      browser_name: getBrowser(),
      country: country,
      city: city,
      state: region
    }).then(() => {
      toast.success('Por favor verifique o seu e-mail pois foi enviado um link para reset de senha!');
      loader.hide();
      setTimeout(() => {
        route.replace({name: 'Login'});  
      }, 5000);
    }).catch(error => {
      toast.error(error.response.data.message);
      loader.hide();
      setTimeout(() => {
        route.replace({name: 'Login'});  
      }, 5000); 
    });
  }

  const getOs = () => {
    const userAgent = window.navigator.userAgent;
    let os = '';

    if (userAgent.indexOf('Win') !== -1) {
      os = 'Windows';
    } else if (userAgent.indexOf('Mac') !== -1) {
      os = 'MacOS';
    } else if (userAgent.indexOf('Linux') !== -1) {
      os = 'Linux';
    } else if (userAgent.indexOf('Android') !== -1) {
      os = 'Android';
    } else if (userAgent.indexOf('iOS') !== -1) {
      os = 'iOS';
    } else {
      os = 'Unknown';
    }

    // Update data
    os = os;
    return os;
  }

  const getBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = '';

    if (userAgent.indexOf('Firefox') > -1) {
      browser = 'Mozilla Firefox';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browser = 'Google Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      browser = 'Apple Safari';
    } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
      browser = 'Opera';
    } else if (userAgent.indexOf('Trident') > -1) {
      browser = 'Microsoft Internet Explorer';
    } else if (userAgent.indexOf('Edge') > -1) {
      browser = 'Microsoft Edge';
    } else if (userAgent.indexOf('SamsungBrowser') > -1) {
      browser = 'Samsung Internet';
    } else {
      browser = 'Unknown';
    }

    browser = browser;
    return browser;
  }

  return { email, forgotPassword }
},
});
