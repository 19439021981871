import { NicheData } from "@/@types";
import { GetterTree } from "vuex";
import { RootState } from "../../..";
import { State } from "./state";

export type Getters = {
  getNiche(state: State): NicheData;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getNiche: (state) => state.nicheData,
};
