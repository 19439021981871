<!-- Content Header (Page header) -->

<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.contract", 2) }} {{ loading }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <div class="container-fluid">
        <app-panel class="card-warning">
            <template v-slot:header>
                <h3 class="card-title">
                    Pesquisar Contratos
                </h3>
            </template>
            <template v-slot:content>
                <div class="row mb-3" id="cliente">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Cliente:
                        </label>
                    </div>
                    <div class="col-sm-5">
                        <Select2 v-model="filterRule.client" :options="clients" :settings="{ 
                                dropdownParent: '#cliente', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <input-inline 
                    label-for="contractNumber"
                    label="Nº" 
                    name="contractNumber"
                    type="text"
                    place-holder=""
                    v-model="filterRule.contractNumber"
                    input-id="contractNumber"
                    div-class="col-sm-2"
                    label-class="col-sm-2 text-right"
                ></input-inline>
                <div class="row mb-3" id="contractType">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Tipo de contrato:
                        </label>
                    </div>
                    <div class="col-sm-2">
                        <Select2 v-model="filterRule.contractType" :options="contractTypes" :settings="{ 
                                dropdownParent: '#contractType', 
                                width: '100%',
                                multiple: false,
                                allowClear: true
                            }" />
                    </div>
                </div>
                <div class="row mb-3" id="active">
                    <div class="col-sm-2 text-right">
                        <label for="Responsável" class="bills-to-pay-label-margin">
                            Ativo:
                        </label>
                    </div>
                    <div class="col mt-2">
                        <app-iradiobox v-for="item in activeItems" :value="item.value" name="active"
                            v-model="filterRule.active" :checked="filterRule.active === item.value">
                            {{ item.text }} &nbsp;
                        </app-iradiobox>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <app-button type="button" class="btn btn-default rounded-0 shadow" @click="onFilterContract">
                    <i class="fa fa-filter warning"></i>
                    Pesquisar
                </app-button>
                &nbsp;
                <a href="contract/create" type="button" class="btn btn-success rounded-0 shadow" id="open"
                    v-if="visibility('services.contracts.contract.insert')">
                    <i class="fa fa-plus-circle green"></i>
                    Novo
                </a>
            </template>
        </app-panel>

        <app-panel class="card-danger">
            <template v-slot:header>
                <h3 class="card-title">
                    Listagem de contratos
                </h3>
            </template>
            <template v-slot:content>
                <app-table :columns="gridColumns" :rows="rows" table-class="table table-striped">
                    <template v-slot:cell-id="{ row: contract }">
                        <a href="javascript:;" @click="onDeleteContract(contract.id)"
                            v-if="visibility('services.contracts.contract.delete')"><i
                                class="fas fa-trash-alt red"></i></a>
                        &nbsp;
                        <a href="javascript:;" @click="onContractChangeState(contract.id)"
                            v-if="visibility('services.contracts.contract.state')">
                            <i class="fa fa-power-off orange"></i>
                        </a>
                        &nbsp;
                        <a :href="`contract/${contract.id}`" v-if="visibility('services.contracts.contract.edit')">
                            <i class="far fa-edit blue"></i>
                        </a>
                    </template>
                    <template v-slot:cell-active="{ row: contract }">
                        <h5 v-if="contract.active === 'Y'"><span class="badge bg-success rounded-0">Sim</span></h5>
                        <h5 v-else="!contract.active === 'N'"><span class="badge bg-danger rounded-0">Não</span></h5>
                    </template>
                </app-table>
            </template>
            <template v-slot:footer>
                <app-pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                    @pagechanged="onPageChange"></app-pagination>
            </template>
        </app-panel>
    </div>
    <!-- /.card -->
</section>
<!-- /.content -->