/* eslint-disable */
import { IssueTaskData, IssueTaskObject } from "@/@types";
import { MutationTree } from "vuex";
import { IssueTaskMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [IssueTaskMutationTypes.SET_INSERT_ISSUE_TASK_DATA](
    state: S,
    payload: IssueTaskObject
  ): void;
  [IssueTaskMutationTypes.SET_ISSUE_TASK_DATA](
    state: S,
    payload: IssueTaskData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IssueTaskMutationTypes.SET_INSERT_ISSUE_TASK_DATA](
    state: State,
    issueTask: IssueTaskObject
  ) {
    state.data = issueTask;
  },
  [IssueTaskMutationTypes.SET_ISSUE_TASK_DATA](
    state: State,
    issueTaskData: IssueTaskData
  ) {
    state.issueTaskData = issueTaskData;
  },
};
