/* eslint-disable */
import inputDefault from "@/components/form/input-default/input-default.vue";
import inputInline from "@/components/form/input-inline/input-inline.vue";
import grid from "@/components/tables/grid/grid.vue";
import Paginate from "@/components/tables/paginate/paginate.vue";
import Breadcrumb from "@/components/ui/breadcrumb/breadcrumb.vue";
import Button from "@/components/ui/button/button.vue";
import RightCurtain from "@/components/ui/curtain/right-curtain/right-curtain.vue";
import Panel from "@/components/ui/panel/panel.vue";
import Iradiobox from "@/components/form/iradio/iradio.vue";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { isVisible } from "@/classes/is_visible";
import { ServiceActionTypes } from "@/store/services/service/action-types";
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useLoading } from "vue-loading-overlay";

export default defineComponent({
  name: "Service",
  components: {
    "app-breadcrumb": Breadcrumb,
    "app-panel": Panel,
    "app-pagination": Paginate,
    "app-table": grid,
    "app-right-curtain": RightCurtain,
    "app-button": Button,
    "app-input-default": inputDefault,
    "app-input-inline": inputInline,
    "app-iradiobox": Iradiobox,
  },
  setup() {
    const store = useStore();
    const $loading = useLoading({
      width: 64,
      height: 64,
      backgroundColor: '#ffffff',
      opacity: 0.5,
      zIndex: 999999,
    });
    const form: any = ref(null);
    const filterRule = ref({
      cnae: "",
      description: "",
      serviceType: "",
      active: ""
    });

    const formCreate = ref({
      form: {
        id: "",
        serviceType: "",
        cnae: "",
        description: "",
        price: "",
        active: "N"
      }
    });

    const rules = computed(() => {
      return {
        form:{
          serviceType: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          cnae: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          description: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          },
          price: { 
            required: helpers.withMessage("Este campo é obrigatório!", required) 
          }
        }
      }
    });

    const v$ = useVuelidate(rules, formCreate);

    const rows: any   = ref({});
    const currentPage = ref(1);
    const totalPages  = ref(1);
    const total       = ref(0);
    const perPage     = ref(10);
    const gridColumns = [
      {
        key: "id",
        text: "",
        thClass: "",
        thWidth: "5%",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "cnae",
        text: "CNAE",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "description",
        text: "Nome",
        thClass: "",
        thWidth: "",
        tdClass: "",
        tdWidth: "",
      },
      {
        key: "serviceType",
        text: "Tipo de Serviço",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      },
      {
        key: "price",
        text: "Valor/Hora",
        thClass: "text-right",
        thWidth: "",
        tdClass: "text-right",
        tdWidth: "",
      },
      {
        key: "active",
        text: "Ativo",
        thClass: "text-center",
        thWidth: "",
        tdClass: "text-center",
        tdWidth: "",
      }
    ];

    const serviceTypes = ref({});
    const serviceTypesForm = ref({});

    const radioGroupItems = ref([
      { text: "Sim", value: "Y" },
      { text: "Não", value: "N" },
    ]);

    const filterRadioGroupItems = ref([
      { text: "Sim", value: 'Y' },
      { text: "Não", value: 'N' },
      { text: "Ambos", value: "" },
    ]);

    const onSearchService = () => {
      onListService();
    };

    const onGetServiceType = async () => {
      try {
        await store.dispatch(ServiceActionTypes.SERVICE_GET_TYPE).then((data) => {
          serviceTypes.value = data;
          serviceTypesForm.value = data;
        });
      } catch (err: any) {
        return;
      }
    };

    const onPaginateService = () => {
      return;
    };

    const onPageChange = (page: number) => {
      currentPage.value = page;
      onListService();
    };

    const onServiceFormOpen = () => {
      formCreate.value.form.id = "";
      formCreate.value.form.description = "";
      form.value.openNav();
    };

    onMounted(() => {
      onListService();
      onGetServiceType();
    });

    const onListService = async () => {
      
      const params = {
        limit: perPage.value,
        offset: (currentPage.value - 1) * perPage.value,
        formData: {
          cnae: filterRule.value.cnae,
          description: filterRule.value.description,
          serviceType: filterRule.value.serviceType,
          active: filterRule.value.active
        }
      };

      const loader = $loading.show({
        loader: "dots",
      });

      await store.dispatch(ServiceActionTypes.SERVICE_LIST, params).then((data) => {
        rows.value = data.data;
        total.value = data.totalRecords;
        totalPages.value = data.totalPages;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
      
    };

    const onStoreService = () => {
      if (formCreate.value.form.id) {
        onUpdateService();
      } else {
        onCreateService();
      }
    };

    const onUpdateService = async () => {
      const result = await v$.value.$validate();
      if(!result){
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(ServiceActionTypes.SERVICE_UPDATE, formCreate.value.form).then(() => {
        formCreate.value.form.description = "";
        formCreate.value.form.cnae = "";
        formCreate.value.form.price = "";
        formCreate.value.form.active = "";
        formCreate.value.form.serviceType = "";
        formCreate.value.form.id = "";
        form.value.closeNav();
        onListService();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record Updated!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch((err) => {
        onListService();
        loader.hide();
        Swal.fire({
          title: "Bad job!",
          text: "Record not updated!",
          icon: "error",
          allowOutsideClick: false,
        });
      });
    };

    const onCreateService = async () => {
      
      const result = await v$.value.$validate();
      if(!result){
        return;
      };

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(ServiceActionTypes.SERVICE_CREATE, formCreate.value.form).then(() => {
        formCreate.value.form.description = "";
        formCreate.value.form.cnae = "";
        formCreate.value.form.price = "";
        formCreate.value.form.active = "N";
        formCreate.value.form.serviceType = "";
        form.value.closeNav();
        onListService();
        loader.hide();
        Swal.fire({
          title: "Good job!",
          text: "Record stored!",
          icon: "success",
          allowOutsideClick: false,
        });
      }).catch(() => {
        loader.hide();
      });
    };

    const onDeleteService = (id: number) => {
      
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(ServiceActionTypes.SERVICE_DELETE, { id: id }).then(() => {
              onListService();
              loader.hide();
              Swal.fire({
                title: "Deleted!",
                text: "Your record has been deleted.",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
            });
          }
        });
      
    };

    const onEditService = (id: number) => {
      formCreate.value.form.id = "";
      formCreate.value.form.description = "";
      formCreate.value.form.cnae = "";
      formCreate.value.form.price = "";
      formCreate.value.form.serviceType = "";
      formCreate.value.form.active = "";

      const loader = $loading.show({
        loader: "dots",
      });

      store.dispatch(ServiceActionTypes.SERVICE_EDIT, { id: id }).then((data) => {
        form.value.openNav();
        formCreate.value.form.cnae = data.cnae;
        formCreate.value.form.description = data.description;
        formCreate.value.form.price = data.price;
        formCreate.value.form.serviceType = data.serviceType;
        formCreate.value.form.active = data.active;
        formCreate.value.form.id = data.id;
        loader.hide();
      }).catch(() => {
        loader.hide();
      });
    };

    const visibility = (name: any) => {
      return isVisible(name);
    };

    const onServiceChangeStatus = (service: number) => {
      
        Swal.fire({
          title: "Are you sure?",
          text: "You can revert it after!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, change it!",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {

            const loader = $loading.show({
              loader: "dots",
            });

            store.dispatch(ServiceActionTypes.SERVICE_STATE, { id: service, }).then(() => {
              onListService();
              loader.hide();
              Swal.fire({
                title: "Status!",
                text: "Your record status has been changed.",
                icon: "success",
                allowOutsideClick: false,
              });
            }).catch(() => {
              loader.hide();
            });
          }
        });
      
    }

    const moneyConfig = computed(() => ({
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: false /* doesn't work with directive */,
      focusOnRight: true,
      minimumNumberOfCharacters: 0,
    }));

    function checkArray(variable: any) {
      return Array.isArray(variable);
    };

    return {
      filterRule,
      formCreate,
      gridColumns,
      rows,
      onSearchService,
      onCreateService,
      onPaginateService,
      currentPage,
      totalPages,
      total,
      perPage,
      onPageChange,
      onServiceFormOpen,
      v$,
      checkArray,
      onListService,
      onDeleteService,
      onEditService,
      onStoreService,
      form,
      visibility,
      moneyConfig,
      radioGroupItems,
      onGetServiceType,
      serviceTypes,
      filterRadioGroupItems,
      onServiceChangeStatus,
      serviceTypesForm
    };
  },
});
