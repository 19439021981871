<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>{{ $t("labels.contact") }}</h1>
            </div>
            <div class="col-sm-6">
                <app-breadcrumb class="float-sm-right"></app-breadcrumb>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <!-- Default box -->
    <!-- ******************* START SEARCH ************************* -->
    <app-panel class="card-warning">
        <template v-slot:header>
            <h3 class="card-title">
                Pesquisar contato
            </h3>
        </template>
        <template v-slot:content>
            <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label text-right">Empresa:</label>
                <div class="col-sm-6">
                    <!-- <input-dropdown :items="companies" @change="getContacts"></input-dropdown> -->
                    <Select2 
                        v-model="company" 
                        :options="companies"
                        :settings="{ allowClear: true }"
                        @change="changed()" 
                        @select="selected()" />
                </div>
              </div>
            
        </template>
    </app-panel>
    <!-- ******************* END SEARCH ************************* -->
    <!-- Default box -->
    <div class="card card-solid rounded-0">
        <div class="card-body pb-0">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-4 d-flex align-items-stretch flex-column" v-for="contact in contacts" :key="contact.id">
                    <div class="card rounded-0 bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                            {{ concatArray(contact.roles) }}
                        </div>
                        <div class="card-body pt-0">
                            <div class="row">
                                <div class="col-sm-7">
                                    <h2 class="lead"><b>{{ contact.fullname }}</b></h2>
                                    <p class="text-muted text-sm"><b>Sobre: </b> {{ contact.observation }} </p>
                                    <ul class="ml-4 mb-0 fa-ul text-muted">
                                        <li class="small">
                                            <span class="fa-li">
                                                <i class="fas fa-lg fa-envelope"></i>
                                            </span> 
                                            <strong>E-mail:</strong> {{ contact.email }}
                                        </li>
                                        <li class="small">
                                            <span class="fa-li">
                                                <i class="fas fa-lg fa-building"></i>
                                            </span> 
                                            <strong>Endereço:</strong> 
                                            <span v-if="contact.address">{{ contact.address }},</span> 
                                            <span v-if="contact.number">{{ contact.number }},</span> 
                                            <span v-if="contact.complement">{{ contact.complement }},</span> 
                                            <span v-if="contact.district">{{ contact.district }},</span> 
                                            <span v-if="contact.city">{{ contact.city }} - {{ contact.state }}</span>
                                        </li>
                                        <li class="small">
                                            <span class="fa-li">
                                                <i class="fas fa-lg fa-phone"></i>
                                            </span>
                                            <strong>Telefone #:</strong> {{ contact.phone }}
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-sm-5 text-center">
                                    <img :src="getImage(contact.id, contact.picture)" alt="user-avatar" class="img-circle img-fluid pic-avatar">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card-footer">
                            <div class="text-right">
                                <a href="#" class="btn btn-sm bg-teal">
                                    <i class="fas fa-comments"></i>
                                </a>
                                &nbsp;
                                <a href="#" class="btn btn-sm btn-primary">
                                    <i class="fas fa-user"></i> Perfil
                                </a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /.card-body -->
        <div class="card-footer rounded-0">
           <!--  <nav aria-label="Contacts Page Navigation">
                <ul class="pagination justify-content-center m-0">
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                    <li class="page-item"><a class="page-link" href="#">6</a></li>
                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                </ul>
            </nav> -->
        </div>
        <!-- /.card-footer -->
    </div>
    <!-- /.card -->
</section>