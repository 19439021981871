import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "ImageInput",
  props: {
    url: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const fileInput: any = ref(null);
    const imageData = ref();

    const chooseImage = () => {
      fileInput.value.click();
    };

    const onSelectFile = () => {
      const input = fileInput.value;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imageData.value = e.target?.result;
        };
        reader.readAsDataURL(files[0]);
        emit("input", files[0]);
      }
    };

    const onRemovePicture = () => {
      emit("removepicture");
    };

    watch(props, function (val: any) {
      imageData.value = val.url;
    });

    return {
      imageData,
      chooseImage,
      onSelectFile,
      fileInput,
      onRemovePicture,
    };
  },
});
