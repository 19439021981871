/* eslint-disable */
import { defineComponent } from "vue";

export default defineComponent({
    name: "Tile",
    props: {
        class: {
            type: String,
            required: false
        },
        fa: {
            type: String,
            required: false
        },
        content: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
    },
});
