/* eslint-disable */
import { NicheData, NicheObject } from "@/@types";
import { MutationTree } from "vuex";
import { NicheMutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
  [NicheMutationTypes.SET_INSERT_NICHE_DATA](
    state: S,
    payload: NicheObject
  ): void;
  [NicheMutationTypes.SET_NICHE_DATA](
    state: S,
    payload: NicheData
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [NicheMutationTypes.SET_INSERT_NICHE_DATA](
    state: State,
    niche: NicheObject
  ) {
    state.data = niche;
  },
  [NicheMutationTypes.SET_NICHE_DATA](
    state: State,
    nicheData: NicheData
  ) {
    state.nicheData = nicheData;
  },
};
