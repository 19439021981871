<div class="form-group row">
    <label :for="labelFor" class="col-form-label" :class="labelClass">
        {{ label }}
    </label>
    <div :class="divClass">
        <input 
            :type="type" 
            :name="name"
            :value="modelValue"
            @input="updateInput"
            class="form-control rounded-0" 
            :id="inputId" 
            :placeholder="placeHolder"
            :class="{'is-valid': isActive, 'is-invalid': hasError, [inputClass]: true}"
            :readonly="readonly"
            @keyup="onKeypress(modelValue)"
            @blur="onBlur(modelValue)"
            :maxlength="maxlength"
            :size="size"
            v-maska:mask 
            :data-maska="maskara"
        /> 
        <span v-if="checkArray(error)"> 
            <small 
                class="text-error form-text" 
                v-for="(errorMessage, index) of error" 
                :key="index"
            >
                {{ errorMessage.$message }}
            </small> 
        </span>
        <span v-if="!checkArray(error)">
            <small class="text-error form-text" v-if="!!error">{{ error }}</small> 
        </span>         
    </div>
</div>